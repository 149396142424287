import React, { useEffect, useState } from 'react'
import { Accordion, Container, Button } from "react-bootstrap";
import { ReactComponent as MessageChatCircle } from "../../assets/media/icons/message-chat-circle.svg";
import { ReactComponent as SupportIcon } from "../../assets/media/icons/support-icon.svg";
import { ReactComponent as ProVersion } from "../../assets/media/icons/pro-version.svg";
import APICall from '../../networking/AxiousServices';
import ApiTypes from '../../networking/APItypes';
import FAQLoader from '../../Component/Loaders/FAQLoader';
import LoadMoreLoader from '../../Component/Loaders/LoadMoreLoader';
import { useNavigate } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';

export default function SupportSection() {

    const navigate = useNavigate()
    function handleScroll(e) {
        e.preventDefault();
        window.scroll({
            top: document.body.offsetHeight,
            left: 0,
            behavior: 'smooth',
        });
    }

    const [list, setList] = useState([]);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(5);
    const [total, setTotal] = useState(0);

    const [fetchLoading, setFetchLoading] = useState(true);
    const [loadMore, setLoadMore] = useState(false);

    const fetchFaqList = async () => {

        let res = await APICall({
            url: ApiTypes.fetchFaqList,
            data: {
                page: page,
                limit: perPage,
            },
        });

        if (res.status === 1) {
            setTotal(res.data.total)
            setFetchLoading(false)
            return res.data.list
        } else {
            return []
        }
    }

    const fetchLoadMoreQues = async () => {
        let data = await fetchFaqList();
        setList([...list, ...data])
        setLoadMore(false)
    }

    const fetchQues = async () => {
        let data = await fetchFaqList();
        setList(data)

    }

    const handleLoadMore = async () => {
        setPage(page + 1);
    };
    useEffect(() => {
        if (page === 1) {
            fetchQues();
        }
    }, []);

    useEffect(() => {
        if (page !== 1) {
            setLoadMore(true)
            fetchLoadMoreQues();
        }
    }, [page]);


    return (<><div className="support-section">
        <Container>
            <div className="support-col">
                <div className="row">
                    <div className="col-lg-4 text-center mb-4 mb-lg-0">
                        <div className="support-icon">
                            <MessageChatCircle />
                        </div>
                        <div className="support-title mt-3 mb-2">
                            7 Day Free Trial
                        </div>
                        <div className="support-discription-area">
                            Discover all the pro version benefits for free for 7 days. Cancel anytime.
                        </div>
                    </div>
                    <div className="col-lg-4 text-center mb-4 mb-lg-0">
                        <div className="support-icon">
                            <ProVersion />
                        </div>
                        <div className="support-title mt-3 mb-2">
                            Pro Version Benefits
                        </div>
                        <div className="support-discription-area">
                            Details of each plan should be listed in each package.
                        </div>
                    </div>
                    <div className="col-lg-4 text-center">
                        <div className="support-icon">
                            <SupportIcon />
                        </div>
                        <div className="support-title mt-3 mb-2">Full Support</div>
                        <div className="support-discription-area">
                            For any questions, <span onClick={(e) => { handleScroll(e) }}>contact us.</span>
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    </div>
        <div className="faqs-section">
            <Container>
                <div className="faq-col">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="support-title">Support</div>
                            <div className="faq-title">FAQs</div>
                            <div className="faq-discription-area">
                                Everything you need to know about the product and billing.
                                Can’t find the answer you’re looking for? Please chat to our
                                team.
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="faq-accordion">
                                <Accordion defaultActiveKey="0" flush>

                                    {!fetchLoading ? list.map((itm, key) =>
                                    (<Accordion.Item key={key} eventKey={key}>
                                        <Accordion.Header>
                                        {ReactHtmlParser(itm.question)}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        {ReactHtmlParser(itm.answer)}
                                        </Accordion.Body>
                                    </Accordion.Item>)
                                    ) :<FAQLoader /> }
                                    {/* <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            Is there a free trial available?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            Yes, The free trial lasts for 7 days.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>
                                            Can I change my plan later?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            Yes, you can try us for free for 7 days. If you want,
                                            we’ll provide you with a free, personalized 30-minute
                                            onboarding call to get you up and running as soon as
                                            possible.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>
                                            What is your cancellation policy?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            Yes, you can try us for free for 7 days. If you want,
                                            we’ll provide you with a free, personalized 30-minute
                                            onboarding call to get you up and running as soon as
                                            possible.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header>
                                            Can other info be added to an invoice?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            Yes, you can try us for free for 7 days. If you want,
                                            we’ll provide you with a free, personalized 30-minute
                                            onboarding call to get you up and running as soon as
                                            possible.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="4">
                                        <Accordion.Header>
                                            How does billing work?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            Yes, you can try us for free for 7 days. If you want,
                                            we’ll provide you with a free, personalized 30-minute
                                            onboarding call to get you up and running as soon as
                                            possible.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="5">
                                        <Accordion.Header>
                                            How do I change my account email?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            Yes, you can try us for free for 7 days. If you want,
                                            we’ll provide you with a free, personalized 30-minute
                                            onboarding call to get you up and running as soon as
                                            possible.
                                        </Accordion.Body>
                                    </Accordion.Item> */}
                                </Accordion>
                                {list.length < total ? (
                                    <div className="row">
                                        <div className="col-12 text-center mt-5">
                                            {!loadMore ? (
                                                <Button
                                                    onClick={() => {
                                                        navigate('/faq')
                                                        // handleLoadMore();
                                                    }}
                                                    variant="primary"
                                                    type="button"
                                                >
                                                    View More
                                                </Button>
                                            ) : (
                                                <LoadMoreLoader />
                                            )}
                                        </div>
                                    </div>
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    </>)
}
