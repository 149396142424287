import React, { useEffect, useRef, useState } from "react";
import { Accordion, Form, InputGroup, NavDropdown } from "react-bootstrap";
import Select from "react-select";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import Row from "react-bootstrap/Row";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as UploadIcon } from "../../assets/media/icons/upload-icon.svg";
import ProfileImg from "../../assets/media/img/profile-img.png";
import MbaseLogo from "../../assets/media/logos/m-base-logo.svg";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import TimezoneSelect from "react-timezone-select";
import Us from "../../assets/media/img/US.png";
import ProgressBar from "react-bootstrap/ProgressBar";
import { ReactComponent as Remove } from "../../assets/media/icons/remove.svg";
import SiteLoader from "../../Component/Loaders/SiteLoader";
import APICall from "../../networking/AxiousServices";
import ApiTypes from "../../networking/APItypes";
import ClientDefault from "../../assets/Images/Defaults/client_default.webp";
import { getBase64 } from "../../common/imageHelper";
import { useDispatch, useSelector } from "react-redux";
import LoadMoreLoader from "../../Component/Loaders/LoadMoreLoader";
import WorkExperience from "./WorkExperience";
import ProfileMenuHeader from "../../Component/ProfileMenuHeader/ProfileMenuHeader";
import { Enums } from "../../constants/Enums";
import PreviewImg from "../../Component/Image/PreviewImg";
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { commonHelper } from "../../common/commonHelper";

function Profile() {
  document.title = process.env.REACT_APP_NAME + " | Profile";
  const UserData = useSelector((state) => state.data);

  const [loading, setLoading] = useState(true);

  const [redirectError, setRedirectError] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);

  const [preProfileDetails, setPreProfileDetails] = useState(null);
  const profileRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [resumeName, setResumeName] = useState(null);
  const resumeRef = useRef(null);
  const [cities, setCities] = useState([]);

  const [formInputs, setFormInputs] = useState({
    first_name: "",
    last_name: "",
    client_email: "",
    client_phone: "",
    profile_img: "",
    website: "",
    linkdin_profile: "",
    main_job_title: "",
    mini_bio: "",
    resume: "",
    address1: "",
    city: "",
    state: "",
    country: "",
    postal_code: "",
  });

  const [personalFormInputsEr, setPersonalFormInputsEr] = useState({
    first_name: "",
    last_name: "",
    profile_img: "",
    resume: "",
  });
  const personalValidationError = {
    first_name: "First name is required.",
    last_name: "Last name is required.",
    client_phone: "Phone number is required.",
  };
  const fetchMyProfile = async () => {
    setLoading(true);
    let res = await APICall({
      url: ApiTypes.getEditProfileDetails,
    });
    if (res.status === 1) {
      setLoading(false);
      setPreProfileDetails(res.data);
      let dataRes = res.data;
      let userDataRes = res.user_data;
      dispatch({
        type: "LOGGED_USER",
        payload: {
          ...UserData.logged_user,
          ...userDataRes,
        },
      });
      commonHelper.setItem(
        "user",
        JSON.stringify({
          ...UserData.logged_user,
          ...userDataRes,
        })
      );
      setFormInputs({
        ...formInputs,
        first_name: dataRes.client_first_name ? dataRes.client_first_name : "",
        last_name: dataRes.client_last_name ? dataRes.client_last_name : "",
        client_email: dataRes.client_email ? dataRes.client_email : "",
        client_phone: dataRes.client_phone ? dataRes.client_phone : "",
        website: dataRes.website ? dataRes.website : "",
        linkdin_profile: dataRes.linkdin_profile ? dataRes.linkdin_profile : "",
        main_job_title: dataRes.main_job_title ? dataRes.main_job_title : "",
        mini_bio: dataRes.mini_bio ? dataRes.mini_bio : "",
        address1: dataRes.address1 ? dataRes.address1 : "",
        city: dataRes.city ? dataRes.city : "",
        state: dataRes.state ? dataRes.state : "",
        country: dataRes.country ? dataRes.country : "",
        postal_code: dataRes.postal_code ? dataRes.postal_code : "",
        location_id: dataRes.location_id ? dataRes.location_id : "",
      });
      if (dataRes.resume) {
        setResumeName(dataRes.resume.split("/").pop());
      }
    }
  };

  const handleSetPrevious = () => {
    if (preProfileDetails) {
      setFormInputs({
        first_name: preProfileDetails.client_first_name
          ? preProfileDetails.client_first_name
          : "",
        last_name: preProfileDetails.client_last_name
          ? preProfileDetails.client_last_name
          : "",
        client_email: preProfileDetails.client_email
          ? preProfileDetails.client_email
          : "",
        client_phone: preProfileDetails.client_phone
          ? preProfileDetails.client_phone
          : "",
        website: preProfileDetails.website ? preProfileDetails.website : "",
        linkdin_profile: preProfileDetails.linkdin_profile
          ? preProfileDetails.linkdin_profile
          : "",
        main_job_title: preProfileDetails.main_job_title
          ? preProfileDetails.main_job_title
          : "",
        mini_bio: preProfileDetails.mini_bio ? preProfileDetails.mini_bio : "",
        address1: preProfileDetails
          ? preProfileDetails.address1
            ? preProfileDetails.address1
            : ""
          : "",
        city: preProfileDetails.city ? preProfileDetails.city : "",
        state: preProfileDetails.state ? preProfileDetails.state : "",
        country: preProfileDetails.country ? preProfileDetails.country : "",
        postal_code: preProfileDetails.postal_code
          ? preProfileDetails.postal_code
          : "",
        profile_img: "",
        resume: "",
      });
    }
    if (preProfileDetails.resume) {
      setResumeName(preProfileDetails.resume.split("/").pop());
    } else {
      setResumeName(null);
    }

    resumeRef.current.value = null;
    profileRef.current.value = null;
  };

  const handleSubmit = async (e) => {
    setRedirectError("");
    e.preventDefault();
    if (validatePersonalInfo()) {
      setSubmitLoading(true);
      let res = await APICall({
        url: ApiTypes.updateProfile,
        data: {
          ...formInputs,
          resume_name: resumeName,
          is_resume_delete: resumeName ? false : true,
          locations: [
            {
              address1: formInputs.address1,
              city: formInputs.city,
              state: formInputs.state,
              postal_code: formInputs.postal_code,
              country: formInputs.country,
            },
          ],
        },
      });
      if (res.status === 1) {
        fetchMyProfile();
        setSubmitLoading(false);
        Swal.fire({
          title: 'Success!',
          text: 'Your profile has been updated successfully.',
          icon: 'success',
          confirmButtonText: 'Okay'
        })
      } else {
        if (res.data.status === 0) {
          setSubmitLoading(false);
          setRedirectError(res.data.message);
        }
      }
    }
  };

  const validatePersonalInfo = () => {
    let errorJson = {
      first_name: "",
      last_name: "",
      client_phone: "",
    };
    let isValidate = true;

    Object.keys(errorJson).forEach((form_er_key) => {
      if (formInputs[form_er_key] === "") {
        errorJson = {
          ...errorJson,
          [form_er_key]: personalValidationError[form_er_key],
        };
        isValidate = false;
      }
    });

    setPersonalFormInputsEr(errorJson);
    return isValidate;
  };

  const handleDeleteResume = (e) => {
    setResumeName(null);
    resumeRef.current.value = null;
  };

  const handleDocFile = (file) => {
    if (file.name.match(/\.(doc|docx|pdf)$/)) {
      if (parseInt((file.size / 1000000).toFixed(2)) <= 5) {
        setPersonalFormInputsEr({
          ...personalFormInputsEr,
          resume: "",
        });
        setResumeName(file.name);
        getBase64(file)
          .then((result) => {
            setFormInputs({
              ...formInputs,
              resume: result,
            });
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        setPersonalFormInputsEr({
          ...personalFormInputsEr,
          resume: "Document should be less than 5 MB.",
        });
      }
    } else {
      setPersonalFormInputsEr({
        ...personalFormInputsEr,
        resume: "Only doc, docx or pdf files are supported.",
      });
    }
  };

  const handleDocInput = (e) => {
    handleDocFile(e.target.files[0]);
  };

  const handleDocDrop = (e) => {
    e.preventDefault();
    handleDocFile(e.dataTransfer.files[0]);
  };
  const handleProfileFile = (file) => {
    if (file.name.match(/\.(jpg|jpeg|png|svg|webp|JPEG|JPG|PNG|SVG|WEBP)$/)) {
      if (parseInt((file.size / 1000000).toFixed(2)) <= 5) {
        setPersonalFormInputsEr({
          ...personalFormInputsEr,
          profile_img: "",
        });
        getBase64(file)
          .then((result) => {
            setFormInputs({
              ...formInputs,
              profile_img: result,
            });
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        setPersonalFormInputsEr({
          ...personalFormInputsEr,
          profile_img: "Image should be less than 5 MB.",
        });
      }
    } else {
      setPersonalFormInputsEr({
        ...personalFormInputsEr,
        profile_img: "Only jpg, jpeg, png, svg or webp files are supported.",
      });
    }
  };
  const handleProfileInput = (e) => {
    handleProfileFile(e.target.files[0]);
  };

  const handleProfileDrop = (e) => {
    e.preventDefault();
    handleProfileFile(e.dataTransfer.files[0]);
  };

  const fetchCities = async () => {
    let resCities = await APICall({
      url: ApiTypes.getCityList,
      data: {
        search: formInputs.city,
      },
    });
    if (resCities.status === 1) {
      setCities(resCities.data);
    }
  };
  useEffect(() => {
    if (formInputs.city.length > 2) {
      fetchCities();
    }
  }, [formInputs.city]);

  const handleLogout = () => {
    let remember_data = commonHelper.getItem("remember_data") ? JSON.parse(commonHelper.getItem("remember_data")) : null
    localStorage.clear();
    commonHelper.setItem("remember_data", JSON.stringify(remember_data));
    dispatch({ type: "AUTH_TOKEN", payload: null });
    dispatch({ type: "LOGGED_USER", payload: Enums.constant_logged_user });
    navigate("/");
  };

  useEffect(() => {
    fetchMyProfile();
  }, []);

  return !loading ? (
    <>
      <div className="main-content">
        <ProfileMenuHeader />
        <div className="account-profile-section">
          <Container>
            <div className="account-profile-col">
              <div className="profile-top-col mt-5">
                <div className="profile-top-section">
                  <div className="profile-title">Personal info</div>
                  <div className="profile-discription">
                    Update your photo and personal details here.
                  </div>
                </div>
                {redirectError !== "" && (
                  <div className="error-div">
                    <div>{redirectError}</div>
                    <div>
                      <i
                        onClick={() => {
                          setRedirectError("");
                        }}
                        className="fa fa-close cursor-pointer"
                      ></i>
                    </div>
                  </div>
                )}
                <div className="form-profile mt-4">
                  <Form className="form pb-2 pt-3 pe-4 ps-4">
                    <Row>
                      <Col col={6}>
                        <Form.Group className="mb-3" controlId="formGroupEmail">
                          <Form.Label>First name <span className="required d-inline-block">*</span></Form.Label>
                          <Form.Control
                            type="text"
                            onChange={(e) => {
                              setFormInputs({
                                ...formInputs,
                                first_name: e.target.value
                                  ? e.target.value
                                  : "",
                              });
                            }}
                            value={formInputs.first_name}
                            placeholder="First name"
                          />

                          {personalFormInputsEr.first_name !== "" && (
                            <Form.Text className="text-danger">
                              {/* Must be at least 8 characters. */}
                              {personalFormInputsEr.first_name}
                            </Form.Text>
                          )}
                        </Form.Group>
                      </Col>
                      <Col col={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>Last name <span className="required d-inline-block">*</span></Form.Label>
                          <Form.Control
                            type="text"
                            onChange={(e) => {
                              setFormInputs({
                                ...formInputs,
                                last_name: e.target.value ? e.target.value : "",
                              });
                            }}
                            value={formInputs.last_name}
                            placeholder="Last name"
                          />

                          {personalFormInputsEr.last_name !== "" && (
                            <Form.Text className="text-danger">
                              {/* Must be at least 8 characters. */}
                              {personalFormInputsEr.last_name}
                            </Form.Text>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                    {formInputs.client_email !== "" && <Row>
                      <Col col={6}>
                        <Form.Group className="mb-4 email-address">
                          <Form.Label>Email address</Form.Label>
                          <Form.Control
                            type="email"
                            disabled={true}
                            value={formInputs.client_email}
                            placeholder="Enter email address."
                          />
                          <span className="gmail-icon">
                            <i className="fa-regular fa-envelope"></i>
                          </span>
                        </Form.Group>
                      </Col>
                    </Row>}
                    <Row>
                      <Col col={6}>
                        <Form.Group className="mb-4 email-address">
                          <Form.Label>Phone <span className="required d-inline-block">*</span></Form.Label>
                          <Form.Control
                            type="text"
                            onChange={(e) => {
                              setFormInputs({
                                ...formInputs,
                                client_phone: e.target.value ? e.target.value : "",
                              });
                            }}
                            value={formInputs.client_phone ? formInputs.client_phone : ''}
                            placeholder="Enter phone number"
                          />
                          <span className="gmail-icon">
                            <i className="fa-regular fa-phone"></i>
                          </span>
                          {personalFormInputsEr.client_phone !== "" && (
                            <Form.Text className="text-danger">
                              {/* Must be at least 8 characters. */}
                              {personalFormInputsEr.client_phone}
                            </Form.Text>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                    <div className="row mb-3">
                      <div className="col-sm-12 col-md-2 mb-3 mb-md-0">
                        <div className="profile-section">
                          <PreviewImg
                            src={
                              formInputs.profile_img !== ""
                                ? formInputs.profile_img
                                : preProfileDetails.profile_img
                                  ? process.env.REACT_APP_ASSETS_URL +
                                  preProfileDetails.profile_img
                                  : ClientDefault
                            }
                            isDefault={
                              formInputs.profile_img !== ""
                                ? true
                                : preProfileDetails.profile_img
                                  ? false
                                  : true
                            }
                            alt="ProfileImg"
                            width={64}
                            height={64}
                            defaultImg={
                              formInputs.profile_img !== ""
                                ? formInputs.profile_img
                                : ClientDefault
                            }
                            className="border-common rounded-circle "
                          />
                          {/* <img
                                                    src={
                                                        formInputs.profile_img !== ""
                                                            ?
                                                            formInputs.profile_img
                                                            :
                                                            preProfileDetails.profile_img ? process.env.REACT_APP_ASSETS_URL + preProfileDetails.profile_img : ClientDefault

                                                    }
                                                    alt="ProfileImg"
                                                    width="64"
                                                    height="64"
                                                    className="border-common rounded-circle obj-fit-cover"
                                                /> */}
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-10">
                        <div
                          draggable={true}
                          onDrop={(e) => handleProfileDrop(e)}
                          className="draggable fileUploadWrap text-center"
                        >
                          <input
                            type="file"
                            name="fileToUpload"
                            accept="image/*"
                            ref={profileRef}
                            onChange={(e) => handleProfileInput(e)}
                            className="w-100 h-100"
                          />
                          <span className="upload-icon mx-auto rounded-circle d-flex align-items-center justify-content-center">
                            <UploadIcon />
                          </span>
                          <div className="fileupload-content mt-2">
                            <span className="me-1 cornflower-blue d-inline-block">
                              Click to upload profile pic
                            </span>
                            or drag and drop
                            <span className="d-block">
                              SVG, PNG, JPG or GIF (max. 800x400px)
                            </span>
                          </div>
                        </div>
                      </div>
                      {personalFormInputsEr.profile_img !== "" && (
                        <Form.Text className="text-danger">
                          {/* Must be at least 8 characters. */}
                          {personalFormInputsEr.profile_img}
                        </Form.Text>
                      )}
                    </div>
                    <Col col={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Street address</Form.Label>
                        <Form.Control
                          onChange={(e) => {
                            setFormInputs({
                              ...formInputs,
                              address1: e.target.value ? e.target.value : "",
                            });
                          }}
                          type="text"
                          value={formInputs.address1}
                          placeholder="Street address"
                        />
                      </Form.Group>
                    </Col>

                    <Col col={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>City</Form.Label>
                        <Form.Control
                          list="cities-list"
                          onInput={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                          }}
                          value={formInputs.city}
                          onChange={(e) => {
                            // let cityDataList = document.getElementById('cities-list');
                            let state_name = formInputs.state;
                            let country_name = formInputs.country;
                            if (e.target.value) {
                              let optionTag = document.getElementsByName(
                                e.target.value
                              );
                              if (optionTag.length) {
                                state_name =
                                  optionTag[0].getAttribute("data-state-name");
                                country_name =
                                  optionTag[0].getAttribute(
                                    "data-country-name"
                                  );
                              }
                            }
                            setFormInputs({
                              ...formInputs,
                              city: e.target.value ? e.target.value : "",
                              state: state_name,
                              country: country_name,
                            });
                          }}
                          type="text"
                          placeholder="City"
                        />
                        <datalist onChange={(e) => { }} id="cities-list">
                          {cities.map((city, index) => (
                            <option
                              key={index}
                              name={city.city_name}
                              data-state-name={city.state_name}
                              data-country-name={city.country_name}
                              value={city.city_name}
                            >
                              {city.city_name}
                            </option>
                          ))}
                        </datalist>
                      </Form.Group>
                    </Col>

                    <Col col={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>State / Province</Form.Label>
                        <Form.Control
                          onChange={(e) => {
                            setFormInputs({
                              ...formInputs,
                              state: e.target.value ? e.target.value : "",
                            });
                          }}
                          type="text"
                          value={formInputs.state}
                          placeholder="State / Province"
                        />
                      </Form.Group>
                    </Col>
                    <Col col={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Postcode</Form.Label>
                        <Form.Control
                        maxlength="10"
                          type="text"
                          onChange={(e) => {
                            setFormInputs({
                              ...formInputs,
                              postal_code:
                                e.target.value
                                  ? e.target.value
                                  : "",
                            });
                          }}
                          value={formInputs.postal_code}
                          placeholder="Postcode"
                        />
                      </Form.Group>
                    </Col>
                    <Col col={6} className="mb-3">
                      <Form.Group>
                        <Form.Label>Country</Form.Label>
                        <Form.Control
                          type="text"
                          onChange={(e) => {
                            setFormInputs({
                              ...formInputs,
                              country: e.target.value ? e.target.value : "",
                            });
                          }}
                          value={formInputs.country}
                          placeholder="Country"
                        />
                      </Form.Group>
                    </Col>
                  </Form>
                  {/* <div className="form-button pb-3 pt-3 mt-3 pe-4 ps-4 text-end">
                  <Button className="transparent-btn me-3">Cancel</Button>
                  <Button variant="primary" onClick={()=>{
                    handleSubmitPersonalInfo()
                  }} type="button">
                    Save changes
                  </Button>
                </div> */}
                </div>
              </div>
              <div className="profile-top-col mt-4">
                <div className="profile-top-section">
                  <div className="profile-title">Professional info</div>
                  <div className="profile-discription">
                    Update your photo and personal details here.
                  </div>
                </div>
                <div className="form-profile mt-4">
                  <Form className="form pb-2 pt-3 pe-4 ps-4">
                    {/* <Row>
                    <Col col={12} className="mb-3">
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="flexSwitchCheckChecked"
                          checked
                        />
                        <label
                          className="form-check-label"
                          for="flexSwitchCheckChecked"
                        >
                          Available for projects
                        </label>
                        <label className="check d-block ps-0">
                          I’m open and available for creative work.
                        </label>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col col={12} className="mb-3">
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="flexSwitchCheckChecked"
                          checked
                        />
                        <label
                          className="form-check-label"
                          for="flexSwitchCheckChecked"
                        >
                          Allow to be tracked
                        </label>
                        <label className="check d-block ps-0">
                          Allow members to track me.
                        </label>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col col={12} className="mb-3">
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="flexSwitchCheckChecked"
                        />
                        <label
                          className="form-check-label"
                          for="flexSwitchCheckChecked"
                        >
                          Allow to be contacted
                        </label>
                        <label className="check d-block ps-0">
                          Allow members to contact me directly within the app.
                        </label>
                      </div>
                    </Col>
                  </Row> */}
                    <Row>
                      <Col col={12}>
                        <Form.Label htmlFor="basic-url">Website</Form.Label>
                        <InputGroup className="mb-3">
                          <InputGroup.Text id="basic-addon3">
                            http://
                          </InputGroup.Text>
                          <Form.Control
                            onChange={(e) => {
                              setFormInputs({
                                ...formInputs,
                                website: e.target.value ? e.target.value : "",
                              });
                            }}
                            value={formInputs.website}
                            id="basic-url"
                            aria-describedby="basic-addon3"
                          />
                        </InputGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col col={12}>
                        <Form.Label htmlFor="basic-url">
                          LinkedIn Profile
                        </Form.Label>
                        <InputGroup className="mb-3">
                          <InputGroup.Text id="basic-addon3">
                            http://
                          </InputGroup.Text>
                          <Form.Control
                            onChange={(e) => {
                              setFormInputs({
                                ...formInputs,
                                linkdin_profile: e.target.value
                                  ? e.target.value
                                  : "",
                              });
                            }}
                            value={formInputs.linkdin_profile}
                            id="basic-url"
                            aria-describedby="basic-addon3"
                          />
                        </InputGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col col={12}>
                        <Form.Label htmlFor="basic-url">
                          Main Job Title
                        </Form.Label>
                        <InputGroup className="mb-3">
                          <Form.Control
                            id="basic-url"
                            onChange={(e) => {
                              setFormInputs({
                                ...formInputs,
                                main_job_title: e.target.value
                                  ? e.target.value
                                  : "",
                              });
                            }}
                            value={formInputs.main_job_title}
                            aria-describedby="basic-addon3"
                          />
                        </InputGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col col={12} className="mb-3">
                        <label className="form-label">About Me</label>
                        <CKEditor
                          editor={ClassicEditor}
                          data={formInputs.mini_bio}
                          onReady={(editor) => {
                            // You can store the "editor" and use when it is needed.
                          }}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            // console.log({ event, editor, data });
                            setFormInputs({
                              ...formInputs,
                              mini_bio: data,
                            });
                          }}
                          onBlur={(event, editor) => { }}
                          onFocus={(event, editor) => { }}
                        />
                        {/* <sapn className="character">275 characters left</sapn> */}
                      </Col>
                    </Row>
                    <div className="row ">
                      <div className="col-12 mb-4">
                        <label className="form-label">Upload Resume</label>
                        <input
                          type="file"
                          ref={resumeRef}
                          name="fileToUpload"
                          accept=".doc, .docx ,.pdf"
                          onChange={(e) => handleDocInput(e)}
                          className="w-100 h-100 d-none"
                        />
                        {!resumeName && (
                          <div
                            draggable={true}
                            onDrop={(e) => handleDocDrop(e)}
                            onClick={() => {
                              resumeRef.current.click();
                            }}
                            className="fileUploadWrap cursor-pointer text-center"
                          >
                            <div className="fileupload-col">
                              <span className="upload-icon mx-auto rounded-circle d-flex align-items-center justify-content-center">
                                <UploadIcon />
                              </span>
                              <div className="fileupload-content mt-2">
                                <span className="me-1 cornflower-blue d-inline-block">
                                  Click to upload resume
                                </span>
                                or drag and drop
                                <span className="d-block">
                                  doc, docx, or pdf
                                </span>
                              </div>
                            </div>
                          </div>
                        )}
                        {personalFormInputsEr.resume !== "" && (
                          <Form.Text className="text-danger">
                            {/* Must be at least 8 characters. */}
                            {personalFormInputsEr.resume}
                          </Form.Text>
                        )}
                        {resumeName && (
                          <div className="fileupload-process">
                            <div className="fileupload-text d-flex">
                              <div className="fileupload-left-icon me-3 d-flex align-items-center justify-content-center">
                                <i className="fa-light fa-file"></i>
                              </div>
                              <div className="fileupload-top-col w-100 d-flex align-items-center">
                                <div className="d-flex justify-content-between w-100">
                                  <div className="fileupload-top">
                                    <div
                                      onClick={async () => {
                                        try {
                                          let link;
                                          if (formInputs.resume !== "") {
                                            link = formInputs.resume;
                                          } else {
                                            link = await commonHelper.getAssetUrl(process.env.REACT_APP_ASSETS_URL + preProfileDetails.resume);
                                          }
                                          if (link) {
                                            let pdfWindow = window.open("");
                                            pdfWindow.document.write(
                                              `<iframe width='100%' height='100%' src=${link}></iframe>`
                                            );
                                          } else {
                                            let aTag =
                                              document.createElement("a");
                                            aTag.setAttribute("href", link);
                                            aTag.setAttribute("target", "_blank");
                                            aTag.click();
                                          }

                                        } catch (error) {
                                          console.log(error);
                                        }
                                        // let link;
                                        // if (formInputs.resume !== "") {
                                        //   link = formInputs.resume;
                                        // } else {
                                        //   link =
                                        //     process.env.REACT_APP_ASSETS_URL +
                                        //     preProfileDetails.resume;
                                        // }
                                        // if (
                                        //   resumeName.split(".").pop() === "pdf"
                                        // ) {
                                        //   let pdfWindow = window.open("");
                                        //   pdfWindow.document.write(
                                        //     `<iframe width='100%' height='100%' src=${link}></iframe>`
                                        //   );
                                        // } else {
                                        //   let aTag =
                                        //     document.createElement("a");
                                        //   aTag.setAttribute("href", link);
                                        //   aTag.setAttribute("target", "_blank");
                                        //   aTag.click();
                                        // }
                                      }}
                                      className="cursor-pointer  file-name"
                                    >
                                      {resumeName}
                                    </div>
                                    {/* <div className="file-size">200 KB</div> */}
                                  </div>
                                  <div className="fileupload-icons-right">
                                    <i className="fa-solid fa-circle-check"></i>
                                  </div>
                                </div>
                                {/* <div className="mt-2 d-flex">
                              <ProgressBar now={60} />
                              <div className="Progressbar-parentage text-end">
                                60%
                              </div>
                            </div> */}
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="fileupload-buttons d-flex">
                          {resumeName && (
                            <>
                              {" "}
                              <span
                                onClick={(e) => handleDeleteResume(e)}
                                className="file-delete enable"
                              >
                                Delete
                              </span>
                              <div
                                onClick={() => {
                                  resumeRef.current.click();
                                }}
                                className="cursor-pointer fileupdate ms-3"
                              >
                                <span className="upload">Update</span>
                                {/* <input type="file" name="fileToUpload" /> */}
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    <WorkExperience />
                    {/* <div className="dropdown-input mt-4">
                    <Row>
                      <Col className="6">
                        <div className="select-wrapper">
                          <Form.Label>Location</Form.Label>
                          <Select options={options} />
                          <img
                            src={Us}
                            alt="icon"
                            className="country-flags"
                            width={20}
                            height={20}
                          />
                        </div>
                      </Col>
                      <Col className="6">
                        <div className="select-wrapper">
                          <Form.Label>Timezone</Form.Label>
                          <TimezoneSelect
                            value={selectedTimezone}
                            onChange={setSelectedTimezone}
                          />
                        </div>
                      </Col>
                    </Row>
                  </div> */}
                  </Form>
                  <div className="form-button pb-3 pt-3 mt-3 pe-4 ps-4 text-end">
                    <Button
                      onClick={handleSetPrevious}
                      className="transparent-btn me-3"
                    >
                      Cancel
                    </Button>
                    {!submitLoading ? (
                      <Button
                        onClick={(e) => {
                          handleSubmit(e);
                        }}
                        variant="primary"
                        type="button"
                      >
                        Save changes
                      </Button>
                    ) : (
                      <LoadMoreLoader />
                    )}
                  </div>
                </div>
              </div>
              {/* <div className="logout-button mt-4">
                            <Button onClick={(e) => handleLogout(e)} className="transparent-btn">
                                <i className="fa-regular fa-arrow-right-from-bracket me-2"></i>Log
                                Out
                            </Button>
                        </div> */}
            </div>
          </Container>
        </div>
      </div>
    </>
  ) : (
    <SiteLoader />
  );
}

export default Profile;
