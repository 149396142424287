import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import MbaseLogo from "../../assets/media/logos/m-base-logo.svg";
import Card from "react-bootstrap/Card";
import AwardIcon from "../../assets/media/icons/award-icon.svg";
import MarkerPin from "../../assets/media/icons/marker-pin.svg";
import TrackerIcon from "../../assets/media/icons/tracker-icon.svg";
import ArtistTopImg from "../../assets/media/img/artist-top-img.png";
import VerifiedTick from "../../assets/media/icons/verified-tick.svg";
import Button from "react-bootstrap/Button";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Offcanvas from "react-bootstrap/Offcanvas";
import Facebook from "../../assets/media/icons/facebook.svg";
import LinkedIn from "../../assets/media/icons/linkedin.svg";
import Youtube from "../../assets/media/icons/youtube.svg";
import Twitter from "../../assets/media/icons/twitter.svg";
import Telegram from "../../assets/media/icons/telegram.svg";
import Tiktok from "../../assets/media/icons/tiktok.svg";
import Applemusic from "../../assets/media/icons/applemusic.svg";
import Spotify from "../../assets/media/icons/spotify.svg";
import Snapchat from "../../assets/media/icons/snapchat.svg";
import APICall from "../../networking/AxiousServices";
import ApiTypes from "../../networking/APItypes";
import { useEffect } from "react";
import NodataFound from "../Errors/NodataFound";
import SiteLoader from "../../Component/Loaders/SiteLoader";
import SocialIcon from "../../common/SocialIcon";
import ArtistDefault from "../../assets/Images/Defaults/artist_default.svg";
import IsFollow from "../../Component/FollowUnFollow/IsFollow";
import { useDispatch, useSelector } from "react-redux";
import ClientDefault from "../../assets/Images/Defaults/client_default.webp"
import PreviewImg from "../../Component/Image/PreviewImg";
import { Enums } from "../../constants/Enums";
import LoadMoreLoader from "../../Component/Loaders/LoadMoreLoader";
import ReactHtmlParser from 'react-html-parser';

export default function ClientDetails() {

  const SubscriptionFeature = useSelector((state) => state.data.logged_user.subscription_feature);
  const isLogIn = useSelector((state) => state.data.auth_token);
  const UserData = useSelector((state) => state.data);
  const authUserId = UserData.logged_user.user_id;

  const [chatLoading, setChatLoading] = useState(false);

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation();
  const query_params = new URLSearchParams(location.search);
  const client_id = query_params.get("cid");
  const [loading, setLoading] = useState(true);
  const [details, setDetails] = useState({});
  const [state, setState] = useState(true);

  const rotateHandler = () => {
    setState(() => !state);
  };


  const fetchClientDetails = async () => {
    let res = await APICall({
      url: ApiTypes.fetchClientDetails,
      data: {
        user_id: client_id,
      },
    });
    if (res.status === 1) {
      setLoading(false);
      setDetails(res.data);
    } else {
      if (res.data.status === 0) {
        setLoading(false)
      }
    }
  };


  useEffect(() => {
    if (client_id) {
      setLoading(true);
      fetchClientDetails();
    }
  }, [client_id]);

  const renderLocation = (value) => {
    let str = value.address1 ? value.address1 + ", " : "" +
      (value?.address2 ? value.address2 + ", " : "") +
      (value?.city ? value.city + ", " : "") +
      (value.state ? value.state + ", " : "") +
      (value.address_3 ? value.address_3 + ", " : "")+
      (value.country ? value.country + ", " : "");
    str = str.trim();
    str = str.slice(0, -1)
    if (str.length && str && str !== "null") {
      return str;
    } else {
      return '-';
    }
  }

  const handleNavigateMessages = async (e, details_got) => {
    e.preventDefault();
    setChatLoading(true)
    let res = await APICall({
      url: ApiTypes.createChatRoom,
      data: {
        sender_user_id: authUserId,
        receiver_user_id: details_got.user_id,
        module: Enums.chat_module.user_to_user
      }
    })
    if (res.status === 1) {
      setChatLoading(false)
      navigate('/messages', {
        state: {
          socket_id: res.data.chat_room_id,
          rec_id: details_got.user_id,
          display_name: details_got.display_name,
          profile_img: details_got.profile_img,
          is_online: parseInt(details_got.is_online)
        }
      })
    }
  }

  return !loading ? (
    Object.keys(details).length ? (
      <div className="main-content">
        <div className="artist-profile mb-4">
          <Container>
            <div className="row">
              <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 mb-3">
                <div className="artist-top-col">
                  <div className="artist-img">
                    <PreviewImg
                      src={details.profile_img ? details.profile_img : ClientDefault}
                      isDefault={details.profile_img ? false : true}
                      alt="ClientTopImg"
                      //  width={240}
                      height={657}
                      defaultImg={ClientDefault}
                      className="w-100"
                    />
                    {/* <img
                                              src={
                                                details.profile_img
                                                  ? process.env.REACT_APP_ASSETS_URL +
                                                  details.profile_img
                                                  : ClientDefault
                                              }
                                              alt="ClientTopImg"
                                              className="w-100"
                                            /> */}
                  </div>
                  {/* <div className="artist-verifie-tick">
                                        <img src={VerifiedTick} alt="VerifiedTick" />
                                    </div> */}
                  <div className="artist-name">
                    {details.display_name}
                  </div>
                </div>
                {/* {SubscriptionFeature.artist.media_association && <div className="artist-social-media">
                      {details.social_connects.map((social, index) => {
                        return (
                          <SocialIcon
                            key={index}
                            social_icon={social.social_icon}
                            url={social.profile_url}
                            classLink={"me-2"}
                          />
                        );
                      })}
                    </div>} */}

                <div className="artist-about mt-4">
                  <div className="artist-left-title">About User</div>
                  <div className="artist-left-discription">
                    <p className="mb-3">
                      {details.mini_bio ? ReactHtmlParser(details.mini_bio) : "-"}
                    </p>
                  </div>
                </div>
                <div className="artist-about-button mt-4 d-flex align-items-center justify-content-between">
                  <div className="about-button">
                    {/* <IsFollow getResBack={(value) => {
                          setArtistDetails({
                            ...details,
                            total_followers: parseInt(value) === 1
                              ? parseInt(details.total_followers) ? parseInt(details.total_followers) + 1 : 1
                              : parseInt(details.total_followers) - 1
                          })
                        }} is_follow={details.is_follow} artist_id={artist_id} /> */}
                    {details.subscription_type !== Enums.subscription_type.free   && authUserId !== details.user_id  ? !chatLoading ? <Button onClick={(e) => {
                      if (isLogIn) {
                        if (SubscriptionFeature.chat.message) {
                          handleNavigateMessages(e, details)
                        } else {
                          dispatch({ type: "SUBSCRIPTION_MODAL", payload: true });
                        }
                      } else {
                        navigate('/login')
                      }

                    }} variant="primary" type="submit">
                      Contact me
                    </Button> : <LoadMoreLoader /> : ""}
                  </div>
                  <div className="tracker d-flex">
                    {/* <div className="tracker-left me-3">
                          <span className="me-2">
                            <img src={TrackerIcon} alt="Award Icon" />
                          </span>
                          <span>{details.total_followers ? details.total_followers : 0} Trackers</span>
                        </div> */}
                    <div className="tracker-right">
                      <span className="me-2">
                        <img src={MarkerPin} alt="Award Icon" />
                      </span>
                      <span>{details.location ? renderLocation(details.location) : "-"}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                <div className="artist-job-col">
                  <div className="artist-job-top" onClick={rotateHandler}>
                    <span className="job-title text-uppercase">
                      User Name
                    </span>
                    <div className="job-bottom-title text-uppercase">
                      {details.display_name}
                    </div>
                  </div>
                  {/* <div
                        className="job-description"
                        style={{ display: state ? " block" : "none" }}
                      >
                        {details.artist_associations.map((artist_asso) => {
                          return (
                            <div className="row mb-4">
                              <div className="col-6">
                                <div className="job-list-left-col">
                                  <label>
                                    {artist_asso.artist_associate_label}
                                  </label>
                                </div>
                              </div>
                              <div className="col-6">
                                <Link to="#" className="video-title">
                                  <span className="d-inline-block text-capitalize">
                                    Music Video Title
                                  </span>
                                  (2022)
                                </Link>
                              </div>
                            </div>
                          );
                        })}
                      </div> */}
                </div>
              </div>
            </div>
          </Container>
        </div>
        {/* <div className="awards-section">
              <Container>
                <div className="row">
                  <div className="col-12">
                    <Card>
                      <Card.Body className="p-0">
                        <Card.Title>Video Awards</Card.Title>
                        <Card.Text>
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                          Porttitor habitant amet arcu dolor ut lorem est, facilisi.
                          Sed a nunc.
                        </Card.Text>
                        <div className="award-items flex-wrap d-flex mt-5">
                          <div className="award-item d-flex align-items-center me-4 mb-3">
                            <span className="award-icon d-flex align-items-center justify-content-center">
                              <img src={AwardIcon} alt="Award Icon" />
                            </span>
                            <p className="award-discription-area mb-0">
                              Consequat ipsum risus turpis elementum.
                            </p>
                          </div>
                          <div className="award-item d-flex align-items-center">
                            <span className="award-icon d-flex align-items-center justify-content-center">
                              <img src={AwardIcon} alt="Award Icon" />
                            </span>
                            <p className="award-discription-area mb-0">
                              Consequat ipsum risus turpis elementum.
                            </p>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>
                </div>
              </Container>
            </div> */}
      </div>
    ) : (
      <NodataFound />
    )
  ) : (
    <SiteLoader />
  );
}
