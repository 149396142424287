import React from 'react'
import ProfileImg from "../../assets/media/img/profile-img.png";
import MarkerPin from "../../assets/media/icons/marker-pin.svg";
import ReactHtmlParser from 'react-html-parser';
import { Modal } from "react-bootstrap";
import { useState } from 'react';
import { useEffect } from 'react';
import APICall from '../../networking/AxiousServices';
import CurrencyDollar from "../../assets/media/icons/currency-dollar.svg";
import ApiTypes from '../../networking/APItypes';
import FetchLoader from '../../Component/Loaders/FetchLoader';
import PreviewImg from '../../Component/Image/PreviewImg';
import DefaultCompany from "../../assets/Images/Defaults/default_company.png";

export default function AppliedJobDetails({ setShowAppliedJobDetails, setAppliedJobDetailId, showAppliedJobDetails, appliedJobDetailId }) {


  const handleClose = () => {
    setAppliedJobDetailId(null)
    setShowAppliedJobDetails(false)
  }

  const [loading, setLoading] = useState(true);
  const [jobDetails, setJobDetails] = useState(null);

  const fetchDetails = async () => {

    let res = await APICall({
      url: ApiTypes.fetchJobDetails,
      data: {
        job_id: appliedJobDetailId
      }
    })

    if (res.status === 1) {
      setJobDetails(res.data)
      setLoading(false)
    }
  }
  const renderLocation = (value) => {
    let str = value.address1 ? value.address1 + ", " : "" +
      (value.city ? value.city + ", " : "") +
      (value.state ? value.state + ", " : "") +
      (value.country ? value.country + ", " : "") +
      (value.postal_code ? value.postal_code + ", " : "");
    str = str.trim();
    str = str.slice(0, -1)
    if (str.length && str && str !== "null") {
      return str;
    } else {
      return '-';
    }
  }

  useEffect(() => {
    if (appliedJobDetailId) {
      fetchDetails()
    }
  }, [appliedJobDetailId]);
  return (<Modal
    show={showAppliedJobDetails}
    onHide={handleClose}
    aria-labelledby="contained-modal-title-vcenter"
    centered
    backdrop="static"
    className="job-profile-modal"
  >
    {!loading ? <> <Modal.Header closeButton className="mb-4 pb-3">
      <div className="modal-top">
        <span className="profile-section me-3">
          <PreviewImg
            src={jobDetails.company_image ? jobDetails.company_image : DefaultCompany}
            isDefault={jobDetails.company_image ? false : true}
            alt="ProfileImg"
            width={'50'}
            height={"48"}
            defaultImg={DefaultCompany}
            className="rounded-circle"
          />
          {/* <img
            src={ProfileImg}
            alt="ProfileImg"
            width="50"
            height="48"
            className="rounded-circle"
          /> */}
        </span>
        <span className="modal-top-title">{jobDetails.company_name}</span>
      </div>
    </Modal.Header>
      <Modal.Body>
        <div className="job-description-section">
          <div className="job-description">
            <div className="job-description-title mb-1">Job Title</div>
            <p className="job-description mb-2">
              {jobDetails.job_title}
            </p>
          </div>
          <div className="job-description mt-3">
            <div className="job-description-title mb-1">Job Type</div>
            <p className="job-description mb-2">
              {jobDetails.job_type_detail}
            </p>
          </div>
          <div className="job-description mt-3">
            <div className="description-title mb-1">Job Description</div>
            <p className="job-description-list mb-2">

              {ReactHtmlParser(jobDetails.job_detail)}
            </p>
          </div>
          <div className="details-col mt-3 d-flex flex-column">
            <div className="mb-2 d-flex">
              <span className="me-2 location-details-icon">
                <img src={MarkerPin} alt="MarkerPin" width={16} height={16} />
              </span>
              <span className="location-details">{renderLocation(jobDetails)}</span>
            </div>
            {/* <div className="me-sm-4">
            <span className="me-2">
              <img
                src={StartToEndTime}
                alt="MarkerPin"
                width={17}
                height={17}
              />
            </span>
            <span>Starts 12/12/2028 - 12/12/2029</span>
          </div> */}
            <div className="">
              <span className="me-2">
                <img
                  src={CurrencyDollar}
                  alt="CurrencyDollar"
                  width={17}
                  height={17}
                />
              </span>
              <span>${jobDetails.payment_amount}</span>
            </div>
          </div>
        </div>
      </Modal.Body>
    </> : <FetchLoader />}
  </Modal>)
}
