import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import MbaseLogo from "../assets/media/logos/m-base-logo.svg";
import Button from "react-bootstrap/Button";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Offcanvas from "react-bootstrap/Offcanvas";
import ProfileImg from "../assets/media/img/profile-img.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import TopBannerImg from "../assets/media/img/banner-img.png";
import Card from "react-bootstrap/Card";
import ThumbnailImg from "../assets/media/img/thumbnail.png";
import ThumbnailFour from "../assets/media/img/thumbnail-4.png";
import ThumbnailTwo from "../assets/media/img/thumbnail-2.png";
import ThumbnailOne from "../assets/media/img/thumbnail-1.png";
import ThumbnailFive from "../assets/media/img/thumbnail-5.png";
import FielStar from "../assets/media/icons/fiel-star.svg";
import BlankStar from "../assets/media/icons/blank-star.svg";
import ClockRotate from "../assets/media/icons/clock-rotate.svg";
import AutoComplete from "../Component/AutoComplete/Index";
import suggestions from "../assets/File/AutoComplete";
import BannerTop from "../Component/Banner/BannerTop";
import { Enums } from "../constants/Enums";
import APICall from "../networking/AxiousServices";
import ApiTypes from "../networking/APItypes";
import VideoLayout from "../Component/Video/VideoLayout";
import HomeVideoLoader from "../Component/Loaders/HomeVideoLoader";
import LoadMoreLoader from "../Component/Loaders/LoadMoreLoader";
import DefaultArtist from "../assets/Images/Defaults/artist_default.svg";
import PreviewImg from "../Component/Image/PreviewImg";
import { useRef } from "react";
import ScrollButtons from "../Component/ScrollButtons/ScrollButtons";

function MyBase() {

  document.title = process.env.REACT_APP_NAME + " | Home";
  const [videoTabs, setVideoTabs] = useState([]);
  const [activeTag, setActiveTag] = useState(null);
  const [videoPage, setVideoPage] = useState(1);
  const [videos, setVideos] = useState([]);
  const [totalVideos, setTotalVideos] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [preLoading, setPreLoading] = useState(true);
  const navigate = useNavigate()
  const scrollRefDiv = useRef(null)
  
  var topbanner = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
    dots: true,
  };



  const fetchHomeVideoTabs = async () => {
    let res = await APICall({
      url: ApiTypes.fetchMyBaseTabs,
    });
    if (res.status === 1) {
      setVideoTabs(res.data.list);
      setActiveTag(Object.keys(res.data.list)[0]);
    }
  };
  const fetchMyBaseVideos = async () => {
    setLoading(true);
    let res = await APICall({
      url: ApiTypes.fetchMyBaseVideos,
      data: {
        is_filter: 1,
        filter: {
          tab_type: activeTag,
        },
        page: videoPage,
        limit: perPage,
      },
    });
    if (res.status === 1) {
      setPreLoading(false);
      setTotalVideos(res.data.total);
      return res.data.list;
    } else {
      return [];
    }
  };
  const handleLoadMore = async () => {
    setVideoPage(videoPage + 1);
  };
  const handleChangeTab = (video_tab) => {
    setVideoPage(1);
    // setPreLoading(true);
    setActiveTag(video_tab);
  };
  const fetchLoadMoreVideo = async () => {
    setLoading(true);
    let data = await fetchMyBaseVideos();
    setVideos([...videos, ...data]);
    setLoading(false);
  };
  useEffect(() => {
    if (videoPage !== 1) {
      fetchLoadMoreVideo();
    }
  }, [videoPage]);
  const tabKeyFetchVideo = async () => {
    let data = await fetchMyBaseVideos();
    setVideos(data);
    setLoading(false);
  };
  useEffect(() => {
    if (activeTag) {
      tabKeyFetchVideo();
    }
  }, [activeTag]);

  useEffect(() => {
    fetchHomeVideoTabs();
  }, []);



  return (
    <div className="main-content">
      <BannerTop />
      <div className="videos">
        <div className="music-tabs">
          <Container>
            <div className="music-tab-item">
              <ul  ref={scrollRefDiv}  className="list-group list-group-horizontal active pl-0 mb-0">
                {Object.keys(videoTabs).length
                  ? Object.keys(videoTabs).map((video_tab, index) => {
                    return (
                      <li
                        key={index}
                        onClick={() => {
                          handleChangeTab(video_tab);
                        }}
                        className="d-inline-block"
                      >
                        <Link
                          to="#"
                          className={`d-inline-block ${activeTag === video_tab ? "active" : ""
                            }`}
                        >
                          {videoTabs[video_tab]}
                        </Link>
                      </li>
                    );
                  })
                  : ""}
              </ul>
              {Object.keys(videoTabs).length ? <ScrollButtons  scrollRefDiv={scrollRefDiv}/> : ""}
            </div>
          </Container>
        </div>
        <div className="video-item-col">
          <Container>
            <div className="title">{videoTabs[activeTag]}</div>
            <div className="row">
              {!preLoading ? (
                videos.length ? (
                  videos.map((video, index) => {
                    if (Enums.my_base_tabs.watched_video === activeTag || Enums.my_base_tabs.watched_video === activeTag || Enums.my_base_tabs.favorite_video === activeTag || Enums.my_base_tabs.watch_later === activeTag) {
                      return (
                        <VideoLayout
                          media_ref_id={video.media_ref_id}
                          is_watch_later={video.is_watch_later}
                          is_favorite={video.is_favorite}
                          videoURL={
                            Enums.urls.youtube_embed_base + video.media_ref_id
                          }
                          title={video.title}
                          video_id={video.video_id}
                          director={
                            video.all_director_name
                              ? video.all_director_name
                              : null
                          }
                          artist={
                            video.all_artist_name ? video.all_artist_name : null
                          }
                          active_tag={activeTag}
                          artist_id_str={video.all_artist_id ? video.all_artist_id : null}
                          key={index}
                        />
                      );
                    }
                    if (Enums.my_base_tabs.favorite_artists === activeTag || Enums.my_base_tabs.followed_artists === activeTag) {
                      return (<div key={index} className="col-lg-4 col-md-6">
                        <Card className="border-0">
                        {/* <img class="card-img-top rounded-0" src="/static/media/artist_default.44fbf85fe24e34f8ecb700c368e3105c.svg"> */}
                          <PreviewImg src={video.video_url ? video.video_url : DefaultArtist}
                            isDefault={video.video_url  ? false : true}
                            alt="artist-img"
                            width={120}
                            height={240}
                            defaultImg={DefaultArtist}
                            className="card-img-top rounded-0"
                          />

                          {/* <Card.Img variant="top rounded-0" src={!video.video_url ? process.env.REACT_APP_ASSETS_URL + video.video_url : DefaultArtist} /> */}
                          <Card.Body className="px-0 pb-0">
                            <Card.Title onClick={() => {
                              navigate(`/artist/?aid=${video.artist_id}`)
                            }} className="cursor-pointer h4 text-center">
                              {video.title}
                            </Card.Title>
                          </Card.Body>
                        </Card>
                      </div>)
                    }
                  })
                ) : (
                  <div className="mt-4 text-center h5">No Data Found!</div>
                )
              ) : (
                <HomeVideoLoader />
              )}
            </div>
            {videos.length < totalVideos ? (
              <div className="row">
                <div className="col-12 text-center mt-5">
                  {!loading ? (
                    <Button
                      onClick={() => {
                        handleLoadMore();
                      }}
                      variant="primary"
                      type="submit"
                    >
                      Load More
                    </Button>
                  ) : (
                    <LoadMoreLoader />
                  )}
                </div>
              </div>
            ) : (
              ""
            )}
          </Container>
        </div>
      </div>
    </div>
  );
}

export default MyBase;
