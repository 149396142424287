export default [
  "PO Value (AED)",
  "PO Qty",
  "No Of POs",
  "PO Approval Ageing",
  "Lead Time/SLA",
  "PO Approval Lead Time",
  "PO Released Graph",
  "On-Hold PO",
  "PO Approval Lead Time",
  "Lead Time/SLA",
  "PO Partner ID",
  "Paid Month",
  "Partner ID",
  "Overall Summary",
  "Payment Request Tickets Details",
  "Payment Pending View Based on SLA’s",
  "Pending With Business/Finance",
  "Current Pending @ Partner ID level",
  "Current Pending @ HOD level",
  "Monthly Trend",
  "Raw Data",
];
