import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import MbaseLogo from "../../assets/media/logos/m-base-logo.svg";
import Card from "react-bootstrap/Card";
import AwardIcon from "../../assets/media/icons/award-icon.svg";
import Button from "react-bootstrap/Button";
import FielStar from "../../assets/media/icons/fiel-star.svg";
import BlankStar from "../../assets/media/icons/blank-star.svg";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Offcanvas from "react-bootstrap/Offcanvas";
import Spotify from "../../assets/media/icons/spotify.svg";
import Snapchat from "../../assets/media/icons/snapchat.svg";
import { ReactComponent as EditIcon } from "../../assets/media/icons/edit-icon.svg";
import DeezerIcon from "../../assets/media/icons/deezer-icon.svg";
import Youtube from "../../assets/media/icons/youtube.svg";
import Tiktok from "../../assets/media/icons/tiktok.svg";
import DefaultBanner from "../../assets/Images/Defaults/default_video_banner.png";
import { Form } from "react-bootstrap";
import APICall from "../../networking/AxiousServices";
import ApiTypes from "../../networking/APItypes";
import SiteLoader from "../../Component/Loaders/SiteLoader";
import NodataFound from "../Errors/NodataFound";
import { Enums } from "../../constants/Enums";
import SocialIcon from "../../common/SocialIcon";
import { commonHelper } from "../../common/commonHelper";
import IsWatchLater from "../../Component/WatchLater/IsWatchLater";
import IsFavourite from "../../Component/Favourite/IsFavourite";
import YouTube, { YouTubeProps } from "react-youtube";
import { useDispatch, useSelector } from "react-redux";
import PreviewImg from "../../Component/Image/PreviewImg";
import LoadMoreLoader from "../../Component/Loaders/LoadMoreLoader";
import Swal from "sweetalert2/dist/sweetalert2.js";
import NoAwardFound from "../Errors/NoAwardFound";
import Slider from "react-slick";

function VideoDetails() {
  const navigate = useNavigate();
  const SubscriptionFeature = useSelector(
    (state) => state.data.logged_user.subscription_feature
  );
  const dispatch = useDispatch();
  const location = useLocation();
  const query_params = new URLSearchParams(location.search);
  const video_id = query_params.get("vid");
  const [show, setShow] = useState(false);
  const [isPlayCounted, setIsPlayCounted] = useState(false);
  const [videoDetails, setVideoDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const initialSuggestionJson = {
    module_id: video_id,
    module_type_term: Enums.module.video,
    suggestion_type: "",
    suggestion_text: "",
    current_text: "",
  };

  const [suggestionJson, setSuggestionJson] = useState(initialSuggestionJson);
  const [suggestionTextEr, setSuggestionTextEr] = useState("");
  const [suggestionSubLoading, setSuggestionSubLoading] = useState(false);

  const fetchVideoDetails = async () => {
    let res = await APICall({
      url: ApiTypes.fetchVideoDetails,
      data: {
        video_id: video_id,
      },
    });
    if (res.status === 1) {
      setLoading(false);
      setVideoDetails(res.data);
    } else {
      if (res.data.status === 0) {
        setLoading(false);
      }
    }
  };
  useEffect(() => {
    if (video_id) {
      setLoading(true);
      fetchVideoDetails();
    }
  }, [video_id]);
  const opts = {
    height: "315",
    width: "560",
  };
  const handleAddPlayCount = () => {
    if (!isPlayCounted) {
      APICall({
        url: ApiTypes.watchCountUpdate,
        data: {
          video_id: video_id,
        },
      });
    }
  };

  const handleClose = () => {
    setSuggestionJson(initialSuggestionJson);
    setShow(false);
  };
  const handleShow = (video_ass) => {
    setSuggestionJson({
      ...suggestionJson,
      suggestion_type: video_ass.term,
      current_text: video_ass.value,
    });
    setShow(true);
  };

  const validateSuggestionForm = () => {
    let isValidate = true;
    if (suggestionJson.suggestion_text === "") {
      isValidate = false;
      setSuggestionTextEr("Please enter your suggestion.");
    } else {
      setSuggestionTextEr("");
    }
    return isValidate;
  };
  const handleSubmitSuggestion = async (e) => {
    e.preventDefault();
    if (validateSuggestionForm()) {
      setSuggestionSubLoading(true);
      let res = await APICall({
        url: ApiTypes.addSuggestion,
        data: suggestionJson,
      });

      if (res.status === 1) {
        setSuggestionSubLoading(false);
        handleClose();
        Swal.fire({
          title: "Success!",
          text: "Your suggestion has been sent successfully.",
          icon: "success",
          confirmButtonText: "Okay",
        });
      } else {
        if (res.status === 0) {
          setSuggestionSubLoading(false);
        } else {
          setSuggestionSubLoading(false);
        }
      }
    }
  };

  var artistVideoSlider = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    dots: false,
    infinite: false,
  };

  return !loading ? (
    Object.keys(videoDetails).length ? (
      <>
        <div className="main-content">
          <div className="artist-profile video-profile mb-4">
            <Container>
              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 mb-3 pb-3 pb-lg-0 pb-xl-0">
                  <div>
                    <div className="artist-bottom-col">
                      <div className="artist-img d-flex">
                        <YouTube
                          className="w-100"
                          videoId={videoDetails.media_ref_id}
                          opts={opts}
                          onPlay={() => {
                            setIsPlayCounted(true);
                            handleAddPlayCount();
                          }}
                        />
                        {/* <iframe
                        width="560"
                        height="315"
                        src={Enums.urls.youtube_embed_base + videoDetails.media_ref_id}
                        title={videoDetails.title ? videoDetails.title : ""}
                        frameborder="0"
                        allowfullscreen
                      ></iframe> */}
                      </div>
                    </div>
                    <div className="artist-social-media">
                      <div className="about-button d-flex">
                        {/* <Button className="transparent-btn star-sharp me-3">
                        <i className="fa-solid fa-star-sharp"></i>
                      </Button> */}

                      <IsFavourite
                        FavComponent={() => (<>
                          <Button className="transparent-btn star-sharp me-3">
                            <i className="fa-solid fa-star-sharp"></i>
                            {/* <img className='cursor-pointer' src={FielStar} alt="Remove Favourite" /> */}
                          </Button>
                        </>)}
                        UnFavComponent={() => (<>
                          <Button className="transparent-btn star-sharp me-3">
                            <i className="start-un-favour fa-solid fa-star-sharp"></i>
                          </Button>
                        </>)}
                        module_id={videoDetails.video_id}
                        module_type={Enums.module.video}
                        is_favorite={videoDetails.is_favorite}
                      />
                      {/* <Button className="transparent-btn me-3">Watched</Button> */}
                      <IsWatchLater
                        WatchComponent={() => (
                          <Button variant="primary" className="remove-watch-later" type="button">
                            Remove Watch Later
                          </Button>
                        )}
                        UnWatchComponent={() => (
                          <Button variant="primary" type="button">
                            Watch Later
                          </Button>
                        )}
                        module_id={videoDetails.video_id}
                        module_type={Enums.module.video}
                        is_watch_later={videoDetails.is_watch_later}
                      />

                    </div>
                 
                    </div>
                  </div>
                  {videoDetails.behind_scenes.length ?<> <div className="h4 mt-4"><b>Behind The Scenes</b></div>
                  <div className="row mt-2 ctm-behind-slider">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-3 pb-3 pb-lg-0 pb-xl-0">
                      <div className="artist-bottom-col video-artist-bottom-img">
                       
                        
                        <div className="artist-video-slider artist-img">
                          <Slider {...artistVideoSlider}>
                            {videoDetails.behind_scenes.map((bh_sc, i) => {
                              return (<div key={i} className="h-100 banner-item">
                                <YouTube
                                  className="w-100 h-100"
                                  videoId={bh_sc.media_ref_id}
                                  opts={{
                                    width: "413",
                                    height: "230",
                                  }}
                                  onPlay={() => {
                                    setIsPlayCounted(true);
                                    handleAddPlayCount();
                                  }}
                                />
                              </div>)
                            })}
                            {/* <div className="banner-item">
                              <YouTube
                                className="w-100"
                                videoId={"BddP6PYo2gs"}
                                opts={{
                                  width: "413",
                                  height: "230",
                                }}
                                onPlay={() => {
                                  setIsPlayCounted(true);
                                  handleAddPlayCount();
                                }}
                              />
                            </div> */}
                          </Slider>
                        </div>
                  {/* {videoDetails.video_banner_images.length ?      <div className="artist-img banner-artist-cu-img artist-bottom-section"> */}
                          {/* <PreviewImg
                            src={
                              videoDetails.video_banner_images.length
                                ? videoDetails.video_banner_images[0].doc_url
                                  ? process.env.REACT_APP_ASSETS_URL +
                                  videoDetails.video_banner_images[0].doc_url
                                  : DefaultBanner
                                : DefaultBanner
                            }
                            isDefault={
                              videoDetails.video_banner_images.length
                                ? videoDetails.video_banner_images[0].doc_url
                                  ? false
                                  : true
                                : true
                            }
                            alt="ArtistTopImg"
                            width={"636px"}
                            height={"470px"}
                            defaultImg={DefaultBanner}
                            className="w-100"
                          /> */}
                          {/* <img
                            width="636px"
                            height="470px"
                            src={
                              videoDetails.video_banner_images.length
                                ? videoDetails.video_banner_images[0].doc_url
                                  ? process.env.REACT_APP_ASSETS_URL +
                                  videoDetails.video_banner_images[0].doc_url
                                  : DefaultBanner
                                : DefaultBanner
                            }
                            alt="ArtistTopImg"
                            className="w-100"
                          /> */}
                        {/* </div> :""} */}
                      </div>
                    </div>
                    {/* <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12"> */}
                      {/* {SubscriptionFeature.video.media_association && ( */}
                        {/* <div className="artist-social-media video-social-media-profile"> */}
                          {/* <div className="row text-center">
                            {videoDetails.social_connects.map(
                              (social, index) => {
                                return (
                                  <div key={index} className="col-4 mb-4">
                                    <SocialIcon
                                      social_icon={social.social_icon}
                                      url={social.profile_url}
                                      classLink={"me-0"}
                                    />
                                  </div>
                                );
                              }
                            )}
                          </div> */}
                          {/* <Link to="#" className="youtube">
                                <img src={Youtube} alt="youtube-icon" />
                              </Link>
                              <Link to="#" className="tiktok">
                                <img src={Tiktok} alt="tiktok-icon" />
                              </Link>
                              <Link to="#" className="snapchat">
                                <img src={Snapchat} alt="snapchat-icon" />
                              </Link>
                              <Link to="#" className="spotify">
                                <img src={Spotify} alt="spotify-icon" />
                              </Link>
                              <Link to="#" className="deezer">
                                <img src={DeezerIcon} alt="spotify-icon" />
                              </Link> */}
                        {/* </div> */}
                      {/* // )} */}
                    {/* </div> */}
                  </div>

                  </>: ""}

                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                  <div className="artist-job-col">
                    <div className="artist-job-top">
                      <span className="job-title">
                        {commonHelper.formatDate(
                          videoDetails.date_of_release,
                          "MM-DD-YYYY"
                        )}
                      </span>
                      <div className="job-bottom-title">
                        {videoDetails.title}
                      </div>
                    </div>
                    <div className="job-description mt-4">
                      {videoDetails.video_associations.map(
                        (video_association, index) => {
                          return (
                            <div key={index} className="mb-4 pb-4 red-rider">
                              {/* <div className="col-12 col-md-6"> */}
                                <div className="job-list-left-col mb-4">
                                  <label>
                                    {video_association.label
                                      ? video_association.label
                                      : ""}
                                  </label>
                                </div>
                              {/* </div> */}
                              <div>
                                <div
                                  className="video-title"
                                  title={
                                    video_association.value
                                      ? video_association.value
                                      : ""
                                  }
                                >
                                  {video_association.artist_id ? (
                                    video_association.artist_id.split(" , ")
                                      .length ? (
                                      video_association.artist_id
                                        .split(" , ")
                                        .map((ar_id, i) => (
                                          <Link
                                            key={i}
                                            to={`/artist?aid=${ar_id}`}
                                            className="video-title-link"
                                          >
                                            <span className="d-inline-block me-3 mb-3">
                                              {
                                                video_association.value.split(
                                                  " , "
                                                )[i]
                                              }
                                            </span>
                                          </Link>
                                        ))
                                    ) : (
                                      <Link
                                        to={`/artist?aid=${video_association.artist_id}`}
                                        className="video-title-link"
                                      >
                                        <span className="d-inline-block me-3 ">
                                          {video_association.value
                                            ? video_association.value
                                            : ""}
                                        </span>
                                      </Link>
                                    )
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <div
                                  className="d-inline-block edit-icon"
                               
                                >
                                  {SubscriptionFeature.video
                                    .suggestion_edit && (<>
                                    <Button onClick={() => {
                                      handleShow(video_association);
                                    }}  type="button" variant="primary">
                                      Suggest
                                    </Button>  
                                    {/* <Button type="button" variant="secondary">
                                      Suggest an Edit
                                    </Button>                          */}
                                    </>)}
                                </div>
                              </div>
                            </div>
                          );
                        }
                      )}

                      {/* <div className="row mb-4 dragonl-purple">
                        <div className="col-6">
                          <div className="job-list-left-col">
                            <label>Extra(s)</label>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="video-title">
                            <Link to="/" className="video-title-link">
                              <span className="d-inline-block me-3">
                                Suggest an Edit
                              </span>
                            </Link>
                            <div
                              className="d-inline-block edit-icon"
                              onClick={handleShow}
                            >
                              <EditIcon />
                            </div>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </Container>
          </div>
          <div className="awards-section">
            <Container>
              <div className="row">
                <div className="col-12">
                  <Card>
                    <Card.Body className="p-0">
                      <Card.Title>Awards</Card.Title>
                      {/* <Card.Text>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Porttitor habitant amet arcu dolor ut lorem est, facilisi.
                      Sed a nunc.
                    </Card.Text> */}
                      <div className="award-items mt-5 ">
                        {videoDetails.award_nominees.length ? (
                          videoDetails.award_nominees.map((award_nom, i) => (
                            <div
                              key={i}
                              className="award-item d-flex align-items-center me-4 mb-3"
                            >
                              <span className="award-icon d-flex align-items-center justify-content-center">
                                <img src={AwardIcon} alt="Award Icon" />
                              </span>
                              <p className={`award-discription-area mb-0`}>
                                {award_nom.award_type_detail} -{" "}
                                {award_nom.year_of_award}{" "}
                                {parseInt(award_nom.is_winner) === 1 ? (
                                  <span className="text-success">(Winner)</span>
                                ) : (
                                  <span className="text-warning">
                                    (Nominee)
                                  </span>
                                )}
                              </p>
                            </div>
                          ))
                        ) : (
                          <NoAwardFound />
                        )}
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              </div>
            </Container>
          </div>
        </div>

        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className=""
        >
          <Modal.Body>
            <Form className="suggestions-form">
              <Form.Group controlId="exampleForm.ControlTextarea1">
                <Form.Label>Type your suggestions below</Form.Label>
                <Form.Control
                  onChange={(e) => {
                    setSuggestionJson({
                      ...suggestionJson,
                      suggestion_text: e.target.value ? e.target.value : "",
                    });
                  }}
                  maxLength="50"
                  value={suggestionJson.suggestion_text}
                  as="textarea"
                  rows={6}
                />
                {suggestionTextEr !== "" && (
                  <Form.Text className="text-danger">
                    {/* Must be at least 8 characters. */}
                    {suggestionTextEr}
                  </Form.Text>
                )}
              </Form.Group>
              <Form.Text className="mb-3 mt-1 text-secondary">
                {/* Must be at least 8 characters. */}
                Maximum 50 characters
              </Form.Text>
            </Form>
          </Modal.Body>
          <Modal.Footer className="d-flex align-items-center justify-content-between">
            <Button
              type="button"
              className="transparent-btn btn btn-primary"
              onClick={handleClose}
            >
              Close
            </Button>
            {!suggestionSubLoading ? (
              <Button
                onClick={(e) => {
                  handleSubmitSuggestion(e);
                }}
                type="button"
              >
                Submit
              </Button>
            ) : (
              <LoadMoreLoader />
            )}
          </Modal.Footer>
        </Modal>
      </>
    ) : (
      <NodataFound />
    )
  ) : (
    <SiteLoader />
  );
}

export default VideoDetails;
