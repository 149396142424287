import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Accordion, Col, InputGroup, Modal, Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import MbaseLogo from "../../assets/media/logos/m-base-logo.svg";
import Button from "react-bootstrap/Button";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Offcanvas from "react-bootstrap/Offcanvas";
import SubscriptionBanner from "../../assets/media/img/bookings.png";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import img from "../../assets/media/img/Image1.png";
import ProfileImg from "../../assets/media/img/profile-img.png";
import { ReactComponent as UploadIcon } from "../../assets/media/icons/upload-icon.svg";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Select from "react-select";
import TimezoneSelect from "react-timezone-select";
import Us from "../../assets/media/img/US.png";
import { ReactComponent as Remove } from "../../assets/media/icons/remove.svg";
import CurrencyDollar from "../../assets/media/icons/currency-dollar.svg";
import MarkerPin from "../../assets/media/icons/marker-pin.svg";
import StartToEndTime from "../../assets/media/icons/start-to-end-time.svg";
import JobListCard from "../../Component/Job/JobListCard";
import APICall from "../../networking/AxiousServices";
import { useEffect } from "react";
import { Enums } from "../../constants/Enums";
import SiteLoader from "../../Component/Loaders/SiteLoader";
import ApiTypes from "../../networking/APItypes";
import ReactHtmlParser from 'react-html-parser';
import NodataFound from "../Errors/NodataFound";
import FetchLoader from "../../Component/Loaders/FetchLoader";
import { useRef } from "react";
import { getBase64 } from "../../common/imageHelper";
import JobWorkExperience from "./JobWorkExperience";
import LoadMoreLoader from "../../Component/Loaders/LoadMoreLoader";
import { commonHelper } from "../../common/commonHelper";
import Swal from 'sweetalert2/dist/sweetalert2.js'

export default function ApplyJob({ showApplyJob, setShowApplyJob, applyJobId, setApplyJobId }) {

  const navigate = useNavigate()

  const [workExperience, setWorkExperience] = useState([]);

  const handleClose = () => {
    setShowApplyJob(false)
    setSubmitLoading(false)

    setResumeName(null)
    setFormInputs({
      user_covering_letter: "",
      resume: ""
    })
    setFormInputsEr({
      user_covering_letter: "",
      resume: ""
    })
    setWorkFormInputs({
      1: {
        company_name: "",
        title: "",
        start_date: "",
        end_date: "",
        video_link: "",
        work_img: "",
        description: ""
      }
    })
    setWorkFormInputsEr({
      1: {
        company_name: "",
        title: "",
        start_date: "",
        end_date: "",
        video_link: "",
        work_img: "",
        description: ""
      }
    })
  }

 

  const [submitLoading, setSubmitLoading] = useState(false);
  const [preProfileDetails, setPreProfileDetails] = useState(null);

  const [resumeName, setResumeName] = useState(null);
  const resumeRef = useRef(null)


  const [formInputs, setFormInputs] = useState({
    user_covering_letter: "",
    resume: ""
  });

  const [formInputsEr, setFormInputsEr] = useState({
    user_covering_letter: "",
    resume: ""
  });
  const validationError = {
    user_covering_letter: "Cover letter is required.",
  }


  const [workFormInputs, setWorkFormInputs] = useState({
    1: {
      company_name: "",
      title: "",
      start_date: "",
      end_date: "",
      video_link: "",
      work_img: "",
      description: ""
    }
  });

  const [workFormInputsEr, setWorkFormInputsEr] = useState({
    1: {
      company_name: "",
      title: "",
      work_img: "",
      time_er: ""
    }
  });

  const workValidationError = {
    company_name: "Company name is required.",
    title: "Title is required",
    start_date: "Start date is required.",
    time_er: "End date should not be greater than start date."
  }



  const [loading, setLoading] = useState(true);
  const [jobDetails, setJobDetails] = useState(null);
  // const [state, setstate] = useState(null);

  const fetchDetails = async () => {
    let response = await APICall({
      url: ApiTypes.getWorkExperience
    })
    let res = await APICall({
      url: ApiTypes.fetchJobDetails,
      data: {
        job_id: applyJobId
      }
    })

    let resProfile = await APICall({
      url: ApiTypes.getEditProfileDetails
    })
    if (res.status === 1 && response.status === 1 && resProfile.status === 1) {
      setWorkExperience(response.data)
      setJobDetails(res.data)
      setPreProfileDetails(resProfile.data)
      setLoading(false)
      if (resProfile.data.resume) {
        setResumeName(resProfile.data.resume.split('/').pop())

      }
    }
  }
  useEffect(() => {
    if (applyJobId) {
      fetchDetails()
    }
  }, [applyJobId]);

  const handleDeleteResume = (e) => {
    setResumeName(null)
    resumeRef.current.value = null;
  }

  const handleDocFile = (file) => {
    if (file.name.match(/\.(doc|docx|pdf)$/)) {
      if (parseInt((file.size / 1000000).toFixed(2)) <= 5) {
        setFormInputsEr({
          ...formInputsEr,
          resume: ''
        })
        setResumeName(file.name)

        getBase64(file)
          .then((result) => {
            setFormInputs({
              ...formInputs,
              resume: result
            })
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        setFormInputsEr({
          ...formInputsEr,
          resume: 'Document should be less than 5 MB.'
        })
      }
    } else {
      setFormInputsEr({
        ...formInputsEr,
        resume: 'Only doc, docx or pdf files are supported.'
      })
    }
  }

  const handleDocInput = (e) => {
    handleDocFile(e.target.files[0]);
  }

  const handleDocDrop = (e) => {
    e.preventDefault()
    handleDocFile(e.dataTransfer.files[0]);

  }
  const renderLocation = (value) => {
    let str = value.address1 ? value.address1 + ", " : "" +
      (value.city ? value.city + ", " : "") +
      (value.state ? value.state + ", " : "") +
      (value.country ? value.country + ", " : "") +
      (value.postal_code ? value.postal_code + ", " : "");
    str = str.trim();
    str = str.slice(0, -1)
    if (str.length && str && str !== "null") {
      return str;
    } else {
      return '-';
    }
  }


  const validateWorkInfo = () => {
    let errorJson = {

    }
    Object.keys(workFormInputsEr).forEach((work_key) => {
      errorJson = {
        ...errorJson,
        [work_key]: {
          company_name: "",
          title: "",
        }
      }
    })
    let isValidate = true;
    if (!workExperience.length) {
      // Object.keys(errorJson).forEach((form_er_key) => {
      //   Object.keys(errorJson[form_er_key]).forEach((item_key) => {
      //     if (workFormInputs[form_er_key][item_key] === "") {
      //       errorJson = {
      //         ...errorJson,
      //         [form_er_key]: {
      //           ...errorJson[form_er_key],
      //           [item_key]: workValidationError[item_key]
      //         }
      //       }
      //       isValidate = false;
      //     }
      //   })

      // })
      Object.keys(workFormInputsEr).forEach((form_er_key) => {
        if (workFormInputs[form_er_key].start_date !== "" && workFormInputs[form_er_key].end_date !== "") {
          if (workFormInputs[form_er_key].end_date < workFormInputs[form_er_key].start_date) {
            errorJson = {
              ...errorJson,
              [form_er_key]: {
                ...errorJson[form_er_key],
                'time_er': workValidationError.time_er
              }
            }
            isValidate = false;
          }

        }
      })
    }

    setWorkFormInputsEr(errorJson)
    return isValidate;
  }

  const validateBasicForm = () => {
    let isValidate = true;
    let errorJson = {
      user_covering_letter: "",
    }
    Object.keys(errorJson).forEach((form_er_key) => {
      if (formInputs[form_er_key] === "") {
        errorJson = {
          ...errorJson,
          [form_er_key]: validationError[form_er_key]
        }
        isValidate = false;
      }
    })
    if (!resumeName) {
      errorJson = {
        ...errorJson,
        resume: "Resume is required."
      }
      isValidate = false;
    }
    setFormInputsEr(errorJson)
    return isValidate;
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    let resBs = validateBasicForm()
    let resWork = validateWorkInfo()
    if (resBs && resWork) {
      setSubmitLoading(true)
      let dataArray = [];
      Object.keys(workFormInputs).forEach((form_er_key) => {
        dataArray = [...dataArray, workFormInputs[form_er_key]]
      })
      let newDataArray = [...dataArray , ...workExperience ]
      let data = {
        ...formInputs,
        job_id: applyJobId,
        resume_name: resumeName,
        job_experience: newDataArray,
        is_resume_delete: resumeName ? false : true,
      }
      let res = await APICall({
        url: ApiTypes.applyJob,
        data: data
      })
      if (res.status === 1) {
        setSubmitLoading(false)
        navigate('/applied-job')
        Swal.fire({
          title: 'Success!',
          text: 'You have applied successfully.',
          icon: 'success',
          confirmButtonText: 'Okay'
        })
      }
    }
  }

  return (<Modal
    show={showApplyJob}
    onHide={handleClose}
    aria-labelledby="contained-modal-title-vcenter"
    centered
    backdrop="static"
    className="job-profile-modal"
  >
    {!loading ? <> <Modal.Header closeButton className="mb-4 pb-3">
      <div className="modal-top">
        <span className="profile-section me-3">
          <img
            src={ProfileImg}
            alt="ProfileImg"
            width="50"
            height="48"
            className="rounded-circle"
          />
        </span>
        <span className="modal-top-title">{jobDetails.company_name}</span>
      </div>
    </Modal.Header>
      <Modal.Body>
        <div className="job-description-section">
          <div className="job-description">
            <div className="job-description-title mb-3">Job Title</div>
            <p className="job-description mb-2">
              {jobDetails.job_title}
            </p>
          </div>
          <div className="job-description mt-3">
            <div className="description-title mb-3">Job Type</div>
            <p className="job-description mb-2">
              {jobDetails.job_type_detail}
            </p>
          </div>
          <div className="job-description mt-3">
            <div className="description-title mb-3">Job Description</div>
            <p className="job-description-list mb-2">

              {ReactHtmlParser(jobDetails.job_detail)}
            </p>
          </div>
          <div className="details-col mt-3 d-flex flex-column">
            <div className="mb-2 d-flex">
              <span className="me-2 location-details-icon">
                <img src={MarkerPin} alt="MarkerPin" width={16} height={16} />
              </span>
              <span className="location-details">{renderLocation(jobDetails)}</span>
            </div>
            {/* <div className="me-sm-4">
            <span className="me-2">
              <img
                src={StartToEndTime}
                alt="MarkerPin"
                width={17}
                height={17}
              />
            </span>
            <span>Starts 12/12/2028 - 12/12/2029</span>
          </div> */}
            <div className="">
              <span className="me-2">
                <img
                  src={CurrencyDollar}
                  alt="CurrencyDollar"
                  width={17}
                  height={17}
                />
              </span>
              <span>${jobDetails.payment_amount}</span>
            </div>
          </div>
        </div>
        <div className="account-profile-col">
          <div className="profile-top-col mt-4">
            <div className="profile-top-section">
              <div className="profile-title">Personal info</div>
            </div>
            <div className="form-profile mt-3">
              <Form className="form pb-2 pt-3 pe-4 ps-4">
                {/* <Row>
                <Col lg={6} md={6} sm={12}>
                  <Form.Group className="mb-3" controlId="formGroupEmail">
                    <Form.Label>First name</Form.Label>
                    <Form.Control type="text" placeholder="Last name" />
                  </Form.Group>
                </Col>
                <Col lg={6} md={6} sm={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Last name</Form.Label>
                    <Form.Control type="text" placeholder="Last name" />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={6} md={6} sm={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control type="text" placeholder="Phone Number" />
                  </Form.Group>
                </Col>
                <Col lg={6} md={6} sm={12}>
                  <Form.Group className="mb-3 email-address">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter email address."
                    />
                    <span className="gmail-icon">
                      <i class="fa-regular fa-envelope"></i>
                    </span>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={6} md={6} sm={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Location</Form.Label>
                    <Form.Control type="text" placeholder="Location" />
                  </Form.Group>
                </Col>
                <Col lg={6} md={6} sm={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>LinkedIn Profile Link</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="LinkedIn Profile Link"
                    />
                  </Form.Group>
                </Col>
              </Row> */}
                <Row>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                    <Form.Label>Cover Letter <span className="required d-inline-block">*</span></Form.Label>
                    <Form.Control value={formInputs.user_covering_letter} onChange={(e) => {
                      setFormInputs({
                        ...formInputs,
                        user_covering_letter: e.target.value ? e.target.value : ""
                      })
                    }} as="textarea" rows={3} />
                    {formInputsEr.user_covering_letter !== "" && <Form.Text className="text-danger">
                      {/* Must be at least 8 characters. */}
                      {formInputsEr.user_covering_letter}
                    </Form.Text>}

                  </Form.Group>
                </Row>
                <div className="row ">
                  <div className="col-12 mb-4">
                    <label className="form-label">Upload Resume <span className="required d-inline-block">*</span></label>
                    <input
                      type="file"
                      ref={resumeRef}
                      name="fileToUpload"
                      accept=".doc, .docx ,.pdf"
                      onChange={(e) => handleDocInput(e)}
                      className="w-100 h-100 d-none"
                    />
                    {!resumeName && <div draggable={true} onDrop={(e) => handleDocDrop(e)} onClick={() => {
                      resumeRef.current.click();
                    }} className="fileUploadWrap cursor-pointer text-center">
                      <div className="fileupload-col">

                        <span className="upload-icon mx-auto rounded-circle d-flex align-items-center justify-content-center">
                          <UploadIcon />
                        </span>
                        <div className="fileupload-content mt-2">
                          <span className="me-1 cornflower-blue d-inline-block">
                            Click to upload resume
                          </span>
                          or drag and drop
                          <span className="d-block">
                            doc, docx, or pdf
                          </span>
                        </div>
                      </div>

                    </div>}
                    {formInputsEr.resume !== "" && <Form.Text className="text-danger">
                      {/* Must be at least 8 characters. */}
                      {formInputsEr.resume}
                    </Form.Text>}
                    {resumeName && <div
                      className="fileupload-process">
                      <div className="fileupload-text d-flex">
                        <div className="fileupload-left-icon me-3 d-flex align-items-center justify-content-center">
                          <i className="fa-light fa-file"></i>
                        </div>
                        <div className="fileupload-top-col w-100 d-flex align-items-center">
                          <div className="d-flex justify-content-between w-100">
                            <div className="fileupload-top">
                              <div onClick={async () => {
                                  try {
                                    let link;
                                    if (formInputs.resume !== "") {
                                      link = formInputs.resume
                                    } else {
                                      link = await commonHelper.getAssetUrl(process.env.REACT_APP_ASSETS_URL + preProfileDetails.resume);
                                      // link = process.env.REACT_APP_ASSETS_URL + preProfileDetails.resume
                                    }
                                    if (resumeName.split('.').pop() === 'pdf') {
                                      let pdfWindow = window.open("")
                                      pdfWindow.document.write(
                                        `<iframe width='100%' height='100%' src=${link}></iframe>`
                                      )
                                    } else {
                                      let aTag = document.createElement('a');
                                      aTag.setAttribute('href', link)
                                      aTag.setAttribute('target', '_blank')
                                      aTag.click();
    
                                    }
                                  }catch(error){
                                    console.log(error);
                                  }

                              }} className="cursor-pointer  file-name">
                                {resumeName}
                              </div>
                              {/* <div className="file-size">200 KB</div> */}
                            </div>
                            <div className="fileupload-icons-right">
                              <i className="fa-solid fa-circle-check"></i>
                            </div>
                          </div>
                          {/* <div className="mt-2 d-flex">
                              <ProgressBar now={60} />
                              <div className="Progressbar-parentage text-end">
                                60%
                              </div>
                            </div> */}
                        </div>
                      </div>
                    </div>}
                    <div className="fileupload-buttons d-flex">
                      {resumeName && <> <span onClick={(e) => handleDeleteResume(e)} className="file-delete enable">Delete</span>
                        <div onClick={() => {
                          resumeRef.current.click();
                        }} className="cursor-pointer fileupdate ms-3">
                          <span className="upload">Update</span>
                          {/* <input type="file" name="fileToUpload" /> */}
                        </div></>}

                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    {/* <label class="form-label">Upload Resume</label> */}
                    {/* <div class="fileUploadWrap text-center">
                      <input
                        type="file"
                        name="fileToUpload"
                        className="w-100 h-100"
                      />
                      <span className="upload-icon mx-auto rounded-circle d-flex align-items-center justify-content-center">
                        <UploadIcon />
                      </span>
                      <div className="fileupload-content mt-2">
                        <span className="cornflower-blue d-inline-block me-2">
                          Click to upload
                        </span>
                        or drag and drop
                        <span className="d-block">
                          SVG, PNG, JPG or GIF (max. 800x400px)
                        </span>
                      </div>
                    </div> */}
                    {/* <div className="fileupload-buttons d-flex">
                      <span className="file-delete enable">Delete</span>
                      <div className="fileupdate ms-3">
                        <span className="upload">Update</span>
                        <input type="file" name="fileToUpload" />
                      </div>
                    </div> */}
                    {/* <div className="add-accordion mt-3">
                      <Accordion>
                        <Accordion.Item eventKey="0" className="mb-4">
                          <Accordion.Header>
                            Add Work Experience
                          </Accordion.Header>
                          <Accordion.Body>
                            <Form className="experience-form">
                              <Row>
                                <Col lg={6} md={6} sm={12}>
                                  <Form.Group
                                    className="mb-3"
                                    controlId="formGroupEmail"
                                  >
                                    <Form.Label>Company Name</Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Company Name"
                                    />
                                  </Form.Group>
                                </Col>
                                <Col col={6}>
                                  <Form.Group className="mb-3">
                                    <Form.Label>Title</Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Title"
                                    />
                                  </Form.Group>
                                </Col>
                              </Row>
                              <Row>
                                <Col col={6}>
                                  <Form.Group
                                    className="mb-3"
                                    controlId="formGroupEmail"
                                  >
                                    <Form.Label>Date</Form.Label>
                                    <input
                                      type="date"
                                      name="begin"
                                      placeholder="dd-mm-yyyy"
                                      value=""
                                      class="form-control"
                                    />
                                  </Form.Group>
                                </Col>
                                <Col col={6}>
                                  <Form.Group className="mb-3">
                                    <Form.Label className="opacity-0">
                                      Title
                                    </Form.Label>
                                    <input
                                      type="date"
                                      name="begin"
                                      placeholder="dd-mm-yyyy"
                                      value=""
                                      class="form-control"
                                    />
                                  </Form.Group>
                                </Col>
                              </Row>
                              <Row>
                                <Col col={12}>
                                  <Form.Group controlId="formGroupEmail">
                                    <Form.Label>
                                      Description of Role
                                    </Form.Label>
                                    <textarea
                                      class="form-control"
                                      rows="4"
                                      cols="50"
                                    />
                                  </Form.Group>
                                </Col>
                              </Row>
                              <div className="row accordion-bottom-section mt-3">
                                <div className="col-lg-7 col-sm-12 enable d-flex align-items-center">
                                  <Remove className="me-2" />
                                  Delete this work experience
                                </div>
                              </div>
                            </Form>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                          <Accordion.Header>
                            Add more Work Experience
                          </Accordion.Header>
                          <Accordion.Body>
                            <Form className="experience-form">
                              <Row>
                                <Col lg={6} md={6} sm={12}>
                                  <Form.Group
                                    className="mb-3"
                                    controlId="formGroupEmail"
                                  >
                                    <Form.Label>Company Name</Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Company Name"
                                    />
                                  </Form.Group>
                                </Col>
                                <Col col={6}>
                                  <Form.Group className="mb-3">
                                    <Form.Label>Title</Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Title"
                                    />
                                  </Form.Group>
                                </Col>
                              </Row>
                              <Row>
                                <Col col={6}>
                                  <Form.Group
                                    className="mb-3"
                                    controlId="formGroupEmail"
                                  >
                                    <Form.Label>Date</Form.Label>
                                    <input
                                      type="date"
                                      name="begin"
                                      placeholder="dd-mm-yyyy"
                                      value=""
                                      class="form-control"
                                    />
                                  </Form.Group>
                                </Col>
                                <Col col={6}>
                                  <Form.Group className="mb-3">
                                    <Form.Label className="opacity-0">
                                      Title
                                    </Form.Label>
                                    <input
                                      type="date"
                                      name="begin"
                                      placeholder="dd-mm-yyyy"
                                      value=""
                                      class="form-control"
                                    />
                                  </Form.Group>
                                </Col>
                              </Row>
                              <Row>
                                <Col col={12}>
                                  <Form.Group controlId="formGroupEmail">
                                    <Form.Label>
                                      Description of Role
                                    </Form.Label>
                                    <textarea
                                      class="form-control"
                                      rows="4"
                                      cols="50"
                                    />
                                  </Form.Group>
                                </Col>
                              </Row>
                              <div className="row accordion-bottom-section mt-3">
                                <div className="col-lg-7 col-sm-12 enable d-flex align-items-center">
                                  <Remove className="me-2" />
                                  Delete this work experience
                                </div>
                              </div>
                            </Form>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div> */}
                    <JobWorkExperience
                    workExperience={workExperience}
                    setWorkExperience={setWorkExperience}
                      workFormInputs={workFormInputs}
                      setWorkFormInputs={setWorkFormInputs}
                      workFormInputsEr={workFormInputsEr}
                      setWorkFormInputsEr={setWorkFormInputsEr}
                      workValidationError={workValidationError}
                    />
                  </div>
                </div>
                {/* <Row>
                <Col col={12}>
                  <Form.Group
                    className="mt-3 termsconditions"
                    controlId="formBasicCheckbox"
                  >
                    <Form.Check
                      type="checkbox"
                      label="I Agree to the Terms & Conditions"
                    />
                  </Form.Group>
                </Col>
              </Row> */}
              </Form>
              <div className="form-button pb-3 pt-2 pe-4 ps-4 text-end">
                <Button onClick={() => { handleClose() }} className="transparent-btn me-3">Cancel</Button>
                {!submitLoading ? <Button onClick={(e) => {
                  handleSubmit(e)
                }} variant="primary" type="button">
                  Apply
                </Button> : <LoadMoreLoader />}
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </> : <FetchLoader />}
  </Modal>)
}
