import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import AuthSliderOne from "../../assets/media/img/auth-slider-1.png";
import AuthSliderTwo from "../../assets/media/img/auth-slider-2.png";
import AuthSliderThree from "../../assets/media/img/auth-slider-3.png";
import StarWhite from "../../assets/media/icons/star-white.svg";
// import SliderLogo from "../../assets/media/logos/slider-logo.svg";
import SliderLogo from "../../assets/media/logos/slider-logo.png";
function AuthSlider() {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    cssEase: "linear",
  };

  return (
    <div className="auth-slider">
      <div className="slider-logo">
        <img src={SliderLogo} alt="Slider Logo" />
      </div>
      <Slider {...settings}>
        <div>
          <img className="slider-img" src={AuthSliderOne} alt="AuthSlider 1" />
          <div className="bottom-card-area">
            {/*  <div className="discription-area">
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Neque
              vitae molestiae veritatis dignissimos rerum esse eos vero qui
              tempora, illo magni ab.
            </div>
            */}
            <div className="artist-area d-flex align-items-start justify-content-between">
              <div className="artist-name">Andi Lane</div>
              <div className="rating-area d-flex">
                <img
                  src={StarWhite}
                  alt=""
                  width={20}
                  height={20}
                  className="mx-1"
                />
                <img
                  src={StarWhite}
                  alt=""
                  width={20}
                  height={20}
                  className="mx-1"
                />
                <img
                  src={StarWhite}
                  alt=""
                  width={20}
                  height={20}
                  className="mx-1"
                />
                <img
                  src={StarWhite}
                  alt=""
                  width={20}
                  height={20}
                  className="mx-1"
                />
                <img
                  src={StarWhite}
                  alt=""
                  width={20}
                  height={20}
                  className="mx-1"
                />
              </div>
            </div>
            <div className="details-area">
              <div className="artist-type">Music Artist, Actress</div>
              <div className="artist-location">Little Rock Studio</div>
            </div>
          </div>
        </div>
        <div>
          <img className="slider-img" src={AuthSliderTwo} alt="AuthSlider 2" />
          <div className="bottom-card-area">
            {/*  <div className="discription-area">
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Neque
              vitae molestiae veritatis dignissimos rerum esse eos vero qui
              tempora, illo magni ab.
            </div>
            */}
            <div className="artist-area d-flex align-items-start justify-content-between">
              <div className="artist-name">Andi Lane</div>
              <div className="rating-area d-flex">
                <img
                  src={StarWhite}
                  alt=""
                  width={20}
                  height={20}
                  className="mx-1"
                />
                <img
                  src={StarWhite}
                  alt=""
                  width={20}
                  height={20}
                  className="mx-1"
                />
                <img
                  src={StarWhite}
                  alt=""
                  width={20}
                  height={20}
                  className="mx-1"
                />
                <img
                  src={StarWhite}
                  alt=""
                  width={20}
                  height={20}
                  className="mx-1"
                />
                <img
                  src={StarWhite}
                  alt=""
                  width={20}
                  height={20}
                  className="mx-1"
                />
              </div>
            </div>
            <div className="details-area">
              <div className="artist-type">Music Artist, Actress</div>
              <div className="artist-location">Little Rock Studio</div>
            </div>
          </div>
        </div>
        <div>
          <img
            className="slider-img"
            src={AuthSliderThree}
            alt="AuthSlider 3"
          />
          <div className="bottom-card-area">
            {/*  <div className="discription-area">
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Neque
              vitae molestiae veritatis dignissimos rerum esse eos vero qui
              tempora, illo magni ab.
            </div> */}
            <div className="artist-area d-flex align-items-start justify-content-between">
              <div className="artist-name">Andi Lane</div>
              <div className="rating-area d-flex">
                <img
                  src={StarWhite}
                  alt=""
                  width={20}
                  height={20}
                  className="mx-1"
                />
                <img
                  src={StarWhite}
                  alt=""
                  width={20}
                  height={20}
                  className="mx-1"
                />
                <img
                  src={StarWhite}
                  alt=""
                  width={20}
                  height={20}
                  className="mx-1"
                />
                <img
                  src={StarWhite}
                  alt=""
                  width={20}
                  height={20}
                  className="mx-1"
                />
                <img
                  src={StarWhite}
                  alt=""
                  width={20}
                  height={20}
                  className="mx-1"
                />
              </div>
            </div>
            <div className="details-area">
              <div className="artist-type">Music Artist, Actress</div>
              <div className="artist-location">Little Rock Studio</div>
            </div>
          </div>
        </div>
      </Slider>
    </div>
  );
}

export default AuthSlider;
