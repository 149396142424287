import React, { useEffect } from 'react'
import { Button } from "react-bootstrap";
import { GoogleLogin } from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import FacebookLogo from "../../assets/media/icons/social-icons/facebook.svg";
import GoogleLogo from "../../assets/media/icons/social-icons/google.svg";
import ApiTypes from '../../networking/APItypes';
import { gapi } from 'gapi-script';
import APICall from '../../networking/AxiousServices';
import { useDispatch } from "react-redux";
import { commonHelper } from '../../common/commonHelper';
import { useNavigate } from 'react-router-dom';
import { Enums } from '../../constants/Enums';

export default function SocialLogin() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const onGoogleLoginSuccess = async (response) => {
        console.log('onGoogleLoginSuccess', response)
        let dataToSend = {
            // display_name: response.profileObj.name ? response.profileObj.name : response.profileObj.givenName + " " + response.profileObj.familyName,
            first_name: response.profileObj.givenName,
            last_name: response.profileObj.familyName,
            username: response.profileObj.email,
            social_type: Enums.social_type.google,
            social_id: response.googleId,
        }
        loginWithSocial(dataToSend)

    }
    const responseGoogleError = (error) => {
        console.log("google error", error);
    }
    const responseFacebook = (response) => {
        console.log('responseFacebook', response)
        let dataToSend = {
            // display_name: response.name,
            first_name: response.name.split(' ')[0],
            last_name: response.name.split(' ')[response.name.split(' ').length - 1],
            username: response.email ? response.email : "",
            social_type: Enums.social_type.facebook,
            social_id: response.id,
        }
        loginWithSocial(dataToSend)

    }
    const loginWithSocial = async (data_received) => {
        let res = await APICall({
            url: ApiTypes.socialLogin,
            data: data_received
        })
        if (res.status === 1) {
            commonHelper.setItem("user", JSON.stringify(res.data));
            commonHelper.setItem("auth_token", res.data.auth_token);
            commonHelper.setItem("user_id", res.data.user_id);
            dispatch({ type: 'AUTH_TOKEN', payload: res.data.auth_token })
            dispatch({ type: 'LOGGED_USER', payload: res.data })
            localStorage.setItem('current_time'  , new Date());
            navigate("/")
        }
    }
    useEffect(() => {
        function start() {
            gapi.client.init({
                clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
                scope: 'email',
            });
        }
        gapi.load('client:auth2', start);
    }, []);

    return (<>
        <GoogleLogin
            clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
            // buttonText="Sign up with Google"
            onSuccess={onGoogleLoginSuccess}
            onFailure={responseGoogleError}
            cookiePolicy={'single_host_origin'}
            render={renderProps => {
                return <Button onClick={renderProps.onClick} className="transparent-btn">
                    <img
                        src={GoogleLogo}
                        className="me-2"
                        width={24}
                        height={24}
                        alt="GoogleLogo"
                    />
                    Sign up with Google
                </Button>
            }}
        />
        {/* <Button className="transparent-btn">
            <img
                src={FacebookLogo}
                className="me-2"
                width={24}
                height={24}
                alt="FacebookLogo"
            />
            Sign up with Facebook
        </Button> */}
        <FacebookLogin
            callback={responseFacebook}
            appId={process.env.REACT_APP_FACEBOOK_APP_ID}
            autoLoad={false}
            fields="name,email,picture"
            render={renderProps => (<Button onClick={() => { console.log("renderProps", renderProps); renderProps.onClick();}} className="transparent-btn">
                <img
                    src={FacebookLogo}
                    className="me-2"
                    width={24}
                    height={24}
                    alt="FacebookLogo"
                />
                Sign up with Facebook
            </Button>)}
        // onClick={componentClicked}
        // callback={responseFacebook} 
        />
    </>
    )
}
