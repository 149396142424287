export const Enums = {
    TermCategory: {
        user_type: 'user_type',
        role_type: 'role_type',
        artist_type: 'artist_type'
    },
    urls: {
        youtube_embed_base: 'https://www.youtube.com/embed/'
    },
    dashboard_video_tabs_enum :{
        latest_video :'latest_video',
        upcoming_video :'upcoming_video',
        anniversary_video :'anniversary_video',
        most_viewed_video :'most_viewed_video',
        behind_scenes_video :'behind_scenes_video'
    },
    artists_tab_enum: {
        'trending_artist': 'Trending Artists',
        'all_artist': 'All Artists',
    },
    home_result_types: {
        'video': 'video',
        'artist': 'artist',
        'client': 'client'
    },
    module: {
        'video': 'video',
        'artist': 'artist',
    },
    my_base_tabs_enum: {
        'watched_video': 'Watched Videos',
        'favorite_video': 'Favourite Videos',
        'watch_later': 'Watch Later',
        'favorite_artists': 'Favourite Artist',
        'followed_artists': 'Followed Artist',
        'recommendations': 'Recommendations',
        'pending_job_order': 'Pending Job Order'
    },
    my_base_tabs: {
        'watched_video': 'watched_video',
        'favorite_video': 'favorite_video',
        'watch_later': 'watch_later',
        'favorite_artists': 'favorite_artists',
        'followed_artists': 'followed_artists',
        'recommendations': 'recommendations',
        'pending_job_order': 'pending_job_order'
    },
    job_request_types: {
        'search_job': 'search_job',
        'created_job': 'created_job',
        'saved_job': 'saved_job',
        'applied_job': 'applied_job',
    },
    subscription_interval: {
        'days': 'Daily',
        'month': 'Monthly',
        'year': 'Yearly',
    },
    subscription_type: {
        'free': 'free',
        'paid': 'paid',
        'trial' :'trial' 
    },
    subscription_type_details: {
        'free': 'Free',
        'paid': 'Paid',
    },
    award_base_tabs_enum: {
        'video_of_the_year': 'video_of_the_year',
        'artist_of_the_year': 'artist_of_the_year',
        'song_of_the_year': 'song_of_the_year',
    },
    award_base_tabs_details: {
        'video_of_the_year': 'Video of the Year',
        'artsit_of_the_year': 'Artist of the Year',
        'song_of_the_year': 'Song of the Year',
    },
    job_search_types: {
        'job_title': 'job_title',
        'location': 'location',
        'company_title': 'company_title',
        'job_type': 'job_type'
    },
    constant_logged_user: {
        subscription_feature: {
            "video": {
                "watch": true,
                "favourite": false,
                "watch_later": false,
                "media_association": true,
                "suggestion_edit": false
            },
            "artist": {
                "follow": false,
                "media_association": true
            },
            "award": {
                "section": true
            },
            "job": {
                "save": false,
                "apply": false,
                "create_booking": false
            },
            "chat": {
                "message": false
            },
            "my_base": {
                "base_access": false
            },
            "notification": {
                "notification_section": false
            },
            "profile": {
                "profile_access": false
            }
        }
    },
    chat_module: {
        'user_to_user': 'user_to_user'
    },
    award_previous_years: 50,
    award_after_years: 0,
    profile_mobile_nav: [
        {
            link: '/messages',
            label: 'Messages',
            is_group: false
        },
        {
            link: '/notifications',
            label: 'Notification',
            is_group: false
        },
        {
            link: '/profile',
            label: 'Profile',
            is_group: false
        },
        {
            link: '/change/password',
            label: 'Password',
            is_group: false
        },
        {
            link: '/billing',
            label: 'Billing',
            is_group: false
        },
        {
            is_group: true,
            label: 'Job',
            group_options: [
                {
                    link: '/my-jobs',
                    label: 'Created Job',
                    is_group: false
                },
                {
                    link: '/saved-job',
                    label: 'Saved Jobs',
                    is_group: false
                },
                {
                    link: '/applied-job',
                    label: 'Applied Jobs',
                    is_group: false
                },
            ]
        },
    ],
    'social_type': {
        'facebook': 'facebook',
        'google': 'google',
    },
};
