import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import MbaseLogo from "../../assets/media/logos/m-base-logo.svg";
import Card from "react-bootstrap/Card";
import AwardIcon from "../../assets/media/icons/award-icon.svg";
import MarkerPin from "../../assets/media/icons/marker-pin.svg";
import TrackerIcon from "../../assets/media/icons/tracker-icon.svg";
import ArtistTopImg from "../../assets/media/img/artist-top-img.png";
import VerifiedTick from "../../assets/media/icons/verified-tick.svg";
import Button from "react-bootstrap/Button";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Offcanvas from "react-bootstrap/Offcanvas";
import Facebook from "../../assets/media/icons/facebook.svg";
import LinkedIn from "../../assets/media/icons/linkedin.svg";
import Youtube from "../../assets/media/icons/youtube.svg";
import Twitter from "../../assets/media/icons/twitter.svg";
import Telegram from "../../assets/media/icons/telegram.svg";
import Tiktok from "../../assets/media/icons/tiktok.svg";
import Applemusic from "../../assets/media/icons/applemusic.svg";
import Spotify from "../../assets/media/icons/spotify.svg";
import Snapchat from "../../assets/media/icons/snapchat.svg";
import APICall from "../../networking/AxiousServices";
import ApiTypes from "../../networking/APItypes";
import { useEffect } from "react";
import NodataFound from "../Errors/NodataFound";
import SiteLoader from "../../Component/Loaders/SiteLoader";
import SocialIcon from "../../common/SocialIcon";
import ArtistDefault from "../../assets/Images/Defaults/artist_default.svg";
import IsFollow from "../../Component/FollowUnFollow/IsFollow";
import { useDispatch, useSelector } from "react-redux";
import PreviewImg from "../../Component/Image/PreviewImg";
import IsFavourite from "../../Component/Favourite/IsFavourite";
import { Enums } from "../../constants/Enums";
import ArtistVideosModal from "./ArtistVideosModal";
import { commonHelper } from "../../common/commonHelper";
import ArtistVideoAssocications from "./ArtistVideoAssocications";
import NoAwardFound from "../Errors/NoAwardFound";
import Swal from "sweetalert2/dist/sweetalert2.js";
import LoadMoreLoader from "../../Component/Loaders/LoadMoreLoader";

function Artist() {
  const [showArtistVideoPopup, setShowArtistVideoPopup] = useState(false);

  const SubscriptionFeature = useSelector(
    (state) => state.data.logged_user.subscription_feature
  );
  const isLogIn = useSelector((state) => state.data.auth_token);
  const UserData = useSelector((state) => state.data);
  const state = useSelector((state) => state);
  const authToken = UserData.auth_token;
  const authUserId = UserData.logged_user.user_id;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const query_params = new URLSearchParams(location.search);
  const artist_id = query_params.get("aid");
  const [loading, setLoading] = useState(true);
  const [artistDetails, setArtistDetails] = useState({});

  const [artistType, setArtistType] = useState(null);

  const [chatLoading, setChatLoading] = useState(false);

  const [claimLoading, setClaimLoading] = useState(false);

  const rotateHandler = () => {
    // setState(() => !state);
  };

  const fetchArtistDetails = async () => {
    let res = await APICall({
      url: ApiTypes.fetchArtistDetails,
      data: {
        artist_id: artist_id,
      },
    });
    if (res.status === 1) {
      setLoading(false);
      setArtistDetails(res.data);
      setClaimLoading(false);
    } else {
      if (res.data.status === 0) {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (artist_id) {
      setLoading(true);
      fetchArtistDetails();
    }
  }, [UserData.subscription_modal, artist_id]);

  // useEffect(() => {
  //   if (artist_id) {
  //     fetchArtistDetails();
  //   }
  // }, []);

  const renderLocation = (value) => {
    let str = value.address1
      ? value.address1 + ", "
      : "" +
        // ", " +
        (value?.address2 ? value.address2 + ", " : "") +
        (value?.city ? value.city + ", " : "") +
        (value.state ? value.state + ", " : "") +
        (value.address_3 ? value.address_3 + ", " : "") +
        (value.country ? value.country + ", " : "");
    str = str.trim();
    str = str.slice(0, -1);
    if (str.length && str && str !== "null") {
      return str;
    } else {
      return "-";
    }
  };

  const handleNavigateMessages = async (e, details_got) => {
    e.preventDefault();
    setChatLoading(true);
    let res = await APICall({
      url: ApiTypes.createChatRoom,
      data: {
        sender_user_id: authUserId,
        receiver_user_id: details_got.ref_user_id,
        module: Enums.chat_module.user_to_user,
      },
    });
    if (res.status === 1) {
      setChatLoading(false);
      navigate("/messages", {
        state: {
          socket_id: res.data.chat_room_id,
          rec_id: details_got.ref_user_id,
          display_name: details_got.ref_display_name,
          profile_img: details_got.ref_profile_img,
          is_online: parseInt(details_got.is_online),
        },
      });
    }
  };

  const handleClaimProfile = async () => {
    if (authToken) {
      if (artistDetails.is_user_subscribe === 1) {
        Swal.fire({
          title: "Are you sure you want to claim this profile?",
          showDenyButton: true,
          confirmButtonText: "Yes",
          denyButtonText: `NO`,
        }).then(async (result) => {
          if (result.isConfirmed) {
            setClaimLoading(true);
            let res = await APICall({
              url: ApiTypes.claimProfile,
              data: {
                artist_id: artist_id,
                client_id: UserData.logged_user.association_id,
              },
            });
            if (res.status === 1) {
              fetchArtistDetails();
            } else {
              if (res.data.status === 0) {
                setClaimLoading(false);
              }
            }
          }
        });
      } else {
        dispatch({ type: "SUBSCRIPTION_MODAL", payload: true });
      }
    } else {
      let redirectUrl = location.pathname.slice(1) + location.search;
      navigate(`/login?redirectUrl=${redirectUrl}`);
    }
  };
  return !loading ? (
    Object.keys(artistDetails).length ? (
      <div className="main-content">
        <div className="artist-profile new-class mb-4">
          <Container>
            <div className="row">
              <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 mb-3 pb-4 pb-lg-0 pb-xl-0">
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 mb-3 mobile-left-col">
                    <div className="artist-top-col">
                      <div className="artist-img">
                        <PreviewImg
                          src={
                            artistDetails.profile_img
                              ? artistDetails.profile_img
                              : ArtistDefault
                          }
                          isDefault={artistDetails.profile_img ? false : true}
                          alt="ArtistTopImg"
                          //  width={240}
                          defaultImg={ArtistDefault}
                          className="w-100 rounded-circle"
                        />
                        {/* <img
                          src={
                            artistDetails.profile_img
                              ? process.env.REACT_APP_ASSETS_URL +
                              artistDetails.profile_img
                              : ArtistDefault
                          }
                          alt="ArtistTopImg"
                          className="w-100"
                        /> */}
                        {parseInt(artistDetails.is_profile_verified) != "0" && (
                          <div className="artist-verifie-tick">
                            <img src={VerifiedTick} alt="VerifiedTick" />
                          </div>
                        )}
                      </div>

                      {/* <div className="artist-name">
                        {artistDetails.display_name}
                      </div> */}
                      <div className="text-center mt-4 claim-button">
                        {artistDetails.is_claimed == 0  ? (
                          !claimLoading ? (
                            <Button
                              type="button"
                              onClick={() => {
                                handleClaimProfile();
                              }}
                              className="w-auto"
                            >
                              CLAIM PROFILE
                            </Button>
                          ) : (
                            <LoadMoreLoader />
                          )
                        ) : (
                          <Button
                            type="button"
                            disabled={true}
                            className="w-auto"
                          >
                            CLAIMED
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 mb-md-3 mobile-right-col">
                    {artistDetails.main_job_title && (
                      <span className="job-title text-uppercase">
                        {artistDetails.main_job_title}
                      </span>
                    )}
                    <div className="artist-name text-uppercase">
                      {artistDetails.display_name}
                    </div>
                    {SubscriptionFeature.artist.media_association && (
                      <div className="artist-social-media">
                        {artistDetails.social_connects.map((social, index) => {
                          return (
                            <SocialIcon
                              key={index}
                              social_icon={social.social_icon}
                              url={social.profile_url}
                              classLink={"me-2 mb-2"}
                            />
                          );
                        })}
                      </div>
                    )}
                    <div className="artist-about-button address-bottom-col my-4 d-none d-md-flex">
                      <div className="tracker-left me-3 d-flex">
                        <span className="me-2 tracker-icon">
                          <img src={TrackerIcon} alt="Award Icon" />
                        </span>
                        <span className="tracker-details trk-1 d-flex">
                          <span className="me-2">
                            {artistDetails.total_followers
                              ? artistDetails.total_followers
                              : 0}{" "}
                          </span>{" "}
                          {!artistDetails.total_followers ||
                          parseInt(artistDetails.total_followers) === 1
                            ? "Tracker"
                            : "Trackers"}
                        </span>
                      </div>
                      <div className="tracker-right">
                        <span className="me-2 tracker-icon">
                          <img src={MarkerPin} alt="Award Icon" />
                        </span>
                        <span className="tracker-details">
                          {renderLocation(artistDetails.location)}
                        </span>
                      </div>
                    </div>
                    <div className="artist-about-button mb-4 mt-2 d-none d-md-flex">
                      <IsFavourite
                        FavComponent={() => (
                          <>
                            <Button className="transparent-btn star-sharp me-3">
                              <i className="fa-solid fa-star-sharp"></i>
                              {/* <img className='cursor-pointer' src={FielStar} alt="Remove Favourite" /> */}
                            </Button>
                          </>
                        )}
                        UnFavComponent={() => (
                          <>
                            <Button className="transparent-btn star-sharp me-3">
                              <i className="start-un-favour fa-solid fa-star-sharp"></i>
                            </Button>
                          </>
                        )}
                        module_id={artistDetails.artist_id}
                        module_type={Enums.module.artist}
                        is_favorite={artistDetails.is_favorite}
                      />
                      <IsFollow
                        getResBack={(value) => {
                          setArtistDetails({
                            ...artistDetails,
                            total_followers:
                              parseInt(value) === 1
                                ? parseInt(artistDetails.total_followers)
                                  ? parseInt(artistDetails.total_followers) + 1
                                  : 1
                                : parseInt(artistDetails.total_followers) - 1,
                          });
                        }}
                        is_follow={artistDetails.is_follow}
                        artist_id={artist_id}
                      />

                      {parseInt(artistDetails.is_profile_verified) === 1 &&
                        artistDetails.ref_user_id &&
                        artistDetails.ref_user_id !== authUserId && (
                          <Button
                            onClick={(e) => {
                              if (isLogIn) {
                                if (SubscriptionFeature.chat.message) {
                                  handleNavigateMessages(e, artistDetails);
                                } else {
                                  dispatch({
                                    type: "SUBSCRIPTION_MODAL",
                                    payload: true,
                                  });
                                }
                              } else {
                                navigate("/login");
                              }
                            }}
                            variant="primary"
                            type="submit"
                          >
                            Contact me
                          </Button>
                        )}

                      {/* <Button onClick={() => {
                      if (isLogIn) {
                        if (SubscriptionFeature.chat.message) {

                        } else {
                          dispatch({ type: "SUBSCRIPTION_MODAL", payload: true });
                        }
                      } else {
                        navigate('/login')
                      }

                    }} variant="primary" type="submit">
                      Contact me
                    </Button> */}
                    </div>
                  </div>
                </div>
                <div className="artist-about mt-4">
                  <div className="artist-left-title">About Me</div>
                  <div className="artist-left-discription">
                    {/* <p className="mb-3">
                     
                    </p> */}
                    {artistDetails.mini_bio ? artistDetails.mini_bio : "-"}
                  </div>
                </div>
                <div className="artist-about-button address-bottom-col my-4 d-flex d-md-none">
                  <div className="tracker-left me-3 d-flex">
                    <span className="me-2 tracker-icon">
                      <img src={TrackerIcon} alt="Award Icon" />
                    </span>
                    <span className="tracker-details trk-2 d-flex">
                      <span className="me-2">
                        {artistDetails.total_followers
                          ? artistDetails.total_followers
                          : 0}{" "}
                      </span>{" "}
                      {parseInt(artistDetails.total_followers) == 0 ||
                      parseInt(artistDetails.total_followers) === 1
                        ? "Tracker"
                        : "Trackers"}
                    </span>
                  </div>
                  <div className="tracker-right">
                    <span className="me-2 tracker-icon">
                      <img src={MarkerPin} alt="Award Icon" />
                    </span>
                    <span className="tracker-details">
                      {renderLocation(artistDetails.location)}
                    </span>
                  </div>
                </div>
                <div className="artist-about-button my-4 d-flex d-md-none">
                  <IsFavourite
                    FavComponent={() => (
                      <>
                        <Button className="transparent-btn star-sharp me-3">
                          <i className="fa-solid fa-star-sharp"></i>
                          {/* <img className='cursor-pointer' src={FielStar} alt="Remove Favourite" /> */}
                        </Button>
                      </>
                    )}
                    UnFavComponent={() => (
                      <>
                        <Button className="transparent-btn star-sharp me-3">
                          <i className="start-un-favour fa-solid fa-star-sharp"></i>
                        </Button>
                      </>
                    )}
                    module_id={artistDetails.artist_id}
                    module_type={Enums.module.artist}
                    is_favorite={artistDetails.is_favorite}
                  />
                  <IsFollow
                    getResBack={(value) => {
                      setArtistDetails({
                        ...artistDetails,
                        total_followers:
                          parseInt(value) === 1
                            ? parseInt(artistDetails.total_followers)
                              ? parseInt(artistDetails.total_followers) + 1
                              : 1
                            : parseInt(artistDetails.total_followers) - 1,
                      });
                    }}
                    is_follow={artistDetails.is_follow}
                    artist_id={artist_id}
                  />

                  {parseInt(artistDetails.is_profile_verified) === 1 &&
                    artistDetails.ref_user_id &&
                    artistDetails.ref_user_id !== authUserId && (
                      <Button
                        onClick={(e) => {
                          if (isLogIn) {
                            if (SubscriptionFeature.chat.message) {
                              handleNavigateMessages(e, artistDetails);
                            } else {
                              dispatch({
                                type: "SUBSCRIPTION_MODAL",
                                payload: true,
                              });
                            }
                          } else {
                            navigate("/login");
                          }
                        }}
                        variant="primary"
                        type="submit"
                      >
                        Contact me
                      </Button>
                    )}

                  {/* <Button onClick={() => {
                      if (isLogIn) {
                        if (SubscriptionFeature.chat.message) {

                        } else {
                          dispatch({ type: "SUBSCRIPTION_MODAL", payload: true });
                        }
                      } else {
                        navigate('/login')
                      }

                    }} variant="primary" type="submit">
                      Contact me
                    </Button> */}
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                <ArtistVideoAssocications
                  setArtistType={setArtistType}
                  setShowArtistVideoPopup={setShowArtistVideoPopup}
                  artist_id={artist_id}
                  rotateHandler={rotateHandler}
                />
              </div>
            </div>
          </Container>
        </div>
        <div className="awards-section">
          <Container>
            <div className="row">
              <div className="col-12">
                <Card>
                  <Card.Body className="p-0">
                    <Card.Title>Awards</Card.Title>
                    {/* <Card.Text>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Porttitor habitant amet arcu dolor ut lorem est, facilisi.
                      Sed a nunc.
                    </Card.Text> */}
                    <div className="award-items mt-5 ">
                      {artistDetails.award_nominees.length ? (
                        artistDetails.award_nominees.map((award_nom, i) => (
                          <div
                            key={i}
                            className="award-item d-flex align-items-center me-4 mb-3"
                          >
                            <span className="award-icon d-flex align-items-center justify-content-center">
                              <img src={AwardIcon} alt="Award Icon" />
                            </span>
                            <p className={`award-discription-area mb-0`}>
                              {award_nom.award_type_detail} -{" "}
                              {award_nom.year_of_award}{" "}
                              {parseInt(award_nom.is_winner) === 1 ? (
                                <span className="text-success">(Winner)</span>
                              ) : (
                                <span className="text-warning">(Nominee)</span>
                              )}
                            </p>
                          </div>
                        ))
                      ) : (
                        <NoAwardFound />
                      )}
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </div>
          </Container>
        </div>
        <ArtistVideosModal
          artistType={artistType}
          artist_id={artist_id}
          showArtistVideoPopup={showArtistVideoPopup}
          setShowArtistVideoPopup={setShowArtistVideoPopup}
        />
      </div>
    ) : (
      <NodataFound />
    )
  ) : (
    <SiteLoader />
  );
}

export default Artist;
