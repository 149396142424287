import React from "react";
import { Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
// import ForgotPassword from "../../assets/media/img/Featured-icon.svg";
import ResetPassword from "../../assets/media/img/pdf-password.png";

function ResetComplete() {
  document.title = process.env.REACT_APP_NAME + " | " + "Reset Successful";
  const navigate = useNavigate();
  return (
    <div className="position-relative d-flex align-items-center justify-content-center vh-100">
      {/* <img src={ForgotPassword} className="auth-logo" alt="Login Logo" /> */}
      <div className="signup-box d-flex flex-column justify-content-center">
        <img src={ResetPassword} className="pdfpassword mb-3" />
        <h1 className="text-center">Password reset</h1>
        <p className="text-center">
          Your password has been successfully reset. Click below to login.
        </p>
        <Form>
          <div className="d-grid gap-3">
            <Button
              onClick={() => {
                navigate("/login");
              }}
              variant="primary"
              type="button"
            >
              Login
            </Button>
          </div>
          {/* <div className="already-login-line">
            <i className="fa-regular fa-arrow-left me-2"></i>Back to log in
          </div> */}
        </Form>
      </div>
    </div>
  );
}

export default ResetComplete;
