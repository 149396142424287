import React from 'react'
import { Table, Form } from "react-bootstrap";
import img from "../../assets/media/img/Image1.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import MbaseLogo from "../../assets/media/logos/m-base-logo.svg";
import Button from "react-bootstrap/Button";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Offcanvas from "react-bootstrap/Offcanvas";
import Visa from "../../assets/media/img/visa.png";
import Pdf from "../../assets/media/img/pdf.png";
import { useState } from 'react';
import { useEffect } from 'react';
import { Enums } from '../../constants/Enums';
import ApiTypes from '../../networking/APItypes';
import APICall from '../../networking/AxiousServices';
import FetchLoader from '../../Component/Loaders/FetchLoader';
import LoadMoreLoader from '../../Component/Loaders/LoadMoreLoader';
import { commonHelper } from '../../common/commonHelper';

export default function Invoice() {
    const [page, setPage] = useState(1);
    const [invoices, setInvoices] = useState([]);
    const [totalInvoices, setTotalInvoices] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [loading, setLoading] = useState(false);
    const [preLoading, setPreLoading] = useState(true);


    const fetchInvoiceList = async () => {
        setLoading(true);
        let res = await APICall({
            url: ApiTypes.fetchInvoices,
            data: {
                is_filter: 1,
                page: page,
                limit: perPage,
            },
        });
        if (res.status === 1) {
            setPreLoading(false);
            setTotalInvoices(res.data.total);
            return res.data.list;
        } else {
            return [];
        }
    };

    const fetchLoadMoreInvoice = async () => {
        setLoading(true);
        let data = await fetchInvoiceList();
        setInvoices([...invoices, ...data]);
        setLoading(false);
    };
    useEffect(() => {
        // if (jobPage !== 1) {
        fetchLoadMoreInvoice();
        // }
    }, [page]);

    const handleLoadMore = async () => {
        setPage(page + 1);
    };



    return (<div className="table-add-inn">
        {!preLoading ?
            <>
                <Table responsive>
                    <thead>
                        <tr>
                            {/* <th>Invoice</th> */}
                            <th>
                                Billing date <i className="fa-regular fa-arrow-down-long"></i>
                            </th>
                            {/* <th>Subscription Id</th> */}
                            <th>Plan Type</th>
                            <th>Plan</th>
                            <th>Amount</th>
                            <th>Start Date</th>
                            <th>Cancel Date</th>
                            <th>Expiry Date</th>
                            <th>Status</th>
                            <th className="width-100px"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {invoices.length > 0 ? invoices.map((invoice, index) => {
                            return (<tr key={index}>
                                {/* <td>
                                    <div className="d-flex align-items-center">
                                        <span className="purple-img">
                                            <img src={Pdf} alt="" />
                                        </span>
                                        <span>Invoice #007 – Dec 2022</span>
                                    </div>
                                </td> */}

                                <td className="gray-color">{invoice.invoice_date ? commonHelper.formatDate(invoice.invoice_date, 'MM-DD-YYYY' , 'utc_to_local') : "-"}</td>
                                {/* <td>{invoice.paypal_subscription_id ? invoice.paypal_subscription_id : "-"}</td> */}
                                <td>{invoice.interval_type ? Enums.subscription_interval[invoice.interval_type] : "-"}</td>
                                <td className="gray-color">{invoice.subscription_name ? invoice.subscription_name : "-"}</td>
                                <td className="gray-color">{invoice.trans_amount ? `USD $ + ${parseFloat(invoice.trans_amount).toFixed(2)}` : "-"}</td>
                                <td className="gray-color">{invoice.start_date ? commonHelper.formatDate(invoice.start_date, 'MM-DD-YYYY') : "-"}</td>
                                <td className="gray-color">{invoice.cancelled_on ? commonHelper.formatDate(invoice.cancelled_on, 'MM-DD-YYYY') : "-"}</td>
                                <td className="gray-color">{invoice.end_date ? commonHelper.formatDate(invoice.end_date, 'MM-DD-YYYY') : "-"}</td>
                                <td>
                                    <label htmlFor="" className={` ${invoice.subscription_type == Enums.subscription_type.paid ? "light-green" : " "} ${invoice.subscription_type == Enums.subscription_type.free ? "light-warning" : " "}`}>
                                        <i className="fa-light fa-check"></i> {Enums.subscription_type_details[invoice.subscription_type]}
                                    </label>
                                </td>
                                <td>
                                    {invoice.invoice_pdf && invoice.invoice_pdf !== "" ? <a target="_blank"
                                        onClick={() => {
                                            let link = process.env.REACT_APP_ASSETS_URL + invoice.invoice_pdf;
                                            if (link.split('.').pop() === 'pdf') {
                                                let pdfWindow = window.open("")
                                                pdfWindow.document.write(
                                                    `<iframe width='100%' height='100%' src=${link}></iframe>`
                                                )
                                            } else {
                                                let aTag = document.createElement('a');
                                                aTag.setAttribute('href', link)
                                                aTag.setAttribute('target', '_blank')
                                                aTag.click();

                                            }
                                        }}
                                        href="#"
                                    >
                                        Download
                                    </a> : ''}
                                </td>
                            </tr>)
                        }) : <tr >
                            <td className='text-center' colspan={7}>No Invoices Found!</td>
                        </tr>}

                    </tbody>
                </Table>
                {invoices.length < totalInvoices ? (
                    <div className="row">
                        <div className="col-12 text-center">
                            {!loading ? (
                                <Button
                                    onClick={() => {
                                        handleLoadMore();
                                    }}
                                    variant="primary"
                                    type="submit"
                                >
                                    Load More
                                </Button>
                            ) : (
                                <LoadMoreLoader />
                            )}
                        </div>
                    </div>
                ) : (
                    ""
                )}
            </>
            : <FetchLoader />}
    </div>)
}
