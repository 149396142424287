import React from 'react'
import { useState } from 'react';
import ApiTypes from '../../networking/APItypes';
import APICall from '../../networking/AxiousServices';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'react-bootstrap'

export default function SaveJobHandle({ jobLength, setJobLength, job_item, jobCardId }) {
    const SubscriptionFeature = useSelector((state) => state.data.logged_user.subscription_feature);
    const dispatch = useDispatch()
    
    const [isSaved, setIsSaved] = useState(job_item.is_saved == 1 ? true : false);
    const [isLoading, setIsLoading] = useState(false);



    const handleSaveJob = async (is_save) => {
        if(SubscriptionFeature.job.save){
            if (!isLoading) {
                let data = {
                    job_id: job_item.job_id,
                    is_save: is_save
                }
                setIsLoading(true)
                let res = await APICall({
                    url: ApiTypes.addOrRemoveFromSaveJob,
                    data: data
                })
                if (res.status === 1) {
                    setIsLoading(false)
                    setIsSaved(parseInt(is_save) === 1 ? true : false)
                    if (parseInt(is_save) === 0 && window.location.pathname !== '/bookings') {
                        let jobCard = document.getElementById(jobCardId);
                        if (jobCard) {
                            jobCard.setAttribute('style' , 'display:none;');
                            setJobLength(jobLength - 1)
                        }
                     
                    }
                }
            }
        }else{
            dispatch({ type: "SUBSCRIPTION_MODAL", payload: true });
        }
      
    }

    return (isSaved ? <Button
        onClick={() => handleSaveJob(0)}
        variant="primary"
        type="button"
        className="transparent-btn btn btn-primary"

    >
        Remove
    </Button> : <Button
        onClick={() => handleSaveJob(1)}
        variant="primary"
        type="button"
        className="transparent-btn btn btn-primary"

    >
        Save
    </Button>)
}
