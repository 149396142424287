import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import SiteLoader from '../../Component/Loaders/SiteLoader';
import ApiTypes from '../../networking/APItypes';
import APICall from '../../networking/AxiousServices';
import ReactHtmlParser from "react-html-parser";
import { Container } from 'react-bootstrap';

export default function TermAndCondition() {
    const [termAndCondition, setTermAndCondition] = useState("");
    const [loading, setLoading] = useState(true);

    const fetchSetting = async () => {
        let res = await APICall({
            url: ApiTypes.fetchWebSetting
        })
        if (res.status === 1) {
            if (Object.keys(res.data).length) {
                setTermAndCondition(res.data.term_and_condition ? res.data.term_and_condition : "")
                setLoading(false)
            } else {
                setLoading(false)
            }
        } else {
            if (res.data.status === 0) {
                setLoading(false)
            } else {
                setLoading(false)

            }
        }
    }

    useEffect(() => {
        fetchSetting()
    }, []);

    return (!loading ?
        <div className='term-and-condition'>
            <Container>
                <h2>Terms And Conditions</h2>
                {ReactHtmlParser(termAndCondition)}
            </Container>
        </div>
        :
        <SiteLoader />
    )
}
