import React, { useState } from "react";
import Container from "react-bootstrap/Container";
//import MbaseLogo from "../assets/media/logos/m-base-logo.svg";
import Button from "react-bootstrap/Button";
//import Navbar from "react-bootstrap/Navbar";
//import Offcanvas from "react-bootstrap/Offcanvas";
import ProfileImg from "../../assets/media/img/profile-img.png";
import Card from "react-bootstrap/Card";
import img from "../../assets/media/img/Image1.png";
import { Modal, Row } from "react-bootstrap";
import ViewResume from "../../assets/media/icons/view-resume.svg";
import ProfileMenuHeader from "../../Component/ProfileMenuHeader/ProfileMenuHeader";
import CreateJob from "./CreateJob";
import { useEffect } from "react";
import APICall from "../../networking/AxiousServices";
import ApiTypes from "../../networking/APItypes";
import { Enums } from "../../constants/Enums";

import SiteLoader from "../../Component/Loaders/SiteLoader";
import LoadMoreLoader from "../../Component/Loaders/LoadMoreLoader";
import JobListCard from "../../Component/Job/JobListCard";
import NodataFound from "../Errors/NodataFound";
function AppliedJob() {
  const [viewCreateModel, setViewCreateJobModel] = useState(false);
  document.title = process.env.REACT_APP_NAME + " | Applied Jobs";

  const [jobPage, setJobPage] = useState(1);
  const [jobs, setJobs] = useState([]);
  const [totalJobs, setTotalJobs] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [preLoading, setPreLoading] = useState(true);

  const fetchJobList = async () => {
    setLoading(true);
    let res = await APICall({
      url: ApiTypes.fetchJobList,
      data: {
        is_filter: 1,
        filter: {
          request_type: Enums.job_request_types.applied_job,
        },
        page: jobPage,
        limit: perPage,
      },
    });
    if (res.status === 1) {
      setPreLoading(false);
      setTotalJobs(res.data.total);
      return res.data.list;
    } else {
      return [];
    }
  };

  const fetchLoadMoreJob = async () => {
    setLoading(true);
    let data = await fetchJobList();
    setJobs([...jobs, ...data]);
    setLoading(false);
  };
  useEffect(() => {
    // if (jobPage !== 1) {
    fetchLoadMoreJob();
    // }
  }, [jobPage]);

  const handleLoadMore = async () => {
    setJobPage(jobPage + 1);
  };

  return (
    <div className="main-content">
      <ProfileMenuHeader />
      <div className="cards-class-all Applied-page-main">
        <Container>
          <div className="title-class-add d-flex justify-content-between">
            <div className="title-add">Applied Jobs</div>
          </div>
          <Row>
            {!preLoading ? (
              jobs.length ? (
                jobs.map((job_item, index) => {
                  return (
                    <JobListCard
                      keyIndex={index}
                      jobsLen={jobs.length}
                      isView={true}
                      key={index}
                      job_item={job_item}
                    />
                  );
                })
              ) : (
                <NodataFound />
              )
            ) : (
              <SiteLoader />
            )}
          </Row>
          {jobs.length < totalJobs ? (
            <div className="row">
              <div className="col-12 text-center mt-5">
                {!loading ? (
                  <Button
                    onClick={() => {
                      handleLoadMore();
                    }}
                    variant="primary"
                    type="submit"
                  >
                    Load More
                  </Button>
                ) : (
                  <LoadMoreLoader />
                )}
              </div>
            </div>
          ) : (
            ""
          )}
        </Container>
      </div>
    </div>
  );
}

export default AppliedJob;
