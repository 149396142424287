import React, { useState } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ForgotPassword from "../../assets/media/img/Featured-icon.svg";
import LoadMoreLoader from "../../Component/Loaders/LoadMoreLoader";
import ApiTypes from "../../networking/APItypes";
import APICall from "../../networking/AxiousServices";

function AuthForgotPass() {
  document.title = process.env.REACT_APP_NAME + " | " + "Forget Password";

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const pattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  );

  const [formInputs, setFormInputs] = useState({
    username: "",
  });

  const [formInputsErrors, setFormInputsErrors] = useState({
    username: "",
  });

  const validationError = {
    username: "Email field is required.",
    validate_email: "Email is not valid",
  };

  const validateForm = () => {
    let errorJson = {};
    let isValidate = true;

    Object.keys(formInputsErrors).forEach((form_er_key) => {
      if (formInputs[form_er_key] == "") {
        errorJson = {
          ...errorJson,
          [form_er_key]: validationError[form_er_key],
        };
        isValidate = false;
      }
      if (form_er_key === "username") {
        if (formInputs[form_er_key] !== "") {
          if (!pattern.test(formInputs.username)) {
            errorJson = {
              ...errorJson,
              [form_er_key]: validationError.validate_email,
            };
            isValidate = false;
          }
        }
      }
    });

    setFormInputsErrors(errorJson);
    return isValidate;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setLoading(true);

      let res = await APICall({
        url: ApiTypes.forgotPassword,
        data: formInputs,
      });

      if (res.status === 1) {
        setLoading(false);
        navigate("/email/sent", {
          state: {
            email: formInputs.username,
          },
        });
      } else {
        if (res.data.status === 0) {
          setFormInputsErrors({
            username: res.data.message,
          });
          setLoading(false);
        }
      }
    }
  };
  return (
    <div className="position-relative d-flex align-items-center justify-content-center vh-100">
      <img src={ForgotPassword} className="auth-logo" alt="Login Logo" />
      <div className="signup-box d-flex flex-column justify-content-center">
        <h1 className="text-center">Forgot password?</h1>
        <p className="text-center">
          No worries, we’ll send you reset instructions.
        </p>
        <Form
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Email <span className="required d-inline-block">*</span></Form.Label>
            <Form.Control
              onChange={(e) => {
                setFormInputs({
                  ...formInputs,
                  username: e.target.value ? e.target.value : "",
                });
              }}
              type="email"
              value={formInputs.username}
              placeholder="Enter your email"
              required
            />
            {formInputsErrors.username !== "" && (
              <Form.Text className="text-danger">
                {/* Must be at least 8 characters. */}
                {formInputsErrors.username}
              </Form.Text>
            )}
          </Form.Group>
          <div className="d-grid gap-3">
            {!loading ? (
              <Button variant="primary" type="submit">
                Reset password
              </Button>
            ) : (
              <LoadMoreLoader />
            )}
          </div>
          <div
            onClick={() => {
              navigate("/login");
            }}
            className="cursor-pointer already-login-line"
          >
            <span className="d-inline-block me-2">
              <i class="fa-solid fa-arrow-left"></i>
            </span>
            Back to log in
          </div>
        </Form>
      </div>
    </div>
  );
}

export default AuthForgotPass;
