import React, { useRef } from "react";
import { Card, Button } from "react-bootstrap";
import img from "../../assets/media/img/Image1.png";
import DefaultCompany from "../../assets/Images/Defaults/default_company.png";
import { Link } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import SaveJobHandle from "./SaveJobHandle";
import ApplyJob from "../../pages/MyJob/ApplyJob";
import { useState } from "react";
import JobApplicants from "../../pages/MyJob/JobApplicants";
import NodataFound from "../../pages/Errors/NodataFound";
import APICall from "../../networking/AxiousServices";
import ApiTypes from "../../networking/APItypes";
import AppliedJobDetails from "../../pages/MyJob/AppliedJobDetails";
import { useDispatch, useSelector } from "react-redux";
import PreviewImg from "../Image/PreviewImg";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

export default function JobListCard({
  jobsLen,
  job_item,
  keyIndex,
  isApplicant = false,
  isDelete = false,
  isSave = false,
  isApply = false,
  isView = false,
}) {
  const SubscriptionFeature = useSelector(
    (state) => state.data.logged_user.subscription_feature
  );
  const dispatch = useDispatch();
  const JObSubscriptionFeature = SubscriptionFeature.job;
  const [jobLength, setJobLength] = useState(jobsLen);
  const [isLoading, setIsLoading] = useState(false);

  //Apply Job
  const [showApplyJob, setShowApplyJob] = useState(false);
  const [applyJobId, setApplyJobId] = useState(null);

  //Job Applicants
  const [showApplicantJob, setShowApplicantJob] = useState(false);
  const [applicantJobId, setApplicantJobId] = useState(null);

  //Applied Job Details
  const [showAppliedJobDetails, setShowAppliedJobDetails] = useState(false);
  const [appliedJobDetailId, setAppliedJobDetailId] = useState(null);

  const handleShowApplicant = (job_id) => {
    setApplicantJobId(job_id);
    setShowApplicantJob(true);
  };

  const renderLocation = (value) => {
    let str =
    value.address1 ? value.address1 + ", " : "" +
      (value.city ? value.city + ", " : "") +
      (value.state ? value.state + ", " : "") +
      (value.country ? value.country + ", " : "") +
      (value.postal_code ? value.postal_code + ", " : "");
    str = str.trim();
    str = str.slice(0, -1);
    if (str.length && str && str !== "null") {
      return str;
    } else {
      return "-";
    }
  };

  const handleDelete = async () => {
    if (JObSubscriptionFeature.create_booking) {
      if (!isLoading) {
        let data = {
          job_id: job_item.job_id,
        };

        setIsLoading(true);

        let res = await APICall({
          url: ApiTypes.removeJob,
          data: data,
        });
        if (res.status === 1) {
          setIsLoading(false);

          let jobCard = document.getElementById(`job-card-${keyIndex}`);
          if (jobCard) {
            jobCard.setAttribute("style", "display:none;");
            setJobLength(jobLength - 1);
          }
        }
      }
    } else {
      dispatch({ type: "SUBSCRIPTION_MODAL", payload: true });
    }
  };

  return jobLength ? (
    <div
      className="col-lg-4 col-xl-4 col-md-6 col-sm-12"
      key={keyIndex}
      id={`job-card-${keyIndex}`}
    >
      <Card>
        <Card.Body>
          <div className="add-all-two-title-and-cards d-flex">
            <Link to="#">
              <PreviewImg
                src={
                  job_item.company_image
                    ? job_item.company_image
                    : DefaultCompany
                }
                isDefault={job_item.company_image ? false : true}
                alt="cards"
                width={"40px"}
                height={"40px"}
                defaultImg={DefaultCompany}
                className="rounded-circle cards"
              />
              {/* <img
                            height={'40px'}
                            width={"40px"}
                            src={job_item.company_image ? process.env.REACT_APP_ASSETS_URL + job_item.company_image : DefaultCompany}
                            className="rounded-circle obj-fit-cover cards"
                            alt="cards"
                        /> */}
            </Link>
            <div>
              <span
                onClick={() => {
                  setShowAppliedJobDetails(true);
                  setAppliedJobDetailId(job_item.job_id);
                }}
                className="d-block ms-2 align-self-center cursor-pointer"
              // onClick={() => setModalCompanyInFoModal(true)}
              >
                {job_item.company_name} <span className="text-dark">(By {job_item.display_name})</span>
              </span>
              <span
                className="d-block ms-2 align-self-center text-dark"
              // onClick={() => setModalCompanyInFoModal(true)}
              >
                {job_item.job_type_detail}
              </span>
            </div>
          </div>
          <Card.Title>
            <div className="title-class">{job_item.job_title}</div>
          </Card.Title>
          <Card.Text>
            <div className="add-text-change-class scroll-here">
              {ReactHtmlParser(job_item.job_detail)}
            </div>
          </Card.Text>
          <div className="location-time-both-class d-flex">
            <div className="loaction-class mb-3 d-inline-block w-100">
              <i className="fa-regular fa-location-dot"></i>
              {/* <span className="ms-2">{renderLocation(job_item)}</span> */}
              <OverlayTrigger
                overlay={<Tooltip>{renderLocation(job_item)}</Tooltip>}
              >
                <span className="ms-2">{renderLocation(job_item)}</span>
              </OverlayTrigger>
            </div>
            {/* <div className="time-class d-flex align-items-center mb-3 justify-content-center ms-2">
                    <i className="fa-regular fa-clock"></i>
                    <span
                        className="ms-2"
                        dangerouslySetInnerHTML={{
                            __html: item.time,
                        }}
                    ></span>
                </div> */}
          </div>
          <div className="d-flex btnSec">
            {isDelete && (
              <div>
                <Button
                  variant="primary"
                  type="button"
                  onClick={handleDelete}
                  className="transparent-btn btn btn-primary"
                >
                  Delete
                </Button>
              </div>
            )}
            {isApplicant && (
              <div>
                <Button
                  type="button"
                  variant="primary button-red-classs"
                  onClick={() => {
                    handleShowApplicant(job_item.job_id);
                  }}
                >
                  Applicants
                </Button>
              </div>
            )}

            {isSave && (
              <SaveJobHandle
                jobCardId={`job-card-${keyIndex}`}
                job_item={job_item}
                jobLength={jobLength}
                setJobLength={setJobLength}
              />
            )}
            {isApply && (
              <Button
                type="button"
                onClick={() => {
                  if (JObSubscriptionFeature.apply) {
                    setShowApplyJob(true);
                    setApplyJobId(job_item.job_id);
                  } else {
                    dispatch({ type: "SUBSCRIPTION_MODAL", payload: true });
                  }
                }}
                variant="primary button-red-classs"
                disabled={job_item.is_applied === 1 ? true : false}
              >
                {job_item.is_applied === 1 ? "Applied" : "Apply"}
              </Button>
            )}
            {isView && (
              <Button
                onClick={() => {
                  setShowAppliedJobDetails(true);
                  setAppliedJobDetailId(job_item.job_id);
                }}
                variant="primary Apply-button w-100"
              >
                View
              </Button>
            )}

            {/* Models */}
            {isApply && (
              <ApplyJob
                applyJobId={applyJobId}
                setApplyJobId={setApplyJobId}
                showApplyJob={showApplyJob}
                setShowApplyJob={setShowApplyJob}
              />
            )}
            {isApplicant && (
              <JobApplicants
                setApplicantJobId={setApplicantJobId}
                showApplicantJob={showApplicantJob}
                setShowApplicantJob={setShowApplicantJob}
                applicantJobId={applicantJobId}
              />
            )}
            <AppliedJobDetails
              setShowAppliedJobDetails={setShowAppliedJobDetails}
              setAppliedJobDetailId={setAppliedJobDetailId}
              showAppliedJobDetails={showAppliedJobDetails}
              appliedJobDetailId={appliedJobDetailId}
            />
          </div>
        </Card.Body>
      </Card>
    </div>
  ) : (
    <NodataFound />
  );
}
