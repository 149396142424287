import React, { useRef } from "react";
import { Col, Form, InputGroup, Modal, Button, Row } from "react-bootstrap";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { useState } from "react";
import { ReactComponent as UploadIcon } from "../../assets/media/icons/upload-icon.svg";
import { useEffect } from "react";
import APICall from "../../networking/AxiousServices";
import ApiTypes from "../../networking/APItypes";
import { getBase64 } from "../../common/imageHelper";
import ClientDefault from "../../assets/Images/Defaults/client_default.webp";
import LoadMoreLoader from "../../Component/Loaders/LoadMoreLoader";
import PreviewImg from "../../Component/Image/PreviewImg";
import SiteLoader from "../../Component/Loaders/SiteLoader";

export default function CreateJob({
  viewCreateModel,
  setViewCreateJobModel,
  fetchJobList,
  setPreLoading,
  setLoading,
  setJobs,
}) {
  const pattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  );

  const [redirectError, setRedirectError] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);

  const [innerLoading, setInnerLoading] = useState(false);
  const [jobTypes, setJobTypes] = useState([]);

  const [jobDetail, setJobDetail] = useState("");

  const profileRef = useRef(null);

  const resetAllFun = () => {
    setRedirectError("");
    setFormInputs({
      company_name: "",
      company_email: "",
      website: "",
      linkedin_profile: "",
      job_title: "",
      // job_detail: "",
      payment_amount: "",
      company_image: "",
      address1: "",
      city: "",
      state: "",
      country: "",
      postal_code: "",
      start_date: "",
      end_date: "",
    });
    setJobDetail("");
    setFormInputsEr({
      first_name: "",
      last_name: "",
      company_image: "",
    });
  };

  const handleClose = () => {
    resetAllFun();
    setViewCreateJobModel(false); 
  };

  const [formInputs, setFormInputs] = useState({
    company_name: "",
    company_email: "",
    website: "",
    linkedin_profile: "",
    job_type: "",
    job_title: "",
    // job_detail: "",
    payment_amount: "",
    company_image: "",
    address1: "",
    city: "",
    state: "",
    country: "",
    postal_code: "",
    start_date: "",
    end_date: "",
  });
  const [formInputsEr, setFormInputsEr] = useState({
    company_name: "",
    company_email: "",
    job_type: "",
    job_title: "",
    job_detail: "",
    start_date: "",
    end_date: "",
    address1: "",
    city: "",
    state: "",
    country: "",
    postal_code: "",
    company_image: "",
    payment_amount: "",
  });

  const validationError = {
    company_name: "Company name is required.",
    company_email: "Company email is required.",
    job_type: "Please select job type.",
    job_title: "Job title is required.",
    job_detail: "Job descriptions is required.",
    start_date: "Start date is required.",
    end_date: "End date is required.",
    payment_amount: "Payment amount is required.",
    address1: "Address is required.",
    city: "City is required.",
    state: "State is required.",
    country: "Country is required.",
    postal_code: "Postal code is required.",
    validate_email: "Email is not valid",
    time_er: "End date should not be greater than start date.",
  };

  const handleProfileFile = (file) => {
    if (file.name.match(/\.(jpg|jpeg|png|svg|webp|JPEG|JPG|PNG|SVG|WEBP)$/)) {
      if (parseInt((file.size / 1000000).toFixed(2)) <= 5) {
        setFormInputsEr({
          ...formInputsEr,
          company_image: "",
        });
        getBase64(file)
          .then((result) => {
            setFormInputs({
              ...formInputs,
              company_image: result,
            });
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        setFormInputsEr({
          ...formInputsEr,
          company_image: "Image should be less than 5 MB.",
        });
      }
    } else {
      setFormInputsEr({
        ...formInputsEr,
        company_image: "Only jpg, jpeg, png, svg or webp files are supported.",
      });
    }
  };
  const handleProfileInput = (e) => {
    handleProfileFile(e.target.files[0]);
  };

  const handleProfileDrop = (e) => {
    e.preventDefault();
    handleProfileFile(e.dataTransfer.files[0]);
  };

  const fetchCities = async () => {
    let resCities = await APICall({
      url: ApiTypes.getCityList,
      data: {
        search: formInputs.city,
      },
    });
    if (resCities.status === 1) {
      setCities(resCities.data);
    }
  };

  const validateForm = () => {
    let errorJson = {
      company_name: "",
      company_email: "",
      job_title: "",
      // job_detail: "",
      job_type: "",
      address1: "",
      city: "",
      state: "",
      country: "",
      postal_code: "",
      start_date: "",
      end_date: "",
      payment_amount: "",
    };
    let isValidate = true;

    Object.keys(errorJson).forEach((form_er_key) => {
      if (formInputs[form_er_key] === "") {
        errorJson = {
          ...errorJson,
          [form_er_key]: validationError[form_er_key],
        };
        isValidate = false;
      } else {
        if (form_er_key === "company_email") {
          if (!pattern.test(formInputs.company_email)) {
            errorJson = {
              ...errorJson,
              [form_er_key]: validationError.validate_email,
            };
            isValidate = false;
          }
        }
      }
      if (formInputs.start_date !== "" && formInputs.end_date !== "") {
        if (formInputs.end_date < formInputs.start_date) {
          errorJson = {
            ...errorJson,
            end_date: validationError.time_er,
          };
          isValidate = false;
        }
      }
    });
    if (jobDetail === "") {
      errorJson = {
        ...errorJson,
        job_detail: validationError.job_detail,
      };
    }
    setFormInputsEr(errorJson);
    return isValidate;
  };
  const handleSubmit = async (e) => {
    setRedirectError("");
    e.preventDefault();
    if (validateForm()) {
      setSubmitLoading(true);
      let res = await APICall({
        url: ApiTypes.saveJob,
        data: {
          ...formInputs,
          job_detail: jobDetail,
          locations: [
            {
              address1: formInputs.address1,
              city: formInputs.city,
              state: formInputs.state,
              postal_code: formInputs.postal_code,
              country: formInputs.country,
            },
          ],
        },
      });
      setSubmitLoading(false);
      if (res.status === 1) {
        handleClose();
        setSubmitLoading(false);
        setPreLoading(true);
        setLoading(true);
        let data = await fetchJobList();
        setJobs(data);
        setLoading(false);
      } else {
        if (res.data.status === 0) {
          setLoading(false);
          setRedirectError(res.data.message);
        }
      }
    }
  };

  const [cities, setCities] = useState([]);

  useEffect(() => {
    if (formInputs.city.length > 2) {
      fetchCities();
    }
  }, [formInputs.city]);

  const fetchJobTypes = async () => {
    setInnerLoading(true);
    setPreLoading(true);
    let res = await APICall({
      url: ApiTypes.getJobTypes,
    });
    if (res.status === 1) {
      setInnerLoading(false);
      setPreLoading(false);
      setJobTypes(res.data.list);
    }
  };
  useEffect(() => {
    if (viewCreateModel) {
      fetchJobTypes();
    }
  }, [viewCreateModel]);

  return (
    <Modal
      show={viewCreateModel}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="job-profile-modal"
    >
      <Modal.Header closeButton>
        <div className="">
          <span className="details-col-title">Post Job</span>
        </div>
      </Modal.Header>
      <Modal.Body>
        {!innerLoading ? (
          <div className="account-profile-col">
            <div className="profile-top-col">
              <div className="profile-top-section">
                <div className="profile-title">Company info</div>
                {/*<div className="profile-discription">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        </div> */}
              </div>
              {redirectError !== "" && (
                <div className="error-div">
                  <div>{redirectError}</div>
                  <div>
                    <i
                      onClick={() => {
                        setRedirectError("");
                      }}
                      className="fa fa-close cursor-pointer"
                    ></i>
                  </div>
                </div>
              )}
              <div className="form-profile mt-4">
                <Form className="form pb-3 pt-3 pe-4 ps-4">
                  <Row>
                    <Col col={12}>
                      <Form.Group className="mb-3" controlId="formGroupEmail">
                        <Form.Label>Company Name <span className="required d-inline-block">*</span></Form.Label>
                        <Form.Control
                          value={formInputs.company_name}
                          onChange={(e) => {
                            setFormInputs({
                              ...formInputs,
                              company_name: e.target.value
                                ? e.target.value
                                : "",
                            });
                          }}
                          type="text"
                          placeholder="Company Name"
                        />
                        {formInputsEr.company_name !== "" && (
                          <Form.Text className="text-danger">
                            {formInputsEr.company_name}
                          </Form.Text>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col col={6}>
                      <Form.Group className="mb-3 email-address">
                        <Form.Label>Company Email Address <span className="required d-inline-block">*</span></Form.Label>
                        <div>
                          <Form.Control
                            type="email"
                            value={formInputs.company_email}
                            onChange={(e) => {
                              setFormInputs({
                                ...formInputs,
                                company_email: e.target.value
                                  ? e.target.value
                                  : "",
                              });
                            }}
                            placeholder="Company Email Address"
                          />
                          <span className="gmail-icon">
                            <i className="fa-regular fa-envelope"></i>
                          </span>
                        </div>
                        {formInputsEr.company_email !== "" && (
                          <Form.Text className="text-danger">
                            {formInputsEr.company_email}
                          </Form.Text>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col col={12}>
                      <Form.Label htmlFor="basic-url">
                        Company Website
                      </Form.Label>
                      <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon3">
                          http://
                        </InputGroup.Text>
                        <Form.Control
                          value={formInputs.website}
                          onChange={(e) => {
                            setFormInputs({
                              ...formInputs,
                              website: e.target.value ? e.target.value : "",
                            });
                          }}
                          id="basic-url"
                          aria-describedby="basic-addon3"
                        />
                      </InputGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col col={12}>
                      <Form.Label htmlFor="basic-url">
                        LinkedIn Profile
                      </Form.Label>
                      <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon3">
                          http://
                        </InputGroup.Text>
                        <Form.Control
                          value={formInputs.linkedin_profile}
                          onChange={(e) => {
                            setFormInputs({
                              ...formInputs,
                              linkedin_profile: e.target.value
                                ? e.target.value
                                : "",
                            });
                          }}
                          id="basic-url"
                          aria-describedby="basic-addon3"
                        />
                      </InputGroup>
                    </Col>
                  </Row>
                  <div className="row creatjobpro">
                    <div className="col-2">
                      <div className="profile-section">
                        {/* <PreviewImg
                                            src={formInputs.company_image !== "" ? formInputs.company_image : ClientDefault}
                                            isDefault={formInputs.company_image !== "" ? false : true}
                                            alt="ProfileImg"
                                            width={64}
                                            height={64}
                                            defaultImg={ClientDefault}
                                            className="rounded-circle"
                                        /> */}
                        <img
                          src={
                            formInputs.company_image !== ""
                              ? formInputs.company_image
                              : ClientDefault
                          }
                          alt="ProfileImg"
                          width="64"
                          height="64"
                          className="rounded-circle"
                        />
                      </div>
                    </div>
                    <div className="col-10">
                      <div
                        draggable={true}
                        onDrop={(e) => handleProfileDrop(e)}
                        className="draggable fileUploadWrap text-center"
                      >
                        <input
                          type="file"
                          name="fileToUpload"
                          accept="image/*"
                          ref={profileRef}
                          onChange={(e) => handleProfileInput(e)}
                          className="w-100 h-100"
                        />
                        <span className="upload-icon mx-auto rounded-circle d-flex align-items-center justify-content-center">
                          <UploadIcon />
                        </span>
                        <div className="fileupload-content mt-2">
                          <span className="me-1 cornflower-blue d-inline-block">
                            Click to upload profile pic
                          </span>
                          or drag and drop
                          <span className="d-block">
                            SVG, PNG, JPG or GIF (max. 800x400px)
                          </span>
                        </div>
                      </div>
                    </div>
                    {formInputsEr.company_image !== "" && (
                      <Form.Text className="text-danger">
                        {/* Must be at least 8 characters. */}
                        {formInputsEr.company_image}
                      </Form.Text>
                    )}
                  </div>
                </Form>
                {/* <div className="form-button pb-3 pt-3 mt-3 pe-4 ps-4 text-end">
                            <Button className="transparent-btn me-3">Cancel</Button>
                            <Button variant="primary" type="submit">
                                Save changes
                            </Button>
                        </div> */}
              </div>
            </div>
            <div className="profile-top-col mt-4">
              <div className="profile-top-section">
                <div className="profile-title">Job info</div>
                {/*<div className="profile-discription">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </div>*/}
              </div>
              <div className="form-profile mt-4">
                <Form className="form pb-3 pt-3 pe-4 ps-4">
                  <Row>
                    <Col className="mb-3" col={12}>
                      <Form.Label htmlFor="basic-url">Job Type</Form.Label>
                      <InputGroup>
                        <Form.Select
                          onChange={(e) => {
                            setFormInputs({
                              ...formInputs,
                              job_type:
                                e.target.value && e.target.value !== ""
                                  ? e.target.value
                                  : "",
                            });
                          }}
                          aria-label="Default select example"
                        >
                          <option value="">Select job type</option>
                          {jobTypes.map((job_item) => (
                            <option
                              selected={
                                job_item.value === formInputs.job_type
                                  ? true
                                  : false
                              }
                              value={job_item.value}
                            >
                              {job_item.label}
                            </option>
                          ))}
                        </Form.Select>
                      </InputGroup>
                      {formInputsEr.job_type !== "" && (
                        <Form.Text className="text-danger">
                          {formInputsEr.job_type}
                        </Form.Text>
                      )}
                    </Col>
                  </Row>

                  <Row>
                    <Col className="mb-3" col={12}>
                      <Form.Label htmlFor="basic-url">Job Title <span className="required d-inline-block">*</span></Form.Label>
                      <InputGroup>
                        <Form.Control
                          value={formInputs.job_title}
                          onChange={(e) => {
                            setFormInputs({
                              ...formInputs,
                              job_title: e.target.value ? e.target.value : "",
                            });
                          }}
                          id="basic-url"
                          aria-describedby="basic-addon3"
                        />
                      </InputGroup>
                      {formInputsEr.job_title !== "" && (
                        <Form.Text className="text-danger">
                          {formInputsEr.job_title}
                        </Form.Text>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col col={12} className="mb-3">
                      <label className="form-label">Job Descriptions <span className="required d-inline-block">*</span></label>
                      <CKEditor
                        editor={ClassicEditor}
                        data={jobDetail}
                        onReady={(editor) => {
                          // You can store the "editor" and use when it is needed.
                          // console.log("Editor is ready to use!", editor);
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();

                          // console.log('ck data' , data)
                          setJobDetail(data ? data : "");
                        }}
                        onBlur={(event, editor) => {
                          // console.log("Blur.", editor);
                        }}
                        onFocus={(event, editor) => {
                          // console.log("Focus.", editor);
                        }}
                      />
                      {/* <sapn className="character">275 characters left</sapn> */}
                      {formInputsEr.job_detail !== "" && (
                        <Form.Text className="text-danger">
                          {formInputsEr.job_detail}
                        </Form.Text>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Street address <span className="required d-inline-block">*</span></Form.Label>
                        <Form.Control
                          onChange={(e) => {
                            setFormInputs({
                              ...formInputs,
                              address1: e.target.value ? e.target.value : "",
                            });
                          }}
                          type="text"
                          value={formInputs.address1}
                          placeholder="Street address"
                        />
                        {formInputsEr.address1 !== "" && (
                          <Form.Text className="text-danger">
                            {formInputsEr.address1}
                          </Form.Text>
                        )}
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>City <span className="required d-inline-block">*</span></Form.Label>
                        <Form.Control
                          list="cities-list"
                          onInput={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                          }}
                          value={formInputs.city}
                          onChange={(e) => {
                            // let cityDataList = document.getElementById('cities-list');
                            let state_name = formInputs.state;
                            let country_name = formInputs.country;
                            if (e.target.value) {
                              let optionTag = document.getElementsByName(
                                e.target.value
                              );
                              if (optionTag.length) {
                                state_name =
                                  optionTag[0].getAttribute("data-state-name");
                                country_name =
                                  optionTag[0].getAttribute(
                                    "data-country-name"
                                  );
                              }
                            }
                            setFormInputs({
                              ...formInputs,
                              city: e.target.value ? e.target.value : "",
                              state: state_name,
                              country: country_name,
                            });
                          }}
                          type="text"
                          placeholder="City"
                        />
                        <datalist onChange={(e) => {}} id="cities-list">
                          {cities.map((city, index) => (
                            <option
                              key={index}
                              name={city.city_name}
                              data-state-name={city.state_name}
                              data-country-name={city.country_name}
                              value={city.city_name}
                            >
                              {city.city_name}
                            </option>
                          ))}
                        </datalist>
                        {formInputsEr.city !== "" && (
                          <Form.Text className="text-danger">
                            {formInputsEr.city}
                          </Form.Text>
                        )}
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>State / Province <span className="required d-inline-block">*</span></Form.Label>
                        <Form.Control
                          onChange={(e) => {
                            setFormInputs({
                              ...formInputs,
                              state: e.target.value ? e.target.value : "",
                            });
                          }}
                          type="text"
                          value={formInputs.state}
                          placeholder="State / Province"
                        />
                        {formInputsEr.state !== "" && (
                          <Form.Text className="text-danger">
                            {formInputsEr.state}
                          </Form.Text>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Postcode <span className="required d-inline-block">*</span></Form.Label>
                        <Form.Control
                          type="text"
                          maxlength="10"
                          onChange={(e) => {
                            setFormInputs({
                              ...formInputs,
                              postal_code:
                                e.target.value
                                  ? e.target.value
                                  : "",
                            });
                          }}
                          value={formInputs.postal_code}
                          placeholder="Postcode"
                        />
                        {formInputsEr.postal_code !== "" && (
                          <Form.Text className="text-danger">
                            {formInputsEr.postal_code}
                          </Form.Text>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={6} className="mb-3">
                      <Form.Group>
                        <Form.Label>Country <span className="required d-inline-block">*</span></Form.Label>
                        <Form.Control
                          type="text"
                          onChange={(e) => {
                            setFormInputs({
                              ...formInputs,
                              country: e.target.value ? e.target.value : "",
                            });
                          }}
                          value={formInputs.country}
                          placeholder="Country"
                        />
                        {formInputsEr.country !== "" && (
                          <Form.Text className="text-danger">
                            {formInputsEr.country}
                          </Form.Text>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <Form.Group className="mb-3" controlId="formGroupEmail">
                        <Form.Label>Start / End Date <span className="required d-inline-block">*</span></Form.Label>
                        <input
                          type="date"
                          name="begin"
                          min={new Date().toLocaleDateString("en-ca")}
                          onChange={(e) => {
                            setFormInputs({
                              ...formInputs,
                              start_date: e.target.value ? e.target.value : "",
                            });
                          }}
                          value={formInputs.start_date}
                          className="form-control"
                        />
                        {formInputsEr.start_date !== "" && (
                          <Form.Text className="text-danger">
                            {formInputsEr.start_date}
                          </Form.Text>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label className="opacity-0">Title <span className="required d-inline-block">*</span></Form.Label>
                        <input
                          type="date"
                          name="begin"
                          onChange={(e) => {
                            setFormInputs({
                              ...formInputs,
                              end_date: e.target.value ? e.target.value : "",
                            });
                          }}
                          min={
                            formInputs.start_date !== ""
                              ? formInputs.start_date
                              : new Date().toLocaleDateString("en-ca")
                          }
                          value={formInputs.end_date}
                          className="form-control"
                        />
                        {formInputsEr.end_date !== "" && (
                          <Form.Text className="text-danger">
                            {formInputsEr.end_date}
                          </Form.Text>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <div className="col-12 ">
                      <div className="position-relative">
                        <div className="payment-amount">
                          <Form.Label htmlFor="basic-url">
                            Payment Amount <span className="required d-inline-block">*</span>
                          </Form.Label>
                          <Form.Control
                            className="ps-5"
                            onChange={(e) => {
                              setFormInputs({
                                ...formInputs,
                                payment_amount: e.target.value
                                  ? e.target.value
                                  : "",
                              });
                            }}
                            type="text"
                            value={formInputs.payment_amount}
                            placeholder="Payment Amount"
                          />
                        </div>
                        {formInputsEr.payment_amount !== "" && (
                          <Form.Text className="text-danger">
                            {formInputsEr.payment_amount}
                          </Form.Text>
                        )}
                      </div>
                    </div>
                  </Row>
                  {/* <div className="dropdown-input mt-1">
                                <Row>
                                    <Col className="6">
                                        <div className="select-wrapper">
                                            <Form.Label>Location</Form.Label>
                                            <Select options={options} />
                                            <img
                                                src={Us}
                                                alt="icon"
                                                className="country-flags"
                                                width={20}
                                                height={20}
                                            />
                                        </div>
                                    </Col>
                                    <Col className="6">
                                        <div className="select-wrapper">
                                            <Form.Label>Timezone</Form.Label>
                                            <TimezoneSelect
                                                value={selectedTimezone}
                                                onChange={setSelectedTimezone}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </div> */}
                  {/* <Row className="mt-4">
                                <div className="mb-3 col-12">
                                    <div className="form-check form-switch">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="flexSwitchCheckChecked"
                                            checked
                                        />
                                        <label
                                            className="form-check-label ms-2"
                                            for="flexSwitchCheckChecked"
                                        >
                                            Allow applicants to add their work experience
                                        </label>
                                    </div>
                                </div>
                                <div className="mb-3 col-12">
                                    <div className="form-check form-switch">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="flexSwitchCheckChecked"
                                            checked
                                        />
                                        <label
                                            className="form-check-label ms-2"
                                            for="flexSwitchCheckChecked"
                                        >
                                            Allow applicants to upload their resume
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-check form-switch">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="flexSwitchCheckChecked"
                                        />
                                        <label
                                            className="form-check-label ms-2"
                                            for="flexSwitchCheckChecked"
                                        >
                                            Allow applicants to send me personal message
                                        </label>
                                    </div>
                                </div>
                            </Row> */}
                </Form>
                <div className="form-button pb-3 pt-3 mt-3 pe-4 ps-4 text-end">
                  <Button
                    onClick={() => {
                      handleClose();
                    }}
                    className="transparent-btn me-3"
                    type="button"
                  >
                    Cancel
                  </Button>
                  {!submitLoading ? (
                    <Button
                      onClick={(e) => {
                        handleSubmit(e);
                      }}
                      variant="primary"
                      type="button"
                    >
                      Post this Job
                    </Button>
                  ) : (
                    <LoadMoreLoader />
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <SiteLoader />
        )}
      </Modal.Body>
    </Modal>
  );
}
