import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { Modal , Container ,Button} from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import HomeVideoLoader from '../../Component/Loaders/HomeVideoLoader';
import LoadMoreLoader from '../../Component/Loaders/LoadMoreLoader';
import VideoLayout from '../../Component/Video/VideoLayout';
import { Enums } from '../../constants/Enums';
import ApiTypes from '../../networking/APItypes';
import APICall from '../../networking/AxiousServices';

export default function ArtistVideosModal({artistType ,  artist_id , showArtistVideoPopup, setShowArtistVideoPopup }) {

    const dispatch = useDispatch();
    const UserData = useSelector((state) => state.data);
    const handleClose = () => {
        setShowArtistVideoPopup(false)
    }
    

    const [videoPage, setVideoPage] = useState(1);
    const [videos, setVideos] = useState([]);
    const [totalVideos, setTotalVideos] = useState(0);
    const [perPage, setPerPage] = useState(9);
    const [loading, setLoading] = useState(false);
    const [preLoading, setPreLoading] = useState(true);

    const fetchArtistVideos = async () => {
        setLoading(true);

        let res = await APICall({
            url: ApiTypes.fetchArtistVideos,
            data: {
                artist_id : artist_id,
                artist_type_term : artistType ,
                is_filter: 1,
                // filter: {
                //   tab_type: activeTag,
                // },
                page: videoPage,
                limit: perPage,
            },
        });
        if (res.status === 1) {
            setPreLoading(false);
            setTotalVideos(res.data.total);
            return res.data.list;
        } else if(res.status === 0) {
            return [];
        }else{
            return [];
        }
    };
    const handleLoadMore = async () => {
        setVideoPage(videoPage + 1);
    };

    const fetchLoadMoreVideo = async () => {
        setLoading(true);
        let data = await fetchArtistVideos();
        setVideos([...videos, ...data]);
        setLoading(false);
    };
    useEffect(() => {
        if (videoPage !== 1) {
            fetchLoadMoreVideo();
        }
    }, [videoPage]);

    const fetchPreVideos = async ()=>{
        
        setPreLoading(true);
        let data =  await  fetchArtistVideos()
        setVideos(data);
    }

    useEffect(() => {
        if (showArtistVideoPopup) {
            fetchPreVideos()
        }
    }, [showArtistVideoPopup]);

    return (<Modal
        size="lg"
        dialogClassName="w-100 subscription-custom-modal"
        show={showArtistVideoPopup}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="updrade-plan-modal"
    >
        <Modal.Header closeButton className="align-items-start">
            <div>
                {/* <sapn className="h4">
                    <span>M</span>|Base Membership
                </sapn> */}
            </div>
        </Modal.Header>
        <Modal.Body>
            <div className="main-content mb-5">
                <div className="videos">
                    <div className="video-item-col">
                        <Container> 
                            
                            {/* <div className="title">{videoTabs[activeTag]}</div> */}
                            <div className="row">
                                {!preLoading ? (
                                    videos.length ? (
                                        videos.map((video, index) => {
                                            return (
                                                <VideoLayout
                                                    media_ref_id={video.media_ref_id}
                                                    is_watch_later={video.is_watch_later}
                                                    is_favorite={video.is_favorite}
                                                    videoURL={
                                                        Enums.urls.youtube_embed_base + video.media_ref_id
                                                    }
                                                    title={video.title}
                                                    video_id={video.video_id}
                                                    director={
                                                        video.all_director_name
                                                            ? video.all_director_name
                                                            : null
                                                    }
                                                    artist={
                                                        video.all_artist_name ? video.all_artist_name : null
                                                    }
                                                    key={index}
                                                />
                                            );
                                        })
                                    ) : (
                                        <div className="mt-4 text-center h5">No Videos Yet!</div>
                                    )
                                ) : (
                                    <HomeVideoLoader />
                                )}
                            </div>
                            {videos.length < totalVideos ? (
                                <div className="row">
                                    <div className="col-12 text-center mt-5">
                                        {!loading ? (
                                            <Button
                                                onClick={() => {
                                                    handleLoadMore();
                                                }}
                                                variant="primary"
                                                type="button"
                                            >
                                                Load More
                                            </Button>
                                        ) : (
                                            <LoadMoreLoader />
                                        )}
                                    </div>
                                </div>
                            ) : (
                                ""
                            )}
                        </Container>
                    </div>
                </div>
            </div>
            {/* <SubscriptionPage IsSupportSection={false} /> */}
        </Modal.Body>
    </Modal>)
}
