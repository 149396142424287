const BaseURL = process.env.REACT_APP_API_URL;
const RootURL = process.env.REACT_APP_ROOT_URL;
const AdminBaseURL = process.env.REACT_APP_ADMIN_API_URL

const ApiTypes = {

  //Options

  jobFilters :  RootURL + 'options/get_job_filter_options',
  getJobTypes : RootURL + 'options/job_types',
  
  //Contact us
  saveContactUs : RootURL + 'options/contact_us',

  //Authentication
  clientSignUp :  BaseURL + 'signup',
  clientLogin :  BaseURL + 'login',
  forgotPassword :  BaseURL + 'forgot/password',
  checkResetToken: AdminBaseURL + "check_reset_token",
  resetPassword: AdminBaseURL + "reset_password",
  changePassword: AdminBaseURL + "change_password",
  socialLogin :  BaseURL + 'social/login',
  getProfileDetails : BaseURL + 'get_profile_details',

  //Profile
  getEditProfileDetails :BaseURL +  'user/edit_profile',
  updateProfile : BaseURL +  'user/update_profile',

  getWorkExperience :BaseURL  + 'client/get_work_experience_by_user',
  saveWorkExperience : BaseURL  + 'client/update_work_experience',
  deleteWorkExperience : BaseURL  + 'client/delete_work_experience',

  //List
  getCityList : RootURL + 'city-list',


  // dashboard
  fetchHomeVideoTabs : BaseURL + 'dashboard/get_video_tabs',
  fetchHomeVideos : BaseURL + 'video/list',
  fetchSearchResult : BaseURL + 'dashboard/get_result_by_search',

  //Artists
  fetchArtists : BaseURL +'artist/list',
  fetchArtistDetails : BaseURL +'artist/get_details',
  fetchArtistsTabs :  BaseURL +'artist/get_artist_tabs_list',
  claimProfile :  BaseURL +'claim_request/add_claim_request',

  //Video
  fetchVideoDetails : BaseURL +'video/get_details',
  addOrRemoveFromFav : BaseURL +'video/add_video_favourite',
  addOrRemoveWatchLater : BaseURL + 'video/video_watch_later',
  fetchArtistVideos : BaseURL + 'artist/get_artist_video_associations',
  
  //MyBase
  fetchMyBaseVideos : BaseURL + 'dashboard/get_result_by_my_base',
  fetchMyBaseTabs :  BaseURL + 'dashboard/get_my_base_tabs',

  //Subscription
  fetchSubscriptionList : BaseURL + 'subscription/list',
  fetchSubscriptionDetails : BaseURL + 'subscription/get_details',
  buySubscription : BaseURL + 'subscription/buy_subscription',
  cancelSubscription : BaseURL + 'subscription/cancel',
  isUserSubscribedBefore : BaseURL + 'subscription/is_user_subscribed_before',

  //Job
  saveJob : BaseURL + 'job/save_job',
  fetchJobList : BaseURL + 'job/get_jobs_list',
  addOrRemoveFromSaveJob : BaseURL + 'job/user_save_job',
  fetchJobDetails :  BaseURL + 'job/get_job_details',
  applyJob : BaseURL + 'job/user_job_apply',
  applicantUsers  : BaseURL + 'job/get_job_applicant_user',
  fetchJobApplicantDetails : BaseURL + 'job/get_job_applicant_details',
  removeJob : BaseURL + 'job/remove_job',
  
  // Billing
  fetchBillingDetails : BaseURL + 'user/billing_details',
  fetchInvoices : BaseURL + 'user/billing_invoices', 

  //Award
  fetchAwardTabs : BaseURL +'dashboard/get_awards_base',
  fetchYearsList : BaseURL + 'award/get_previous_year_list',
  fetchYearAwards : BaseURL + 'award/get_selected_years_awards',

  followUnFollowArtist : BaseURL + 'client/follow_artist',
  watchCountUpdate : BaseURL + 'client/watch_video_count_update',

  //Chat
  createChatRoom : BaseURL + 'chat/create_room',
  getUploadLink  :  BaseURL + 'chat/get_upload_link',
  fetchClientDetails : BaseURL +'client/get_details',

  // Notification 
  getNotificationList : BaseURL +  'get_notifications',
  unreadNotifications : BaseURL +  'get_unread_notifications',
  markAsReadNotifications : BaseURL +  'mark_as_read_notifications',

  //Video Suggestion Add
  addSuggestion : BaseURL + 'suggestion/add_suggestion',

  //Chats
  fetchSidePanelChatList : BaseURL + 'chat/get_chat_list_by_id',
  sendMessage : BaseURL + 'chat/insert_chat_message',
  fetchMessages : BaseURL + 'chat/get_chat_messages',


  //WebSetting
  fetchWebSetting : BaseURL + 'settings/get_web_setting',

  //Faq

  fetchFaqList : BaseURL + 'faq/list',
  
};
export default ApiTypes;