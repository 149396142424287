import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { Card, Button, Modal } from "react-bootstrap";
import FetchLoader from '../../Component/Loaders/FetchLoader';
import ApiTypes from '../../networking/APItypes';
import APICall from '../../networking/AxiousServices';
import NodataFound from '../Errors/NodataFound';
import ViewResume from "../../assets/media/icons/view-resume.svg";
import ReactHtmlParser from 'react-html-parser';
import { commonHelper } from '../../common/commonHelper';
import ClientDefault from "../../assets/Images/Defaults/client_default.webp"
import { useNavigate } from 'react-router-dom';
import { Enums } from '../../constants/Enums';
import { useSelector } from 'react-redux';
import LoadMoreLoader from '../../Component/Loaders/LoadMoreLoader';
import PreviewImg from "../../Component/Image/PreviewImg";

export default function ApplicantDetails({ showApplicantDetails, setShowApplicantDetails, jobApplicantId, setJobApplicantId }) {
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate()
    const [chatLoading, setChatLoading] = useState(false);
    const UserData = useSelector((state) => state.data);
    const authUserId = UserData.logged_user.user_id;
    const [applicantDetails, setApplicantDetails] = useState({});
    const handleClose = () => {
        setJobApplicantId(null)
        setShowApplicantDetails(false)
    }

    const fetchJobApplicantDetails = async () => {
        let res = await APICall({
            url: ApiTypes.fetchJobApplicantDetails,
            data: {
                job_applicant_id: jobApplicantId
            }
        })
        if (res.status === 1) {
            setApplicantDetails(res.data)
            setLoading(false)

        }
    }

    const handleNavigateMessages = async (e, applicantDetails) => {
        e.preventDefault();
        setChatLoading(true)
        let res = await APICall({
            url: ApiTypes.createChatRoom,
            data: {
                sender_user_id: authUserId,
                receiver_user_id: applicantDetails.user_id,
                module: Enums.chat_module.user_to_user
            }
        })
        if (res.status === 1) {
            setChatLoading(false)
            navigate('/messages', {
                state: {
                    socket_id: res.data.chat_room_id,
                    rec_id: applicantDetails.user_id,
                    display_name: applicantDetails.display_name,
                    profile_img: applicantDetails.profile_img,
                    is_online : applicantDetails.is_online
                }
            })
        }
    }

    useEffect(() => {
        if (jobApplicantId) {
            fetchJobApplicantDetails()
        }

    }, [jobApplicantId]);

    return (<Modal
        show={showApplicantDetails}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        className="job-profile-modal"
    >
        <Modal.Header closeButton>
            <div className="">
                <PreviewImg />
                <span className="profile-section me-3">
                    <PreviewImg
                        src={applicantDetails.profile_img ? applicantDetails.profile_img : ClientDefault}
                        isDefault={applicantDetails.profile_img ? false : true}
                        alt="ProfileImg"
                        width={40}
                        height={40}
                        defaultImg={ClientDefault}
                        className="rounded-circle"
                    />

                    {/* <img
                        src={applicantDetails.profile_img ? process.env.REACT_APP_ASSETS_URL + applicantDetails.profile_img : ClientDefault}
                        alt="ProfileImg"
                        width="40"
                        height="40"
                        className="rounded-circle"
                    /> */}
                </span>
                <span className="details-col-title">{applicantDetails.display_name ? applicantDetails.display_name : "-"}</span>
            </div>
        </Modal.Header>
        <Modal.Body>
            {!loading ? Object.keys(applicantDetails).length > 0 ? <div className="applications-section">
                <div className="Application-col pb-2">
                    <div className="d-flex justify-content-center justify-content-between">
                        <div className="title mb-3">Job Application</div>
                        {applicantDetails.user_id !== authUserId?  !chatLoading ? <Button onClick={(e) => { handleNavigateMessages(e, applicantDetails) }}>
                            Message
                        </Button> : <LoadMoreLoader /> :""}
                    </div>

                    <div className="mb-2">
                        <span className="left-col me-1">Name :</span>
                        <span className="right-col">{applicantDetails.display_name ? applicantDetails.display_name : "-"}</span>
                    </div>
                    <div className="mb-2">
                        <span className="left-col me-1">Phone Number :</span>
                        <span className="right-col">{applicantDetails.phone ? applicantDetails.phone : "-"}</span>
                    </div>
                    <div className="mb-2">
                        <span className="left-col me-1">Email Address :</span>
                        <span className="right-col">{applicantDetails.email ? applicantDetails.email : "-"}</span>
                    </div>
                    {/* <div className="mb-2">
                        <span className="left-col me-1">
                            Personal Message to Employer :
                        </span>
                        <span className="right-col">Regular</span>
                    </div> */}
                    <div className="mb-2">
                        <span className="left-col d-block mb-1">About Applicant : </span>
                        <span className="right-col">
                            {applicantDetails.mini_bio ? ReactHtmlParser(applicantDetails.mini_bio) : ""}
                        </span>
                    </div>
                </div>
                <div className="view-resume-col mt-3 mb-3 pb-2 pt-2">
                    <span className="icon me-3">
                        <img src={ViewResume} alt="ViewResume" width={17} height={17} />
                    </span>
                    <span onClick={async () => {
                        try {
                            let link = process.env.REACT_APP_ASSETS_URL + applicantDetails.resume;
                            const signedUrl = await commonHelper.getAssetUrl(link);
                            if (signedUrl) {
                                let aTag = document.createElement('a');
                                aTag.setAttribute('href', signedUrl)
                                aTag.setAttribute('target', '_blank')
                                aTag.click();
                            }

                        } catch (error) {
                            console.log(error);
                        }


                        // let resumeName = applicantDetails.resume.split('/').pop();
                        // if (resumeName.split('.').pop() === 'pdf') {
                        //     let pdfWindow = window.open("")
                        //     pdfWindow.document.write(
                        //         `<iframe width='100%' height='100%' src=${link}></iframe>`
                        //     )
                        // } else {
                        //     let aTag = document.createElement('a');
                        //     aTag.setAttribute('href', link)
                        //     aTag.setAttribute('target', '_blank')
                        //     aTag.click();

                        // }
                    }} className="view-resume">click here to view resume</span>
                </div>
                <div className="experience-col">
                    <div className="title mb-3">Work Experience</div>
                    {applicantDetails.user_job_work_experience.length > 0 ?
                        applicantDetails.user_job_work_experience.map((work_experience) => {
                            return (<Card className='py-2 px-3 mb-2'>
                                <div className="mb-2">
                                    <span className="left-col me-1">Company Name :</span>
                                    <span className="right-col">{work_experience.company_name ? work_experience.company_name : ""}</span>
                                </div>
                                <div className="mb-2">
                                    <span className="left-col me-1">Title :</span>
                                    <span className="right-col">{work_experience.title ? work_experience.title : "'"}</span>
                                </div>
                                <div className="mb-2">
                                    <span className="left-col me-1">Date :</span>
                                    <span className="right-col">{work_experience.start_date ? commonHelper.formatDate(work_experience.start_date, 'MM-DD-YYYY') : "-"} To {work_experience.end_date ? commonHelper.formatDate(work_experience.end_date, 'MM-DD-YYYY') : "-"}</span>
                                </div>
                                <div className="mb-2">
                                    <span className="left-col d-block mb-1">
                                        Description of Role :
                                    </span>
                                    <span className="right-col">
                                        {work_experience.description ? work_experience.description : ""}
                                    </span>
                                </div>
                            </Card>)
                        })
                        : <div className='text-center'>
                            No Work Experience Found !
                        </div>}


                </div>
            </div> : <NodataFound /> : <FetchLoader />}
        </Modal.Body>
    </Modal>)
}
