import React from 'react'
import FielStar from "../../assets/media/icons/fiel-star.svg";
import BlankStar from "../../assets/media/icons/blank-star.svg";
import { Card } from "react-bootstrap";
import ClockRotate from "../../assets/media/icons/clock-rotate.svg";
import secondThumbnail from "../../assets/media/img/An-image.png";
import IsFavourite from "../Favourite/IsFavourite";
import { Enums } from "../../constants/Enums";
import IsWatchLater from "../WatchLater/IsWatchLater";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import WatchedLater from "../../assets/media/icons/watched-later.svg";
import DefaultArtist from "../../assets/Images/Defaults/artist_default.svg";
import APICall from '../../networking/AxiousServices';
import ApiTypes from '../../networking/APItypes';
import YouTube, { YouTubeProps } from 'react-youtube';
import { useState } from 'react';
import { commonHelper } from '../../common/commonHelper';
import { useEffect } from 'react';

export default function AwardCard({ award_item }) {
    const opts = {
        height: '260',
        width: '416',
    };
    const navigate = useNavigate();
    const UserData = useSelector((state) => state.data);
    const authToken = UserData.auth_token;

    const [isPlayCounted, setIsPlayCounted] = useState(false);

    const handleRedirection = (association_type_term, association_id) => {
        if (association_type_term === Enums.module.video) {
            navigate(`/video/?vid=${association_id}`)
        }
        if (association_type_term === Enums.module.artist) {
            navigate(`/artist/?aid=${association_id}`)
        }
    }
    const handleAddPlayCount = () => {

        if (!isPlayCounted) {
            APICall({
                url: ApiTypes.watchCountUpdate,
                data: {
                    video_id: award_item.association_id
                }
            })
        }
    }

    const [imgPreviewUrl, setImgPreviewUrl] = useState(award_item.image);

    async function getImageUrls(src_get) {
        if (award_item.association_type_term === Enums.module.artist) {
            if (award_item.image) {
                const signedUrl = await commonHelper.getAssetUrl(src_get);
                setImgPreviewUrl(signedUrl);
            } else {
                setImgPreviewUrl(DefaultArtist);

            }
        } else {
            setImgPreviewUrl(secondThumbnail);
        }
    }

    useEffect(() => {
        getImageUrls(award_item.image);
    }, [award_item.image]);

    return (<div className={`mb-3 mb-lg-5 ${award_item.association_type_term === Enums.module.artist ? "col-xxl-3 col-xl-4 col-lg-4 col-md-4 col-sm-6":"col-lg-4 col-md-6"}`}>
        <Card className={`border-0 ${award_item.association_type_term === Enums.module.artist ? "artist-award-container":""}`}>
            <div className={`youvideo-col ${award_item.association_type_term === Enums.module.artist ? "artist-award-image":""}`}>            {
                award_item.association_type_term === Enums.module.video
                    ? <YouTube
                        videoId={award_item.media_ref_id}
                        opts={opts}
                        onPlay={() => {
                            setIsPlayCounted(true)
                            handleAddPlayCount()
                        }}
                    />
                    : award_item.association_type_term === Enums.module.artist
                        ? award_item.image
                            ? <Card.Img height={'260px'} variant="top rounded-0" className='obj-fit-cover' src={imgPreviewUrl} />
                            : <Card.Img height={'260px'} variant="top rounded-0" className='obj-fit-cover' src={imgPreviewUrl} />
                        : <Card.Img height={'260px'} variant="top rounded-0" className='obj-fit-cover' src={imgPreviewUrl} />
            }

                {authToken && (<>
                    <div className="icons">
                        <span className="mb-1">

                            <IsFavourite
                                FavComponent={() => (<><img className='cursor-pointer' src={FielStar} alt="Remove Favourite" />
                                </>)}
                                UnFavComponent={() => (<>
                                    <img className='cursor-pointer' src={BlankStar} alt="Add Favourite" />
                                </>)}
                                module_id={award_item.association_id}
                                module_type={award_item.association_type_term}
                                is_favorite={award_item.is_favorite}
                            />

                        </span>
                        <span>
                            {
                                award_item.association_type_term === Enums.module.video ?
                                    <IsWatchLater
                                        WatchComponent={() => (
                                            <img alt='Remove Watch Later' className='cursor-pointer' src={WatchedLater} />
                                        )}
                                        UnWatchComponent={() => (
                                            <img alt='Add Watch Later' className='cursor-pointer' src={ClockRotate} />
                                        )}
                                        module_id={award_item.association_id}
                                        module_type={award_item.association_type_term}
                                        is_watch_later={award_item.is_watch_later}
                                    />
                                    : ""}
                        </span>

                    </div>
                </>)}
            </div>
            {parseInt(award_item.is_winner) === 1 && <div style={{ marginTop: "2.5px" }} className="icons-right align-items-center">

                <i style={{ fontSize: "20px" }} className="fa-solid text-warning fa-crown"></i>

            </div>}
            <Card.Body className="px-0 pb-0">
                {award_item.association_type_term === Enums.module.video && <Card.Text className="directed-dy">
                    {/* Directed By: {award_item.all_director_name ? award_item.all_director_name : ""} */}
                    {award_item.ref_assoication_type ? award_item.ref_assoication_type :""}
                </Card.Text>}
                <Card.Title onClick={() => {
                    handleRedirection(award_item.association_type_term, award_item.association_id)
                }} className={`music-video-title cursor-pointer ${award_item.association_type_term === Enums.module.artist && "text-center"}`}>
                    {award_item.name}
                </Card.Title>
                {award_item.association_type_term === Enums.module.video && <Card.Text className="artist-name">
                    {award_item.all_artist_name ? award_item.all_artist_name : ""}
                </Card.Text>}
            </Card.Body>
        </Card>
    </div>)
}
