import { useState } from "react";
import "./AutoComplete.css";
import AvatarImg from "../../assets/Images/Defaults/artist_default.svg";
import InfinityLoaderSVG from "../../assets/Images/Loaders/InfinityLoader.svg";
import { useEffect } from "react";
import APICall from "../../networking/AxiousServices";
import ApiTypes from "../../networking/APItypes";
import InfiniteScroll from "react-infinite-scroll-component";
import InfinityLoader from "../Loaders/InfinityLoader";
import { Enums } from "../../constants/Enums";
import { useNavigate } from "react-router-dom";
import PreviewImg from "../Image/PreviewImg";

const AutoComplete = ({ suggestions }) => {
  const navigate = useNavigate();

  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);
  const [searchSuggestions, setSearchSuggestions] = useState(false);
  const [search, setSearch] = useState("");
  const [fetchLoader, setFetchLoader] = useState(false);

  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [hashMore, setHashMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const handleOnChange = (e) => {
    setSearch(e.target.value.trim() ? e.target.value :"");
    setPage(1);
    setHashMore(true);
  };

  const handleOnClickSearch = (e) => { };

  const fetchSearchResult = async () => {
    let res = await APICall({
      url: ApiTypes.fetchSearchResult,
      data: {
        search: search,
        limit: perPage,
        page: page,
      },
    });
    if (res.status === 1) {
      if (
        parseInt(searchSuggestions.length) + parseInt(res.data.list.length) >=
        parseInt(res.data.total)
      ) {
        setHashMore(false);
      }
      setFetchLoader(false);
      setLoading(false);
      return res.data.list;
    } else {
      if (res.data.status === 0) {
        setFetchLoader(false);
        setLoading(false);
        return [];
      }

    }
  };

  const newSearchResult = async () => {
    setFetchLoader(true);
    setLoading(true);
    let data = await fetchSearchResult();
    setSearchSuggestions(data);
  };

  useEffect(() => {
    if (search.length) {
      newSearchResult();
    } else {
      setSearchSuggestions([]);
    }
  }, [search]);
  const fetchMoreResult = async () => {
    setLoading(true);
    let data = await fetchSearchResult();
    setSearchSuggestions([...searchSuggestions, ...data]);
  };
  useEffect(() => {
    if (page !== 1) {
      fetchMoreResult();
    }
  }, [page]);

  const handleRedirection = (search_res) => {
    if (search_res.type === Enums.home_result_types.client) {
      navigate(`/client/?cid=${search_res.value}`);
    }
    if (search_res.type === Enums.home_result_types.video) {
      navigate(`/video/?vid=${search_res.value}`);
    }
    if (search_res.type === Enums.home_result_types.artist) {
      navigate(`/artist/?aid=${search_res.value}`);
    }
    setSearch("")
    setSearchSuggestions([])
  };



  return (
    <>
      <input
        type="search"
        placeholder="Search for Music Videos, Artists, Directors. etc..."
        onChange={(e) => handleOnChange(e)}
        // onKeyDown={onKeyDown}
        value={search}
      />
      <span onClick={(e) => handleOnClickSearch(e)} className="search-icon">
        <i className="fa-regular fa-magnifying-glass"></i>
      </span>
      {search.length ?!fetchLoader ? (
      searchSuggestions.length ? (
        <ul className="suggestions" style={{ 
          overflow : "auto",
          height:"400px"
         }} id="searchable-component">
          <InfiniteScroll
            scrollableTarget="searchable-component"
            dataLength={parseInt(searchSuggestions.length)} //This is important field to render the next data
            next={() => {
              if (!loading) setPage(page + 1);
            }}
            style={{ overflow : 'none' }}
            hasMore={hashMore}
            loader={<InfinityLoader />}
          >
            {searchSuggestions.map((searchSuggestion, index) => {
              return (
                <li
                  className={"suggestion-active"}
                  onClick={() => handleRedirection(searchSuggestion)}
                  key={index}
                >
                  <div className="suggestions-item d-flex">
                    <span className="suggestions-img d-inline-block me-3">
                      <PreviewImg
                        src={
                          searchSuggestion.profile
                            ? searchSuggestion.profile
                            : AvatarImg
                        }
                        isDefault={searchSuggestion.profile ? false : true}
                        alt="avatar-img"
                        // width={240}
                        // height={240}
                        defaultImg={AvatarImg}
                        className="rounded-circle d-inline-block"
                      />
                      {/* <img
                      src={searchSuggestion.profile ? process.env.REACT_APP_ASSETS_URL + searchSuggestion.profile : AvatarImg}
                      alt="avatar-img"
                      className="rounded-circle d-inline-block"
                    /> */}
                    </span>
                    <div className="suggestions-right d-flex flex-column">
                      <span className="suggestions-name">
                        {searchSuggestion.title}
                      </span>
                      <span className="suggestions-director">
                        {searchSuggestion.sub_title}
                      </span>
                    </div>
                  </div>
                </li>
              );
            })}
          </InfiniteScroll>
        </ul>
      ) : (
        <div className="no-suggestions">
          <span role="img" aria-label="tear emoji">
            😪
          </span>
          <em>sorry no suggestions</em>
        </div>
      )
    ) : (
      <div className="no-suggestions p-0 d-flex justify-content-center  align-items-center">
        <img height={"30px"} width={"30px"} src={InfinityLoaderSVG} alt=""></img>
      </div>
    ) : ""}
    </>
  );
};

export default AutoComplete;
