import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import MbaseLogo from "../../assets/media/logos/m-base-logo.svg";
import Button from "react-bootstrap/Button";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Offcanvas from "react-bootstrap/Offcanvas";
import SubscriptionBanner from "../../assets/media/img/subscription-banner.png";
import ProfileImg from "../../assets/media/img/profile-img.png";
import { useEffect } from "react";
import APICall from "../../networking/AxiousServices";
import ApiTypes from "../../networking/APItypes";
import SiteLoader from "../../Component/Loaders/SiteLoader";
import { useState } from "react";
import CheckOutModel from "./CheckOutModel";
import { Enums } from "../../constants/Enums";
import LoadMoreLoader from "../../Component/Loaders/LoadMoreLoader";
import SupportSection from "./SupportSection";
import { useDispatch, useSelector } from "react-redux";
import { commonHelper } from "../../common/commonHelper";
import ReactHtmlParser from "react-html-parser";

function SubscriptionPage({ IsSupportSection = true }) {
  document.title = process.env.REACT_APP_NAME + " | " + "Subscription";

  const dispatch = useDispatch();
  const navigate = useNavigate()
  const UserData = useSelector((state) => state.data);

  const [subscriptions, setSubscriptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isShowCheckOut, setIsShowCheckOut] = useState(false);
  const [subscriptionId, setSubscriptionId] = useState(null);
  const [subPlanId, setSubPlanId] = useState(null);

  const [cancelLoading, setCancelLoading] = useState(false);

  const [isSubscribed, setIsSubscribed] = useState(false);

  const [isDisabled, setIsDisabled] = useState(false);

  const [isFreeTrial, setIsFreeTrial] = useState(false);

  const fetchSubscriptionList = async () => {
    setLoading(true);
    let res = await APICall({
      url: ApiTypes.fetchSubscriptionList,
    });
    if (res.status === 1) {
      let isDisabledVar = false;
      res.data.forEach((res_data) => {
        if (res_data.subscription_type === Enums.subscription_type.paid) {
          if (res_data.active_subscription_status) {
            if(res_data.remaining_trial_days <= 0){
              isDisabledVar = true
            }
            else{
              if(!res_data.cancelled_on){
                isDisabledVar = true
              }else{
                setIsFreeTrial(true)
              }
            }
          }
        }
      });
      setIsDisabled(isDisabledVar)
      setLoading(false);
      setSubscriptions(res.data);
    }
  };

  const handleCancelToken = async (subscription_id) => {
    setCancelLoading(true);
    let res = await APICall({
      url: ApiTypes.cancelSubscription,
      data: { user_subscription_id: subscription_id },
    });
    if (res.status === 1) {
      fetchSubscriptionList();
      dispatch({ type: "SUBSCRIPTION_MODAL", payload: false });
      dispatch({
        type: 'LOGGED_USER', payload: {
          ...UserData.logged_user,
          subscription: { ...res.data.subscription },
          subscription_feature: { ...res.data.subscription_feature }

        }
      })
      commonHelper.setItem("user", JSON.stringify({
        ...UserData.logged_user,
        subscription: { ...res.data.subscription },
        subscription_feature: { ...res.data.subscription_feature }
      }));

      if(!UserData.subscription_modal){

        navigate('/')

      }

      setCancelLoading(false);
    } else {
      if (res.data.status === 0) {
        setCancelLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchSubscriptionList();
  }, []);

  return !loading ? (
    <>
      <div className="main-content subscription">
        <div className="page-top-banner">
          <img src={SubscriptionBanner} alt="top-banner" className="w-100" />
          <div className="top-banner-content">
            <Container>
              <div className="banner-content-col">
                <div className="trending-banner-content">
                  Pricing & Membership
                </div>
                <div className="banner-title-content membership">
                  <span>M</span>|Base Membership
                </div>
                {/* <div className="discription-content-area">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Lectus massa nibh in scelerisque. Et a aliquet elit
                  consectetur molestie amet lorem.
                  </div> */}
              </div>
            </Container>
          </div>
        </div>
        <div className="plan-price">
          <Container>
            <div className="row">
              {subscriptions.map((subscription, index) => {
                return (
                  <div key={index} className="col-12 col-md-6 col-lg-6 col-xl-4 mb-4">
                    <div className="plan-card">
                      <div className="plan-label text-center">
                        <span>{subscription.subscription_name}</span>
                      </div>
                      <div className="monthly-price text-center">
                        ${parseFloat(subscription.price).toFixed(2)}/
                        {subscription.interval_type}
                      </div>
                      {/* <div className="billing text-center">
                        Billed Annually ($
                        {parseFloat(subscription.price * 12).toFixed(2)})
                      </div> */}
                      <div className="access-list">
                        {/* <ul className="ps-0 mb-0 list-unstyled">
                    <li className="enable mb-3">
                      <i className="fa-regular fa-circle-check"></i>
                      Access to all basic features
                    </li>
                    <li className="enable mb-3">
                      <i className="fa-regular fa-circle-check"></i>
                      Basic reporting and analytics
                    </li>
                    <li className="enable mb-3">
                      <i className="fa-regular fa-circle-check"></i>
                      Sagittis pretium condimentum sit.
                    </li>
                    <li className="enable mb-3">
                      <i className="fa-regular fa-circle-check"></i>
                      Pellentesque consequat laoreet eget.
                    </li>
                    <li className="enable">
                      <i className="fa-regular fa-circle-check"></i>
                      Sit vulputate condimentum quisque.
                    </li>
                  </ul> */}
                        {ReactHtmlParser(subscription.subscription_details)}
                      </div>
                      {subscription.subscription_type ===
                        Enums.subscription_type.paid ? (
                        !subscription.active_subscription_status ? (
                          <Button
                            disabled={isDisabled}
                            onClick={() => {
                              if (!isDisabled) {
                                setSubPlanId(subscription.paypal_plan_id)
                                setSubscriptionId(subscription.subscription_id);
                                setIsShowCheckOut(true);
                              }
                            }}
                            variant="primary w-100"
                            type="button"
                          >
                            Get started
                          </Button>
                        ) : !cancelLoading ?
                        parseInt(subscription.is_auto_payment) === 1 ? 
                        (
                          <Button
                            onClick={(e) => {
                              handleCancelToken(
                                subscription.user_subscription_id
                              );
                            }}
                            variant="outline-primary w-100"
                            type="button"
                          >
                            Cancel Subscription
                          </Button>
                        )
                        
                       : 
                       (
                        !isFreeTrial ?<Button
                          disabled={true}
                          variant="outline-primary w-100"
                          type="button"
                        >
                          Cancelled
                        </Button> :  <Button
                            disabled={isDisabled}
                            onClick={() => {
                              if (!isDisabled) {
                                setSubPlanId(subscription.paypal_plan_id)
                                setSubscriptionId(subscription.subscription_id);
                                setIsShowCheckOut(true);
                              }
                            }}
                            variant="primary w-100"
                            type="button"
                          >
                            Get started
                          </Button>
                      )
                        : (
                          <LoadMoreLoader classButton={"w-100"} />
                        )
                      ) : subscription.active_subscription_status ? (
                        <Button
                          disabled={true}
                          variant="outline-primary w-100"
                          type="button"
                        >
                          Activated
                        </Button>
                      ) : (
                        <Button
                          disabled={true}
                          variant="outline-primary w-100"
                          type="button"
                        >
                          Default
                        </Button>
                      )}
                    </div>
                  </div>
                );
              })}

              {IsSupportSection && <SupportSection />}
              {/* <div className="col-lg-4">
              <div className="plan-card">
                <div className="plan-label text-center">
                  <span>Monthly Billing</span>
                </div>
                <div className="monthly-price text-center">$14.99/mth</div>
                <div className="billing text-center">Monthly Billing</div>
                <div className="access-list">
                  <ul className="ps-0 mb-0 list-unstyled">
                    <li className="enable mb-3">
                      <i className="fa-regular fa-circle-check"></i>
                      Access to all basic features
                    </li>
                    <li className="enable mb-3">
                      <i className="fa-regular fa-circle-check"></i>
                      Basic reporting and analytics
                    </li>
                    <li className="enable mb-3">
                      <i className="fa-regular fa-circle-check"></i>
                      Sed lectus pellentesque ultrices.
                    </li>
                    <li className="enable mb-3">
                      <i className="fa-regular fa-circle-check"></i>
                      Duis sit odio tincidunt platea varius.
                    </li>
                    <li className="enable">
                      <i className="fa-regular fa-circle-check"></i>
                      Mauris id eu odio morbi mauris.
                    </li>
                  </ul>
                </div>
                <Button variant="primary w-100" type="submit">
                  Get started
                </Button>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="plan-card">
                <div className="plan-label text-center">
                  <span>Free Version</span>
                </div>
                <div className="monthly-price text-center">$0.00/mth</div>
                <div className="billing text-center">Limited Features</div>
                <div className="access-list">
                  <ul className="ps-0 mb-0 list-unstyled">
                    <li className="enable mb-3">
                      <i className="fa-regular fa-circle-check"></i>
                      Access to all basic features
                    </li>
                    <li className="enable mb-3">
                      <i className="fa-regular fa-circle-check"></i>
                      Basic reporting and analytics
                    </li>
                    <li className="disable mb-3">
                      <i className="fa-regular fa-circle-check"></i>
                      Ornare ut accumsan aliquet augue.
                    </li>
                    <li className="disable mb-3">
                      <i className="fa-regular fa-circle-check"></i>A hac
                      pellentesque eget dictum.
                    </li>
                    <li className="disable">
                      <i className="fa-regular fa-circle-check"></i>
                      Dui facilisis consectetur cras.
                    </li>
                  </ul>
                </div>
                <Button variant="primary w-100" type="submit">
                  Get started
                </Button>
              </div>
            </div> */}
            </div>
          </Container>
        </div>
        <CheckOutModel
          isShowCheckOut={isShowCheckOut}
          setIsShowCheckOut={setIsShowCheckOut}
          subscriptionId={subscriptionId}
          subPlanId={subPlanId}
          setSubPlanId={setSubPlanId}
        />
      </div>
    </>
  ) : (
    <SiteLoader />
  );
}

export default SubscriptionPage;
