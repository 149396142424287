import React, { useEffect } from "react";
import FielStar from "../../assets/media/icons/fiel-star.svg";
import BlankStar from "../../assets/media/icons/blank-star.svg";
import { Card } from "react-bootstrap";
import ClockRotate from "../../assets/media/icons/clock-rotate.svg";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import IsFavourite from "../Favourite/IsFavourite";
import { Enums } from "../../constants/Enums";
import IsWatchLater from "../WatchLater/IsWatchLater";
import WatchedLater from "../../assets/media/icons/watched-later.svg";
import YouTube, { YouTubeProps } from 'react-youtube';
import { useState } from "react";
import APICall from "../../networking/AxiousServices";
import ApiTypes from "../../networking/APItypes";

export default function VideoLayout({
  videoURL,
  title = null,
  director = null,
  artist = null,
  video_id = null,
  is_favorite = null,
  is_watch_later = null,
  media_ref_id = null,
  artist_id_str=null,
  active_tag=null
}) {
  const navigate = useNavigate();
  const UserData = useSelector((state) => state.data);
  const authToken = UserData.auth_token;
  const [isPlayCounted, setIsPlayCounted] = useState(false);
  const opts = {
    height: '260',
    width: '416',
  };
  const handleAddPlayCount = () => {
    if (!isPlayCounted) {
      APICall({
        url: ApiTypes.watchCountUpdate,
        data: {
          video_id: video_id
        }
      })
    }
  }

  return (
    <div className="col-lg-4 mb-4 mb-lg-5">
      <Card className="border-0">
        {/* <iframe id="video-id-first" className="w-100" src={videoURL} height="260px"></iframe> */}
        <div className="youvideo-col">
          <YouTube
            videoId={media_ref_id}
            opts={opts}
            className={''}
            iframeClassName={''}
            onPlay={() => {
              setIsPlayCounted(true)
              handleAddPlayCount()
            }}
          />
          {/* <Card.Img variant="top rounded-0" src={ThumbnailImg} /> */}
          {/* {authToken && ( */}
          <div className="icons">
            <span className="mb-1">
              {
                is_favorite !== null ?
                  <IsFavourite
                    FavComponent={() => (<><img className='cursor-pointer' src={FielStar} alt="Remove Favourite" />
                    </>)}
                    UnFavComponent={() => (<>
                      <img className='cursor-pointer' src={BlankStar} alt="Add Favourite" />
                    </>)}
                    module_id={video_id}
                    module_type={Enums.module.video}
                    is_favorite={is_favorite}
                  />
                  : ""}
            </span>
            <span>
              {
                is_watch_later !== null ?
                  <IsWatchLater
                    WatchComponent={() => (
                      <img alt='Remove Watch Later' className='cursor-pointer' src={WatchedLater} />
                    )}
                    UnWatchComponent={() => (
                      <img alt='Add Watch Later' className='cursor-pointer' src={ClockRotate} />
                    )}
                    module_id={video_id}
                    module_type={Enums.module.video}
                    is_watch_later={is_watch_later}
                  />
                  : ""}
            </span>
          </div>
        </div>
        <Card.Body className="px-0 pb-0">
          <Card.Text className="directed-dy">
            Directed By: {director ? director : ""}
          </Card.Text>
          <Card.Title
            onClick={() => {
              if(active_tag !== Enums.dashboard_video_tabs_enum.behind_scenes_video){
                if (video_id && title) {
                  navigate(`/video?vid=${video_id}`);
                }
              }
              
            }}
            className={`${active_tag !== Enums.dashboard_video_tabs_enum.behind_scenes_video ? 'cursor-pointer' :""} music-video-title`}
          >
            {title ? title : ""}
          </Card.Title>
          <Card.Text className="artist-name">{artist ? artist.includes(',') ?
            artist.split(',').map((artist_name, i) => {
              return (<span className="cursor-pointer p-0" onClick={()=>{
                navigate(`/artist?aid=${artist_id_str.split(',')[i].trim()}`)
              }} >
                {artist_name.trim()} {i + 1 === artist.split(',').length ? "" : " , "}
              </span>)
            })
            : <span className="cursor-pointer p-0" onClick={()=>{
              navigate(`/artist?aid=${artist_id_str.trim()}`)
            }} >{artist}</span> : ""}</Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
}
