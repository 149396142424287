import React from "react";
import { useState } from "react";
import {
  Accordion,
  Nav,
  Button,
  Container,
  Form,
  InputGroup,
  NavDropdown,
  Row,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import suggestions from "../../assets/File/AutoComplete";
import AutoComplete from "../../Component/AutoComplete/Index";
import { Enums } from "../../constants/Enums";

export default function ProfileMenuHeader() {
  const dispatch = useDispatch();
  const UserData = useSelector((state) => state.data);
  const SubscriptionFeature = UserData.logged_user.subscription_feature;

  const [mobileSelected, setMobileSelected] = useState(
    window.location.pathname
  );

  const navigate = useNavigate();

  return (
    <>
      <div className="account-profile-menu d-none d-lg-block">
        <Container>
          <div className="account-profile-menu-top d-flex align-items-center">
            <div className="profile-menu-left d-flex align-items-center me-3 pe-3">
              <Nav as="ul">
                <div
                  onClick={() => {
                    if (SubscriptionFeature.chat.message) {
                      navigate("/messages");
                    } else {
                      dispatch({ type: "SUBSCRIPTION_MODAL", payload: true });
                    }
                  }}
                  className="cursor-pointer messages-col"
                >
                  Messages
                  {/* <span className="messages-number ms-2">0</span> */}
                </div>

                <Nav.Item
                  as="li"
                  className={
                    window.location.pathname === "/notifications"
                      ? "active"
                      : ""
                  }
                >
                  <Nav.Link
                    onClick={() => {
                      if (
                        SubscriptionFeature.notification.notification_section
                      ) {
                        navigate("/notifications");
                      } else {
                        dispatch({ type: "SUBSCRIPTION_MODAL", payload: true });
                      }
                    }}
                    eventKey="link-0"
                  >
                    Notification
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
            <div className="profile-menu-right">
              <Nav as="ul">
                <Nav.Item
                  as="li"
                  className={
                    window.location.pathname === "/profile" ? "active" : ""
                  }
                >
                  {/* my-job */}
                  <Nav.Link
                    onClick={() => {
                      if (SubscriptionFeature.profile.profile_access) {
                        navigate("/profile");
                      } else {
                        dispatch({ type: "SUBSCRIPTION_MODAL", payload: true });
                      }
                    }}
                    eventKey="link-0"
                  >
                    Profile
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item
                  as="li"
                  className={
                    window.location.pathname === "/change/password"
                      ? "active"
                      : ""
                  }
                >
                  <Nav.Link
                    onClick={() => {
                      navigate("/change/password");
                    }}
                    eventKey="link-1"
                  >
                    Password
                  </Nav.Link>
                </Nav.Item>
                {/* <Nav.Item as="li">
                            <Nav.Link eventKey="link-2">Plan</Nav.Link>
                        </Nav.Item> */}
                <Nav.Item
                  as="li"
                  className={
                    window.location.pathname === "/billing" ? "active" : ""
                  }
                >
                  <Nav.Link
                    onClick={() => {
                      //Commented on 14-10-22
                      // if (UserData.logged_user.subscription) {
                        navigate("/billing");
                      // } else {
                      //   dispatch({ type: "SUBSCRIPTION_MODAL", payload: true });
                      // }
                    }}
                    eventKey="link-3"
                  >
                    Billing
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item
                  as="li"
                  className={
                    window.location.pathname === "/my-jobs" ||
                    window.location.pathname === "/applied-job" ||
                    window.location.pathname === "/saved-job"
                      ? "active"
                      : ""
                  }
                >
                  <NavDropdown title="Job" id="nav-dropdown">
                    <NavDropdown.Item
                      onClick={() => {
                        if (SubscriptionFeature.job.create_booking) {
                          navigate("/my-jobs");
                        } else {
                          dispatch({
                            type: "SUBSCRIPTION_MODAL",
                            payload: true,
                          });
                        }
                      }}
                      className={
                        window.location.pathname === "/my-jobs" ? "active" : ""
                      }
                    >
                      Created Job
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      onClick={() => {
                        if (SubscriptionFeature.job.save) {
                          navigate("/saved-job");
                        } else {
                          dispatch({
                            type: "SUBSCRIPTION_MODAL",
                            payload: true,
                          });
                        }
                      }}
                      className={
                        window.location.pathname === "/saved-job"
                          ? "active"
                          : ""
                      }
                    >
                      Saved Jobs
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      onClick={() => {
                        if (SubscriptionFeature.job.apply) {
                          navigate("/applied-job");
                        } else {
                          dispatch({
                            type: "SUBSCRIPTION_MODAL",
                            payload: true,
                          });
                        }
                      }}
                      className={
                        window.location.pathname === "/applied-job"
                          ? "active"
                          : ""
                      }
                    >
                      Applied Jobs
                    </NavDropdown.Item>
                  </NavDropdown>
                </Nav.Item>
              </Nav>
            </div>
            {/* <div className="banner-search ms-auto">
              <AutoComplete suggestions={suggestions} />
            </div> */}
          </div>
        </Container>
      </div>
      <div className="mobile-main-head d-lg-none d-block py-3">
        <Container>
          <Row>
            <div className="col-sm-12 col-md-12 col-lg-12 mobile-select ">
              <select
                onChange={(e) => {
                  setMobileSelected(e.target.value);
                  navigate(e.target.value);
                }}
                name="languages"
              >
                {Enums.profile_mobile_nav.map((profile_item, i) => {
                  if (!profile_item.is_group) {
                    return (
                      <option
                        selected={
                          mobileSelected === profile_item.link ? true : false
                        }
                        key={i}
                        value={profile_item.link}
                      >
                        {profile_item.label}
                      </option>
                    );
                  } else {
                    return (
                      <optgroup key={i} label={profile_item.label}>
                        {profile_item.group_options.map((inner_option, j) => {
                          return (
                            <option
                              key={j}
                              selected={
                                mobileSelected === inner_option.link
                                  ? true
                                  : false
                              }
                              value={inner_option.link}
                            >
                              {inner_option.label}
                            </option>
                          );
                        })}
                      </optgroup>
                    );
                  }
                })}
                {/* <option value="/messages">Messages</option>
                <option value="/notifications">Notification</option>
                <option value="/profile">Profile</option>
                <option value="/change/password">Password</option>
   
                <option value="/billing">Billing</option>
                <optgroup label="Job">
                  <option value="/my-jobs">Created Job</option>
                  <option value="/saved-job">Saved Jobs</option>
                  <option value="/applied-job">Applied Jobs</option>
                </optgroup> */}
              </select>
            </div>
            <div className="mobile-search col-sm-12 col-md-12 col-lg-12 mt-3 mt-lg-0">
              <AutoComplete suggestions={suggestions} />
            </div>
          </Row>
        </Container>
      </div>
    </>
  );
}
