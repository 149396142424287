import React from 'react'
import { commonHelper } from '../../common/commonHelper'
import Button from "react-bootstrap/Button";
import { useState } from 'react';
import { useEffect } from 'react';

export default function ScrollButtons({ scrollRefDiv }) {
  const [checkSize, setCheckSize] = useState(scrollRefDiv.current.clientWidth);
  return (<div className="list-group-button">
   {scrollRefDiv.current.scrollLeft !== 0 && <Button type="button" onClick={() => {  setCheckSize((scrollRefDiv.current.clientWidth / 2) - scrollRefDiv.current.scrollLeft + scrollRefDiv.current.clientWidth);commonHelper.handleScroll(scrollRefDiv, 'l') }} className="slick-arrow slick-prev"></Button>}
    {/* {checkSize + 70 < scrollRefDiv.current.scrollWidth ? <Button type="button" onClick={() => { setCheckSize(((scrollRefDiv.current.clientWidth / 2)) + scrollRefDiv.current.scrollLeft + scrollRefDiv.current.clientWidth) ;commonHelper.handleScroll(scrollRefDiv, 'r') }} className="slick-arrow slick-next"></Button> : ""} */}
    {checkSize < scrollRefDiv.current.scrollWidth ? <Button type="button" onClick={() => { setCheckSize(((scrollRefDiv.current.clientWidth / 2)) + scrollRefDiv.current.scrollLeft + scrollRefDiv.current.clientWidth) ;commonHelper.handleScroll(scrollRefDiv, 'r') }} className="slick-arrow slick-next"></Button> : ""}
  </div>)
}
