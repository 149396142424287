import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Offcanvas from "react-bootstrap/Offcanvas";
import ProfileImg from "../../assets/media/img/profile-img.png";
import VerifiedTick from "../../assets/media/icons/verified-tick.svg";
import ArtistTopImg from "../../assets/media/img/artist-top-img.png";
import Spotify from "../../assets/media/icons/spotify.svg";
import Snapchat from "../../assets/media/icons/snapchat.svg";
import Youtube from "../../assets/media/icons/youtube.svg";
import Tiktok from "../../assets/media/icons/tiktok.svg";
import DeezerIcon from "../../assets/media/icons/deezer-icon.svg";
import Facebook from "../../assets/media/icons/facebook.svg";
import LinkedIn from "../../assets/media/icons/linkedin.svg";
import TrackerIcon from "../../assets/media/icons/tracker-icon.svg";
import MarkerPin from "../../assets/media/icons/marker-pin.svg";
import { useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import { SocketContext } from '../../Context/socket';
import ProfileMenuHeader from "../../Component/ProfileMenuHeader/ProfileMenuHeader";
import ChatSidePanel from "./ChatSidePanel";
import ChatBox from "./ChatBox";
import { Dropdown, Form, Button, Card } from "react-bootstrap";
import ChatSidePanelLoader from "../../Component/Loaders/ChatSidePanelLoader";
import APICall from "../../networking/AxiousServices";
import ApiTypes from "../../networking/APItypes";
import PreviewImg from "../../Component/Image/PreviewImg";
import ClientDefault from "../../assets/Images/Defaults/client_default.webp";
import { commonHelper } from "../../common/commonHelper";
import ReactHtmlParser from 'react-html-parser';
import InfiniteScroll from "react-infinite-scroll-component";
import InfinityLoader from "../../Component/Loaders/InfinityLoader";

function ChatMessages() {
  const location = useLocation()
  const [isLocationSet, setIsLocationSet] = useState(false);

  const navigate = useNavigate();
  const UserData = useSelector((state) => state.data);
  const authUserId = UserData.logged_user.user_id;
  const [showResults, setShowResults] = useState(false);


  const [chatUsersList, setChatUsersList] = useState([]);
  const [perSidePanelPage, setPerSidePanelPage] = useState(15);
  const [sidePanelPage, setSidePanelPage] = useState(1);
  const [sidePanelHashMore, setSidePanelHashMore] = useState(true);
  const [sideChatLoading, setSideChatLoading] = useState(true);
  const [loadMoreSideLoading, setLoadMoreSideLoading] = useState(false);


  const [page, setPage] = useState(1);
  const [intervalHandler, setIntervalHandler] = useState(null);
  const [socketId, setSocketId] = useState(null);
  const [hashMore, setHashMore] = useState(true);


  const [isChatBoxEmpty, setIsChatBoxEmpty] = useState(false);

  const [chatRoomId, setChatRoomId] = useState(null);
  const [receiverId, setReceiverId] = useState(null);

  const [receiverImage, setReceiverImage] = useState(null);
  const [receiverName, setReceiverName] = useState(null);

  const [receiverIsOnline, setReceiverIsOnline] = useState(false);

  const [selectedUserData, setSelectedUserData] = useState(null);
  const socket = useContext(SocketContext);


  const [CurrentOpenChat, setCurrentOpenChat] = useState(
    window.innerWidth > 800
      ? {
        profile: ProfileImg,
        is_online: false,
        name: "Poonam Shah",
        job_title: "Asp.NET",
        last_message_on: "5min ago",
        chat_messages: [
          {
            message: "Satish Thummar Hi 1",
            time: "8:00PM",
            date: "",
            is_my_message: false,
          },
          {
            message: "Satish Thummar Hi 2",
            time: "",
            date: "",
            is_my_message: true,
          },
          {
            message: "Satish Thummar Hi 3",
            time: "",
            date: "",
            is_my_message: true,
          },
        ],
      }
      : null
  );

  socket.on('createdRoomRes', async function (data) {
    // console.log('chat msg createdRoomRes data' ,data)
  })


  const fetchChatList = async () => {

    let res = await APICall({
      url: ApiTypes.fetchSidePanelChatList,
      data: {
        limit: perSidePanelPage,
        page: sidePanelPage,
      },
    })
    if (res.status === 1) {
      if (
        parseInt(chatUsersList.length) + parseInt(res.data.list.length) >=
        parseInt(res.data.total)
      ) {
        setSidePanelHashMore(false);
      }
      setSideChatLoading(false);
      // setSidePanelHashMore(false);
      setLoadMoreSideLoading(false)
      return res.data.list;
    } else {
      if (res.data.status === 0) {
        setSideChatLoading(false);
        // setSidePanelHashMore(false);
        setLoadMoreSideLoading(false)

        return [];
      }

    }


    // if (!isSocketConnected) {
    //     app.chatLists.findIndex(function(el) {
    //         if(el.socket_id == data.socket_id){
    //             el.online = 'N';
    //             el.socket_id = '';
    //         }
    //     });
    // }else if (isSocketConnected) {
    //     app.chatLists.findIndex(function(el) {
    //         if(el.id == data.userId){
    //             el.online = 'Y';
    //             el.socket_id = data.socket_id;
    //         }
    //     });
    // }else {
    //     data.chatList.findIndex(function(el) {
    //         el.msgCount = 0;
    //     });
    //     app.chatLists = data.chatList;
    // }
    // });

  }

  const fetchMainFunChatList = async () => {
    setSideChatLoading(true);
    let data = await fetchChatList();
    setChatUsersList(data);
    if (data.length) {
      if (isLocationSet) {
        setChatRoomId(data[0].chat_room_id)
        setReceiverId(authUserId === data[0].rec_user_id ? data[0].sen_user_id : data[0].rec_user_id)
        setReceiverImage(authUserId === data[0].rec_user_id ? data[0].sen_profile_img : data[0].rec_profile_img)
        setReceiverName(authUserId === data[0].rec_user_id ? data[0].sen_display_name : data[0].rec_display_name)
        if (authUserId === data[0].rec_user_id) {
          setReceiverIsOnline(data[0].sen_is_online == 1)
        } else {
          setReceiverIsOnline(data[0].rec_is_online == 1)
        }
      } else {
        if (location.state) {
          setChatRoomId(location.state.socket_id)
          setReceiverId(location.state.rec_id)
          setReceiverImage(location.state.profile_img)
          setReceiverName(location.state.display_name)
        } else {
          setChatRoomId(data[0].chat_room_id)
          setReceiverId(authUserId === data[0].rec_user_id ? data[0].sen_user_id : data[0].rec_user_id)
          setReceiverImage(authUserId === data[0].rec_user_id ? data[0].sen_profile_img : data[0].rec_profile_img)
          setReceiverName(authUserId === data[0].rec_user_id ? data[0].sen_display_name : data[0].rec_display_name)
          if (authUserId === data[0].rec_user_id) {
            setReceiverIsOnline(data[0].sen_is_online == 1)
          } else {
            setReceiverIsOnline(data[0].rec_is_online == 1)
          }
        }
        setIsLocationSet(true)
      }

    } else {
      setIsChatBoxEmpty(true)
    }
    if (data.chatList)
      setSideChatLoading(false)
    if ('chatList' in data) {
      setSocketId(data.socket_id)
      setChatUsersList(data.chatList)
    }
  }

  const fetchSearchMainResult = async () => {
    setLoadMoreSideLoading(true)
    let data = await fetchChatList();
    setChatUsersList([...chatUsersList, ...data]);
  }

  useEffect(() => {
    if (sidePanelPage !== 1) {
      fetchSearchMainResult();
    }
  }, [sidePanelPage]);

  useEffect(() => {
    // socket = socketIOClient(ENDPOINT, {
    //     auth: {
    //         token: authUserId
    //     }
    // });
    fetchMainFunChatList()

  }, [socket]);

  const getChatProfile = async () => {
    let res = await APICall({
      url: ApiTypes.fetchClientDetails,
      data: {
        user_id: receiverId
      }
    })

    if (res.status == 1) {
      setSelectedUserData(res.data)
    }

  }
  useEffect(() => {
    if (receiverId) {
      getChatProfile()
    }
  }, [receiverId]);

  const renderLocation = (value) => {
    let str =
    value.address1 ? value.address1 + ", " : "" +
      (value.city ? value.city + ", " : "") +
      (value.state ? value.state + ", " : "") +
      (value.country ? value.country + ", " : "") +
      (value.postal_code ? value.postal_code + ", " : "");
    str = str.trim();
    str = str.slice(0, -1);
    if (str.length && str && str !== "null") {
      return str;
    } else {
      return "-";
    }
  };

  return (<>
    <div className="main-content">
      {/* <div className="account-profile-menu"> */}
      <ProfileMenuHeader />
      {/* </div> */}
      <div className="account-profile-section">
        <Container>
          <div className="account-profile mt-5">
            <div className="account-title">All Messages</div>
            <div className="chetprofilesec mt-4 d-flex">
              <div
                className={`chat-section d-flex w-100 ${showResults ? "chat-section-col" : "chat-section-hide"
                  }`}
              >
                <div
                  className={
                    !showResults
                      ? "messages-left-section"
                      : "messages-left-section-hide"
                  }
                >
                  {/* <div className="notification-messages-search mb-3">
                    <Form className="d-flex">
                      <Form.Control
                        type="search"
                        placeholder="Search"
                        aria-label="Search"
                      />
                      <Button variant="search-icon">
                        <i className="fa-regular fa-magnifying-glass"></i>
                      </Button>
                    </Form>
                  </div> */}
                  <div id="notificationMessages" className="notification-messages">
                    {!sideChatLoading ? chatUsersList.length ?
                      <InfiniteScroll
                        scrollableTarget="notificationMessages"
                        dataLength={parseInt(chatUsersList.length)} //This is important field to render the next data
                        next={() => {
                          if (!loadMoreSideLoading) setSidePanelPage(sidePanelPage + 1);
                        }}
                        hasMore={sidePanelHashMore}
                        loader={<InfinityLoader />}
                      >
                        {chatUsersList.map((item, index) => (
                          <ChatSidePanel
                            receiverId={receiverId}
                            authUserId={authUserId}
                            key={index}
                            onClick={(item) => {
                              setChatRoomId(item.chat_room_id)
                              setReceiverId(authUserId === item.rec_user_id ? item.sen_user_id : item.rec_user_id)
                              setReceiverImage(authUserId === item.rec_user_id ? item.sen_profile_img : item.rec_profile_img)
                              setReceiverName(authUserId === item.rec_user_id ? item.sen_display_name : item.rec_display_name)
                              setReceiverIsOnline(item.sen_is_online === "1" ? true : false)
                              setPage(1)
                              setHashMore(true)
                              clearInterval(intervalHandler)
                              setIntervalHandler(null)
                              socket.emit('create', item.chat_room_id);
                            }}
                            item={item}
                            showResults={showResults}
                            setShowResults={setShowResults}
                            CurrentOpenChat={CurrentOpenChat}
                            setCurrentOpenChat={setCurrentOpenChat}
                          />))}
                      </InfiniteScroll>
                      : <div className="w-100 text-center mt-5">No Chat Yet!</div>
                      : <ChatSidePanelLoader />
                    }
                  </div>
                  {/* {CurrentOpenChat !== null && ( */}

                  {/* )} */}
                </div>

                {!isChatBoxEmpty ? <ChatBox
                  receiverImage={receiverImage}
                  receiverName={receiverName}
                  setIntervalHandler={setIntervalHandler}
                  intervalHandler={intervalHandler}
                  socketId={socketId}
                  receiverId={receiverId}
                  setPage={setPage}
                  page={page}
                  setReceiverId={setReceiverId}
                  chatRoomId={chatRoomId}
                  setChatRoomId={setChatRoomId}
                  showResults={showResults}
                  setShowResults={setShowResults}
                  CurrentOpenChat={CurrentOpenChat}
                  setCurrentOpenChat={setCurrentOpenChat}
                  receiverIsOnline={receiverIsOnline}
                  setHashMore={setHashMore}
                  hashMore={hashMore}
                /> : <div className="w-100 text-center mt-5">No conversation!</div>}
              </div>

              <div
                className={
                  showResults
                    ? "side-panel-profile"
                    : "side-panel-profile-hide"
                }
              >
                <div className="side-profile-col">
                  <div
                    className={
                      showResults
                        ? "left-profile-button"
                        : "left-profile-button-hide"
                    }
                  >
                    <button
                      type="submit"
                      className="btn btn-primary back-massage-button"
                      onClick={() => {
                        setShowResults(false);
                      }}
                    >
                      <i className="fa-solid fa-arrow-left"></i>
                    </button>
                  </div>
                  <div className="side-profile-top">
                    {/* <img
                      src={ArtistTopImg}
                      alt="side-profile-img"
                      width={360}
                      height={267}
                      className="w-100"
                    /> */}

                    <PreviewImg
                      src={selectedUserData && selectedUserData.profile_img ? selectedUserData.profile_img : ClientDefault}
                      isDefault={selectedUserData ? selectedUserData.profile_img ? false : true : true}
                      alt="artist-img"
                      width={360}
                      height={267}
                      defaultImg={ClientDefault}
                      className="w-100"
                    />
                    {/* <div className="artist-verifie-tick">
                      <img
                        src={VerifiedTick}
                        alt="VerifiedTick"
                        width="24"
                        height="24"
                      />
                    </div> */}
                  </div>
                  {/* <div className="side-profile-social-media w-100">
                    <Link to="#" className="facebook">
                      <img src={Facebook} alt="facebook-icon" />
                    </Link>
                    <Link to="#" className="youtube">
                      <img src={Youtube} alt="youtube-icon" />
                    </Link>
                    <Link to="#" className="tiktok">
                      <img src={Tiktok} alt="tiktok-icon" />
                    </Link>
                    <Link to="#" className="snapchat">
                      <img src={Snapchat} alt="snapchat-icon" />
                    </Link>
                    <Link to="#" className="spotify">
                      <img src={Spotify} alt="spotify-icon" />
                    </Link>
                    <Link to="#" className="deezer">
                      <img src={DeezerIcon} alt="spotify-icon" />
                    </Link>
                    <Link to="#" className="linkedin">
                      <img src={LinkedIn} alt="linked-in-icon" />
                    </Link>
                  </div> */}
                  <div className="side-profile-bottom">
                    <div className="side-profile-top-col">
                      <div className="side-profile">
                        {selectedUserData ? selectedUserData.client_first_name + " " + selectedUserData.client_last_name : ""}<sapn ></sapn>
                      </div>
                      {/* <div className="side-profile-job-title">
                        Main Job Title
                      </div> */}
                    </div>
                    <div className="buttons mt-2">
                      {/* <Button className="transparent-btn me-3">
                        Track Me
                      </Button> */}
                      <Button variant="primary" type="submit" onClick={() => {
                        navigate(`/client/?cid=${selectedUserData && selectedUserData.user_id ? selectedUserData.user_id : ""}`)
                      }} >
                        View details
                      </Button>
                    </div>
                    <div className="tracker d-flex mt-2">
                      {/* <div className="tracker-left me-3">
                        <span className="me-2">
                          <img
                            src={TrackerIcon}
                            alt="Award Icon"
                            width={15}
                            height={15}
                          />
                        </span>
                        <span>11.3k Trackers</span>
                      </div> */}
                      <div className="tracker-right pb-3">
                        <span className="me-2">
                          <img
                            src={MarkerPin}
                            alt="Award Icon"
                            width={15}
                            height={15}
                          />
                        </span>
                        <span>{selectedUserData && selectedUserData.location ? renderLocation(selectedUserData.location) : "-"}</span>
                      </div>
                    </div>
                    <div className="description-experience mt-4">
                      <div className="description-col">
                        <div className="description-title mb-2">About</div>
                        <div className="about-us">

                        </div>
                        <p className="description mb-2">
                          {selectedUserData && selectedUserData.mini_bio ? ReactHtmlParser(selectedUserData.mini_bio) : "-"}
                        </p>
                      </div>
                      <div className="experience-col mt-4">
                        <div className="experience-title mb-3">
                          Work experience
                        </div>
                        {/* <div className="experience-field mb-4">
                          <div className="experience-field-title">
                            Music Video Title (Year)
                          </div>
                          <div className="experience-field-list mt-2">
                            <span className="actor me-1">Actor</span>
                            <span className="producer me-1">Producer</span>
                            <span className="director me-1">
                              Art Director
                            </span>
                          </div>
                        </div>
                        <div className="experience-field mb-4">
                          <div className="experience-field-title">
                            Music Video Title (Year)
                          </div>
                          <div className="experience-field-list mt-2">
                            <span className="actor me-1">Choreographer</span>
                            <span className="producer me-1">Singer</span>
                            <span className="director me-1">
                              Screen Writer
                            </span>
                          </div>
                        </div>
                        <div className="experience-field">
                          <div className="experience-field-title">
                            Music Video Title (Year)
                          </div>
                          <div className="experience-field-list mt-2">
                            <span className="actor me-1">
                              Custom Designer
                            </span>
                            <span className="producer me-1">
                              Videographer
                            </span>
                          </div>
                        </div> */}
                        {selectedUserData && selectedUserData.work_experience.length > 0 ?
                          selectedUserData.work_experience.map((work_experience) => {
                            return (<Card className='py-2 px-3 mb-2'>
                              <div className="mb-2">
                                <b><span className="left-col me-1">Company Name :</span></b><br></br>
                                <span className="right-col">{work_experience.company_name ? work_experience.company_name : ""}</span>
                              </div>
                              <div className="mb-2">
                                <b><span className="left-col me-1">Title :</span></b><br></br>
                                <span className="right-col">{work_experience.title ? work_experience.title : "'"}</span>
                              </div>
                              <div className="mb-2">
                                <b><span className="left-col me-1">Date :</span></b><br></br>
                                <span className="right-col">{work_experience.start_date ? commonHelper.formatDate(work_experience.start_date, 'MM-DD-YYYY') : "-"} To {work_experience.end_date ? commonHelper.formatDate(work_experience.end_date, 'MM-DD-YYYY') : "-"}</span>
                              </div>
                              <div className="mb-2">
                                <b><span className="left-col d-block mb-1">
                                  Description of Role :
                                </span></b>
                                <span className="right-col">
                                  {work_experience.description ? work_experience.description : ""}
                                </span>
                              </div>
                            </Card>)
                          })
                          : <div className='text-center'>
                            No Work Experience Found !
                          </div>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>


  </>);
}

export default ChatMessages;
