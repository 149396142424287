import React from 'react'
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export default function ArtistVideoAssociationLoader() {
  return ( <div className='mt-5'>{[...Array(7).fill(0)].map((_, i) => (<div className='container px-3'>
        <div className='row my-1'>
            <div key={i} className="px-1 col-lg-12">

                <SkeletonTheme height={38.9}>
                    <p>
                        <Skeleton />
                    </p>
                </SkeletonTheme>
            </div>
            </div>
        </div>))}</div>)
}
