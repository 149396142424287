import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import BlankStar from "../../assets/media/icons/blank-star.svg";
import FielStar from "../../assets/media/icons/fiel-star.svg";
import ApiTypes from '../../networking/APItypes';
import APICall from '../../networking/AxiousServices';
import { useNavigate } from 'react-router-dom';

export default function IsFavourite({ FavComponent, UnFavComponent, module_id, module_type, is_favorite }) {
    const SubscriptionFeature = useSelector((state) => state.data.logged_user.subscription_feature);
    const isLogIn = useSelector((state) => state.data.auth_token);
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [isFavour, setIsFavour] = useState(is_favorite == 1 ? true : false);
    const [isLoading, setIsLoading] = useState(false);

    const handleFavourite = async (is_favourite) => {
        if (isLogIn) {
            if (SubscriptionFeature.video.favourite) {
                if (!isLoading) {
                    let data = {
                        module_id: module_id,
                        module_type_term: module_type,
                        is_favourite: is_favourite,
                    }
                    setIsLoading(true)
                    let res = await APICall({
                        url: ApiTypes.addOrRemoveFromFav,
                        data: data
                    })
                    if (res.status === 1) {
                        setIsLoading(false)
                        setIsFavour(parseInt(res.data.is_favourite) === 1 ? true : false)
                    }
                }
            } else {
                dispatch({ type: "SUBSCRIPTION_MODAL", payload: true });
            }
        }else{
            navigate('/login')
        }

    }

    return isFavour ?
        (<div onClick={() => { handleFavourite(0) }}>
            <FavComponent />
        </div>
        )
        : (<div onClick={() => { handleFavourite(1) }}>
            <UnFavComponent />
        </div>
        )
}
