import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import MbaseLogo from "../../assets/media/logos/m-base-logo.svg";
import Button from "react-bootstrap/Button";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Offcanvas from "react-bootstrap/Offcanvas";
import ProfileImg from "../../assets/media/img/profile-img.png";

import APICall from "../../networking/AxiousServices";
import ApiTypes from "../../networking/APItypes";
import ProgressBar from "react-bootstrap/ProgressBar";
import Badge from "react-bootstrap/Badge";
import NodataFound from "../Errors/NodataFound";
import { Enums } from "../../constants/Enums";
import NotificationImg from "../../assets/media/img/notification-img.png";
import NotificationImg2 from "../../assets/media/img/notification-img-2.png";
import NotificationImg3 from "../../assets/media/img/notification-img-3.png";
import { commonHelper } from "../../common/commonHelper";
import { Form, NavDropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import ProfileMenuHeader from "../../Component/ProfileMenuHeader/ProfileMenuHeader";
import SiteLoader from "../../Component/Loaders/SiteLoader";
import FetchLoader from "../../Component/Loaders/FetchLoader";

function NotificationMessage() {
  const [notificationList, setNotificationList] = useState(null);
  const [totalotification, setTotalNotification] = useState(null);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [hashMore, setHashMore] = useState(true);
  const navigate = useNavigate();

  const fetchNotificationList = async () => {
    let data = {
      page: page,
      limit: perPage,
    };

    let res = await APICall({
      url: ApiTypes.getNotificationList,
      data: data,
    });
    if (res.status === 1) {
      setTotalNotification(res.data.total);

      if (res.data.list) {
        if (
          res.data.total ==
          res.data.list?.length + notificationList?.length
        ) {
          setHashMore(false);
        }
        setNotificationList(
          notificationList
            ? [...notificationList, ...res.data.list]
            : res.data.list
        );
      }
    }
  };

  const markAsRead = async () => {
    let res = await APICall(ApiTypes.markAsReadNotifications, { read_all: 1 });
    if (res.status === 1) {
      setNotificationList(res.data.list);
    }
  };

  const handleNotification = () => {};

  useEffect(() => {
    fetchNotificationList();
  }, [page]);
  useEffect(() => {
    markAsRead();
  }, []);

  return (
    <>
      <div className="main-content">
        {/* <div className="account-profile-menu"> */}
        <ProfileMenuHeader />
        {/* </div> */}
        <div className="account-profile-section">
          <Container>
            <div className="account-profile mt-5">
              <div className="account-title mb-4">Notifications</div>
              {notificationList != null ? (
                notificationList.length != 0 ? (
                  <InfiniteScroll
                    dataLength={notificationList.length}
                    next={() => {
                      setPage(page + 1);
                    }}
                    hasMore={hashMore}
                    loader={<FetchLoader />}
                  >
                    {notificationList.map((item, index) => {
                      return (
                        <div
                          onClick={() => {
                            handleNotification();
                          }}
                          className="notification-lists mb-4"
                        >
                          <div className="notification-list d-flex">
                            {/*<div className="notification-img me-3">
                            <img
                            src={NotificationImg3}
                            alt="NotificationImg"
                            width={40}
                            height={40}
                            className="rounded-3"
                          /> 
                            </div> */}
                            <div className="notification-message w-100">
                              <span className="message-title d-block">
                                {item.notification_title}
                              </span>
                              <div className="d-flex mt-2 justify-content-between">
                                <span className="message-discription-area pe-3">
                                  {item.notification_description}
                                </span>
                                <span className="message-discription-area dateformat text-end">
                                  {commonHelper.formatDate(
                                    item.created_at,
                                    "MM-DD-YYYY"
                                  )}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        // <div key={index} className='noti-div-inner'>
                        //     <div className="d-flex justify-content-between mt-2">
                        //         <div className='noti-dis'>{item.notification_description}</div>

                        //     </div>

                        //     <div className='noti-date'>  {commonHelper.formatDate(item.created_at, 'MM-DD-YYYY')}</div>
                        // </div>
                      );
                    })}
                  </InfiniteScroll>
                ) : (
                  <div className="noti-not-found h5 text-center">
                    No notifications found
                  </div>
                )
              ) : (
                <div className="notification-loader">
                  {Array.apply(null, { length: 10 }).map((e, i) => (
                    <div className="my-3 ">
                      <div className="noti-fancy-loader my-2"></div>
                      <div
                        style={{ height: "14px", width: "20%" }}
                        className="noti-fancy-loader my-2"
                      ></div>
                    </div>
                  ))}
                </div>
              )}

              {/* <div className="notification-lists mt-4">
              <div className="notification-list d-flex">
                <div className="notification-img me-3">
                  <img
                    src={NotificationImg3}
                    alt="NotificationImg"
                    width={40}
                    height={40}
                    className="rounded-3"
                  />
                </div>
                <div className="notification-message">
                  <span className="message-title d-block">Subscription</span>
                  <span className="message-discription-area">
                    Your subscription plan will be expire after 2 days on 12
                    August 2022
                  </span>
                </div>
              </div>
            </div> */}
            </div>
          </Container>
        </div>
      </div>
    </>
  );
}

export default NotificationMessage;
