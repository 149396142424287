import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { Accordion, Button, InputGroup, Modal, Row } from "react-bootstrap";
import ApiTypes from '../../networking/APItypes';
import APICall from '../../networking/AxiousServices';
import NodataFound from '../Errors/NodataFound';
import ClientDefault from '../../assets/Images/Defaults/client_default.webp'
import FetchLoader from '../../Component/Loaders/FetchLoader';
import LoadMoreLoader from '../../Component/Loaders/LoadMoreLoader';
import ApplicantDetails from './ApplicantDetails';
import PreviewImg from '../../Component/Image/PreviewImg';

export default function JobApplicants({ showApplicantJob, applicantJobId, setShowApplicantJob, setApplicantJobId }) {

    const [showApplicantDetails, setShowApplicantDetails] = useState(false);
    const [jobApplicantId, setJobApplicantId] = useState(false);


    const [loading, setLoading] = useState(true)
    const [loadMoreLoading, setLoadMoreLoading] = useState(false);

    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);


    const [totalApplicants, setTotalApplicants] = useState(0);
    const [applicants, setApplicants] = useState([]);

    const handleClose = () => {
        setShowApplicantJob(false)
        setApplicantJobId(null)
    }

    const fetchApplicants = async () => {
        let res = await APICall({
            url: ApiTypes.applicantUsers,
            data: {
                job_id: applicantJobId,
                page: page,
                limit: perPage
            }
        })
        if (res.status === 1) {
            setLoading(false)
            setLoadMoreLoading(false)
            setTotalApplicants(res.data.total)
            return res
        } else {
            return null;
        }
    }

    const handleLoadMore = async () => {
        setLoadMoreLoading(true)
        let res = await fetchApplicants()
        if (res) {
            setApplicants([...applicants, ...res.data.list])
        }
    }

    const applyPrimaryApplicants = async () => {
        let res = await fetchApplicants()
        if (res) {
            setApplicants(res.data.list)
        }

    }

    useEffect(() => {
        if (applicantJobId) {
            applyPrimaryApplicants()
        }
    }, [applicantJobId]);


    return (<Modal
        show={showApplicantJob}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="view-details-modal"
    >
        <Modal.Header closeButton className="align-items-start">
            <div className="title">View Application Details</div>
        </Modal.Header>
        <Modal.Body>
            <div className="details-section">
                {!loading ? applicants.length > 0 ?
                    applicants.map((app_item, index) => {
                        return (<div key={index} className="details-col d-flex justify-content-between align-items-center">
                            <div className="d-flex">
                                <div className="profile-section me-3">

                                    <PreviewImg
                                        src={app_item.profile_img ? app_item.profile_img : ClientDefault}
                                        isDefault={app_item.profile_img ? false : true}
                                        alt="ProfileImg"
                                        width={40}
                                        height={40}
                                        defaultImg={ClientDefault}
                                        className="rounded-circle"
                                    />
                                    {/* <img
                                        src={app_item.profile_img ? process.env.REACT_APP_ASSETS_URL + app_item.profile_img : ClientDefault}
                                        alt="ProfileImg"
                                        width="40"
                                        height="40"
                                        className="rounded-circle"
                                    /> */}
                                    <span className="online-tik"></span>
                                </div>
                                <div className="">
                                    <div className="details-col-title">{app_item.display_name ? app_item.display_name : ""}</div>
                                    <div className="details-col-job-title mt-1">
                                        {app_item.job_title ? app_item.job_title : ""}
                                    </div>
                                </div>
                            </div>
                            <div className="job-detail-button">
                                <Button
                                    variant="primary"
                                    type="button"
                                    className="transparent-btn btn btn-primary"
                                    onClick={() => {
                                        setJobApplicantId(app_item.job_applicant_id)
                                        setShowApplicantDetails(true)
                                    }
                                    }
                                >
                                    View Job Details
                                </Button>
                            </div>
                        </div>)
                    })
                    : <NodataFound /> : <FetchLoader />}
            </div>
            {applicants.length < totalApplicants ? (
                <div className="row">
                    <div className="col-12 text-center mt-5">
                        {!loadMoreLoading ? (
                            <Button
                                onClick={() => {
                                    handleLoadMore();
                                }}
                                variant="primary"
                                type="button"
                            >
                                Load More
                            </Button>
                        ) : (
                            <LoadMoreLoader />
                        )}
                    </div>
                </div>
            ) : (<></>
                // <NodataFound/>
            )}
            <ApplicantDetails
                showApplicantDetails={showApplicantDetails}
                setShowApplicantDetails={setShowApplicantDetails}
                jobApplicantId={jobApplicantId}
                setJobApplicantId={setJobApplicantId}
            />
        </Modal.Body>
    </Modal>)
}
