import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { commonHelper } from '../../common/commonHelper';
import ApiTypes from '../../networking/APItypes';
import { Link, useNavigate } from "react-router-dom";
import APICall from '../../networking/AxiousServices';
import ArtistVideoAssociationLoader from '../../Component/Loaders/ArtistVideoAssociationLoader';

export default function ArtistVideoAssocications({ setShowArtistVideoPopup, setArtistType, rotateHandler, artist_id }) {
  const navigate = useNavigate()
  const [state, setState] = useState(true);
  const UserData = useSelector((state) => state.data);

  const [videoPage, setVideoPage] = useState(1);
  const [videos, setVideos] = useState([]);
  const [totalVideos, setTotalVideos] = useState(0);
  const [perPage, setPerPage] = useState(7);
  const [loading, setLoading] = useState(false);
  const [preLoading, setPreLoading] = useState(true);

  let isLastPage = ((videoPage - 1) * perPage) + videos.length >= totalVideos ? true : false

  const handleNavigateVideo = (e, video_id) => {
    e.preventDefault();
    navigate(`/video?vid=${video_id}`);
  }
  const handleArtistVideos = (e, artistType) => {
    e.preventDefault()
    setShowArtistVideoPopup(true)
    setArtistType(artistType)
  }

  const fetchArtistVideos = async () => {
    setLoading(true);

    let res = await APICall({
      url: ApiTypes.fetchArtistVideos,
      data: {
        artist_id: artist_id,
        // artist_type_term : artistType ,
        is_filter: 1,
        // filter: {
        //   tab_type: activeTag,
        // },
        page: videoPage,
        limit: perPage,
      },
    });
    if (res.status === 1) {
      setLoading(false);
      setTotalVideos(res.data.total);
      setVideos(res.data.list)
      return res.data.list;
    } else if (res.status === 0) {
      return [];
    } else {
      return [];
    }
  };
  useEffect(() => {
    fetchArtistVideos()
  }, [videoPage]);

  return (<div className="artist-job-col">
    <div className="artist-job-top" onClick={rotateHandler}>
      {/* <span className="job-title text-uppercase">
                      MAIN JOB TITLE(S)
                    </span> */}
      {/* <div className="job-bottom-title text-uppercase">
                      {artistDetails.display_name}
                    </div> */}
      <div className="job-bottom-title text-uppercase">
        work experience
      </div>
    </div>
    {!loading ? <div
      className="job-description"
      style={{ display: state ? " block" : "none" }}
    >
      {videos.map((video) => {
        return (
          <div className="row mb-4">
            <div className="col-6">
              <div className="job-list-left-col">
                <label>
                  {video.artist_associate_label}
                </label>
              </div>
            </div>
            <div className="col-6">
              <Link to="#" className="video-title" style={{ display: "flex", justifyContent: "space-between" }}>
                {video ?
                  video.title ? <span title={video ?
                    video.date_of_release ? video.title + " " + `(${commonHelper.formatDate(video.date_of_release, 'YYYY') + ") "}` : ""
                    : ""} onClick={(e) => {
                      handleNavigateVideo(e, video.video_id)
                    }} className="me-2 d-inline-block text-capitalize association-artist-text" style={{ maxWidth: "90%" }}>
                    {video.title + " "}
                    {video ?
                      video.date_of_release ? `(${commonHelper.formatDate(video.date_of_release, 'YYYY') + ") "}` : ""
                      : ""}
                  </span> : ""
                  : ""}
                {/* <span title={video.video_associcates_count ? video.video_associcates_count : 0} style={{ maxWidth: "10%" }} onClick={(e) => {
                  handleArtistVideos(e, video.artist_type_term)
                }} className="d-inline-block text-capitalize association-artist-text">
                  {video.video_associcates_count ? video.video_associcates_count : 0}
                </span> */}
              </Link>
            </div>
          </div>
        );
      })}
    </div> : <ArtistVideoAssociationLoader />}
    <div className="paginations d-flex align-items-center justify-content-end">
      <div className="pagination-page-number me-4">{(videoPage - 1 ? ((videoPage - 1) * perPage) + 1: totalVideos  ? videoPage :0)}-{isLastPage ? totalVideos : ((videoPage - 1) * perPage) + perPage} of {totalVideos}</div>
      <div className="pagination-item">
        <button onClick={() => {
          if (videoPage !== 1) {
            setVideoPage(1)
          }
        }} type="button" disabled={videoPage === 1 ? true : false} className={`pagination-first-page ${videoPage === 1 ? "disabled" : ""}`}>
          <i class="fa-sharp fa-solid fa-arrow-left-from-line"></i>
        </button>
        <button disabled={videoPage === 1 ? true : false} onClick={() => {
          if (videoPage !== 1) {
            setVideoPage(videoPage - 1)
          }
        }} type="button" className={`pagination-previous-page ${videoPage === 1 ? "disabled" : ""}`}>
          <i class="fa-sharp fa-solid fa-angle-left"></i>
        </button>
        <button disabled={((videoPage - 1) * perPage) + videos.length >= totalVideos ? true : false} onClick={() => {
          if (((videoPage - 1) * perPage) + videos.length < totalVideos) {
            setVideoPage(videoPage + 1)
          }
        }} type="button" className={`pagination-next-page ${((videoPage - 1) * perPage) + videos.length >= totalVideos ? "disabled" : ""}`}>
          <i class="fa-sharp fa-solid fa-angle-right"></i>
        </button>
        <button disabled={((videoPage - 1) * perPage) + videos.length >= totalVideos} onClick={() => {
          if (((videoPage - 1) * perPage) + videos.length < totalVideos) {
            setVideoPage(Math.ceil(totalVideos / perPage))
          }
        }} type="button" className={`pagination-last-page ${((videoPage - 1) * perPage) + videos.length >= totalVideos ? "disabled" : ""}`}>
          <i class="fa-sharp fa-solid fa-arrow-right-from-line"></i>
        </button>
      </div>
    </div>
  </div>)
}
