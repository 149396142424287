import React from 'react'
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export default function ChatBoxLoader() {
    let count = true;
    return (<>
        {[...Array(15).fill(0)].map((_, i) => {
            count = !count
            return (<div className='container-fluid'>
                <div className={`row d-flex ${count ? 'justify-content-end' : ""}`}>
                    <div key={i} className="px-1 w-45 ">

                        <SkeletonTheme  borderRadius={'13px'} height={38}>
                            <p>
                                <Skeleton />
                            </p>
                        </SkeletonTheme>
                    </div>
                </div>
            </div>)
        })}
    </>)
}
