import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";
import BlankStar from "../../assets/media/icons/blank-star.svg";
import ClockRotate from "../../assets/media/icons/clock-rotate.svg";
import FielStar from "../../assets/media/icons/fiel-star.svg";
import secondThumbnail from "../../assets/media/img/An-image.png";
import TopBannerImg from "../../assets/media/img/Award.png";
import AwardCard from "../../Component/Award/AwardCard";
import HomeVideoLoader from "../../Component/Loaders/HomeVideoLoader";
import LoadMoreLoader from "../../Component/Loaders/LoadMoreLoader";
import { Enums } from "../../constants/Enums";
import ApiTypes from "../../networking/APItypes";
import APICall from "../../networking/AxiousServices";
import NodataFound from "../Errors/NodataFound";
import Slider from "react-slick";
import suggestions from "../../assets/File/AutoComplete";
import AutoComplete from "../../Component/AutoComplete/Index";
import { useRef } from "react";
import ScrollButtons from "../../Component/ScrollButtons/ScrollButtons";

function Awards() {
  var awardsbanner = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
    dots: true,
    infinite: true,
  };

  document.title = process.env.REACT_APP_NAME + " | Awards";
  const scrollRefDiv = useRef(null)

  const [page, setPage] = useState(2);
  const [awardList, setAwardList] = useState([]);
  const [totalAwards, setTotalAwards] = useState(0);
  const [perPage, setPerPage] = useState(9);

  const [loading, setLoading] = useState(false);

  const [yearsList, setYearsList] = useState([]);
  const [selectedYear, setSelectedYear] = useState(null);

  const [selectedTab, setSelectedTab] = useState(null);
  const [selectedAwardCategory, setSelectedAwardCategory] = useState(null);

  const [awardTabs, setAwardTabs] = useState([]);

  const [preLoading, setPreLoading] = useState(true);

  const fetchAwardYears = async () => {
    let res = await APICall({
      url: ApiTypes.fetchYearsList,
      data: {
        previous_year: Enums.award_previous_years,
        after_year: Enums.award_after_years,
      },
    });
    let response = await APICall({
      url: ApiTypes.fetchAwardTabs,
    });
    if (res.status === 1 && response.status === 1) {
      setYearsList(res.data.list);
      setSelectedYear(parseInt(res.data.current_year));
      setSelectedTab(response.data.list[0].value);
      setSelectedAwardCategory(response.data.list[0].ref_term);
      setAwardTabs(response.data.list);
    }
  };

  const fetchAwardList = async (pageItem = 1, perPageItem = 9) => {
    let res = await APICall({
      url: ApiTypes.fetchYearAwards,
      data: {
        year: selectedYear,
        is_filter: 1,
        award_type_term: selectedAwardCategory,
        nominee_category_term: selectedTab,
        filter: {
          award_category: selectedAwardCategory,
        },
        page: pageItem,
        limit: perPageItem,
      },
    });
    if (res.status === 1) {
      setTotalAwards(res.data.total);
      setPreLoading(false);
      return res.data.list;
    } else {
      return [];
    }
  };

  const fetchPreAwardList = async () => {
    let data = await fetchAwardList();
    setAwardList(data);
  };
  useEffect(() => {
    if (selectedYear && selectedTab) {
      setPreLoading(true);
      fetchPreAwardList();
    }
  }, [selectedYear, selectedTab]);

  useEffect(() => {
    fetchAwardYears();
  }, []);

  const fetchLoadMore = async () => {
    setLoading(true);
    let data = await fetchAwardList(page, perPage);
    setAwardList([...awardList, ...data]);
    setLoading(false);
  };

  useEffect(() => {
    if (page !== 1) {
      fetchLoadMore();
    }
  }, [page]);

  const handleLoadMore = async () => {
    setPage(page + 1);
  };

  const SliderDiv = () => {
    return (<Slider className="award-slider" {...awardsbanner}>
    <div className="banner-item">
      <Container>
        <div className="text-center">
          {/*<div className="trending-banner-content">Nominees</div>*/}
          <div className="banner-title-content">
            MTV Music Video Awards
          </div>
          <div className="banner-text-add">
            Explore all the nominees and winners of previous and upcoming
            MTV Music Video Awards shows.
          </div>
          {/*<div className="banner-search">
            <AutoComplete suggestions={suggestions} />
          </div>*/}
        </div>
      </Container>
    </div>
    <div className="banner-item">
      <Container>
        <div className="text-center">
          {/*<div className="trending-banner-content">Nominees</div>*/}
          <div className="banner-title-content">
            More Awards shows coming soon.
          </div>
          {/* <div className="banner-text-add">
            More Awards shows coming soon.
          </div> */}
          {/*<div className="banner-search">
            <AutoComplete suggestions={suggestions} />
          </div>*/}
        </div>
      </Container>
    </div>
  </Slider>)
  }
  return (
    <div className="main-content">
      <div className="page-top-banner top-banner">
        <img
          className="slider-img w-100"
          src={TopBannerImg}
          alt="AuthSlider 1"
          width={1440}
          height={410}
        />
        <SliderDiv/>
        <div className="banner-search-col">
          <div className="banner-search">
            {/* <AutoComplete suggestions={suggestions} /> */}
          </div>
        </div>
      </div>
      {/* <div className="page-top-banner ">
        <img src={TopBannerImg} alt="top-banner" className="w-100" />
        <div className="top-banner-content">
          <Container>
            <Row>
              <div className="col-md-12">
                <div className="trending-banner-content">Nominees</div>
                <div className="banner-title-content">
                  MTV Music Video Awards 2022
                </div>
                <div className="banner-text-add">
                  Explore all the nominees and winners of previous and upcoming
                  MTV Music Video Awards shows.
                </div>
              </div>
            </Row>
          </Container>
        </div>
      </div> */}
      <div className="videos Awards-page-main">
        <div className="music-tabs">
          <Container>
            <div className="music-tab-item">
              <ul ref={scrollRefDiv} className="list-group list-group-horizontal active pl-0 mb-0">
                {yearsList.map((year) => {
                  return (
                    <li className="d-inline-block">
                      <Link
                        onClick={() => {
                          setSelectedYear(parseInt(year));
                        }}
                        to="#"
                        className={`${parseInt(year) === parseInt(selectedYear)
                          ? "active"
                          : ""
                          } d-inline-block`}
                      >
                        {year}
                      </Link>
                    </li>
                  );
                })}
              </ul>
              {yearsList.length ? <ScrollButtons  scrollRefDiv={scrollRefDiv}/> : ""}
            </div>
          </Container>
        </div>
        <div className="award-titles">
          <Container>
            <div className="award_tabs">
              {awardTabs.map((award_tab) => {
                return (
                  <Card
                    onClick={() => {
                      setSelectedAwardCategory(award_tab.ref_term);
                      setSelectedTab(award_tab.value);
                    }}
                    className={`cursor-pointer ${award_tab.value === selectedTab ? "card-award-active" : ""
                      }`}
                  >
                    {award_tab.term_detail}
                  </Card>
                );
              })}
            </div>
          </Container>
        </div>

        <div className="video-item-col">
          <Container>
            <div className="title d-flex justify-content-between">
              {Enums.award_base_tabs_details[selectedTab]}
              {/* <div className="button-class">
                <Button variant="primary">View All</Button>
              </div> */}
            </div>
            <div className="row">
              {!preLoading ? (
                awardList.length > 0 ? (
                  awardList.map((award_item, index) => {
                    return <AwardCard key={index} award_item={award_item} />;
                  })
                ) : (
                  <NodataFound />
                )
              ) : (
                <HomeVideoLoader />
              )}
            </div>
            {awardList.length < totalAwards ? (
              <div className="row">
                <div className="col-12 text-center mt-5">
                  {!loading ? (
                    <Button
                      onClick={() => {
                        handleLoadMore();
                      }}
                      variant="primary"
                      type="button"
                    >
                      Load More
                    </Button>
                  ) : (
                    <LoadMoreLoader />
                  )}
                </div>
              </div>
            ) : (
              ""
            )}
          </Container>
        </div>
      </div>
    </div>
  );
}

export default Awards;
