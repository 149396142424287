import React, { useEffect, useState } from 'react'
import { Col, Form, Modal, Button, Row } from "react-bootstrap";
import { ReactComponent as Crown } from "../../assets/media/icons/crown.svg";
import PaymentIcon from "../../assets/media/img/Payment-icon.png";
import ReactFlagsSelect from "react-flags-select";
import APICall from "../../networking/AxiousServices";
import ApiTypes from "../../networking/APItypes";
import dropin from "braintree-web-drop-in"
import SiteLoader from '../../Component/Loaders/SiteLoader';
import LoadMoreLoader from '../../Component/Loaders/LoadMoreLoader';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { commonHelper } from '../../common/commonHelper';
// var braintree = require('braintree-web');

import {
    PayPalScriptProvider,
    PayPalHostedFieldsProvider,
    PayPalHostedField,
    usePayPalHostedFields,
    PayPalButtons
} from "@paypal/react-paypal-js";
import { useRef } from 'react';

// const CUSTOM_FIELD_STYLE = { "border": "1px solid #606060", "boxShadow": "2px 2px 10px 2px rgba(0,0,0,0.1)" };
// const INVALID_COLOR = {
//     color: "#dc3545",
// };

// Example of custom component to handle form submit
// const SubmitPayment = ({ customStyle }) => {
// 	const [paying, setPaying] = useState(false);
// 	const cardHolderName = useRef(null);
// 	const hostedField = usePayPalHostedFields();

// 	return (
// 		<>
//             <label title="This represents the full name as shown in the card">
// 				Card Holder Name
// 				<input
// 					id="card-holder"
// 					ref={cardHolderName}
// 					className="card-field"
// 					style={{ ...customStyle, outline: "none" }}
// 					type="text"
// 					placeholder="Full name"
// 				/>
// 				</label>
// 			<button
// 				className={`btn${paying ? "" : " btn-primary"}`}
// 				style={{ float: "right" }}
// 				onClick={handleClick}
// 			>
// 				{paying ? <div className="spinner tiny" /> : "Pay"}
// 			</button>
// 		</>
// 	);
// };

export default function CheckOutModel({ subPlanId, setSubPlanId, isShowCheckOut, setIsShowCheckOut, subscriptionId }) {

    const [clientToken, setClientToken] = useState(null);
    const [paying, setPaying] = useState(false);
    // 	const cardHolderName = useRef(null);
    // const hostedField = usePayPalHostedFields();

    // const SubmitPayment = () => {
    //     const [paying, setPaying] = useState(false);
    //     const cardHolderName = useRef(null);
    //     const hostedField = usePayPalHostedFields();

    //     const handleClick = () => {
    //         if (!hostedField?.cardFields) {
    //             const childErrorMessage = 'Unable to find any child components in the <PayPalHostedFieldsProvider />';

    //             // action(ERROR)(childErrorMessage);
    //             throw new Error(childErrorMessage);
    //         }
    //         const isFormInvalid =
    //             Object.values(hostedField.cardFields.getState().fields).some(
    //                 (field) => !field.isValid
    //             ) || !cardHolderName?.current?.value;
    //         console.log("hostedField", hostedField.cardFields.getState())
    //         validateForm()
    //         // if (isFormInvalid) {
    //         //     return alert(
    //         //         "The payment form is invalid"
    //         //     );
    //         // }
    //         // setPaying(true);
    //         // hostedField.cardFields
    //         //     .submit({
    //         //         cardholderName: cardHolderName?.current?.value,
    //         //     })
    //         //     .then((data) => {
    //         //         // Your logic to capture the transaction
    //         //         fetch("url_to_capture_transaction", {
    //         //             method: "post",
    //         //         })
    //         //             .then((response) => response.json())
    //         //             .then((data) => {
    //         //                 // Here use the captured info
    //         //             })
    //         //             .catch((err) => {
    //         //                 // Here handle error
    //         //             })
    //         //             .finally(() => {
    //         //                 setPaying(false);
    //         //             });
    //         //     })
    //         //     .catch((err) => {
    //         //         console.log("err", err)
    //         //         // Here handle error
    //         //         setPaying(false);
    //         //     });
    //     };

    //     return (
    //         <>
    //             <Button onClick={(e) => {
    //                 handleClick()
    //             }} type="button">Purchase</Button>
    //         </>
    //     );
    // };


    useEffect(() => {



        // (async () => {
        //     const response = await (
        //         await fetch(
        //             "https://braintree-sdk-demo.herokuapp.com/api/paypal/hosted-fields/auth"
        //         )
        //     ).json();
        //     setClientToken(response?.client_token || response?.clientToken);
        // })();

        // console.log("hostedField", hostedField)
    }, []);

    const pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    const [submitLoading, setSubmitLoading] = useState(false);
    const UserData = useSelector((state) => state.data);
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [selected, setSelected] = useState("");
    // const [checkoutField, setCheckoutField] = useState(null);
    const [loading, setLoading] = useState(false);

    const [cities, setCities] = useState([]);
    const [subscriptionDetails, setSubscriptionDetails] = useState(null);
    const [userDetails, setUserDetails] = useState(null);

    const [formInputs, setFormInputs] = useState({
        email: "",
        address1: "",
        city: "",
        state: "",
        country: "",
        postal_code: "",
        auto_renew: 1
    });
    const [formInputsEr, setFormInputsEr] = useState({
        // card_number: "",
        // expiry_date: "",
        // card_holder_name: "",
        // cvv: "",
        email: "",
        address1: "",
        city: "",
        state: "",
        country: "",
        postal_code: ""
    });

    const [redirectError, setRedirectError] = useState("");

    const validateMsg = {
        // card_number: "Card number is required.",
        // expiry_date: "Expiry date is required.",
        // card_holder_name: "Card holder name is required.",
        // cvv: "CVV is required.",
        email: "Email is required.",
        address1: "Address is required.",
        city: "City is required.",
        state: "State is required.",
        country: "Country is required.",
        postal_code: "Postal code is required.",
        valid_expiry_date: "Please enter valid expiry date.",
        valid_card_number: "Please enter card number.",
        valid_cvv: "Please enter valid cvv.",
        valid_expiry_date: "Please enter valid expiry date.",
        validate_email: "Email is not valid",
    }

    const handleClose = () => {
        setIsShowCheckOut(false)
        setFormInputs({
            email: "",
            address1: "",
            city: "",
            state: "",
            country: "",
            postal_code: "",
            auto_renew: 1
        })
        setFormInputsEr({
            // card_number: "",
            // expiry_date: "",
            // card_holder_name: "",
            // cvv: "",
            email: "",
            address1: "",
            city: "",
            state: "",
            country: "",
            postal_code: ""
        })
        setUserDetails(null)
        setSubscriptionDetails(null)
        setSubPlanId(null)
        setCities([])
        // setCheckoutField(null)
    }

    const validateForm = () => {
        let isValidate = true;
        // let checkOutNameJson = {
        //     card_holder_name: 'cardholderName',
        //     cvv: 'cvv',
        //     expiry_date: 'expirationDate',
        //     card_number: 'number'
        // }
        // let checkOutArray = ['card_holder_name', 'cvv', 'expiry_date', 'card_number']
        let errorJson = {
            // card_number: "",
            // expiry_date: "",
            // card_holder_name: "",
            // cvv: "",
            email: "",
            address1: "",
            city: "",
            state: "",
            country: "",
            postal_code: ""
        }
        // let check_states = checkoutField.getState().fields;
        Object.keys(formInputsEr).forEach((error_key) => {
            // if (checkOutArray.includes(error_key)) {
            // if (check_states[checkOutNameJson[error_key]].isEmpty) {
            //     errorJson = {
            //         ...errorJson,
            //         [error_key]: validateMsg[error_key]
            //     }
            //     isValidate = false;
            // } else {
            //     if (!check_states[checkOutNameJson[error_key]].isValid) {
            //         errorJson = {
            //             ...errorJson,
            //             [error_key]: validateMsg['valid_' + error_key]
            //         }
            //         isValidate = false;
            //     }
            // }
            // } else {
            if (formInputs[error_key] === "") {
                errorJson = {
                    ...errorJson,
                    [error_key]: validateMsg[error_key]
                }
                isValidate = false;
            }
            if (error_key === "email") {
                if (formInputs[error_key] !== "") {
                    if (!pattern.test(formInputs.email)) {
                        errorJson = {
                            ...errorJson,
                            [error_key]: validateMsg.validate_email
                        }
                        isValidate = false;
                    }
                }
            }
            // }
        })
        setFormInputsEr(errorJson)
        return isValidate;
    }
    const handleSubmit = async (e) => {
        setRedirectError("")
        e.preventDefault();
        // if (validateForm()) {
        //     setSubmitLoading(true)
        //     checkoutField.tokenize(async function (err, payload) {
        //         if (!err) {

        //             let res = await APICall({
        //                 url: ApiTypes.buySubscription,
        //                 data: {
        //                     ...formInputs,
        //                     subscription_id: subscriptionId,
        //                     nonce: payload.nonce
        //                 }
        //             })
        //             if (res.status === 1) {
        //                 handleClose()
        //                 setSubmitLoading(false)
        //                 dispatch({ type: "SUBSCRIPTION_MODAL", payload: false });
        //                 dispatch({
        //                     type: 'LOGGED_USER', payload: {
        //                         ...UserData.logged_user,
        //                         subscription: { ...res.data.subscription },
        //                         subscription_feature: { ...res.data.subscription_feature }

        //                     }
        //                 })
        //                 commonHelper.setItem("user", JSON.stringify({
        //                     ...UserData.logged_user,
        //                     subscription: { ...res.data.subscription },
        //                     subscription_feature: { ...res.data.subscription_feature }
        //                 }));
        //                 navigate('/billing')

        //             } else {
        //                 if (res.data.status === 0) {
        //                     setRedirectError(res.data.message)
        //                     setLoading(false);
        //                     setSubmitLoading(false)
        //                 }
        //             }
        //         }
        //     });

        // }
        // checkoutField.tokenize(function (err, payload) {
        //     if(err){

        //     }else{
        //         // fields.
        //     }

        // });
    }

    // const clientDidCreate = (err, client) => {
    //     braintree.hostedFields.create(
    //         {
    //             client: client,
    //             styles: {
    //                 input: {
    //                     // change input styles to match
    //                     // bootstrap styles
    //                     'font-size': '1rem',
    //                     color: '#495057',
    //                     height: '20px'
    //                 }, ':focus': {
    //                     'color': 'blue'
    //                 },
    //                 '.valid': {
    //                     'color': 'green'
    //                 },
    //                 '.invalid': {
    //                     'color': 'red'
    //                 },
    //             },

    //             fields: {
    //                 cardholderName: {
    //                     selector: '#card-name',
    //                     placeholder: 'Name as it appears on your card'
    //                 },
    //                 number: {
    //                     selector: "#card-number",
    //                     placeholder: '4111 1111 1111 1111'
    //                 },
    //                 cvv: {
    //                     selector: "#cvv",
    //                     placeholder: '123'
    //                 },
    //                 expirationDate: {
    //                     selector: "#expiration-date",
    //                     placeholder: 'MM / YY'
    //                 },
    //             },
    //         },
    //         hostedFieldsDidCreate
    //     );
    // }

    // const hostedFieldsDidCreate = (err, hostedFields) => {
    //     if (!err) {
    //         setCheckoutField(hostedFields)
    //     }

    // }
    // const createCheckoutInputs = () => {
    //     braintree.client.create(
    //         {
    //             authorization: 'sandbox_x6tnswjt_4nyxsdg6m4wrqm2c',
    //         },
    //         clientDidCreate
    //     );
    // }
    const fetchSubscriptionDetails = async () => {
        let res = await APICall({
            url: ApiTypes.fetchSubscriptionDetails,
            data: {
                subscription_id: subscriptionId
            }
        })
        let response = await APICall({
            url: ApiTypes.getProfileDetails,
        })

        if (res.status === 1 && response.status === 1) {
            setSubscriptionDetails(res.data)
            setUserDetails(response.data)
            setFormInputs({
                ...formInputs,
                email: response.data.client_email ? response.data.client_email : "",
                address1: response.data.location ? response.data.location.address1 : "",
                city: response.data.location ? response.data.location.city : "",
                state: response.data.location ? response.data.location.state : "",
                postal_code: response.data.location ? response.data.location.postal_code : "",
            })
            setLoading(false)
            // createCheckoutInputs()
        }
    }

    useEffect(() => {
        setLoading(true)
        if (subscriptionId) {
            fetchSubscriptionDetails()
        }

    }, [isShowCheckOut])

    const fetchCities = async () => {
        let resCities = await APICall({
            url: ApiTypes.getCityList,
            data: {
                search: formInputs.city
            },
        })
        if (resCities.status === 1) {
            setCities(resCities.data)
        }
    }

    useEffect(() => {
        if (formInputs.city.length > 2) {
            fetchCities()
        }

    }, [formInputs.city]);

    const paypalOnError = (err) => {
        console.log("Error", err)
    }
   
    
    return (<Modal
        show={isShowCheckOut}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="updrade-plan-modal"
    >
        {!loading && subscriptionDetails && userDetails ? <>


            <Modal.Header closeButton className="align-items-start">
                <div>
                    <div className="membership-logo mb-3 d-none">
                        <Crown />
                    </div>
                    <div className="modal-title">Subscribe to {subscriptionDetails.subscription_name}</div>
                    <span className="modal-discription-area">
                        Flexible pricing that grows with you.
                    </span>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="membership">
                    <div className="membership-logo">
                        <Crown />
                    </div>
                    <div className="membership-text">{subscriptionDetails.short_details}</div>
                    {/* <div className="membership-discontent">
                        <span>Annual Billing | Save 20%</span>
                    </div> */}
                    <div className="membership-price">{subscriptionDetails.price}/{subscriptionDetails.interval_type}</div>
                    {/* <div className="membership-supporting-text">
                        Billed Annually ({(parseInt(subscriptionDetails.price) * 12).toFixed(2)})
                    </div> */}
                </div>
                <div className="change-membership-plan d-flex justify-content-between d-none">
                    <div className="change-membership-text">
                        <div className="membership-text-top">Pro Membership Plan</div>
                        <div className="membership-text-bottom">
                            Billed Annually ($119.88)
                        </div>
                    </div>
                    <div className="check-icon">
                        <i className="fa-solid fa-circle-check"></i>
                    </div>
                </div>
                <div className="payment-method mb-3">
                    {redirectError !== "" && <div className='error-div'>
                        <div>{redirectError}</div>
                        <div>
                            <i onClick={() => { setRedirectError("") }} className="fa fa-close cursor-pointer"></i>
                        </div>
                    </div>}
                    {/* <div className="payment-tile">Enter your card details</div> */}
                    {/* <div className="payment-detail">Enter your card details.</div> */}
                </div>
                <PayPalScriptProvider
                    options={{
                        "client-id":
                            // "AQ0khC4IQ5oA8MDRcLqk2uRSqNxX8MqYOaCbobMMYdVtJmV_y0U4bR5_PqrGbMA7MjxbGwRrSCkKluSL",//Old test client id
                            // "ASDfh7RWtk2HuJvDaRohrrp3dT1s038bYSz0n1Iz-IwSWiBPWBGibBGM_F59KMuOmTIPkY2oRCN0dbg5",//New test client id
                        process.env.REACT_APP_PAYPAL_CLIENT_ID,
                        // 'AarM223QNzPdTcuQeSOD-e-OmaxazCLb7dbSHfomTezG6O_LxUvclgndWlfdf04fiWnWI9gD4zFf6R14',
                        components: "buttons",
                        // "data-client-token": clientToken,
                        intent: "subscription",
                        vault: true,
                        commit:true,
                        "debug":true
                    }}
                >
                    <PayPalButtons
                        createSubscription={(data, actions) => {
                            let currentDate = new Date();
                            currentDate.setDate(currentDate.getDate() + parseInt(subscriptionDetails.remaining_trial_days))
                            let sub_start_date = currentDate.toISOString()
                            return actions.subscription.create({
                                plan_id: subPlanId,
                                start_time : sub_start_date
                            });
                        }}
                        onApprove={async (data, actions) => {

                            let res = await APICall({
                                url: ApiTypes.buySubscription,
                                data: {
                                    // ...formInputs,
                                    subscription_id: subscriptionId,
                                    ...data
                                }
                            })
                            if (res.status === 1) {
                                handleClose()
                                setSubmitLoading(false)
                                dispatch({ type: "SUBSCRIPTION_MODAL", payload: false });
                                dispatch({
                                    type: 'LOGGED_USER', payload: {
                                        ...UserData.logged_user,
                                        subscription: { ...res.data.subscription },
                                        subscription_feature: { ...res.data.subscription_feature }

                                    }
                                })
                                commonHelper.setItem("user", JSON.stringify({
                                    ...UserData.logged_user,
                                    subscription: { ...res.data.subscription },
                                    subscription_feature: { ...res.data.subscription_feature }
                                }));
                                navigate('/billing')

                            } else {
                                if (res.data.status === 0) {
                                    setRedirectError(res.data.message)
                                    setLoading(false);
                                    setSubmitLoading(false)
                                }
                            }
                        }


                        }

                        catchError={paypalOnError}
                        onError={paypalOnError}
                        onCancel={paypalOnError}
                    />
                </PayPalScriptProvider>


                





            </Modal.Body>
            <Modal.Footer className="d-flex align-items-center justify-content-between">
                <Button
                    type="button"
                    className="transparent-btn btn btn-primary"
                    onClick={handleClose}
                >
                    Close
                </Button>
                {/* {!submitLoading ?
                            <SubmitPayment />
                            : <LoadMoreLoader />} */}



            </Modal.Footer>
        </> : <SiteLoader />}
    </Modal>)
}
