import React, { useEffect, useState } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ForgotPassword from "../../assets/media/img/Featured-icon.svg";
import LoadMoreLoader from "../../Component/Loaders/LoadMoreLoader";
import SiteLoader from "../../Component/Loaders/SiteLoader";
import ApiTypes from "../../networking/APItypes";
import APICall from "../../networking/AxiousServices";
import PageExpires from "../Errors/PageExpires";

function ResetPassword() {
  document.title = process.env.REACT_APP_NAME + " | " + "Reset Password";
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [isExpired, setIsExpired] = useState(false);
  const [formInputs, setFormInputs] = useState({
    password: "",
    confirm_password: "",
  });

  const [formInputsErrors, setFormInputsErrors] = useState({
    password: "",
    confirm_password: "",
  });

  const validationError = {
    password: "Password field is required.",
    confirm_password: "Confirm Password field is required.",
    validate_password: "Password and confirm password must match.",
  };

  const validateForm = () => {
    let errorJson = {};
    let isValidate = true;
    Object.keys(formInputsErrors).forEach((form_er_key) => {
      if (formInputs[form_er_key] === "") {
        errorJson = {
          ...errorJson,
          [form_er_key]: validationError[form_er_key],
        };
        isValidate = false;
      }
    });
    if (isValidate === true) {
      if (formInputs.password !== formInputs.confirm_password) {
        errorJson = {
          ...errorJson,
          confirm_password: validationError.validate_password,
        };
        isValidate = false;
      }
    }
    setFormInputsErrors(errorJson);
    return isValidate;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setButtonLoading(true);
      let data = {
        email_token: params.email_token,
        password: formInputs.password,
      };
      let res = await APICall({ url: ApiTypes.resetPassword, data: data });
      if (res.status == 1) {
        setButtonLoading(false);
        navigate("/reset/successful");
      }
    }
  };
  const checkResetToken = async () => {
    let data = { email_token: params.email_token };
    const res = await APICall({
      url: ApiTypes.checkResetToken,
      data: data,
    });
    if (res.status === 1) {
      setLoading(false);
    } else {
      if (res.data.status === 0) {
        setLoading(false);
        setIsExpired(true);
      }
    }
  };

  useEffect(() => {
    checkResetToken();
  }, []);

  return !loading ? (
    !isExpired ? (
      <div className="position-relative d-flex align-items-center justify-content-center vh-100">
        <img src={ForgotPassword} className="auth-logo" alt="Login Logo" />
        <div className="signup-box d-flex flex-column justify-content-center">
          <h1 className="text-center">Set new password</h1>
          <p className="text-center">
            Your new password must be different to previously used passwords.
          </p>
          <Form onSubmit={(e) => handleSubmit(e)}>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Password <span className="required d-inline-block">*</span></Form.Label>
              <Form.Control
                value={formInputs.password}
                onChange={(e) => {
                  setFormInputs({
                    ...formInputs,
                    password: e.target.value ? e.target.value : "",
                  });
                }}
                type="password"
                placeholder="Password"
              />
              {formInputsErrors.password !== "" && (
                <Form.Text className="text-danger">
                  {/* Must be at least 8 characters. */}
                  {formInputsErrors.password}
                </Form.Text>
              )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Confirm Password <span className="required d-inline-block">*</span></Form.Label>
              <Form.Control
                value={formInputs.confirm_password}
                onChange={(e) => {
                  setFormInputs({
                    ...formInputs,
                    confirm_password: e.target.value ? e.target.value : "",
                  });
                }}
                type="password"
                placeholder="Confirm Password"
              />
              {formInputsErrors.confirm_password !== "" && (
                <Form.Text className="text-danger">
                  {/* Must be at least 8 characters. */}
                  {formInputsErrors.confirm_password}
                </Form.Text>
              )}
            </Form.Group>
            <div className="d-grid gap-3">
              {!buttonLoading ? (
                <Button variant="primary" type="submit">
                  Reset password
                </Button>
              ) : (
                <LoadMoreLoader />
              )}
            </div>
            <div
              onClick={() => {
                navigate("/login");
              }}
              className="cursor-pointer already-login-line"
            >
              <i className="fa-regular fa-arrow-left me-2"></i>Back to log in
            </div>
          </Form>
        </div>
      </div>
    ) : (
      <PageExpires />
    )
  ) : (
    <SiteLoader />
  );
}

export default ResetPassword;
