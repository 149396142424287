import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export default function FAQLoader() {
    return (
        <>
          {[...Array(4).fill(0)].map((_, i) => (
            <div key={i} className="h2">
              <SkeletonTheme height={48} borderRadius={0}>
             
                  <Skeleton className="skl-faq"/>
               
              </SkeletonTheme>
            </div>
          ))}
        </>
      );
}
