const accessKeyId = process.env.REACT_APP_ACCESS_KEY
const secretAccessKey = process.env.REACT_APP_S3_SECRET_ACCESS_KEY
const region = process.env.REACT_APP_S3_REGION
const Bucket = process.env.REACT_APP_S3_BUCKET
const signedUrlExpireSeconds = process.env.REACT_APP_S3_SIGNED_URL_EXPIRY

export const AwsConstants = {
    accessKeyId: accessKeyId,
    secretAccessKey: secretAccessKey,
    region: region,
    Bucket: Bucket,
    signedUrlExpireSeconds: signedUrlExpireSeconds,
};

