import React from "react";
import { useState } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import AuthLogo from "../../assets/media/logos/login-logo.svg";
import { commonHelper } from "../../common/commonHelper";
import ApiTypes from "../../networking/APItypes";
import APICall from "../../networking/AxiousServices";
import { useDispatch } from "react-redux";
import SocialLogin from "./SocialLogin";
import LoadMoreLoader from "../../Component/Loaders/LoadMoreLoader";

function AuthSignup() {
  document.title = process.env.REACT_APP_NAME + " | " + "Sign-up";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  );

  const [formInputs, setFormInputs] = useState({
    // display_name: "",
    first_name: "",
    last_name: "",
    username: "",
    password: "",
  });
  const [formInputsEr, setFormInputsEr] = useState({
    // display_name: "",
    first_name: "",
    last_name: "",
    username: "",
    password: "",
    other_er: "",
  });
  const [loading, setLoading] = useState(false);

  const validationMsgs = {
    // display_name: 'Name is required.',
    first_name: "First name is required.",
    last_name: "Last name is required.",
    username: "Email is required.",
    password: "Password is required.",
    validate_email: "Email is not valid",
    pass_length: "Must be at least 8 characters. ",
  };

  const validateForm = () => {
    let error_json = {
      display_name: "",
      username: "",
      password: "",
    };
    let isValidate = true;
    Object.keys(formInputsEr).forEach((er_key) => {
      if (formInputs[er_key] === "") {
        error_json = {
          ...error_json,
          [er_key]: validationMsgs[er_key],
        };
        isValidate = false;
      } else {
        if (er_key === "username") {
          if (!pattern.test(formInputs.username)) {
            error_json = {
              ...error_json,
              [er_key]: validationMsgs.validate_email,
            };
            isValidate = false;
          }
        }
        if (er_key === "password") {
          if (formInputs.password.length < 8) {
            error_json = {
              ...error_json,
              [er_key]: validationMsgs.pass_length,
            };
            isValidate = false;
          }
        }
      }
    });
    setFormInputsEr(error_json);
    return isValidate;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      let res = await APICall({
        url: ApiTypes.clientSignUp,
        data: formInputs,
      });
      if (res.status === 1) {
        commonHelper.setItem("user", JSON.stringify(res.data));
        commonHelper.setItem("auth_token", res.data.auth_token);
        commonHelper.setItem("user_id", res.data.user_id);
        dispatch({ type: "AUTH_TOKEN", payload: res.data.auth_token });
        dispatch({ type: "LOGGED_USER", payload: res.data });
        setLoading(false);
        navigate("/");
        localStorage.setItem('current_time'  , new Date());
      } else {
        if (res.data.status === 0) {
          setFormInputsEr({
            display_name: "",
            username: "",
            password: "",
            other_er: res.data.message,
          });
          setLoading(false);
        }
      }
    }
  };

  return (
    <div className="position-relative d-flex align-items-center justify-content-center signup-hight">
      <img
        src={AuthLogo}
        onClick={() => {
          navigate("/");
        }}
        className="auth-logo"
        alt="Login Logo"
      />
      <div className="signup-box d-flex flex-column justify-content-sm-center">
        <h1>Sign up</h1>
        {/* <p>Amet aliquet tellus ornare orci tincidunt.</p> */}
        {formInputsEr.other_er !== "" && (
          <div className="text-danger">{formInputsEr.other_er}</div>
        )}
        <Form
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>First Name <span className="required d-inline-block">*</span></Form.Label>
            <Form.Control
              onChange={(e) => {
                setFormInputs({
                  ...formInputs,
                  first_name: e.target.value ? e.target.value : "",
                });
              }}
              value={formInputs.first_name}
              type="text"
              placeholder="Enter your first name"
            />
            {formInputsEr.first_name !== "" && (
              <Form.Text className="text-danger">
                {/* Must be at least 8 characters. */}
                {formInputsEr.first_name}
              </Form.Text>
            )}
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Last Name <span className="required d-inline-block">*</span></Form.Label>
            <Form.Control
              onChange={(e) => {
                setFormInputs({
                  ...formInputs,
                  last_name: e.target.value ? e.target.value : "",
                });
              }}
              value={formInputs.last_name}
              type="text"
              placeholder="Enter your last name"
            />

            {formInputsEr.last_name !== "" && (
              <Form.Text className="text-danger">
                {/* Must be at least 8 characters. */}
                {formInputsEr.last_name}
              </Form.Text>
            )}
          </Form.Group>

          {/* <Form.Group className="mb-3" controlId="formBasicEmail"> */}
          {/* <Form.Label>Name*</Form.Label> */}
          {/* <Form.Control onChange={(e) => {
              if (e.target.value) {
                setFormInputs({
                  ...formInputs,
                  display_name: e.target.value
                })
              } else {
                setFormInputs({
                  ...formInputs,
                  display_name: ""
                })
              }

            }} value={formInputs.display_name} type="text" placeholder="Enter your name" /> */}
          {/* {formInputsEr.display_name !== "" && <Form.Text className="text-danger">
         
              {formInputsEr.display_name}
            </Form.Text>} */}
          {/* </Form.Group> */}
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Email <span className="required d-inline-block">*</span></Form.Label>
            <Form.Control
              onChange={(e) => {
                if (e.target.value) {
                  setFormInputs({
                    ...formInputs,
                    username: e.target.value,
                  });
                } else {
                  setFormInputs({
                    ...formInputs,
                    username: "",
                  });
                }
              }}
              value={formInputs.username}
              type="email"
              placeholder="Enter your email"
            />
            {formInputsEr.username !== "" && (
              <Form.Text className="text-danger">
                {formInputsEr.username}
              </Form.Text>
            )}
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Password <span className="required d-inline-block">*</span></Form.Label>
            <Form.Control
              onChange={(e) => {
                if (e.target.value) {
                  setFormInputs({
                    ...formInputs,
                    password: e.target.value,
                  });
                } else {
                  setFormInputs({
                    ...formInputs,
                    password: "",
                  });
                }
              }}
              value={formInputs.password}
              type="password"
              placeholder="Create a password"
            />
            {formInputsEr.password !== "" && (
              <Form.Text className="text-danger">
                {/* Must be at least 8 characters. */}
                {formInputsEr.password}
              </Form.Text>
            )}
          </Form.Group>
          <div className="d-grid gap-3">
            {!loading ? (
              <Button variant="primary" type="submit">
                Submit
              </Button>
            ) : (
              <LoadMoreLoader />
            )}
            <SocialLogin />
          </div>
          <div
            onClick={() => navigate("/login")}
            className="already-login-line"
          >
            Already have an account? <Link to="">Log in</Link>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default AuthSignup;
