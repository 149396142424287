import React from "react";
import socketIOClient from "socket.io-client";
import { useSelector } from "react-redux";
// import { SOCKET_URL } from "config";


export const socket = socketIOClient(process.env.REACT_APP_NODE_API, {
    auth: {
        token: localStorage.getItem('user_id')
    }
});
export const SocketContext = React.createContext();