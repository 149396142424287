import React, { useEffect, useRef, useState } from 'react'
import { Row, Col, Form, Button, Accordion, InputGroup, NavDropdown, Card } from "react-bootstrap";
import { ReactComponent as UploadIcon } from "../../assets/media/icons/upload-icon.svg";
import { ReactComponent as Remove } from "../../assets/media/icons/remove.svg";
import APICall from '../../networking/AxiousServices';
import ApiTypes from '../../networking/APItypes';
import SiteLoader from '../../Component/Loaders/SiteLoader';
import { getBase64 } from '../../common/imageHelper';
import LoadMoreLoader from '../../Component/Loaders/LoadMoreLoader';

export default function WorkExperience() {

    const imageRef = useRef(null)
    const [loading, setLoading] = useState(false);
    const [workExperience, setWorkExperience] = useState([]);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);

    const [redirectError, setRedirectError] = useState("");

    const [workFormInputs, setWorkFormInputs] = useState({
        company_name: "",
        title: "",
        start_date: "",
        end_date: "",
        video_link: "",
        work_img: "",
        description: ""
    });

    const [workFormInputsEr, setWorkFormInputsEr] = useState({
        company_name: "",
        title: "",
        work_img: "",
        time_er: ""
    });

    const workValidationError = {
        company_name: "Company name is required.",
        title: "Title is required",
        start_date: "Start date is required.",
        time_er: "End date should not be greater than start date."
    }

    const handleDeleteExperience = async (work_ex_id) => {
        setRedirectError("")
        if (!deleteLoading) {
            setDeleteLoading(true)
            let res = await APICall({
                url: ApiTypes.deleteWorkExperience,
                data: {
                    work_experience_id: work_ex_id
                }
            })
            if (res.status === 1) {
                setWorkFormInputs({
                    company_name: "",
                    title: "",
                    start_date: "",
                    end_date: "",
                    video_link: "",
                    work_img: ""
                })
                fetchWorkExperience()
            } else {
                if (res.data.status === 0) {
                    setDeleteLoading(false)
                    setRedirectError(res.data.message)
                }
            }
        }
    }

    const validateInfo = () => {
        let errorJson = {
            company_name: "",
            title: "",
        };
        let isValidate = true;

        Object.keys(errorJson).forEach((form_er_key) => {
            if (workFormInputs[form_er_key] === "") {
                errorJson = {
                    ...errorJson,
                    [form_er_key]: workValidationError[form_er_key]
                }
                isValidate = false;
            }
        })
        if (workFormInputs.start_date !== "" && workFormInputs.end_date !== "") {
            if (workFormInputs.end_date < workFormInputs.start_date) {
                errorJson = {
                    ...errorJson,
                    'time_er': workValidationError.time_er
                }
                isValidate = false;
            }

        }
        setWorkFormInputsEr(errorJson)
        return isValidate;
    }

    const handleSaveWorkExperience = async (e) => {
        setRedirectError("")
        e.preventDefault();
        if (validateInfo()) {
            setSubmitLoading(true)
            let res = await APICall({
                url: ApiTypes.saveWorkExperience,
                data: workFormInputs
            })
            if (res.status === 1) {
                setWorkFormInputs({
                    company_name: "",
                    title: "",
                    start_date: "",
                    end_date: "",
                    video_link: "",
                    work_img: ""
                })
                fetchWorkExperience()
            } else {
                if (res.data.status === 0) {
                    setSubmitLoading(false)
                    setRedirectError(res.data.message)
                }
            }
        }
    }


    const handleFile = (file) => {
        if (file.name.match(/\.(jpg|jpeg|png|svg|webp|JPEG|JPG|PNG|SVG|WEBP)$/)) {
            if (parseInt((file.size / 1000000).toFixed(2)) <= 5) {
                setWorkFormInputsEr({
                    ...workFormInputsEr,
                    work_img: ''
                })
                getBase64(file)
                    .then((result) => {
                        setWorkFormInputs({
                            ...workFormInputs,
                            work_img: result
                        })
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            } else {
                setWorkFormInputsEr({
                    ...workFormInputsEr,
                    work_img: 'Image should be less than 5 MB.'
                })
            }
        } else {
            setWorkFormInputsEr({
                ...workFormInputsEr,
                profile_img: 'Only jpg, jpeg, png, svg or webp files are supported.'
            })
        }
    }
    const handleInput = (e) => {

        handleFile(e.target.files[0]);

    }

    const handleDrop = (e) => {
        e.preventDefault()
        handleFile(e.dataTransfer.files[0]);
    }



    const fetchWorkExperience = async () => {
        setLoading(true)
        let response = await APICall({
            url: ApiTypes.getWorkExperience
        })

        if (response.status === 1) {
            setWorkExperience(response.data)
            setLoading(false)
            setSubmitLoading(false)
            setDeleteLoading(false)
        } else {
            if (response.data.status === 0) {
                setRedirectError(response.data.message)
            }
        }
    }

    useEffect(() => {
        fetchWorkExperience()
    }, []);

    return (<div className="add-accordion">

        <Accordion>
            <Accordion.Item eventKey="0" className="mb-4">
                <Accordion.Header>
                    Add Work Experience
                </Accordion.Header>
                <Accordion.Body>

                    {redirectError !== "" && <div className='error-div'>
                        <div>{redirectError}</div>
                        <div>
                            <i onClick={() => { setRedirectError("") }} className="fa fa-close cursor-pointer"></i>
                        </div>
                    </div>}



                    {!loading ? <Form className="experience-form">
                        <Row>
                            <Col col={6}>
                                <Form.Group
                                    className="mb-3"
                                    controlId="formGroupEmail"
                                >
                                    <Form.Label>Company Name <span className="required d-inline-block">*</span></Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={workFormInputs.company_name}
                                        onChange={(e) => {
                                            setWorkFormInputs({
                                                ...workFormInputs,
                                                company_name: e.target.value ? e.target.value : ""
                                            })
                                        }}
                                        placeholder="Company Name"
                                    />
                                    {workFormInputsEr.company_name !== "" && <Form.Text className="text-danger">
                                        {/* Must be at least 8 characters. */}
                                        {workFormInputsEr.company_name}
                                    </Form.Text>}
                                </Form.Group>
                            </Col>
                            <Col col={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Title <span className="required d-inline-block">*</span></Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={workFormInputs.title}
                                        onChange={(e) => {
                                            setWorkFormInputs({
                                                ...workFormInputs,
                                                title: e.target.value ? e.target.value : ""
                                            })
                                        }}
                                        placeholder="Title"
                                    />
                                    {workFormInputsEr.title !== "" && <Form.Text className="text-danger">
                                        {/* Must be at least 8 characters. */}
                                        {workFormInputsEr.title}
                                    </Form.Text>}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col col={6}>
                                <Form.Group
                                    className="mb-3"
                                    controlId="formGroupEmail"
                                >
                                    <Form.Label>From</Form.Label>
                                    <input
                                        type="date"
                                        onChange={(e) => {
                                            setWorkFormInputs({
                                                ...workFormInputs,
                                                start_date: e.target.value ? e.target.value : ""
                                            })
                                        }}
                                        value={workFormInputs.start_date}
                                        className="form-control"
                                    />
                                </Form.Group>
                            </Col>
                            <Col col={6}>
                                <Form.Label>To</Form.Label>
                                <Form.Group className="mb-3">
                                    <input
                                        type="date"
                                        onChange={(e) => {
                                            setWorkFormInputs({
                                                ...workFormInputs,
                                                end_date: e.target.value ? e.target.value : ""
                                            })
                                        }}
                                        value={workFormInputs.end_date}
                                        className="form-control"
                                    />
                                    {workFormInputsEr.time_er !== "" &&
                                        <Form.Text className="text-danger">
                                            {workFormInputsEr.time_er}
                                        </Form.Text>}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col col={12}>
                                <Form.Label htmlFor="basic-url">
                                    Video Link
                                </Form.Label>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon3">
                                        http://
                                    </InputGroup.Text>
                                    <Form.Control
                                        id="basic-url"
                                        onChange={(e) => {
                                            setWorkFormInputs({
                                                ...workFormInputs,
                                                video_link: e.target.value ? e.target.value : ""
                                            })
                                        }}
                                        value={workFormInputs.video_link}
                                        aria-describedby="basic-addon3"
                                    />
                                </InputGroup>
                            </Col>
                        </Row>
                        <Col col={12}>
                            <Form.Group controlId="formGroupEmail">
                                <Form.Label>
                                    Description of Role
                                </Form.Label>
                                <Form.Control
                                    onChange={(e) => [
                                        setWorkFormInputs({
                                            ...workFormInputs,
                                            description: e.target.value ? e.target.value : ""
                                        })
                                    ]}
                                    value={workFormInputs.description}
                                    as="textarea"
                                    rows={4} />
                            </Form.Group>
                        </Col>
                        {/* <div className="row">
                            <div className="col-12">
                                {workFormInputs.work_img === "" ? <div draggable={true} onDrop={(e) => handleDrop(e)} className="fileUploadWrap draggable text-center">
                                    <input
                                        type="file"
                                        accept="image/*"
                                        ref={imageRef}
                                        onChange={(e) => handleInput(e)}
                                        className="w-100 h-100"
                                    />
                                    <span className="upload-icon mx-auto rounded-circle d-flex align-items-center justify-content-center">
                                        <UploadIcon />
                                    </span>
                                    <div className="fileupload-content mt-2">
                                        <span className="cornflower-blue d-inline-block">
                                            Click to upload profile pic
                                        </span>
                                        or drag and drop
                                        <span className="d-block">
                                            SVG, PNG, JPG or GIF (max. 800x400px)
                                        </span>
                                    </div>
                                </div> : <div className="fileUploadWrap draggable text-center">
                                    <img
                                        src={workFormInputs.work_img}
                                        alt="ProfileImg"
                                        className="border-common w-100 h-100 obj-fit-cover"
                                    />
                                </div>}
                            </div>
                        </div> */}
                        <div className="row accordion-bottom-section mt-3">
                            <div className="col-3 enable d-flex align-items-center">
                                {/* <Remove className="me-2" />
                                Delete experience */}
                            </div>
                            <div className={`col-9  ${workFormInputs.work_img !== "" ? 'enable' : ""} justify-content-end d-flex text-end p-0 align-items-center`}>
                                {/* <span onClick={() => {
                                        setWorkFormInputs({
                                            ...workFormInputs,
                                            work_img :""
                                        })
                              
                                }} className="me-3">Delete Cover</span> */}
                                {!submitLoading ?
                                    <Button onClick={(e) => { handleSaveWorkExperience(e) }} variant="primary" type="button">
                                        Save Work Experience
                                    </Button>
                                    : <LoadMoreLoader />}
                            </div>
                        </div>
                    </Form> : <SiteLoader />}
                </Accordion.Body>
            </Accordion.Item>
            {/* <Accordion.Item eventKey="1">
                <Accordion.Header>Add Awards</Accordion.Header>
                <Accordion.Body>
                    <Form className="experience-form">
                        <Row>
                            <Col col={12}>
                                <Form.Group
                                    className="mb-3"
                                    controlId="formGroupEmail"
                                >
                                    <Form.Label>Name of Award</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Name of Award"
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col col={12}>
                                <Form.Group controlId="formGroupEmail">
                                    <Form.Label>
                                        Description of Award
                                    </Form.Label>
                                    <textarea
                                        className="form-control"
                                        rows="4"
                                        cols="50"
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <div className="row accordion-bottom-section mt-3">
                            <div className="col-7 enable d-flex align-items-center">
                                <Remove className="me-2" />
                                Delete this Award
                            </div>
                        </div>
                    </Form>
                </Accordion.Body>
            </Accordion.Item> */}
        </Accordion>
        <div className='mb-3'>
            {workExperience.map((work_item, index) => (
                <Card className='mb-3'>
                    <Card.Header className='form-label'>Work Experiences # {index + 1}</Card.Header>
                    <Card.Body>
                        <Form className="experience-form">
                            <Row>
                                <Col col={6}>
                                    <Form.Group
                                        className="mb-3"
                                        controlId="formGroupEmail"
                                    >
                                        <Form.Label>Company Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            disabled={true}
                                            value={work_item.company_name}
                                            onChange={(e) => {
                                                // setWorkFormInputs({
                                                //     ...workFormInputs,
                                                //     company_name: e.target.value ? e.target.value : ""
                                                // })
                                            }}
                                            placeholder="Company Name"
                                        />
                                    </Form.Group>
                                </Col>
                                <Col col={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Title</Form.Label>
                                        <Form.Control
                                            type="text"
                                            disabled={true}
                                            value={work_item.title}
                                            onChange={(e) => {
                                                // setWorkFormInputs({
                                                //     ...workFormInputs,
                                                //     title: e.target.value ? e.target.value : ""
                                                // })
                                            }}
                                            placeholder="Title"
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col col={6}>
                                    <Form.Group
                                        className="mb-3"
                                        controlId="formGroupEmail"
                                    >
                                        <Form.Label>From</Form.Label>
                                        <input
                                            type="date"
                                            disabled={true}
                                            value={work_item.start_date}
                                            onChange={(e) => {
                                                // console.log(e.target.value)
                                                // setWorkFormInputs({
                                                //     ...workFormInputs,
                                                //     start_date: e.target.value ? e.target.value : ""
                                                // })
                                            }}

                                            className="form-control"
                                        />
                                    </Form.Group>
                                </Col>
                                <Col col={6}>
                                    <Form.Label>To</Form.Label>
                                    <Form.Group className="mb-3">
                                        <input
                                            type="date"
                                            disabled={true}
                                            value={work_item.end_date}
                                            onChange={(e) => {
                                                // console.log(e.target.value)
                                                // setWorkFormInputs({
                                                //     ...workFormInputs,
                                                //     start_date: e.target.value ? e.target.value : ""
                                                // })
                                            }}
                                            className="form-control"
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col col={12}>
                                    <Form.Label htmlFor="basic-url">
                                        Video Link
                                    </Form.Label>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text id="basic-addon3">
                                            http://
                                        </InputGroup.Text>
                                        <Form.Control
                                            id="basic-url"
                                            onChange={(e) => {
                                                // console.log(e.target.value)
                                                // setWorkFormInputs({
                                                //     ...workFormInputs,
                                                //     video_link: e.target.value ? e.target.value : ""
                                                // })
                                            }}
                                            disabled={true}
                                            value={work_item.video_link}
                                            aria-describedby="basic-addon3"
                                        />
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Col col={12}>
                                <Form.Group controlId="formGroupEmail">
                                    <Form.Label>
                                        Description of Role
                                    </Form.Label>
                                    <Form.Control
                                        disabled={true}
                                        onChange={(e) => [
                                            // setWorkFormInputs({
                                            //     ...workFormInputs,
                                            //     description: e.target.value ? e.target.value : ""
                                            // })
                                        ]}
                                        value={work_item.description ? work_item.description : ""}

                                        as="textarea"
                                        rows={4} />
                                </Form.Group>
                            </Col>
                            {/* <div className="row">
                                <div className="col-12">
                                    {workFormInputs.work_img === "" ? <div draggable={true} onDrop={(e) => handleDrop(e)} className="fileUploadWrap draggable text-center">
                                        <input
                                            type="file"
                                            accept="image/*"
                                            ref={imageRef}
                                            onChange={(e) => handleInput(e)}
                                            className="w-100 h-100"
                                        />
                                        <span className="upload-icon mx-auto rounded-circle d-flex align-items-center justify-content-center">
                                            <UploadIcon />
                                        </span>
                                        <div className="fileupload-content mt-2">
                                            <span className="cornflower-blue d-inline-block">
                                                Click to upload profile pic
                                            </span>
                                            or drag and drop
                                            <span className="d-block">
                                                SVG, PNG, JPG or GIF (max. 800x400px)
                                            </span>
                                        </div>
                                    </div> : <div className="fileUploadWrap draggable text-center">
                                        <img
                                            src={workFormInputs.work_img}
                                            alt="ProfileImg"
                                            className="border-common w-100 h-100 obj-fit-cover"
                                        />
                                    </div>}
                                </div>
                            </div> */}
                            <div className=" row accordion-bottom-section mt-3 mb-4">
                                <div onClick={(e) => { handleDeleteExperience(work_item.work_experience_id) }} className={`${deleteLoading ? "fileupload-buttons" : ""} col-6 enable d-flex align-items-center`}>

                                    <Remove className="me-2" />
                                    Delete experience


                                </div>
                                {/* <div className={`col-9  ${workFormInputs.work_img !== "" ? 'enable' : ""} justify-content-end d-flex text-end p-0 align-items-center`}>
                                    <span onClick={() => {
                                            setWorkFormInputs({
                                                ...workFormInputs,
                                                work_img :""
                                            })
                                  
                                    }} className="me-3">Delete Cover</span>
                                </div> */}
                            </div>
                        </Form>

                    </Card.Body>
                </Card>
            ))}
        </div>

    </div>)
}
