import { commonHelper } from "../common/commonHelper";
import { Enums } from "../constants/Enums";
const initialState = {
  logged_user: commonHelper.getItem("user") ? JSON.parse(commonHelper.getItem("user")) : Enums.constant_logged_user,
  auth_token: commonHelper.getItem("auth_token") ? commonHelper.getItem("auth_token") : null,
  user_id: commonHelper.getItem("user_id") ? commonHelper.getItem("user_id") : null,
  subscription_modal: false
};

const userDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOGGED_USER":
      return {
        ...state,
        logged_user: action.payload,
      };
    case "AUTH_TOKEN":
      return {
        ...state,
        auth_token: action.payload,
      };
    case "USER_ID":
      return {
        ...state,
        user_id: action.payload,
      };
    case "SUBSCRIPTION_MODAL":
      return {
        ...state,
        subscription_modal: action.payload,
      };
    default:
      return state;
  }
};
export default userDataReducer;
