import React from 'react'
import { Modal } from "react-bootstrap";
import SubscriptionPage from './SubscriptionPage';
import { useDispatch, useSelector } from "react-redux";


export default function SubscriptionModal() {

    const dispatch = useDispatch();
    const UserData = useSelector((state) => state.data);
    const handleClose = () => {
        dispatch({ type: "SUBSCRIPTION_MODAL", payload: false });
    }
    return (<Modal
        size="lg"
        dialogClassName="w-100 subscription-custom-modal"
        show={UserData.subscription_modal}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="updrade-plan-modal"
    >
        <Modal.Header closeButton className="align-items-start">
            <div>
                <sapn className="h4">
                    <span>M</span>|Base Membership
                </sapn>
            </div>
        </Modal.Header>
        <Modal.Body>
            <SubscriptionPage IsSupportSection={false} />
        </Modal.Body>
    </Modal>
    )
}
