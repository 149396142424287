import React from "react";
import { Link } from "react-router-dom";
// import { ArtistDefault} from "../../assets/Images/Defaults/artist_default.svg";
import ArtistDefault from "../../assets/Images/Defaults/artist_default.svg";
import PreviewImg from "../Image/PreviewImg";

export default function ArtistCard({
  artist_name = null,
  artist_profile = null,
  artist_id = null,
}) {
  
  return (
    <div className="col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-5 text-center">
      <Link to={`/artist?aid=${artist_id}`} className="d-inline-block">
        <div className="artist-img mb-3">
          <PreviewImg
               src={artist_profile ? artist_profile : ArtistDefault}
               isDefault={artist_profile ? false : true}
               alt="artist-img"
               width={240}
               height={240}
               defaultImg={ArtistDefault}
               className="rounded-circle"
          />
          {/* <img
            src={artist_profile ? artist_profile : ArtistDefault}
            alt="artist-img"
            width={240}
            height={240}
            className="rounded-circle obj-fit-cover"
          /> */}
        </div>
        <div className="music-video-title mb-0">{artist_name}</div>
      </Link>
    </div>
  );
}
