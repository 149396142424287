import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import SiteLoader from '../../Component/Loaders/SiteLoader';
import ApiTypes from '../../networking/APItypes';
import APICall from '../../networking/AxiousServices';
import ReactHtmlParser from "react-html-parser";
import { Container } from 'react-bootstrap';

export default function PrivacyPolicy() {

  const [privacyPolicy, setPrivacyPolicy] = useState("");
  const [loading, setLoading] = useState(true);

  const fetchSetting = async () => {
    let res = await APICall({
      url: ApiTypes.fetchWebSetting
    })
    if (res.status === 1) {
      if (Object.keys(res.data).length) {
        setPrivacyPolicy(res.data.policy ? res.data.policy : "")
        setLoading(false)
      } else {
        setLoading(false)
      }
    } else {
      if (res.data.status === 0) {
        setLoading(false)
      } else {
        setLoading(false)

      }
    }
  }

  useEffect(() => {
    fetchSetting()
  }, []);

  return (!loading ?
    <div className='privacy-policy'>
      <Container>
        <h2>Privacy Policy</h2>
        {ReactHtmlParser(privacyPolicy)}
      </Container>
    </div>
    :
    <SiteLoader />
  )
}
