import React from "react";
import { Button, Form } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import NewPassword from "../../assets/media/img/Email.svg";
import { useNavigate } from "react-router-dom";

export default function AuthResetSuccess() {
  document.title = process.env.REACT_APP_NAME + " | " + "Email Sent";
  const navigate = useNavigate();

  const location = useLocation();
  let user_email = null;
  if (location.state) {
    user_email = location.state.email;
  }
  return (
    <div className="position-relative d-flex align-items-center justify-content-center vh-100">
      <img src={NewPassword} className="auth-logo " alt="Login Logo" />
      <div className="signup-box d-flex flex-column justify-content-center">
        <h1 className="text-center">Check your email</h1>
        <p className="text-center">
          {user_email && (
            <span>
              We sent a password reset link to{" "}
              <span className="h6"> {user_email} </span>
            </span>
          )}
        </p>
        <Form>
          {/* <div className="d-grid gap-3">
        <Button variant="primary" type="submit">
          Open email app
        </Button>
      </div> */}
          <div className="already-login-line">
            Didn’t receive the email?
            <Link className="ms-1" to="/forgot-Password">
              Click to resend
            </Link>
          </div>
          <div
            onClick={() => {
              navigate("/login");
            }}
            className="cursor-pointer already-login-line"
          >
            <span className="d-inline-block me-2">
              <i class="fa-solid fa-arrow-left"></i>
            </span>
            Back to log in
          </div>
        </Form>
      </div>
    </div>
  );
}
