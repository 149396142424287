import React from 'react'
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export default function ChatSidePanelLoader() {
    return (<>
        {[...Array(15).fill(0)].map((_, i) => (<div className='container px-3'>
        <div className='row'>
            <div key={i} className="px-2 col-lg-2 mb-1">
                <SkeletonTheme  height={40}>
                    <p>
                        <Skeleton circle={true} />
                    </p>
                </SkeletonTheme>
            </div>
            <div key={i} className="px-1 col-lg-10 mb-1">

                <SkeletonTheme height={40}>
                    <p>
                        <Skeleton />
                    </p>
                </SkeletonTheme>
            </div>
            </div>
        </div>))}
    </>)
}
