import React from 'react'
import { useState, useEffect, useContext } from 'react';
// import socketIOClient from "socket.io-client";
import ProfileImg from "../../assets/Images/Defaults/artist_default.svg";
import { commonHelper } from '../../common/commonHelper';
import PreviewImg from '../../Component/Image/PreviewImg';

export default function ChatSidePanel({ receiverId , authUserId, item, setShowResults, showResults, setCurrentOpenChat, CurrentOpenChat, onClick }) {



    // const ENDPOINT = process.env.REACT_APP_NODE_API;
    // var socket;
    // const [isSocketConnected, setSocketIsConnected] = useState(false);
    // function fnCurrentOpenChat(item) {
    //     setCurrentOpenChat(item);
    // }
    const renderIsActiveFile = ()=>{
        let checkId  =  ""
        if(authUserId === item.sen_user_id){
            checkId =  item.rec_user_id
        }else{
            checkId =  item.sen_user_id
        }
        if(checkId === receiverId ){
            return "active"
        }else{
            return ""
        }
    }

    return (
        <div
            className={`cursor-pointer messages-item-col ${
                renderIsActiveFile()
                }`}
            onClick={(e) => onClick(item)}
        >
            {/* <div className="unread-message"></div> */}
            <div className="d-flex justify-content-between">
                <div className="d-flex">
                    <div className="messages-img me-3">
                        <PreviewImg
                            src={authUserId !== item.sen_user_id ?
                                item.sen_profile_img ? process.env.REACT_APP_ASSETS_URL + item.sen_profile_img : ProfileImg
                                : item.rec_profile_img ? process.env.REACT_APP_ASSETS_URL + item.rec_profile_img : ProfileImg}
                            alt={"profile"}
                            width={"40"}
                            height={"40"}
                            className="rounded-circle"
                            isDefault={authUserId !== item.sen_user_id ?
                                item.sen_profile_img ? false : true
                                : item.rec_profile_img ? false : true}
                            defaultImg={ProfileImg}
                        />
                        {/* <img
                            src={authUserId === item.sen_profile_img ? item.sen_profile_img ? process.env.REACT_APP_ASSETS_URL + item.sen_profile_img : ProfileImg  : item.rec_profile_img ? process.env.REACT_APP_ASSETS_URL + item.rec_profile_img : ProfileImg   }
                            alt="profile"
                            isDefault={authUserId === item.sen_profile_img ? item.sen_profile_img ? false : true  : item.rec_profile_img ? false : true   }
                            defaultImg={ProfileImg}
                            onError={(e) => {
                                e.target.setAttribute('src', ProfileImg)
                            }}
                            width="40"
                            height="40"
                            className="rounded-circle"
                        /> */}
                        {/* <span
                            className={`mode ${authUserId === item.rec_user_id ? parseInt(item.sen_is_online) === 1 ? 'online' : "" : parseInt(item.rec_is_online) === 1 ? 'online' : ""}`}
                        ></span> */}
                    </div>
                    <div className="messages-details">
                        <div className="messager-name">{authUserId === item.rec_user_id ? item.sen_display_name : item.rec_display_name}</div>
                        <div className="messages">
                            {item.last_message}
                        </div>
                    </div>
                </div>
                <div className="hr-time">{item.last_message_on ? commonHelper.getChatDate(item.last_message_on, "MM-DD-YYYY") : ""}</div>
            </div>
        </div>
    )
}
