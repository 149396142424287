import Moment from "moment";
import CryptoJS from 'crypto-js';
import { Credentials } from "aws-sdk";
import S3 from "aws-sdk/clients/s3";
import { AwsConstants } from '../constants/AwsConstants';

const cryptoKey = "SqT_cL@SsRoOm_S@T!Sh_393987";


const s3 = new S3({
  accessKeyId: AwsConstants.accessKeyId,
  secretAccessKey: AwsConstants.secretAccessKey,
  // credentials: s3_access,
  region: AwsConstants.region,
  signatureVersion: "v4",
});

export const commonHelper = {
  setItem,
  getItem,
  removeItem,
  getHeaders,
  changeTimeFormate,
  formatDate,
  getDaysDifference,
  getDaysDifferenceFromCurrent,
  getChatDate,
  getAssetUrl,
  handleScroll,
  getMinuteDifference
};

async function getAssetUrl(path) {

  var options = {
    Bucket: AwsConstants.Bucket,
    Key: path, /* Filename in the bucket */
    Expires: 3000 /* Seconds */
  };
  const url = await s3.getSignedUrlPromise("getObject", options);
  return url;
}

function removeItem(key) {
  localStorage.removeItem(key);
}

function setItem(key, strString) {
  localStorage.setItem(key, CryptoJS.AES.encrypt(strString, cryptoKey));
}

function getItem(key) {

  let dataValues = localStorage.getItem(key) || "";
  let dataStr = "";
  if (dataValues !== "") {
    var bytes = CryptoJS.AES.decrypt(dataValues, cryptoKey);
    dataStr = bytes.toString(CryptoJS.enc.Utf8);
  }
  return dataStr;
}


function getHeaders() {
  if (getItem("auth_token")) {
    getItem("auth_token")
    return {
      "Content-type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + getItem("auth_token"),
    }
  } else {
    return {
      "Content-Type": "application/json",
      Accept: "application/json",
    }
  }
}

function changeTimeFormate(time, format = '') {
  let localDate = '';
  if (time !== null && time !== "" && time !== undefined) {
    // let stillUtc = Moment.utc(date).toDate();
    localDate = Moment.utc(time, 'HH:mm:ss').format(format != '' ? format : 'HH:mm:ss');
  }
  return localDate;
}

function formatDate(date, format = '', type = 'utc_to_local') {

  let localDate = '';
  if (date !== null && date !== "" && date !== undefined) {
    if (type === 'utc_to_local') {

      localDate = Moment(date).local().format(format != '' ? format : "YYYY-MM-DD");

    } else if (type === 'local_to_utc') {

      localDate = Moment(date).utc().format(format != '' ? format : "YYYY-MM-DD");

    } else {

      localDate = Moment(date).utc().format(format != '' ? format : "YYYY-MM-DD");

    }
  }
  return localDate;
}

function getDaysDifference(start_date, end_date) {
  var given = Moment(formatDate(start_date, 'YYYY,MM,DD'));
  var current = Moment(formatDate(end_date, 'YYYY,MM,DD'));
  let result = Moment.duration(current.diff(given)).asDays();
  return result;
}

function getMinuteDifference(start_date, end_date) {
  var given = Moment(formatDate(start_date, 'YYYY,MM,DD HH:mm:ss'));
  var current = Moment(formatDate(end_date, 'YYYY,MM,DD HH:mm:ss'));
  let result = Moment.duration(current.diff(given)).asMinutes();
  return result;
}


function getDaysDifferenceFromCurrent(end_date) {
  var given = Moment(formatDate(end_date, 'YYYY,MM,DD'));
  var current = Moment().startOf('day');
  let result = Moment.duration(given.diff(current)).asDays();
  return result;
}
function getChatDate(date, format = null) {
  if (formatDate(date, format ? format : 'YYYY-MM-DD') == formatDate(new Date(), format ? format : 'YYYY-MM-DD')) {
    return Moment.utc(date).fromNow(true);
  } else {
    return formatDate(date, format ? format : 'YYYY-MM-DD')
  }
}
function handleScroll(scrollRefDiv, option) {
  if (option == 'l') {
    let x = ((scrollRefDiv.current.clientWidth / 2)) - scrollRefDiv.current.scrollLeft;    
    scrollRefDiv.current.scrollTo(-x, 0);
  } else {
    let x = ((scrollRefDiv.current.clientWidth / 2)) + scrollRefDiv.current.scrollLeft;
    scrollRefDiv.current.scrollTo(x, 0);
  }
}