import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./assets/style/App.scss";
import "react-loading-skeleton/dist/skeleton.css";
import SiteLoader from "./Component/Loaders/SiteLoader";
import Artist from "./pages/Artist/Artist";
import TrendingArtists from "./pages/Artist/TrendingArtists";
import AuthForgotPass from "./pages/Authentication/AuthForgotPass";
import AuthLayout from "./pages/Authentication/AuthLayout";
import AuthLogin from "./pages/Authentication/AuthLogin";
import ResetPassword from "./pages/Authentication/ResetPassword";
import ResetComplete from "./pages/Authentication/ResetComplete";
import AuthSignup from "./pages/Authentication/AuthSignup";
import Home from "./pages/Home/Home";
import SubscriptionPage from "./pages/Subscription/SubscriptionPage";
import VideoDetails from "./pages/Video/VideoDetails";
import PageExpires from "./pages/Errors/PageExpires";
import Profile from "./pages/Profile/Profile";
import CreatedJobList from "./pages/MyJob/CreatedJobList";
import SavedJob from "./pages/MyJob/SavedJob";
import AppliedJob from "./pages/MyJob/AppliedJob";
import Booking from "./pages/MyJob/Booking";
import Billing from "./pages/Billing/Billing";
import Awards from "./pages/Award/Awards";
import AwardsNominees from "./pages/Award/AwardsNominees";
import AuthResetSuccess from "./pages/Authentication/AuthResetSuccess";
import SubscriptionModal from "./pages/Subscription/SubscriptionModal";
import MyBase from "./pages/MyBase";
import NotificationMessage from "./pages/Notification/NotificationMessage";
import ProfilePassword from "./pages/Profile/ProfilePassword";
import Messages from "./pages/Message/ChatMessages";
import { SocketContext, socket } from './Context/socket';
import ClientDetails from "./pages/Client/ClientDetails";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PrivacyPolicy from "./pages/ContentPages/PrivacyPolicy";
import TermAndCondition from "./pages/ContentPages/TermAndCondition";
import ScrollToTop from "./ScrollToTop";
import 'sweetalert2/src/sweetalert2.scss'
import { useEffect } from "react";
import { commonHelper } from "./common/commonHelper";
import Faq from "./pages/FAQ/Faq";
const Index = React.lazy(() => import("./pages/Index"));


// const UpgradePlan = React.lazy(() => import("./pages/UpgradePlan"));

function App() {
  const UserData = useSelector((state) => state.data);
  const SubscriptionFeature = UserData.logged_user.subscription_feature;
  const dispatch = useDispatch()
  const isLogIn = useSelector((state) => state.data.auth_token);
  const authToken = UserData.auth_token;

  // useEffect(() => {
  //   if (localStorage.getItem('current_time')) {

  //     if(commonHelper.getMinuteDifference(localStorage.getItem('current_time'), new Date()) > 60){

  //       localStorage.clear();

  //     }
  //   }
  // }, []);

  const appRoute = () => (
    <>
      <Route path="*" element={<Navigate to="/" replace />} />
      <Route index path="/" element={<Index Component={Home} />} />
      <Route index path="/artist" element={<Index Component={Artist} />} />
      <Route index path="/artists" element={<Index Component={TrendingArtists} />} />
      <Route index path="/video" element={<Index Component={VideoDetails} />} />
      <Route index path="/subscription" element={isLogIn ? <Index Component={SubscriptionPage} /> :<Navigate to="/login" replace /> } />
      <Route index path="/my-base" element={isLogIn ? SubscriptionFeature.my_base.base_access ? <Index Component={MyBase} /> : <Navigate to="/subscription" replace /> : <Navigate to="/login" replace />} />
      <Route index path="/my-jobs" element={isLogIn ? SubscriptionFeature.job.create_booking ? <Index Component={CreatedJobList} /> : <Navigate to="/subscription" replace /> : <Navigate to="/login?redirectUrl=my-jobs" replace />} />
      {/* <Route path="/artist" element={<Artist />} /> */}
      {/* <Route path="/artists" element={<TrendingArtists />} /> */}
      <Route path="/bookings" element={isLogIn ? SubscriptionFeature.job.create_booking ? <Index Component={Booking} /> : <Navigate to="/subscription" replace /> : <Navigate to="/login" replace />} />
      <Route path="/saved-job" element={isLogIn ? SubscriptionFeature.job.save ? <Index Component={SavedJob} /> : <Navigate to="/subscription" replace /> : <Navigate to="/login" replace />} />
      <Route path="/applied-job" element={isLogIn ? SubscriptionFeature.job.apply ? <Index Component={AppliedJob} /> : <Navigate to="/subscription" replace /> : <Navigate to="/login" replace />} />
      <Route path="/billing" element={isLogIn ? <Index Component={Billing} /> : <Navigate to="/login" replace />} />
      <Route index path="/profile" element={isLogIn ? SubscriptionFeature.profile.profile_access ? <Index Component={Profile} /> : <Navigate to="/subscription" replace /> : <Navigate to="/login" replace />} />
      <Route path="/awards" element={SubscriptionFeature.award.section ? <Index Component={Awards} /> : <Navigate to="/subscription" replace />} />
      {/* <Route path="/award/nominees" element={<Index Component={AwardsNominees} />} /> */}
      <Route index path="/change/password" element={isLogIn ? <Index Component={ProfilePassword} /> : <Navigate to="/login" replace />} />
      {/* <Route index path="/upgrade-plan" element={<UpgradePlan />} /> */}
      <Route index path="/messages" element={isLogIn ? SubscriptionFeature.chat.message ? <Index Component={Messages} /> : <Navigate to="/subscription" replace /> : <Navigate to="/login" replace />} />
      <Route index path="/notifications" element={isLogIn ? SubscriptionFeature.notification.notification_section ? <Index Component={NotificationMessage} /> : <Navigate to="/subscription" replace /> : <Navigate to="/login" replace />} />

      <Route index path="/client" element={<Index Component={ClientDetails} />} />
      <Route index path="/privacy-policy" element={<Index Component={PrivacyPolicy} />} />
      <Route index path="/terms-and-conditions" element={<Index Component={TermAndCondition} />} />
      <Route index path="/faq" element={<Index Component={Faq} />} />
      {/* <Route path="/client" element={<ClientDetails />} /> */}
      {/* <Route path="/subscription-page" element={<SubscriptionPage />} /> */}
      {/* <Route path="/createjob" element={<Index Component={Createjob} />} /> */}

    </>
  );

  const authRoute = () => (
    <>
      {appRoute()}
      <Route path="*" element={<Navigate to="/login" replace />} />
      <Route
        path="/login"
        element={<AuthLayout PageComponent={AuthLogin} />}
      ></Route>
      <Route
        path="/sign-up"
        element={<AuthLayout PageComponent={AuthSignup} />}
      ></Route>
      <Route
        path="/forgot-Password"
        element={<AuthLayout PageComponent={AuthForgotPass} />}
      ></Route>
      <Route
        path="/email/sent"
        element={<AuthLayout PageComponent={AuthResetSuccess} />}
      ></Route>
      <Route
        path="/reset/password/:email_token"
        element={<AuthLayout PageComponent={ResetPassword} />}
      ></Route>

      <Route
        path="/reset/successful"
        element={<AuthLayout PageComponent={ResetComplete} />}
      ></Route>
      <Route
        path="/page/expired"
        element={<AuthLayout PageComponent={PageExpires} />}
      ></Route>

    </>
  );

  return (
    <BrowserRouter>
      <React.Suspense fallback={<SiteLoader />}>
        <SubscriptionModal />
        <SocketContext.Provider value={socket}>
          <ScrollToTop />
          <Routes>
            {authToken ? appRoute() : authRoute()}
          </Routes>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </SocketContext.Provider>
      </React.Suspense>
    </BrowserRouter>
  );
}

export default App;
