import React, { useState, useContext } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { Dropdown, Form, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import VerifiedTick from "../../assets/media/icons/verified-tick.svg";
import MbaseLogo from "../../assets/media/logos/m-base-logo.svg";
import { commonHelper } from "../../common/commonHelper";
import ChatBoxLoader from "../../Component/Loaders/ChatBoxLoader";
import LoadMoreLoader from "../../Component/Loaders/LoadMoreLoader";
import { SocketContext } from '../../Context/socket';
import { getBase64 } from "../../common/imageHelper";
import APICall from "../../networking/AxiousServices";
import ApiTypes from "../../networking/APItypes";
import ProfileImg from "../../assets/Images/Defaults/artist_default.svg";
import PreviewImg from "../../Component/Image/PreviewImg";
import InfinityLoader from "../../Component/Loaders/InfinityLoader";
import InfiniteScroll from "react-infinite-scroll-component";
import { alerts } from "../../common/alertService";

export default function ChatBox({ hashMore, setHashMore, intervalHandler, setIntervalHandler, setPage, page, receiverImage, receiverName, socketId, receiverId, setReceiverId, chatRoomId, setChatRoomId, showResults, setShowResults, setCurrentOpenChat, CurrentOpenChat, receiverIsOnline = false }) {
    const [chatLoading, setChatLoading] = useState(true);


    let docArray = ['pdf', 'docx', 'doc', 'xlsm', 'xlm'];

    const socket = useContext(SocketContext);

    const imageRef = useRef(null)
    const [localError, setLocalError] = useState("");

    const UserData = useSelector((state) => state.data);
    const authUserId = UserData.logged_user.user_id;

    const [sendLoading, setSendLoading] = useState(false);

    const [message, setMessage] = useState("");
    const [sendImage, setSendImage] = useState("");

    const [imageFile, setImageFile] = useState(null);

    const [chatMessages, setChatMessages] = useState([]);
    const [perPage, setPerPage] = useState(15);

    const [messagesLoading, setMessagesLoading] = useState(true);
    const [loadMoreLoading, setLoadMoreLoading] = useState(false);

    const [intervalCount, setIntervalCount] = useState(0);


    const [isFile, setIsFile] = useState(false);
    // const [newPageFetching, setNewPageFetching] = useState(false);
    // let intervalHandler = null;
    // 

    const chatDivRef = useRef(null)

    function backToProfile() {
        setCurrentOpenChat(null);
    }

    function onEnterPress(e) {
        if (e.keyCode == 13 && !e.shiftKey) {
            e.preventDefault();
            handleSendMsg(e)
        }
    }


    // socket.on('addMessageResponse', async function (data) {
    //     let dataToAppend = {
    //         ...data[0],
    //         is_my_message: data[0].sender_user_id === authUserId ? 1 : 0
    //     }
    //     setChatMessages([...chatMessages, dataToAppend])
    //     setChatLoading(false)
    //     setSendLoading(false)
    //     chatDivRef.current.scrollTop = chatDivRef.current.scrollHeight;
    // })

    const fetchMessages = async () => {
        // socket.emit('getMessages', { user_id: authUserId, chat_room_id: chatRoomId });
        // socket.on('getMessagesResponse', async function (data) {
        //     if ('result' in data) {

        //         setChatMessages(data.result)
        //         setChatLoading(false)
        //         return await data;
        //     }
        // })
        // socket.emit('create', chatRoomId)

        let res = await APICall({
            url: ApiTypes.fetchMessages,
            data: {
                limit: perPage,
                page: page,
                chat_room_id: chatRoomId
            },
        })
        if (res.status === 1) {
            if (
                parseInt(chatMessages.length) + parseInt(res.data.list.length) >=
                parseInt(res.data.total)
            ) {
                setHashMore(false);
            }
            setMessagesLoading(false);
            // setHashMore(false);
            setLoadMoreLoading(false)
            // setNewPageFetching(false)
            return res.data.list;
        } else {
            if (res.data.status === 0) {
                setMessagesLoading(false);
                // setNewPageFetching(false)

                // setHashMore(false);
                setLoadMoreLoading(false)
                return [];
            } else {
                setMessagesLoading(false);
                // setNewPageFetching(false)

                // setHashMore(false);
                setLoadMoreLoading(false)
            }

        }
    }

    const fetchMainFunChatList = async () => {
        // setChatLoading(true)
        let data = await fetchMessages()
        if (!intervalHandler) {
            setChatMessages(data);
        } else {
            setChatMessages([...chatMessages, ...data]);
        }
    }

    const fetchSearchMainResult = async () => {
        setLoadMoreLoading(true)
        // setNewPageFetching(true)
        let data = await fetchMessages()
        setChatMessages([...chatMessages, ...data]);
        setIntervalCount(intervalCount + 1)
        // let intervalVar = setInterval(() => {
        //     console.log(234)
        //     fetchMainFunChatList();
        // }, 5000);
        // console.log("intervalVar" , intervalVar)
        // setIntervalHandler(intervalVar)
    }

    useEffect(() => {
        if (page !== 1) {
            if (chatRoomId) {
                // console.log('intervalHandler' ,intervalHandler)
                clearInterval(intervalHandler)
                fetchSearchMainResult();
            }
        }
    }, [page]);



    useEffect(() => {
        let intervalVar = null;
        if (chatRoomId) {
            fetchMainFunChatList();
            intervalVar = setInterval(() => {
                fetchMainFunChatList();
            }, 5000);
            setIntervalHandler(intervalVar)
        }
        // return () => clearInterval(intervalVar);
        return () => {
            clearInterval(intervalHandler)
            clearInterval(intervalVar)

        }
    }, [chatRoomId, intervalCount]);

    const handleImage = async (e) => {

        if (parseInt((e.target.files[0].size / 1000000).toFixed(2)) <= 30) {
            if (e.target.files[0].name.match(/\.(jpg|jpeg|png|svg|webp|JPEG|JPG|PNG|SVG|WEBP|pdf|docx|doc|xlsm|xlm)$/)) {
                setLocalError("")
                // setSendLoading(true)
                getBase64(e.target.files[0])
                    .then(async (result) => {
                        setSendImage(result)
                        setImageFile(e.target.files[0])
                        let fileData = e.target.files[0]
                        let fileExte = fileData.name.split('.').pop();
                        if (docArray.includes(fileExte)) {
                            //   let media_name = fileData.name.replace(`.${fileExte}`, "").replace(/\ /g, "_").replace(/\./g, '_');
                            setIsFile(true)
                        }


                        // let res = await APICall({
                        //     url: ApiTypes.getUploadLink,
                        //     data: {
                        //         chat_room_id: chatRoomId,
                        //         media_source: result,
                        //         media_name: media_name
                        //     }
                        // })
                        // if (res.status === 1) {
                        //     if (res.data.url) {
                        //         socket.emit('addMessage', {
                        //             chat_room_id: chatRoomId,
                        //             type: "file",
                        //             file_formate: fileData.name.split('.').pop(),
                        //             file_url: res.data.url,
                        //             sender_user_id: authUserId,
                        //             message: "",
                        //             receiver_user_id: receiverId,
                        //             socket_id: socketId
                        //         });
                        //         handleSendMsg(e)
                        //     }
                        // }
                        // setImageUrl(result)
                    })
                    .catch((err) => {
                        setSendLoading(false)
                        console.log(err);
                    });
            }
            else {
                alerts.notifyError("Only jpg, jpeg, png, svg, webp, pdf, docx, doc, xlsm, and xlm files are supported.")
                setLocalError("Only jpg, jpeg, png, svg, webp, pdf, docx, doc, xlsm, and xlm files are supported.")
            }
        }
        else {
            alerts.notifyError("Image should be less than 5 MB.")
            setLocalError("Image should be less than 5 MB.")
        }
    }
    const clearAllInputs = () => {
        setSendImage("")
        setImageFile(null)
        setMessage("")
        imageRef.current.value = null;
    }
    const handleSendMsg = async (e) => {
        e.preventDefault();
        let dataGot = await fetchMessages()
        setChatMessages(dataGot);


        // if (sendImage !== "") {
        let fileExte = "";
        let media_name = "";

        if (sendImage !== "") {
            fileExte = imageFile.name.split('.').pop();
            media_name = imageFile.name.replace(`.${fileExte}`, "").replace(/\ /g, "_").replace(/\./g, '_')
        }

        //     setSendLoading(true)
        //     let res = await APICall({
        //         url: ApiTypes.getUploadLink,
        //         data: {
        //             chat_room_id: chatRoomId,
        //             media_source: sendImage,
        //             media_name: media_name
        //         }
        //     })
        //     if (res.status === 1) {
        //         if (res.data.url) {
        //             let response_msg = await socket.emit('addMessage', {
        //                 chat_room_id: chatRoomId,
        //                 type: "file",
        //                 file_formate: imageFile.name.split('.').pop(),
        //                 file_url: res.data.url,
        //                 sender_user_id: authUserId,
        //                 message: "",
        //                 receiver_user_id: receiverId,
        //                 socket_id: socketId
        //             });
        //             if (response_msg) {
        //                 if (message.trim() !== "") {
        //                     sendOnlyMessage()
        //                 } else {
        //                     clearAllInputs()
        //                 }
        //             }
        //         }
        //     }
        // } else {
        //     if (message.trim() !== "") {
        //         sendOnlyMessage()
        //     } else {
        //         clearAllInputs()
        //     }
        // }
        let data = {}

        if (sendImage !== "") {
            data = {
                chat_room_id: chatRoomId,
                type: "file",
                file_formate: imageFile.name.split('.').pop(),
                sender_user_id: authUserId,
                message: message,
                receiver_user_id: receiverId,
                socket_id: socketId,
                media_source: sendImage,
                media_name: media_name,
            }
        } else {
            data = {
                chat_room_id: chatRoomId,
                type: "text",
                file_formate: null,
                sender_user_id: authUserId,
                message: message,
                receiver_user_id: receiverId,
                socket_id: socketId,
            }
        }
        if (sendImage !== "" || message.trim() !== "") {
            setSendLoading(true)
            let res = await APICall({
                url: ApiTypes.sendMessage,
                data: data
            })
            if (res.status === 1) {
                clearAllInputs()
                let dataAfter = await fetchMessages()
                setChatMessages(dataAfter);
                setSendLoading(false)
                setIsFile(false)
                if (typeof chatDivRef.current.scrollHeight !== 'undefined') {
                    chatDivRef.current.scrollTop = chatDivRef.current.scrollHeight;
                }
            } else {
                if (res.data.status == 0) {
                    setSendLoading(false)
                } else {
                    setSendLoading(false)
                }
            }
        }
    }
    const sendOnlyMessage = async () => {
        setSendLoading(true)
        let resmessage = await socket.emit('addMessage', {
            chat_room_id: chatRoomId,
            type: "text",
            file_formate: null,
            file_url: null,
            sender_user_id: authUserId,
            message: message,
            receiver_user_id: receiverId,
            socket_id: socketId,
            created_at: commonHelper.formatDate(new Date(), 'YYYY-MM-DD HH:mm:ss', 'local_to_utc')
        });
        // chatMessages.length 
        if (resmessage) {
            setSendLoading(false)
            // setMessage("")
            // fetchMessages()
            clearAllInputs()
        } else {
            return false;
        }
    }
    function getFileName(fileUrl) {
        let fileNameArray = fileUrl.split("/")
        let fileName = fileNameArray[fileNameArray.length - 1]
        return fileName;
    }
    function getFileExt(fileUrl) {
        let fileExte = fileUrl.split('.').pop().toLowerCase();
        return fileExte;
    }
    return (!messagesLoading ? <div
        className={`view-profile active w-100 ${showResults ? "view-profile-hide" : "view-profile-col"
            } ${window.innerWidth > 767 ? "FullView" : "MobileView"}`}
    >
        {/* {window.innerWidth > 767 ? null : (
            <div className="mobile-view-header d-flex align-items-center justify-content-between">
                <div className="mobile-profile-logo">
                    <Link to="/">
                        <img
                            src={MbaseLogo}
                            className="header-logo"
                            alt="Header Logo"
                            width={160}
                            height={38}
                        />
                    </Link>
                </div>
                <div className="closeButton d-flex justify-content-end">
                    <button
                        className="btn btn-close"
                        onClick={backToProfile}
                    ></button>
                </div>
            </div>
        )} */}
        <div className="view-profile-header d-flex justify-content-between">
            <div className="view-profile-header-left d-flex">
                <div
                    className={
                        showResults
                            ? "left-profile-button"
                            : "left-profile-button-hide"
                    }
                >
                    <button
                        type="submit"
                        className="btn btn-primary back-massage-button"
                        onClick={() => {
                            setShowResults(false);
                        }}
                    >
                        <i className="fa-solid fa-arrow-left"></i>
                    </button>
                </div>
                <div className="ms-2 view-profile-header-img me-3 d-flex align-items-center">
                    {/* <img
                        src={receiverImage ? process.env.REACT_APP_ASSETS_URL + receiverImage : ProfileImg}
                        alt="profile"
                        width="56"
                        height="56"
                        className="rounded-circle"
                    /> */}
                    <PreviewImg
                        src={receiverImage}
                        alt={"profile"}
                        width={"56"}
                        height={"56"}
                        className="rounded-circle"
                        isDefault={receiverImage ?
                            false : true
                        }
                        defaultImg={ProfileImg}
                    />
                    {/* <div className="artist-verifie-tick">
                        <img
                            src={VerifiedTick}
                            alt="VerifiedTick"
                            width="17"
                            height="17"
                        />
                    </div> */}
                    <div className="ms-2 view-profile-name">
                        {receiverName}
                        {/*   <div className="view-profile-header-text">

                            <div className="view-profile-job-title">
                                {CurrentOpenChat.job_title}
                            </div>
                        </div> */}
                        {/* {receiverIsOnline ? (
                            <div className="tik online">Online</div>
                        ) : (
                            <div className="tik">Offline</div>
                        )} */}
                    </div>
                </div>
            </div>
            <div
                className={
                    showResults
                        ? "view-profile-header-right-section-hide"
                        : "view-profile-header-right-section"
                }
            >
                <div className="view-profile-header-right mb-auto d-flex">
                    <button
                        type="submit"
                        className="btn btn-primary view-profile"
                        onClick={() => {
                            setShowResults(true);
                        }}
                    >
                        View profile
                    </button>
                    {/* <Dropdown>
                        <Dropdown.Toggle
                            variant="profile vertical-dropdown"
                            id="dropdown-basic"
                        >
                            <i className="fa-regular fa-ellipsis-vertical"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Button
                                variant="primary"
                                className="w-100"
                                type="button"
                            >
                                Chat Delete
                            </Button>
                        </Dropdown.Menu>
                    </Dropdown> */}
                </div>
            </div>
        </div>
        <div className="">
            <div ref={chatDivRef} style={{
                overflow: 'auto',
                display: 'flex',
            }} id="chats-main-id" className="chats d-flex flex-column">
                {/* <div className="day-line mt-3 mb-4">
                <span>today</span>
            </div> */}

                {chatMessages.length ?

                    <InfiniteScroll
                        scrollableTarget="chats-main-id"
                        dataLength={parseInt(chatMessages.length)} //This is important field to render the next data
                        next={() => {
                            if (!loadMoreLoading) setPage(page + 1);
                        }}
                        style={{ display: 'flex', flexDirection: 'column-reverse' }}
                        inverse={true}
                        hasMore={hashMore}
                        loader={<InfinityLoader />}
                    >
                        {chatMessages.map((item, index) =>
                             parseInt(item.is_my_message) === 1 ? (

                                <div
                                    className="chat-you-col d-flex ms-auto mb-3"
                                    key={index}
                                >
                                    <div className="ms-auto d-flex flex-column">
                                        <div className="d-flex justify-content-between">
                                            <span className="you-message me-3">You</span>
                                            <span className="now-message">
                                                {commonHelper.changeTimeFormate(commonHelper.formatDate(item.created_at), 'MM-DD-YYYY HH:mm')}
                                            </span>
                                        </div>
                                        <div className="you-send-message d-inline-block ms-auto mt-2">
                                            {item.type === 'text' ?
                                                item.message :
                                                // <img width="40"
                                                //     height="40" src={process.env.REACT_APP_ASSETS_URL + item.file_url} ></img>


                                                getFileExt(item.file_url) == "jpg" || getFileExt(item.file_url) == "jpeg" || getFileExt(item.file_url) == "png" || getFileExt(item.file_url) == "gif" || getFileExt(item.file_url) == "webp" ?
                                                    <PreviewImg
                                                        src={item.file_url}
                                                        isDefault={false}
                                                        width={"120"}
                                                        height={"120"}
                                                    /> :
                                                    <div className="fileupload-process " onClick={async () => {

                                                        let fileS3Url = await commonHelper.getAssetUrl(item.file_url)

                                                        if (fileS3Url) {
                                                            window.open(fileS3Url, '_blank');
                                                        }

                                                    }}>
                                                        <div className="fileupload-text d-flex">
                                                            <div className="fileupload-left-icon me-3 d-flex align-items-center justify-content-center">
                                                                <i className="fa-light fa-file"></i>
                                                            </div>
                                                            <div className="fileupload-top-col w-100 d-flex align-items-center">
                                                                <div className="d-flex justify-content-between w-100">
                                                                    <div className="fileupload-top">
                                                                        <div className="cursor-pointer  file-name">
                                                                            {getFileName(item.file_url)}
                                                                        </div>
                                                                        {/* <div className="file-size">200 KB</div> */}
                                                                    </div>

                                                                </div>
                                                                {/* <div className="mt-2 d-flex">
                                                        <ProgressBar now={60} />
                                                        <div className="Progressbar-parentage text-end">
                                                          60%
                                                        </div>
                                                      </div> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div
                                    className="chat-online me-auto mb-3 d-flex"
                                    key={index}
                                >
                                    <div className="me-3">
                                        {/* <img
                                    src={item.sender_user_id === authUserId
                                        ? item.receiver_profile ? process.env.REACT_APP_ASSETS_URL + item.receiver_profile : ProfileImg
                                        : item.sender_profile ? process.env.REACT_APP_ASSETS_URL + item.sender_profile : ProfileImg
                                    }
                                    alt="profile"
                                    width="40"
                                    height="40"
                                    className="rounded-circle"
                                /> */}

                                        <PreviewImg
                                            src={item.sender_user_id === authUserId
                                                ? item.receiver_profile ? process.env.REACT_APP_ASSETS_URL + item.receiver_profile : ProfileImg
                                                : item.sender_profile ? process.env.REACT_APP_ASSETS_URL + item.sender_profile : ProfileImg
                                            }
                                            alt={"profile"}
                                            width={"56"}
                                            height={"56"}
                                            className="rounded-circle"
                                            isDefault={item.sender_user_id === authUserId
                                                ? item.receiver_profile ? false : true
                                                : item.sender_profile ? false : true
                                            }
                                            defaultImg={ProfileImg}
                                        />
                                    </div>
                                    <div>
                                        <div className="d-flex justify-content-between">
                                            <span className="you-message text-capitalize me-3">
                                                {item.sender_user_id === authUserId ? item.receiver_name : item.sender_name}
                                            </span>
                                            <span className="now-message text-capitalize">
                                                {commonHelper.changeTimeFormate(commonHelper.formatDate(item.created_at), 'MM-DD-YYYY HH:mm')}
                                            </span>
                                        </div>
                                        <div className="online-send-message mt-2 d-inline-block">
                                            {item.type === 'text' ?
                                                item.message :


                                                getFileExt(item.file_url) == "jpg" || getFileExt(item.file_url) == "jpeg" || getFileExt(item.file_url) == "png" || getFileExt(item.file_url) == "gif" || getFileExt(item.file_url) == "webp" ?
                                                    <PreviewImg
                                                        src={item.file_url}
                                                        isDefault={false}
                                                        width={"120"}
                                                        height={"120"}
                                                    /> :
                                                    <div className="fileupload-process" onClick={async () => {

                                                        let fileS3Url = await commonHelper.getAssetUrl(item.file_url)

                                                        if (fileS3Url) {
                                                            window.open(fileS3Url, '_blank');
                                                        }

                                                    }}>
                                                        <div className="fileupload-text d-flex">
                                                            <div className="fileupload-left-icon me-3 d-flex align-items-center justify-content-center">
                                                                <i className="fa-light fa-file"></i>
                                                            </div>
                                                            <div className="fileupload-top-col w-100 d-flex align-items-center">
                                                                <div className="d-flex justify-content-between w-100">
                                                                    <div className="fileupload-top">
                                                                        <div className="cursor-pointer  file-name">
                                                                            {getFileName(item.file_url)}
                                                                        </div>
                                                                        {/* <div className="file-size">200 KB</div> */}
                                                                    </div>

                                                                </div>
                                                                {/* <div className="mt-2 d-flex">
                                                    <ProgressBar now={60} />
                                                    <div className="Progressbar-parentage text-end">
                                                      60%
                                                    </div>
                                                  </div> */}
                                                            </div>
                                                        </div>
                                                    </div>

                                                // <img src={process.env.REACT_APP_ASSETS_URL +item.file_url} ></img>
                                            }
                                        </div>
                                    </div>
                                </div>
                            )
                        )}

                    </InfiniteScroll>
                    : <div className="w-100 text-center text-muted mt-5">Start conversation...</div>
                }
            </div>
        </div>

        <div className="send-message">
            {sendImage !== "" && <div className="previewimage">
                <span className="previewimage-col">

                    {!isFile ?
                        <img className="obj-fit-cover" width={100} height={100} id="blah" src={sendImage} alt="your image" />
                        :
                        <div style={{ border: "none" }} className="fileupload-process mt-3">
                            <div className="fileupload-text d-flex">
                                <div className="fileupload-left-icon me-3 d-flex align-items-center justify-content-center">
                                    <i className="fa-light fa-file"></i>
                                </div>
                                <div className="fileupload-top-col w-100 d-flex align-items-center">
                                    <div className="d-flex justify-content-between w-100">
                                        <div className="fileupload-top">
                                            <div
                                                onClick={async () => {
                                                    try {
                                                        let link = sendImage;
                                                        // if (formInputs.resume !== "") {
                                                        //     link = formInputs.resume;
                                                        // } else {
                                                        //     link = await commonHelper.getAssetUrl(process.env.REACT_APP_ASSETS_URL + preProfileDetails.resume);
                                                        // }
                                                        if (imageFile.name.split('.').pop() === 'pdf') {
                                                            let pdfWindow = window.open("");
                                                            pdfWindow.document.write(
                                                                `<iframe width='100%' height='100%' src=${link}></iframe>`
                                                            );
                                                        } else {
                                                            let aTag = document.createElement("a");
                                                            aTag.setAttribute("href", link);
                                                            aTag.setAttribute("target", "_blank");
                                                            aTag.click();
                                                        }

                                                    } catch (error) {
                                                        console.log(error);
                                                    }
                                                    // let link;
                                                    // if (formInputs.resume !== "") {
                                                    //   link = formInputs.resume;
                                                    // } else {
                                                    //   link =
                                                    //     process.env.REACT_APP_ASSETS_URL +
                                                    //     preProfileDetails.resume;
                                                    // }
                                                    // if (
                                                    //   resumeName.split(".").pop() === "pdf"
                                                    // ) {
                                                    //   let pdfWindow = window.open("");
                                                    //   pdfWindow.document.write(
                                                    //     `<iframe width='100%' height='100%' src=${link}></iframe>`
                                                    //   );
                                                    // } else {
                                                    //   let aTag =
                                                    //     document.createElement("a");
                                                    //   aTag.setAttribute("href", link);
                                                    //   aTag.setAttribute("target", "_blank");
                                                    //   aTag.click();
                                                    // }
                                                }}
                                                className="cursor-pointer  file-name"
                                            >
                                                {imageFile.name.replace(`.${imageFile.name.split('.').pop()}`, "").replace(/\ /g, "_").replace(/\./g, '_')}
                                            </div>
                                            {/* <div className="file-size">200 KB</div> */}
                                        </div>
                                        <div className="fileupload-icons-right">
                                            <i className="fa-solid fa-circle-check"></i>
                                        </div>
                                    </div>
                                    {/* <div className="mt-2 d-flex">
                              <ProgressBar now={60} />
                              <div className="Progressbar-parentage text-end">
                                60%
                              </div>
                            </div> */}
                                </div>
                            </div>
                        </div>






                    }
                    <span onClick={() => {
                        setSendImage("")
                        setIsFile(false)
                        imageRef.current.value = null;
                    }} className="cursor-pointer xmark-icon"><i class="fa-duotone fa-xmark"></i></span>
                </span>
            </div>}
            <Form onSubmit={(e) => { handleSendMsg(e) }} >
                <Form.Control
                    as="textarea"
                    onChange={(e) => {
                        setMessage(e.target.value ? e.target.value : "")
                    }}
                    value={message}
                    placeholder="Send a message"
                    rows="4"
                    onKeyDown={(e) => {
                        onEnterPress(e)
                    }}
                />

                <div className="position-absolute b-20 end-0 mx-3">
                    <div className="d-flex align-items-center">
                        {!sendLoading && <div><i onClick={() => {
                            imageRef.current.click();

                        }} className="cursor-pointer fa-solid fa-paperclip"></i></div>}
                        {!sendLoading ? <Button className="mx-3 position-relative end-0" variant="primary" type="submit">
                            Send
                        </Button> :
                            <LoadMoreLoader />
                        }
                        <input ref={imageRef} accept="image/*,.pdf,.docx,.doc,.xlsm,xlm" onChange={(e) => {
                            handleImage(e)
                        }} type="file" className="d-none" />
                    </div>

                </div>




            </Form>

        </div>
    </div> : <div className="send-message w-100"><ChatBoxLoader /></div>)
}
