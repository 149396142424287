import React from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import MbaseLogo from "../../assets/media/logos/m-base-logo.svg";
import Button from "react-bootstrap/Button";
import Navbar from "react-bootstrap/Navbar";
import { Nav, Spinner } from "react-bootstrap";
import Offcanvas from "react-bootstrap/Offcanvas";
import StarWhite from "../../assets/media/icons/star-white.svg";
import ArtistsImg from "../../assets/media/img/artists-img.png";
import Slider from "react-slick";
import Image1 from "../../assets/media/img/Image-1.png";
import Image2 from "../../assets/media/img/Image-2.png";
import Image3 from "../../assets/media/img/Image-3.png";
import Image4 from "../../assets/media/img/Image-4.png";
import Image5 from "../../assets/media/img/Image-5.png";
import Image6 from "../../assets/media/img/Image-6.png";
import Image7 from "../../assets/media/img/Image-7.png";
import Image8 from "../../assets/media/img/Image-8.png";
import Image9 from "../../assets/media/img/Image-9.png";
import Image10 from "../../assets/media/img/Image-10.png";
import Image11 from "../../assets/media/img/Image-11.png";
import Image12 from "../../assets/media/img/Image-12.png";
//import TopBannerImg from "../../assets/media/img/banner-img.png";
import BannerTop from "../../Component/Banner/BannerTop";

import { Enums } from "../../constants/Enums";
import { useState } from "react";
import { useEffect } from "react";
import APICall from "../../networking/AxiousServices";
import ApiTypes from "../../networking/APItypes";
import ArtistCard from "../../Component/Artist/ArtistCard";
import ArtistsLoader from "../../Component/Loaders/ArtistsLoader";
import LoadMoreLoader from "../../Component/Loaders/LoadMoreLoader";
import { useDispatch, useSelector } from "react-redux";
import { useRef } from "react";
import ScrollButtons from "../../Component/ScrollButtons/ScrollButtons";

function TrendingArtists() {
  const SubscriptionFeature = useSelector((state) => state.data.logged_user.subscription_feature);
  const dispatch = useDispatch()

  document.title = process.env.REACT_APP_NAME + " | Artists";
  var artistcommunity = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    cssEase: "linear",
  };

  const scrollRefDiv = useRef(null)


  const [activeTag, setActiveTag] = useState(null);
  const [activeTagType, setActiveTagType] = useState(null);


  const [artistTags, setArtistTags] = useState([]);

  const [artistPage, setArtistPage] = useState(1);
  const [artists, setArtists] = useState([]);
  const [totalArtists, setTotalArtists] = useState(0);
  const [perPage, setPerPage] = useState(12);
  const [loading, setLoading] = useState(false);
  const [preLoading, setPreLoading] = useState(true);

  const fetchArtists = async () => {
    setLoading(true);

    let res = await APICall({
      url: ApiTypes.fetchArtists,
      data: {
        is_filter: 1,
        filter: {
          tab_type: activeTagType,
          search_key: activeTag
        },
        page: artistPage,
        limit: perPage,
      },
    });
    if (res.status === 1) {
      setPreLoading(false);
      setTotalArtists(res.data.total);
      return res.data.list;
    } else {
      return [];
    }
  };

  const handleChangeTab = (artist_tab, tab_type) => {
    setArtistPage(1);
    setActiveTag(artist_tab);
    setActiveTagType(tab_type)
  };

  const tabKeyFetchArtists = async () => {
    let data = await fetchArtists();
    setArtists(data);
    setLoading(false);
  };

  const fetchLoadMoreVideo = async () => {
    setLoading(true);
    let data = await fetchArtists();
    setArtists([...artists, ...data]);
    setLoading(false);
  };
  useEffect(() => {
    if (artistPage !== 1) {
      fetchLoadMoreVideo();
    }
  }, [artistPage]);
  const handleLoadMore = async () => {
    setArtistPage(artistPage + 1);
  };
  useEffect(() => {
    if (activeTag) {
      setPreLoading(true);
      tabKeyFetchArtists();
    }
  }, [activeTag]);


  const fetchArtistTabs = async () => {
    let res = await APICall({
      url: ApiTypes.fetchArtistsTabs,
    });
    setActiveTag(res.data.list[0].value)
    setActiveTagType(res.data.list[0].tab_type)
    setArtistTags(res.data.list)

  }

  useEffect(() => {
    fetchArtistTabs()
  }, []);


  return (
    <div className="main-content">
      <BannerTop />
      {/*<div className="page-top-banner">
        <img src={TopBannerImg} alt="top-banner" className="w-100" />
        <div className="top-banner-content">
          <Container>
            <div className="banner-content-col">
              <div className="trending-banner-content">Trending Now</div>
              <div className="banner-title-content">
                MTV’s Trending Artists of Today
              </div>
              <div className="discription-content-area">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Elit
                massa non ut cras. Urna tellus, nunc, nunc varius consectetur
                rhoncus.
              </div>
            </div>
          </Container>
        </div>
      </div>*/}
      <div className="videos">
        <div className="music-tabs">
          <Container>
            <div className="music-tab-item">
              <ul ref={scrollRefDiv} className="list-group list-group-horizontal active pl-0 mb-0">
                {/* <li className="d-inline-block">
                    <Link to="/" className="active d-inline-block">
                      Trending Artists
                    </Link>
                  </li>
                  <li className="d-inline-block">
                    <Link to="/" className="d-inline-block">
                      All Artists
                    </Link>
                  </li> */}
                {artistTags.map((active_tag, index) => {
                  return (
                    <li
                      onClick={() => {
                        handleChangeTab(active_tag.value, active_tag.tab_type);
                      }}
                      className="d-inline-block"
                    >
                      <Link
                        to="#"
                        className={`${activeTag === active_tag.value ? "active" : ""
                          } d-inline-block`}
                      >
                        {active_tag.label}
                      </Link>
                    </li>
                  );
                })}
              </ul>
              {artistTags.length ? <ScrollButtons scrollRefDiv={scrollRefDiv}/>:""}
            </div>
          </Container>
        </div>
        <div className="video-item-col">
          <Container>
            <div className="title mb-5">
              {Enums.artists_tab_enum[activeTag]}
            </div>
            <div className="row">
              {!preLoading ? (
                artists.length ? (
                  artists.map((artist, index) => {
                    return (
                      <ArtistCard
                        artist_id={artist.artist_id}
                        artist_name={
                          artist.display_name ? artist.display_name : null
                        }
                        artist_profile={
                          artist.profile_img
                            ?
                            artist.profile_img
                            : null
                        }
                      />
                    );
                  })
                ) : (
                  <div className="mt-4 text-center h5">No Artists Yet!</div>
                )
              ) : (
                <ArtistsLoader />
              )}
              { }
            </div>
            {artists.length < totalArtists ? (
              <div className="row">
                <div className="col-12 text-center mt-5">
                  {!loading ? (
                    <Button
                      onClick={() => {
                        handleLoadMore();
                      }}
                      variant="primary"
                      type="submit"
                    >
                      Load More
                    </Button>
                  ) : (
                    <LoadMoreLoader />
                  )}
                </div>
              </div>
            ) : (
              ""
            )}
          </Container>
        </div>
      </div>
      <div className="artist-community">
        <Container>
          <div className="row align-items-center ">
            <div className="col-lg-6 mb-4 mb-lg-0 ">
              <div className="title-artist-community">
                Join 4,000+ artists and grow with the community
              </div>
              {/*  <div className="artist-discription-area">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </div> */}
              {/* <Button className="transparent-btn me-3">Learn more</Button> */}
              <Button variant="primary" type="button">
                Get started
              </Button>
            </div>
            <div className="col-lg-6">
              <div className="artist-community-banner">
                <Slider {...artistcommunity}>
                  <div>
                    <img
                      className="slider-img w-100"
                      src={ArtistsImg}
                      alt="AuthSlider 1"
                      width={636}
                      height={654}
                    />
                    <div className="bottom-card-area">
                      {/* <div className="discription-area mb-4">
                        “Lorem ipsum dolor sit amet, consectetur adipiscing
                        elit. Cras morbi eu ipsum lobortis id tinedu.”
                      </div> */}
                      <div className="artist-area mb-3 d-flex align-items-start justify-content-between">
                        <div className="artist-name">Andi Lane</div>
                        <div className="rating-area d-flex">
                          <img
                            src={StarWhite}
                            alt=""
                            width={20}
                            height={20}
                            className="mx-1"
                          />
                          <img
                            src={StarWhite}
                            alt=""
                            width={20}
                            height={20}
                            className="mx-1"
                          />
                          <img
                            src={StarWhite}
                            alt=""
                            width={20}
                            height={20}
                            className="mx-1"
                          />
                          <img
                            src={StarWhite}
                            alt=""
                            width={20}
                            height={20}
                            className="mx-1"
                          />
                          <img
                            src={StarWhite}
                            alt=""
                            width={20}
                            height={20}
                            className="mx-1"
                          />
                        </div>
                      </div>
                      <div className="details-area">
                        <div className="artist-type mb-1">
                          Music Artist, Actress
                        </div>
                        <div className="artist-location">
                          Little Rock Studio
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <img
                      className="slider-img w-100"
                      src={ArtistsImg}
                      alt="AuthSlider 2"
                      width={636}
                      height={654}
                    />
                    <div className="bottom-card-area">
                      {/* <div className="discription-area mb-4">
                        “Lorem ipsum dolor sit amet, consectetur adipiscing
                        elit. Cras morbi eu ipsum lobortis id tinedu.”
                      </div> */}
                      <div className="artist-area mb-3 d-flex align-items-start justify-content-between">
                        <div className="artist-name">Allan Hesterson</div>
                        <div className="rating-area d-flex">
                          <img
                            src={StarWhite}
                            alt=""
                            width={20}
                            height={20}
                            className="mx-1"
                          />
                          <img
                            src={StarWhite}
                            alt=""
                            width={20}
                            height={20}
                            className="mx-1"
                          />
                          <img
                            src={StarWhite}
                            alt=""
                            width={20}
                            height={20}
                            className="mx-1"
                          />
                          <img
                            src={StarWhite}
                            alt=""
                            width={20}
                            height={20}
                            className="mx-1"
                          />
                          <img
                            src={StarWhite}
                            alt=""
                            width={20}
                            height={20}
                            className="mx-1"
                          />
                        </div>
                      </div>
                      <div className="details-area">
                        <div className="artist-type mb-1">
                          Musician, Producer
                        </div>
                        <div className="artist-location">MyMusic Studios</div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <img
                      className="slider-img w-100"
                      src={ArtistsImg}
                      alt="AuthSlider 3"
                      width={636}
                      height={654}
                    />
                    <div className="bottom-card-area">
                      {/*  <div className="discription-area mb-4">
                        “Lorem ipsum dolor sit amet, consectetur adipiscing
                        elit. Cras morbi eu ipsum lobortis id tinedu.”
                      </div> */}
                      <div className="artist-area mb-3 d-flex align-items-start justify-content-between">
                        <div className="artist-name">Andi Lane</div>
                        <div className="rating-area d-flex">
                          <img
                            src={StarWhite}
                            alt=""
                            width={20}
                            height={20}
                            className="mx-1"
                          />
                          <img
                            src={StarWhite}
                            alt=""
                            width={20}
                            height={20}
                            className="mx-1"
                          />
                          <img
                            src={StarWhite}
                            alt=""
                            width={20}
                            height={20}
                            className="mx-1"
                          />
                          <img
                            src={StarWhite}
                            alt=""
                            width={20}
                            height={20}
                            className="mx-1"
                          />
                          <img
                            src={StarWhite}
                            alt=""
                            width={20}
                            height={20}
                            className="mx-1"
                          />
                        </div>
                      </div>
                      <div className="details-area">
                        <div className="artist-type mb-1">
                          Music Artist, Actress
                        </div>
                        <div className="artist-location">
                          Little Rock Studio
                        </div>
                      </div>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
}

export default TrendingArtists;
