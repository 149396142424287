import React from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import MbaseLogo from "../../assets/media/logos/m-base-logo.svg";
import Button from "react-bootstrap/Button";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Offcanvas from "react-bootstrap/Offcanvas";
import ProfileImg from "../../assets/media/img/profile-img.png";
import { Form, NavDropdown } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import ProfileMenuHeader from "../../Component/ProfileMenuHeader/ProfileMenuHeader";
import { useState } from "react";
import ApiTypes from "../../networking/APItypes";
import APICall from "../../networking/AxiousServices";
import LoadMoreLoader from "../../Component/Loaders/LoadMoreLoader";

function ProfilePassword() {
  const [isLoading, setIsLoading] = useState(false);
  const [afterResEr, setAfterResEr] = useState(null);
  const [success, setSuccess] = useState(null);

  const errorMessages = {
    current_password: "Current Password field is required.",
    password: "Password field is required.",
    confirm_password: 'Confirm Password field is required.',
    not_matched: 'Password and Confirm password must match.',
    pass_valid: "Must be at least 8 characters.",
  }
  const initialState = {
    current_password: "",
    password: "",
    confirm_password: ""
  }
  const [formInputs, setFormInputs] = useState({
    current_password: "",
    password: "",
    confirm_password: ""
  });
  const [formInputsErrors, setFormInputsErrors] = useState({
    current_password: "",
    password: "",
    confirm_password: ""
  });


  const resetAll = () => {
    setFormInputs(initialState)
    setFormInputsErrors(initialState)
  }
  const checkValidation = () => {
    let fieldEmpty = false;
    let isValidate = true;
    let er_current_password = "";
    let er_password = "";
    let er_confirm_password = "";
    if (formInputs.current_password == "") {
      er_current_password = errorMessages.current_password;
      fieldEmpty = true;
      isValidate = false;
    }
    if (formInputs.password == "") {
      er_password = errorMessages.password;
      fieldEmpty = true;
      isValidate = false;
    }else{
      if (formInputs.password !== "" && formInputs.password.length < 8 )  {
        er_password = errorMessages.pass_valid;
        fieldEmpty = true;
        isValidate = false;
    }
    }
    if (formInputs.confirm_password == "") {
      er_confirm_password = errorMessages.confirm_password;
      fieldEmpty = true;
      isValidate = false;
    }
   
    if (!fieldEmpty) {
      if (formInputs.confirm_password != formInputs.password) {
        isValidate = false;
        er_confirm_password = errorMessages.not_matched;
      }
    }
    setFormInputsErrors({
      current_password: er_current_password,
      password: er_password,
      confirm_password: er_confirm_password,
    })
    return isValidate;
  }
  const printError = (msg) => {
    setAfterResEr(msg)
    setIsLoading(false)
  }

  const handleChangePassword = async (e) => {
    e.preventDefault();
    setAfterResEr(null)
    if (checkValidation()) {
      setIsLoading(true)
      let data = {
        current_password: formInputs.current_password,
        password: formInputs.password
      }

      let res = await APICall({ url: ApiTypes.changePassword, data })
      if (res.status == 1) {
        setFormInputs(initialState);
        setFormInputsErrors(initialState)
        setIsLoading(false)
        setSuccess(res.message)
      } else {
        if (res.data.status === 0) {
          if ('type' in res.data) {
            if (res.data.type === 'incorrect_cur_pass') {
              setFormInputsErrors({
                ...formInputsErrors,
                current_password: res.data.message ? res.data.message : "",
              })
              setIsLoading(false)
            } else if (res.data.type === 'same_pass') {
              setFormInputsErrors({
                ...formInputsErrors,
                password: res.data.message ? res.data.message : "",
              })
              setIsLoading(false)
            } else {
              printError(res.data.message)
              setIsLoading(false)
            }
          } else {
            printError(res.data.message)
            setIsLoading(false)
          }
        } else {
          setIsLoading(false)
          printError(res.data.message)
        }

      }

    }
  }

  return (<>
    <div className="main-content">
      {/* <div className="account-profile-menu"> */}
        <ProfileMenuHeader />
      {/* </div> */}
      <div className="account-profile-section">
        <Container>
          <div className="account-profile-col">
            <div className="profile-top-col mt-5">
              <div className="profile-title">Password</div>
              <div className="profile-discription">
                Please enter your current password to change your password.
              </div>
              {afterResEr && <div className="mt-3 error-div">
                <div>{afterResEr}</div>
                <div>
                  <i onClick={() => { setAfterResEr(null) }} className="fa fa-close cursor-pointer"></i>
                </div>

              </div>}
              {success && <div style={{ background: "#198754" }} className="mt-3 error-div">

                <div> {success}</div>
                <div>
                  <i onClick={() => { setSuccess(null) }} className="fa fa-close cursor-pointer"></i>
                </div>


              </div>}
            </div>
            <div className="set-password">
              <Form onSubmit={(e) => handleChangePassword(e)} className="form-password mt-4 pt-4">
                <Row>
                  <Form.Group as={Col} md="12" className="mb-3">
                    <Form.Label>Current password <span className="required d-inline-block">*</span></Form.Label>
                    <Form.Control
                      onChange={(e) => {
                        setFormInputs({
                          ...formInputs,
                          current_password: e.target.value ? e.target.value : ""
                        })
                      }}
                      value={formInputs.current_password}
                      type="password"
                      placeholder="Current password"
                      className={formInputsErrors.current_password !== "" ? "invalid" : ""}
                    />
                    {formInputsErrors.current_password !== "" ? <div className="invalid-message mt-1">
                      {formInputsErrors.current_password}
                    </div> : ""}
                  </Form.Group>
                  <Form.Group as={Col} md="12" className="mb-3">
                    <Form.Label>New password <span className="required d-inline-block">*</span></Form.Label>
                    <Form.Control

                      onChange={(e) => {
                        setFormInputs({
                          ...formInputs,
                          password: e.target.value ? e.target.value : ""
                        })
                      }}
                      value={formInputs.password}
                      type="password"
                      placeholder="New password"
                      className={formInputsErrors.password !== "" ? "invalid" : ""}
                    />
                    {formInputsErrors.password !== "" ? <div className="invalid-message mt-1">
                      {formInputsErrors.password}
                    </div> : ""}
                  </Form.Group>
                  <Form.Group as={Col} md="12">
                    <Form.Label>Confirm new password <span className="required d-inline-block">*</span></Form.Label>
                    <Form.Control

                      onChange={(e) => {
                        setFormInputs({
                          ...formInputs,
                          confirm_password: e.target.value ? e.target.value : ""
                        })
                      }}
                      value={formInputs.confirm_password}
                      type="password"
                      className={formInputsErrors.confirm_password !== "" ? "invalid" : ""}
                      placeholder="Confirm new password"
                    />
                    {formInputsErrors.confirm_password !== "" ? <div className="invalid-message mt-1">
                      {formInputsErrors.confirm_password}
                    </div> : ""}
                  </Form.Group>
                </Row>
                <div className="form-button text-end">
                  <Button onClick={resetAll} type="button" className="transparent-btn me-3">Cancel</Button>
                  {!isLoading ? <Button variant="primary" type="submit">
                    Update password
                  </Button> : <LoadMoreLoader />}
                </div>
              </Form>
            </div>
          </div>
        </Container>
      </div>
    </div>
  </>
  );
}

export default ProfilePassword;
