import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
export default function HomeVideoLoader(props) {
  return (
    <>
      {[...Array(6).fill(0)].map((_, i) => (
        <div key={i} className="col-lg-4 mb-5">
          <SkeletonTheme height={260} borderRadius={0}>
            <p>
              <Skeleton />
            </p>
          </SkeletonTheme>
          <SkeletonTheme height={18}>
            <p>
              <Skeleton />
            </p>
          </SkeletonTheme>
          <SkeletonTheme height={22}>
            <p>
              <Skeleton />
            </p>
          </SkeletonTheme>
          <SkeletonTheme height={20}>
            <p>
              <Skeleton />
            </p>
          </SkeletonTheme>
        </div>
      ))}
    </>
  );
}
