import React, { useState, useEffect } from 'react';
import ApiTypes from '../../networking/APItypes';
import { Accordion, Container, Button } from "react-bootstrap";
import APICall from '../../networking/AxiousServices';
import FAQLoader from '../../Component/Loaders/FAQLoader';
import ReactHtmlParser from 'react-html-parser';

export default function Faq() {

    const [list, setList] = useState([]);

    const [fetchLoading, setFetchLoading] = useState(true);
    const [loadMore, setLoadMore] = useState(false);

    const fetchFaqList = async () => {

        let res = await APICall({
            url: ApiTypes.fetchFaqList
        });

        if (res.status === 1) {
            setFetchLoading(false)
            setList(res.data.list)
        }
    }

    useEffect(() => {
        fetchFaqList();
    }, []);



    return ( <div className="faqs-section">
    <Container>
        <div className="faq-col">
            <div className="row">
                {/* <div className="col-lg-5">
                    <div className="support-title">Support</div>
                    <div className="faq-title">FAQs</div>
                    <div className="faq-discription-area">
                        Everything you need to know about the product and billing.
                        Can’t find the answer you’re looking for? Please chat to our
                        team.
                    </div>
                </div> */}
                <div className="col-lg-12">
                    <div className="faq-accordion">
                        <Accordion defaultActiveKey="0" flush>

                            {!fetchLoading ? list.map((itm, key) =>
                            (<Accordion.Item key={key} eventKey={key}>
                                <Accordion.Header>
                                {ReactHtmlParser(itm.question)}
                                </Accordion.Header>
                                <Accordion.Body>
                                {ReactHtmlParser(itm.answer)}
                                </Accordion.Body>
                            </Accordion.Item>)
                            ) :<FAQLoader /> }
                        </Accordion>
                    </div>
                </div>
            </div>
        </div>
    </Container>
</div>)
}
