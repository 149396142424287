import React from "react";
import ForgotPassword from "../../assets/media/img/Featured-icon.svg";

export default function PageExpires() {
  return (
    <div className="position-relative d-flex align-items-center justify-content-center vh-100">
      <img src={ForgotPassword} className="auth-logo" alt="Login Logo" />
      <div className="signup-box d-flex flex-column justify-content-center">
        <h1 className="text-center">This page is expired.</h1>
        {/* <p className="text-center">
      Your new password must be different to previously used passwords.
    </p> */}
      </div>
    </div>
  );
}
