import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Accordion, Col, InputGroup, Modal, Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import MbaseLogo from "../../assets/media/logos/m-base-logo.svg";
import Button from "react-bootstrap/Button";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Offcanvas from "react-bootstrap/Offcanvas";
import SubscriptionBanner from "../../assets/media/img/bookings.png";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import img from "../../assets/media/img/Image1.png";
import ProfileImg from "../../assets/media/img/profile-img.png";
import { ReactComponent as UploadIcon } from "../../assets/media/icons/upload-icon.svg";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Select from "react-select";
import TimezoneSelect from "react-timezone-select";
import Us from "../../assets/media/img/US.png";
import { ReactComponent as Remove } from "../../assets/media/icons/remove.svg";
import CurrencyDollar from "../../assets/media/icons/currency-dollar.svg";
import MarkerPin from "../../assets/media/icons/marker-pin.svg";
import StartToEndTime from "../../assets/media/icons/start-to-end-time.svg";
import JobListCard from "../../Component/Job/JobListCard";
import APICall from "../../networking/AxiousServices";
import { useEffect } from "react";
import { Enums } from "../../constants/Enums";
import SiteLoader from "../../Component/Loaders/SiteLoader";
import ApiTypes from "../../networking/APItypes";
import ProfileMenuHeader from "../../Component/ProfileMenuHeader/ProfileMenuHeader";
import NodataFound from "../Errors/NodataFound";

function SavedJob() {
  const [modalShowJobGigs, setModalJobGigsModal] = React.useState(false);
  const [viewCreateModel, setViewCreateJobModel] = useState(false)

  document.title = process.env.REACT_APP_NAME + " | Saved Jobs";
  
  const [jobPage, setJobPage] = useState(1);
  const [jobs, setJobs] = useState([]);
  const [totalJobs, setTotalJobs] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [preLoading, setPreLoading] = useState(true);


  const fetchJobList = async () => {
      setLoading(true);
      let res = await APICall({
          url: ApiTypes.fetchJobList,
          data: {
              is_filter: 1,
              filter: {
                  request_type: Enums.job_request_types.saved_job,
              },
              page: jobPage,
              limit: perPage,
          },
      });
      if (res.status === 1) {
          setPreLoading(false);
          setTotalJobs(res.data.total);
          return res.data.list;
      } else {
          return [];
      }
  };

  const fetchLoadMoreJob = async () => {
      setLoading(true);
      let data = await fetchJobList();
      setJobs([...jobs, ...data]);
      setLoading(false);
  };
  useEffect(() => {
      // if (jobPage !== 1) {
      fetchLoadMoreJob();
      // }
  }, [jobPage]);

  const handleLoadMore = async () => {
      setJobPage(jobPage + 1);
  };


  return (
    <div className="main-content">
       <ProfileMenuHeader />
      <div className="cards-class-all Savedjob-class-main">
        <Container>
          <div className="title-class-add d-flex justify-content-between">
            <div className="title-add">Saved Jobs</div>
          </div>
          <Row>
          {!preLoading ?  jobs.length ? jobs.map((job_item, index) => {
              return (
                <JobListCard jobsLen={jobs.length} isSave={true} isApply={true} keyIndex={index} key={index} job_item={job_item} />
              );
            }) :<NodataFound/> : <SiteLoader />}
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default SavedJob;
