import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import AuthSlider from "./AuthSlider";

function AuthLayout({ PageComponent }) {
  return (
    <Container fluid={true} className="authentication-page p-0">
      <Row className="m-0">
        <Col lg={6} className="p-0">
          <PageComponent />
        </Col>
        <Col lg={6} className="p-0">
          <AuthSlider />
        </Col>
      </Row>
    </Container>
  );
}

export default AuthLayout;
