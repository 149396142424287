import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export default function ArtistsLoader() {
  return (
    <>
      {[...Array(6).fill(0)].map((_, i) => (
        <div className="col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-5 text-center">
          <SkeletonTheme width={240} height={240} borderRadius={250}>
            <p>
              <Skeleton />
            </p>
          </SkeletonTheme>
          <SkeletonTheme height={22}>
            <p>
              <Skeleton />
            </p>
          </SkeletonTheme>
        </div>
      ))}
    </>
  );
}
