import React from "react";
import TopBannerImg from "../../assets/media/img/banner-img.png";
import Slider from "react-slick";
// import suggestions from "../../assets/File/AutoComplete";
import { Container } from "react-bootstrap";
// import AutoComplete from "../../Component/AutoComplete/Index";

export default function BannerTop() {
  var topbanner = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
    dots: true,
  };
  return (
    <div className="top-banner">
      <img
        className="slider-img w-100"
        src={TopBannerImg}
        alt="AuthSlider 1"
        width={1440}
        height={410}
      />
      <Slider {...topbanner}>
        <div className="banner-item">
          <Container>
            <div className="banner-content text-center">
              <div className="banner-title">Welcome to M | Base</div>
              <div className="banner-discription-area">
                Your platform for all music videos content. <br />
                Watch your favorite music videos, search and follow your favorite artists.
              </div>
            </div>
          </Container>
        </div>
        <div className="banner-item">
          <Container>
            <div className="banner-content text-center">
              <div className="banner-title">Welcome to M | Base</div>
              <div className="banner-discription-area">
                Sign up for M Base Pro to claim your page and access more features like bookings and contact other professionals
              </div>
            </div>
          </Container>
        </div>
      </Slider>
      {/* <div className="banner-search-col">
        <div className="banner-search">
          <AutoComplete suggestions={suggestions} />
        </div>
      </div> */}
    </div>
  );
}
