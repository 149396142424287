import React from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import MbaseLogo from "../../assets/media/logos/m-base-logo.svg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import ArtistsImg from "../../assets/media/img/artists-img.png";
import { Link, useNavigate } from "react-router-dom";
import StarWhite from "../../assets/media/icons/star-white.svg";
import { Card, Spinner } from "react-bootstrap";
import ThumbnailImg from "../../assets/media/img/thumbnail.png";
import ThumbnailFour from "../../assets/media/img/thumbnail-4.png";
import ThumbnailTwo from "../../assets/media/img/thumbnail-2.png";
import ThumbnailOne from "../../assets/media/img/thumbnail-1.png";
import ThumbnailFive from "../../assets/media/img/thumbnail-5.png";
import FielStar from "../../assets/media/icons/fiel-star.svg";
import BlankStar from "../../assets/media/icons/blank-star.svg";
import ClockRotate from "../../assets/media/icons/clock-rotate.svg";
import AutoComplete from "../../Component/AutoComplete/Index";
import suggestions from "../../assets/File/AutoComplete";
import { useEffect } from "react";
import ApiTypes from "../../networking/APItypes";
import APICall from "../../networking/AxiousServices";
import { unstable_batchedUpdates } from "react-dom";
import { useState } from "react";
import VideoLayout from "../../Component/Video/VideoLayout";
import { Enums } from "../../constants/Enums";
import HomeVideoLoader from "../../Component/Loaders/HomeVideoLoader";
import LoadMoreLoader from "../../Component/Loaders/LoadMoreLoader";
import BannerTop from "../../Component/Banner/BannerTop";
import { commonHelper } from "../../common/commonHelper";
import { useRef } from "react";
import ScrollButtons from "../../Component/ScrollButtons/ScrollButtons";
import { useSelector } from "react-redux";

export default function Home() {
  document.title = process.env.REACT_APP_NAME + " | Home";
  const [videoTabs, setVideoTabs] = useState([]);
  const [activeTag, setActiveTag] = useState(null);
  const [videoPage, setVideoPage] = useState(1);
  const [videos, setVideos] = useState([]);
  const [totalVideos, setTotalVideos] = useState(0);
  const [perPage, setPerPage] = useState(9);
  const [loading, setLoading] = useState(false);
  const scrollRefDiv = useRef(null)
  const [preLoading, setPreLoading] = useState(true);
  const navigate = useNavigate()
  const data_selector = useSelector((state) => state.data);
  const isLogIn = data_selector.auth_token;
  let is_subscribed = false;


  if ('subscription' in data_selector.logged_user) {
    if(data_selector.logged_user.subscription){
      if (data_selector.logged_user.subscription.subscription_type === Enums.subscription_type.paid) {
        is_subscribed = true;
      }
    }
  
  }

  var artistcommunity = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    cssEase: "linear",
  };

  const fetchHomeVideoTabs = async () => {
    let res = await APICall({
      url: ApiTypes.fetchHomeVideoTabs,
    });
    if (res.status === 1) {
      setVideoTabs(res.data.list);
      setActiveTag(res.data.list[0].value);
    }
  };
  const fetchHomeVideos = async () => {
    setLoading(true);
    let res = await APICall({
      url: ApiTypes.fetchHomeVideos,
      data: {
        is_filter: 1,
        filter: {
          tab_type: activeTag,
        },
        page: videoPage,
        limit: perPage,
      },
    });
    if (res.status === 1) {
      setPreLoading(false);
      setTotalVideos(res.data.total);
      return res.data.list;
    } else {
      return [];
    }
  };
  const handleLoadMore = async () => {
    setVideoPage(videoPage + 1);
  };
  const handleChangeTab = (e, video_tab) => {
    setVideoPage(1);
    setActiveTag(video_tab);
  };
  const fetchLoadMoreVideo = async () => {
    setLoading(true);
    let data = await fetchHomeVideos();
    setVideos([...videos, ...data]);
    setLoading(false);
  };
  useEffect(() => {
    if (videoPage !== 1) {
      fetchLoadMoreVideo();
    }
  }, [videoPage]);
  const tabKeyFetchVideo = async () => {
    let data = await fetchHomeVideos();
    setVideos(data);
    setLoading(false);
  };
  useEffect(() => {
    if (activeTag) {
      setPreLoading(true);
      tabKeyFetchVideo();
    }
  }, [activeTag]);

  useEffect(() => {
    fetchHomeVideoTabs();
  }, []);

  return (
    <div className="main-content">
      <BannerTop />
      <div className="videos">
        <div className="music-tabs">
          <Container>
            <div className="music-tab-item">
              <ul ref={scrollRefDiv} className="list-group list-group-horizontal active pl-0 mb-0">
                {videoTabs.length
                  ? videoTabs.map((video_tab, index) => {
                    return (
                      <li
                        key={index}
                        onClick={(e) => {
                          handleChangeTab(e, video_tab.value);
                        }}
                        className="d-inline-block"
                      >
                        <Link
                          to="#"
                          className={`d-inline-block ${activeTag === video_tab.value ? "active" : ""
                            }`}
                        >
                          {video_tab.label}
                        </Link>
                      </li>
                    );
                  })
                  : ""}
              </ul>

              {videoTabs.length ? <ScrollButtons scrollRefDiv={scrollRefDiv} /> : ""}
            </div>
          </Container>
        </div>
        <div className="video-item-col">
          <Container>
            <div className="title">{videoTabs.length ? videoTabs.filter((obj) => obj.value === activeTag).length ? videoTabs.filter((obj) => obj.value === activeTag)[0].label : "" : ""}</div>
            <div className="row">
              {!preLoading ? (
                videos.length ? (
                  videos.map((video, index) => {
                    return (
                      <VideoLayout
                        media_ref_id={video.media_ref_id}
                        is_watch_later={video.is_watch_later}
                        is_favorite={video.is_favorite}
                        videoURL={
                          Enums.urls.youtube_embed_base + video.media_ref_id
                        }
                        title={video.title}
                        video_id={video.video_id}
                        director={
                          video.all_director_name
                            ? video.all_director_name
                            : null
                        }
                        artist={
                          video.all_artist_name ? video.all_artist_name : null
                        }
                        active_tag={activeTag}
                        artist_id_str={video.all_artist_id ? video.all_artist_id : null}
                        key={index}
                      />
                    );
                  })
                ) : (
                  <div className="mt-4 text-center h5">No Videos Yet!</div>
                )
              ) : (
                <HomeVideoLoader />
              )}
            </div>
            {videos.length < totalVideos ? (
              <div className="row">
                <div className="col-12 text-center mt-5">
                  {!loading ? (
                    <Button
                      onClick={() => {
                        handleLoadMore();
                      }}
                      variant="primary"
                      type="button"
                    >
                      Load More
                    </Button>
                  ) : (
                    <LoadMoreLoader />
                  )}
                </div>
              </div>
            ) : (
              ""
            )}
          </Container>
        </div>
      </div>
      <div className="artist-community">
        <Container>
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="title-artist-community">
                Join 4,000+ artists and grow with the community
              </div>
              {/*  <div className="artist-discription-area">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </div> */}
              {/* <Button className="transparent-btn me-3">Learn more</Button> */}
              {!isLogIn || !is_subscribed ? <Button variant="primary" onClick={() => {

                if (!isLogIn) {
                  navigate('/login')
                }else{
                  if(!is_subscribed){
                    navigate('/subscription')
                  }
                }
              }} type="button">
                Get started
              </Button> : ""}
            </div>
            <div className="col-lg-6 mt-4 mt-lg-0">
              <div className="artist-community-banner">
                <Slider {...artistcommunity}>
                  <div>
                    <img
                      className="slider-img w-100"
                      src={ArtistsImg}
                      alt="AuthSlider 1"
                      width={636}
                      height={654}
                    />
                    <div className="bottom-card-area">
                      {/*  <div className="discription-area mb-4">
                        “Lorem ipsum dolor sit amet, consectetur adipiscing
                        elit. Cras morbi eu ipsum lobortis id tinedu.”
                      </div> */}
                      <div className="artist-area mb-3 d-flex align-items-start justify-content-between">
                        <div className="artist-name">Andi Lane</div>
                        <div className="rating-area d-flex">
                          <img
                            src={StarWhite}
                            alt=""
                            width={20}
                            height={20}
                            className="mx-1"
                          />
                          <img
                            src={StarWhite}
                            alt=""
                            width={20}
                            height={20}
                            className="mx-1"
                          />
                          <img
                            src={StarWhite}
                            alt=""
                            width={20}
                            height={20}
                            className="mx-1"
                          />
                          <img
                            src={StarWhite}
                            alt=""
                            width={20}
                            height={20}
                            className="mx-1"
                          />
                          <img
                            src={StarWhite}
                            alt=""
                            width={20}
                            height={20}
                            className="mx-1"
                          />
                        </div>
                      </div>
                      <div className="details-area">
                        <div className="artist-type mb-1">
                          Music Artist, Actress
                        </div>
                        <div className="artist-location">
                          Little Rock Studio
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <img
                      className="slider-img w-100"
                      src={ArtistsImg}
                      alt="AuthSlider 2"
                      width={636}
                      height={654}
                    />
                    <div className="bottom-card-area">
                      {/*  <div className="discription-area mb-4">
                        “Lorem ipsum dolor sit amet, consectetur adipiscing
                        elit. Cras morbi eu ipsum lobortis id tinedu.”
                      </div> */}
                      <div className="artist-area mb-3 d-flex align-items-start justify-content-between">
                        <div className="artist-name">Allan Hesterson</div>
                        <div className="rating-area d-flex">
                          <img
                            src={StarWhite}
                            alt=""
                            width={20}
                            height={20}
                            className="mx-1"
                          />
                          <img
                            src={StarWhite}
                            alt=""
                            width={20}
                            height={20}
                            className="mx-1"
                          />
                          <img
                            src={StarWhite}
                            alt=""
                            width={20}
                            height={20}
                            className="mx-1"
                          />
                          <img
                            src={StarWhite}
                            alt=""
                            width={20}
                            height={20}
                            className="mx-1"
                          />
                          <img
                            src={StarWhite}
                            alt=""
                            width={20}
                            height={20}
                            className="mx-1"
                          />
                        </div>
                      </div>
                      <div className="details-area">
                        <div className="artist-type mb-1">
                          Musician, Producer
                        </div>
                        <div className="artist-location">MyMusic Studios</div>
                      </div>
                    </div>
                  </div>

                </Slider>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
}
