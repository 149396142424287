import React, { useEffect } from 'react'
import { useState } from 'react';
import { commonHelper } from '../../common/commonHelper';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export default function PreviewImg({ src = null, isDefault = true, className = null, defaultImg = null, alt = null, width = null, height = null }) {

    const [imgPreviewUrl, setImgPreviewUrl] = useState(src);

    const [loading, setLoading] = useState(true);

    async function getImageUrls(src_get) {
        if (isDefault) {
            setImgPreviewUrl(defaultImg);
            setLoading(false)
        } else {
            const signedUrl = await commonHelper.getAssetUrl(src_get);
            setImgPreviewUrl(signedUrl);
            setLoading(false)
        }
    }

    useEffect(() => {
        getImageUrls(src);
    }, [src]);

    return (!loading ? <img
        src={imgPreviewUrl}
        onError={(e)=>{
            e.target.src = defaultImg
        }}
        alt={alt ? alt : ""}
        width={width ? width : ""}
        height={height ? height : ""}
        className={`${className ? className : "rounded-circle "} obj-fit-sc-down`}
    /> : <img
        src={defaultImg}
        alt={""}
        onError={(e)=>{
            e.target.src = defaultImg
        }}
        width={width ? width : ""}
        height={height ? height : ""}
        className={`${className ? className : "rounded-circle "} obj-fit-sc-down`}
    />)
}
