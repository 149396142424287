import React from "react";
import { ReactComponent as HomeLoader } from "../../assets/Images/Loaders/loader-spin.svg";
export default function FetchLoader() {
  return (
    <div className="home-loader">
      <div className="loader">
        <HomeLoader />
        <span className="loading-text">Loading...</span>
      </div>
    </div>
  );
}
