import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Accordion, Col, InputGroup, Modal, Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import MbaseLogo from "../../assets/media/logos/m-base-logo.svg";
import Button from "react-bootstrap/Button";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Offcanvas from "react-bootstrap/Offcanvas";
import SubscriptionBanner from "../../assets/media/img/bookings.png";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import img from "../../assets/media/img/Image1.png";
import ProfileImg from "../../assets/media/img/profile-img.png";
import { ReactComponent as UploadIcon } from "../../assets/media/icons/upload-icon.svg";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Select from "react-select";
import TimezoneSelect from "react-timezone-select";
import Us from "../../assets/media/img/US.png";
import { ReactComponent as Remove } from "../../assets/media/icons/remove.svg";
import CurrencyDollar from "../../assets/media/icons/currency-dollar.svg";
import MarkerPin from "../../assets/media/icons/marker-pin.svg";
import StartToEndTime from "../../assets/media/icons/start-to-end-time.svg";
import JobListCard from "../../Component/Job/JobListCard";
import APICall from "../../networking/AxiousServices";
import { useEffect } from "react";
import { Enums } from "../../constants/Enums";
import SiteLoader from "../../Component/Loaders/SiteLoader";
import ApiTypes from "../../networking/APItypes";
import NodataFound from "../Errors/NodataFound";
import LoadMoreLoader from "../../Component/Loaders/LoadMoreLoader";

function Bookings() {
  const [modalShowJobGigs, setModalJobGigsModal] = React.useState(false);
  const [viewCreateModel, setViewCreateJobModel] = useState(false);

  document.title = process.env.REACT_APP_NAME + " | Bookings";

  const [jobPage, setJobPage] = useState(1);
  const [jobs, setJobs] = useState([]);
  const [totalJobs, setTotalJobs] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [preLoading, setPreLoading] = useState(true);

  const [jobTitles, setJobTitles] = useState([]);
  const [jobTypes, setJobTypes] = useState([]);
  const [locations, setLocations] = useState([]);
  const [companies, setCompanies] = useState([]);

  const [jobTitleText, setJobTitleText] = useState("");
  const [jobTypeText, setJobTypeText] = useState("");
  const [locationText, setLocationText] = useState("");
  const [companyText, setCompanyText] = useState("");

  const [resetCount, setResetCount] = useState(0);

  // const [searchJobInputs, setSearchJobInputs] = useState({
  //   job_title: "",
  //   job_type: "",
  //   location: "",
  //   company_title: "",
  // });

  const fetchJobList = async () => {
    setLoading(true);
    let res = await APICall({
      url: ApiTypes.fetchJobList,
      data: {
        is_filter: 1,
        filter: {
          request_type: Enums.job_request_types.search_job,
          job_type: jobTypeText,
          location: locationText,
          company_title: companyText,
        },
        page: jobPage,
        limit: perPage,
      },
    });
    if (res.status === 1) {
      setPreLoading(false);
      setTotalJobs(res.data.total);
      return res.data.list;
    } else {
      return [];
    }
  };

  const fetchLoadMoreJob = async () => {
    setLoading(true);
    let data = await fetchJobList();
    setJobs([...jobs, ...data]);
    setLoading(false);
  };

  const fetchSearchedJob = async () => {
    setPreLoading(true);
    setPerPage(10);
    let data = await fetchJobList();
    setJobs(data);
  };

  // useEffect(() => {
  //   // if (jobPage !== 1) {
  //   if (
  //     searchJobInputs.company_title !== "" ||
  //     searchJobInputs.job_title !== "" ||
  //     searchJobInputs.location !== ""
  //   ) {
  //     fetchSearchedJob();
  //   }
  //   // }
  // }, [
  //   searchJobInputs.company_title,
  //   searchJobInputs.job_title,
  //   searchJobInputs.location,
  // ]);

  const handleJobFilter = (e) => {
    e.preventDefault();
    // if (
    //   companyText !== "" ||
    //   jobTypeText !== "" ||
    //   locationText !== ""
    // ) {
    fetchSearchedJob();
    // }
    // }
  };

  const clearJobInputs = (e) => {
    e.preventDefault();
    // setSearchJobInputs({
    //   job_title: "",
    //   job_type: "",
    //   location: "",
    //   company_title: "",
    // })
    setJobTitleText("");
    setJobTypeText("");
    setLocationText("");
    setCompanyText("");
    setResetCount(resetCount + 1);
  };

  useEffect(() => {
    // if (jobPage !== 1) {
    fetchLoadMoreJob();
    // }
  }, [jobPage]);

  useEffect(() => {
    fetchSearchedJob();
  }, [resetCount]);

  const handleLoadMore = async () => {
    setJobPage(jobPage + 1);
  };

  const fetchDropListFun = async (search_type, search) => {
    let res = await APICall({
      url: ApiTypes.jobFilters,
      data: {
        is_filter: 1,
        filter: {
          search_type: search_type,
          search: search,
        },
      },
    });
    if (res.status === 1) {
      return res.data.list;
    } else {
      return [];
    }
  };

  const fetchDropList = async (search_type, search) => {
    let data = await fetchDropListFun(search_type, search);
    if (search_type === Enums.job_search_types.job_title) {
      setJobTitles(data);
    } else if (search_type === Enums.job_search_types.location) {
      setLocations(data);
    } else if (search_type === Enums.job_search_types.company_title) {
      setCompanies(data);
    } else if (search_type === Enums.job_search_types.job_type) {
      setJobTypes(data);
    }
  };

  useEffect(() => {
    if (jobTitleText !== "") {
      fetchDropList(Enums.job_search_types.job_title, jobTitleText);
    }
  }, [jobTitleText]);

  useEffect(() => {
    if (jobTypeText !== "") {
      fetchDropList(Enums.job_search_types.job_type, jobTypeText);
    }
  }, [jobTypeText]);

  useEffect(() => {
    if (locationText !== "") {
      fetchDropList(Enums.job_search_types.location, locationText);
    }
  }, [locationText]);
  useEffect(() => {
    if (companyText !== "") {
      fetchDropList(Enums.job_search_types.company_title, companyText);
    }
  }, [companyText]);

  // const JobGigsModal = (props) => {
  //   return (

  //   );
  // };
  return (
    <>
      <div className="main-content">
        <div className="page-top-banner">
          <img src={SubscriptionBanner} alt="top-banner" className="w-100" />
          <div className="top-banner-content">
            <Container>
              <Row>
                <div className="col-md-12">
                  {/* <div className="trending-banner-content">
                    Job Search & Bookings
                  </div> */}
                  <div className="banner-title-content">
                    Explore numerous music video-related jobs listings.
                  </div>
                </div>
                <div className="col-md-12 dropdown-class-Add">
                  <div className="banner-content-col">
                    <div className="all-dropdown-class">
                      {/* <div className="dropdown-one"> */}

                      {/* <Form.Control
                          className="ps-5"
                          list="jobs-title-list"
                          onInput={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                          }}
                          onChange={(e) => {
                            let optionTag = document.getElementsByName(
                              e.target.value
                            );
                            if (optionTag.length) {
                              setSearchJobInputs({
                                ...searchJobInputs,
                                job_title: optionTag[0].value,
                              });
                            }
                            setJobTitleText(
                              e.target.value ? e.target.value : ""
                            );
                          }}
                          type="text"
                          value={jobTitleText}
                          placeholder="Job Title "
                        /> */}
                      {/* <datalist onChange={(e) => {}} id="jobs-title-list"> */}
                      {/* {jobTitles.map((jobTitle, index) => (
                            <option
                              key={index}
                              name={jobTitle.label}
                              value={jobTitle.label}
                            >
                              {jobTitle.label}
                            </option>
                          ))} */}
                      {/* </datalist> */}
                      {/* </div> */}

                      <div className="dropdown-one">
                        {/* <Form.Select aria-label="Default select example">
                          <option> Job Title </option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </Form.Select> */}
                        <Form.Control
                          className="ps-5"
                          list="jobs-type-list"
                          onInput={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                          }}
                          onChange={(e) => {
                            // let optionTag = document.getElementsByName(
                            //   e.target.value
                            // );
                            // if (optionTag.length) {
                            //   setSearchJobInputs({
                            //     ...searchJobInputs,
                            //     job_type: optionTag[0].value,
                            //   });
                            // }
                            setJobTypeText(
                              e.target.value ? e.target.value : ""
                            );
                          }}
                          type="text"
                          value={jobTypeText}
                          placeholder="Job Type"
                        />
                        <datalist onChange={(e) => {}} id="jobs-type-list">
                          {jobTypes.map((jobType, index) => (
                            <option
                              key={index}
                              name={jobType.label}
                              value={jobType.label}
                            >
                              {jobType.label}
                            </option>
                          ))}
                        </datalist>
                      </div>
                      <div className="dropdown-two">
                        <Form.Control
                          className="ps-5"
                          list="location-list"
                          onInput={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                          }}
                          onChange={(e) => {
                            // let optionTag = document.getElementsByName(
                            //   e.target.value
                            // );
                            // if (optionTag.length) {
                            //   setSearchJobInputs({
                            //     ...searchJobInputs,
                            //     location: optionTag[0].value,
                            //   });
                            // }
                            setLocationText(
                              e.target.value ? e.target.value : ""
                            );
                          }}
                          type="text"
                          value={locationText}
                          placeholder="Location"
                        />
                        <datalist onChange={(e) => {}} id="location-list">
                          {locations.map((title, index) => (
                            <option
                              key={index}
                              name={title.label}
                              value={title.label}
                            >
                              {title.label}
                            </option>
                          ))}
                        </datalist>
                      </div>
                      <div className="dropdown-three">
                        <Form.Control
                          className="ps-5"
                          list="company-list"
                          onInput={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                          }}
                          onChange={(e) => {
                            // let optionTag = document.getElementsByName(
                            //   e.target.value
                            // );
                            // if (optionTag.length) {
                            //   setSearchJobInputs({
                            //     ...searchJobInputs,
                            //     company_title: optionTag[0].value,
                            //   });
                            // }
                            setCompanyText(
                              e.target.value ? e.target.value : ""
                            );
                          }}
                          type="text"
                          value={companyText}
                          placeholder="Company"
                        />
                        <datalist onChange={(e) => {}} id="company-list">
                          {companies.map((title, index) => (
                            <option
                              key={index}
                              id={`company-${title.value}`}
                              name={title.label}
                              value={title.label}
                            >
                              {title.label}
                            </option>
                          ))}
                        </datalist>
                      </div>
                      <div className="d-flex justify-content-around">
                        <div className="ms-3 d-flex align-items-center">
                          <i
                            style={{ color: "#ffffff" }}
                            onClick={(e) => {
                              handleJobFilter(e);
                            }}
                            className="cursor-pointer fa fa-search"
                            aria-hidden="true"
                          ></i>
                        </div>
                        <div className="ms-3 d-flex align-items-center">
                          <i
                            style={{ color: "#ffffff" }}
                            onClick={(e) => {
                              clearJobInputs(e);
                            }}
                            className="cursor-pointer fa fa-refresh"
                            aria-hidden="true"
                          ></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Row>
            </Container>
          </div>
        </div>
        <div className="cards-class-all">
          <Container>
            <Row>
              {!preLoading ? (
                jobs.length ? (
                  jobs.map((job_item, index) => {
                    return (
                      <JobListCard
                        keyIndex={index}
                        jobsLen={jobs.length}
                        isSave={true}
                        isApply={true}
                        key={index}
                        job_item={job_item}
                      />
                    );
                  })
                ) : (
                  <NodataFound />
                )
              ) : (
                <SiteLoader />
              )}
            </Row>
            {jobs.length < totalJobs ? (
              <div className="row">
                <div className="col-12 text-center mt-5">
                  {!loading ? (
                    <Button
                      onClick={() => {
                        handleLoadMore();
                      }}
                      variant="primary"
                      type="submit"
                    >
                      Load More
                    </Button>
                  ) : (
                    <LoadMoreLoader />
                  )}
                </div>
              </div>
            ) : (
              ""
            )}
          </Container>
        </div>
      </div>
      {/* {modalShowJobGigs && (
        <JobGigsModal
          show={modalShowJobGigs}
          onHide={() => setModalJobGigsModal(false)}
        />
      )} */}
    </>
  );
}

export default Bookings;
