import React, { useState } from 'react'
import ClockRotate from "../../assets/media/icons/clock-rotate.svg";
import WatchedLater from "../../assets/media/icons/watched-later.svg";
import ApiTypes from '../../networking/APItypes';
import { useDispatch, useSelector } from 'react-redux';
import APICall from '../../networking/AxiousServices';
import { useNavigate } from 'react-router-dom';

export default function IsWatchLater({ WatchComponent, UnWatchComponent, module_id, module_type, is_watch_later }) {
    const [isWatchLater, setIsWatchLater] = useState(is_watch_later == 1 ? true : false);
    const [isLoading, setIsLoading] = useState(false);
    const isLogIn = useSelector((state) => state.data.auth_token);
    const navigate = useNavigate()
    const SubscriptionFeature = useSelector((state) => state.data.logged_user.subscription_feature);
    const dispatch = useDispatch()

    const handleWatchLater = async (is_watch_later_value) => {
        if (isLogIn) {
            if (SubscriptionFeature.video.watch_later) {
                if (!isLoading) {
                    let data = {
                        module_id: module_id,
                        module_type_term: module_type,
                        is_watch_later: is_watch_later_value,
                    }

                    setIsLoading(true)
                    let res = await APICall({
                        url: ApiTypes.addOrRemoveWatchLater,
                        data: data
                    })
                    if (res.status === 1) {
                        setIsLoading(false)
                        setIsWatchLater(parseInt(res.data.is_watch_later) === 1 ? true : false)
                    }
                }
            } else {
                dispatch({ type: "SUBSCRIPTION_MODAL", payload: true });
            }
        }else{
            navigate('/login')
        }

    }
    return isWatchLater ?
        (<div onClick={() => {
            handleWatchLater(0)
        }}>
            <WatchComponent />
        </div>
        )
        : (<div onClick={() => {
            handleWatchLater(1)
        }}>
            <UnWatchComponent />
        </div>
        )
}
