import React from 'react'
import { useState } from 'react';
import Button from "react-bootstrap/Button";
import ApiTypes from '../../networking/APItypes';
import APICall from '../../networking/AxiousServices';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';

export default function IsFollow({ is_follow, artist_id, getResBack }) {
    const SubscriptionFeature = useSelector((state) => state.data.logged_user.subscription_feature);
    const isLogIn = useSelector((state) => state.data.auth_token);
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [isFollow, setIsFollow] = useState(is_follow === 1 ? true : false);
    const [isLoading, setIsLoading] = useState(false);
    const handleFollow = async (is_follow_item) => {
        if (isLogIn) {
            if (SubscriptionFeature.artist.follow) {
                if (!isLoading) {
                    setIsLoading(true)
                    let res = await APICall({
                        url: ApiTypes.followUnFollowArtist,
                        data: {
                            artist_id: artist_id,
                            is_follow: is_follow_item
                        }
                    })
                    if (res.status === 1) {
                        setIsLoading(false)
                        setIsFollow(parseInt(res.data.is_follow) === 1 ? true : false)
                        getResBack(res.data.is_follow)
                    }
                }
            } else {
                dispatch({ type: "SUBSCRIPTION_MODAL", payload: true });
            }
        } else {
            navigate('/login')
        }

    }
    return (isFollow ?
        <Button onClick={() => { handleFollow(0) }} className="transparent-btn me-3">Unfollow</Button>
        :
        <Button onClick={() => { handleFollow(1) }} className="transparent-btn me-3">Follow</Button>
    )
}
