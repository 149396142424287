import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import APICall from "../../networking/AxiousServices";
import ApiTypes from "../../networking/APItypes";
import SiteLoader from "../../Component/Loaders/SiteLoader";
import { Row, Button } from "react-bootstrap";
import ProgressBar from "react-bootstrap/ProgressBar";
import Table from "react-bootstrap/Table";
import Badge from "react-bootstrap/Badge";
import { Form, NavDropdown } from "react-bootstrap";
import NodataFound from "../Errors/NodataFound";
import ProfileMenuHeader from "../../Component/ProfileMenuHeader/ProfileMenuHeader";
import { Enums } from "../../constants/Enums";
import { commonHelper } from "../../common/commonHelper";
import Invoice from "./Invoice";
import LoadMoreLoader from "../../Component/Loaders/LoadMoreLoader";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function Billing() {


  document.title = process.env.REACT_APP_NAME + " | Billing";
  const [billingDetails, setBillingDetails] = useState({});
  const [cancelLoading, setCancelLoading] = useState(false);
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate()
  const UserData = useSelector((state) => state.data);

  const fetchBillingDetails = async () => {
    let res = await APICall({
      url: ApiTypes.fetchBillingDetails,

    })
    if (res.status === 1) {
      setLoading(false)
   
      let trial_remaining_days = commonHelper.getDaysDifferenceFromCurrent(res.data.subscription.trial_end_date)

      let sub_remaining_days = commonHelper.getDaysDifference(res.data.subscription.start_date , res.data.subscription.end_date)
      
      let total_days = commonHelper.getDaysDifference(res.data.subscription.start_date, res.data.subscription.end_date)
      let remaining_days = commonHelper.getDaysDifferenceFromCurrent(res.data.subscription.end_date)

      if(trial_remaining_days <= 0){
        trial_remaining_days = 0
      }else{
        if(res.data.subscription.cancelled_on){
          remaining_days = commonHelper.getDaysDifferenceFromCurrent(res.data.subscription.trial_end_date)
          total_days = res.data.subscription.total_trial_days
        }else{
          total_days = total_days + res.data.subscription.total_trial_days
        }

      }
      
      let passed_days = parseInt(total_days) - parseInt(remaining_days)
      let completed_percentage = ((passed_days * 100) / total_days).toFixed(2);
      setBillingDetails({
        ...res.data,
        sub_remaining_days:sub_remaining_days,
        trial_remaining_days :trial_remaining_days,
        remaining_days: remaining_days,
        completed_percentage: completed_percentage
      })

    } else {
      if (res.data.status === 0) {
        setLoading(false)
      }
    }
  }
  const handleCancelToken = async (subscription_id) => {
    setCancelLoading(true);
    let res = await APICall({
      url: ApiTypes.cancelSubscription,
      data: { user_subscription_id: subscription_id }
    });
    if (res.status === 1) {


      dispatch({ type: "SUBSCRIPTION_MODAL", payload: false });
      dispatch({
        type: 'LOGGED_USER', payload: {
          ...UserData.logged_user,
          subscription: { ...res.data.subscription },
          subscription_feature: { ...res.data.subscription_feature }

        }
      })
      commonHelper.setItem("user", JSON.stringify({
        ...UserData.logged_user,
        subscription: { ...res.data.subscription },
        subscription_feature: { ...res.data.subscription_feature }
      }));
      // navigate('/')
      setLoading(true)
      fetchBillingDetails()
      setCancelLoading(false);
    } else {
      if (res.data.status === 0) {
        setCancelLoading(false);
      }
    }
  }

  useEffect(() => {

    fetchBillingDetails();
  }, []);


  return (!loading ? Object.keys(billingDetails).length ?
    <>
      <div className="main-content">
        <ProfileMenuHeader />
        <div className="Billing-page-main  ">
          <Container>
            <div className="title-class-add-new">
              <div className="title-add">Billing </div>
              <div className="class-second-title">
                Manage your billing and payment details.
              </div>
            </div>
            <Row>
              <div className="start col-xxl-6 col-xl-6 col-md-12">
                <Card className="text-center tabspace">
                  <Card.Body>
                    <div className="plan-month d-flex justify-content-between">
                      <Card.Title>
                        {billingDetails.subscription.subscription_name}
                        <Badge bg="light" text="dark">
                          {billingDetails.subscription.subscription_type === Enums.subscription_type.paid || billingDetails.subscription.subscription_type === Enums.subscription_type.trial ? Enums.subscription_interval[billingDetails.subscription.interval_type] : ""}
                        </Badge>
                      </Card.Title>
                      <Card.Text>
                        <div className="class-fourteen">
                          $<span className="gray">{billingDetails.subscription.price ? parseFloat(billingDetails.subscription.price).toFixed(2) : 0}</span>
                          {billingDetails.subscription.subscription_type === Enums.subscription_type.paid && <span className="month"> per {billingDetails.subscription.interval_type}</span>}
                        </div>
                      </Card.Text>
                    </div>
                    <Card.Subtitle className="my-2 text-muted title-add-flexible">
                      {!cancelLoading ? billingDetails.subscription.subscription_type === Enums.subscription_type.paid ?
                        parseInt(billingDetails.subscription.is_auto_payment) === 1 ?
                          <Button onClick={(e) => { handleCancelToken(billingDetails.subscription.user_subscription_id) }} variant="outline-primary w-50" type="button">
                            Cancel Subscription
                          </Button>
                          : <Button disabled={true} variant="outline-primary w-50" type="button">
                            Cancelled
                          </Button>

                        : <Button variant="primary w-50" type="button">
                          Default Activated
                        </Button> : <LoadMoreLoader classButton={"w-50"} />}
                    </Card.Subtitle>
                    {billingDetails.subscription.subscription_type === Enums.subscription_type.paid || billingDetails.subscription.subscription_type === Enums.subscription_type.trial ?
                      <div className="progressbar-class ">
                        <Card.Text className="dayss-renewal">
                        {parseInt(billingDetails.subscription.is_auto_payment) === 1 ?
                         billingDetails.trial_remaining_days ?   `${billingDetails.trial_remaining_days} trial days and ${billingDetails.sub_remaining_days} days remaining before auto-renewal`
                         : `${billingDetails.remaining_days} days remaining`
                        
                        : billingDetails.trial_remaining_days ?  `${billingDetails.trial_remaining_days} trial days remaining`: `${billingDetails.remaining_days} days remaining`}
                        {/* {parseInt(billingDetails.subscription.is_auto_payment) === 1 ?

                          billingDetails.trial_remaining_days ? 
                          `${billingDetails.trial_remaining_days} trial days and after ${billingDetails.sub_remaining_days} days before auto-renewal`
                          :`${billingDetails.remaining_days} days remaining`


                          :  billingDetails.trial_remaining_days ?  `${billingDetails.trial_remaining_days} trial days remaining` :   `${billingDetails.sub_remaining_days} days before auto-renewal `} */}
                        </Card.Text>
                        <ProgressBar now={billingDetails.completed_percentage ? billingDetails.completed_percentage : 0} label={`${billingDetails.completed_percentage}%`} visuallyHidden />
                      </div> : ""}
                  </Card.Body>
                  {/* <div className="switch-and-billing">
                    <Card.Footer>
                      <p className="text-muted m-0">
                        Switch to annual billing & save 20%
                        <i className="fa-solid fa-arrow-up-right ms-2"></i>
                      </p>
                    </Card.Footer>
                  </div> */}
                </Card>
              </div>
              {Object.keys(billingDetails.payment_method).length ? <div className="start col-xxl-6 col-xl-6 col-md-12">
                <Card className="text-center h-100">
                  <Card.Body>
                    <div className="payment-and-change">
                      <Card.Title>Payment method</Card.Title>
                      {/* <Card.Text>Change how you pay for your plan.</Card.Text> */}
                    </div>
                    <div className="second-card-add">
                      <div className="class-visa-and-text d-flex">
                        <div className="img-viza">
                          <Card.Img variant="top rounded-0" src={billingDetails.payment_method.image_url} />
                        </div>
                        <div className="main-two-class d-flex justify-content-between">
                          <div className="text-and-content-mix text-start ms-3">
                            <Card.Text className="m-0 visa-class mb-1">
                              Visa ending in {billingDetails.payment_method.last4}
                            </Card.Text>
                            <Card.Subtitle className="mb-2 text-muted two-mix-class">
                              Expiry {billingDetails.payment_method.exp_month}/{billingDetails.payment_method.exp_year}
                            </Card.Subtitle>
                            {/* <div className="mail-text d-flex">
                            <Card.Subtitle className="mb-2 text-muted two-mix-class">
                              <i className="fa-light fa-envelope me-2"></i>
                              MarcLindfors@gmail.com
                            </Card.Subtitle>
                          </div> */}
                          </div>
                        </div>
                      </div>
                      {/* <Button
                      variant="primary"
                      type="button"
                      className="transparent-btn btn btn-primary  billing-button"
                    >
                      Edit
                    </Button> */}
                    </div>
                  </Card.Body>
                </Card>
              </div> : ""}
            </Row>

            <div className="title-class-add-new-two d-flex justify-content-between mt-4">
              <div className="title-add-invoice">
                Invoicing
                {/* <div className="class-second-title-two">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                </div> */}
              </div>
              {/* <div className="download">
              <Button className="transparent-btn btn btn-primary download-all-class">
                <i className="fa-regular fa-cloud-arrow-down me-2"></i>Download all
              </Button>
            </div> */}
            </div>
            <Invoice />
          </Container>
        </div>
      </div>
    </> : <NodataFound /> : <SiteLoader />);
}

export default Billing;
